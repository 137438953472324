import { extend } from 'ol/extent'
import { ViewDataLoadingProps } from './ViewDataLoading'

export const LocateSvgCode = `
<?xml version="1.0"?>
<svg width="25px" height="36px" viewBox="0 0 25 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Comon" transform="translate(-714.000000, -758.000000)">
            <g id="定位" transform="translate(714.000000, 758.000000)">
                <path d="M11.8619441,35.9964553 C11.884444,35.9942054 12.2365673,36.0054553 12.2376923,35.9964553 C13.6709356,29.5918605 17.8739158,23.5022642 19.8089067,21.5638984 C22.3671446,19.0011604 24.1288863,15.8286754 24.1288863,12.0644431 C24.1277613,5.39997455 18.7255368,0 12.0633182,0 C5.39997455,0 -2.71781315e-15,5.40109956 -2.71781315e-15,12.0644431 C-2.71781315e-15,15.920925 1.67624209,18.9764106 4.34472952,21.5638984 C6.26847044,23.4302646 11.1273226,30.5008562 11.8619441,35.9964553 Z" id="Path" fill="#00FDF9" fill-rule="nonzero"></path>
                <circle id="Oval" fill="#00AFAC" cx="12.4149603" cy="10.841213" r="5"></circle>
            </g>
        </g>
    </g>
</svg>
`
export const MapOptions = {
  center: [114.19499, 22.350884],
  extent: [
    12636251.250551049, 2508924.875931976, 12795663.35074925, 2607463.134547468
  ],
  zoom: 11
}

export const PrimaryMapPointKey = 'treeId'

export const PointAnimationDuration = 300

export const DefaultLoadingProps = {
  status: false,
  size: 'small',
  placement: 'left-top',
  treeTotalCount: 0,
  treeLoadedCount: 0
}


export const LowBatteryThreshHold = 3200
export const MediumBatteryThreshHold = 3500