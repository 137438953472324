import React, { forwardRef } from 'react'
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps
} from '@mui/material'

interface TipIconBtnProps extends IconButtonProps {
  tip: React.ReactNode
  placement?: TooltipProps['placement']
}

const TipIconBtn = forwardRef<typeof IconButton, TipIconBtnProps>(
  ({ tip, placement = 'top', children, ...props }, ref) => {
    return (
      <Tooltip title={tip} placement={placement}>
        <span>
          <IconButton sx={{ color: 'inherit' }} {...props}>
            {children}
          </IconButton>
        </span>
      </Tooltip>
    )
  }
)

TipIconBtn.displayName = 'TipIconBtn'
export default TipIconBtn
