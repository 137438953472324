import {
  TextField,
  Stack,
  Paper,
  IconButton,
  MenuProps,
  Typography,
  MenuItem,
  Tooltip
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import NewUser from './actions/NewUser'
import EditUser from './actions/EditUser'
import { deleteUser, getUsers } from '@/service/manage/users'
import {
  RolesEnum,
  User,
  OrganizationOptions,
  GroupOptions
} from './users.types'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { Icons } from '@/assets/icons'
import Password from './actions/Password'
import TipIconBtn from '@/components/TipIconBtn'
import { RoleNames, RoleNamesColors, RoleOpitons } from './users.const'
import ActionMenu from './actions/ActionMenu'
import { useNavigate } from 'react-router-dom'
import AlertList from '@/components/AlertList'
import { getOrganizations, getGroups } from '@/service/manage/users'
import Confirm from '@/components/Confirm'
import { Layout, Body } from '@/components/page'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import CopyButton from '@/components/CopyButton'
import RootLayout from '@/components/page/RootLayout'
import OrgGroupSelector from '@/components/OrgGroupSelector'
import TextFieldC from '@/components/TextFieldC'
import { grey } from '@mui/material/colors'
import { ManageAlertListStyle } from '../manage.const'

export type UserPageFilters = {
  username?: string
  organizationId?: string
  groupId?: string
  role?: RolesEnum
}
export type UserPageListParams = PageListParams<UserPageFilters>

const Users = () => {
  const navigate = useNavigate()

  const [organizations, setOrganizations] = useState<OrganizationOptions[]>([])
  const [groups, setGroups] = useState<GroupOptions[]>([])

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  } = usePageList<UserPageFilters, User>('users', getUsers)

  const [acitonParams, setActionParams] = useState<{
    type: 'menu' | 'new' | 'change-group'
    anchor?: React.MouseEvent<HTMLButtonElement, MouseEvent>['currentTarget']
    data?: User
  }>(null)

  const [actionItemParams, setActionItemParams] = useState<{
    type: 'edit' | 'delete' | 'password'
    anchor?: MenuProps['anchorEl']
    user?: User
  }>(null)

  const updateUser = (user) => {
    const index = listData.findIndex((u) => u.id === user.id)
    index > -1 && updateDataByIdx(index, user)
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/map', { state: { navTreeId: treeId } })
  }

  const delUser = async (userId: string) => {
    const resp = await deleteUser(userId)
    if (resp?.code === 200) {
      refresh()
      setActionItemParams(null)
    }
  }

  useEffect(() => {
    if (open) {
      getOrganizations().then((resp) => {
        if (resp?.code === 200) {
          setOrganizations(resp.data)
        }
      })
    }
  }, [open])

  useEffect(() => {
    if (open && filters?.organizationId) {
      getGroups(filters?.organizationId).then((resp) => {
        if (resp?.code === 200) {
          setGroups(resp.data)
        }
      })
    }
  }, [open, filters?.organizationId])

  return (
    <RootLayout>
      <Layout>
        <Body>
          <Stack sx={{ py: 1, height: 1 }} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <TextFieldC
                  size="small"
                  value={filters?.username}
                  placeholder="User name"
                  onChange={(evt) =>
                    changeFilters({ username: evt.target.value })
                  }
                />
                <OrgGroupSelector
                  size="small"
                  organizationId={filters?.organizationId}
                  groupId={filters?.groupId}
                  onOrgChange={(organizationId) =>
                    changeFilters({ organizationId })
                  }
                  onGroupChange={(groupId) => changeFilters({ groupId })}
                />

                <TextFieldC
                  select
                  size="small"
                  sx={{ width: 200 }}
                  value={filters?.role || ''}
                  placeholder="Role"
                  SelectProps={{ placeholder: 'Role', displayEmpty: true }}
                  onChange={(evt) =>
                    changeFilters({ role: evt.target.value as RolesEnum })
                  }>
                  <MenuItem key={'any-role'} value={''}>
                    Any Role
                  </MenuItem>
                  {RoleOpitons.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextFieldC>
              </Stack>
              <Stack direction="row" alignItems="center">
                <TipIconBtn
                  tip="Refresh"
                  onClick={() => setActionParams({ type: 'new' })}>
                  <Icons.Add fontSize="small" />
                </TipIconBtn>
                <TipIconBtn tip="Refresh" onClick={() => refresh()}>
                  <Icons.Refresh fontSize="small" />
                </TipIconBtn>
              </Stack>
            </Stack>

            <Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <DataGridC
                loading={loading}
                getRowId={(row) => row.id}
                rows={listData}
                rowCount={totalCount}
                pageSizeOptions={[15, 50]}
                paginationModel={{
                  page: page > 0 ? page - 1 : 0,
                  pageSize: limit
                }}
                onPaginationModelChange={(params) => {
                  const isPageSizeChanged = limit !== params.pageSize
                  changePage({
                    page: isPageSizeChanged ? 1 : params.page + 1,
                    limit: params.pageSize
                  })
                }}
                columns={getColums({
                  onEdit: (data) =>
                    setActionItemParams({ type: 'edit', user: data }),
                  onPassword: (anchor, data) =>
                    setActionItemParams({
                      type: 'password',
                      anchor,
                      user: data
                    }),
                  onAction: (
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    data: User
                  ) =>
                    setActionParams({
                      type: 'menu',
                      anchor: event.currentTarget,
                      data
                    })
                })}
              />
            </Paper>
          </Stack>

          <Confirm
            open={
              actionItemParams?.type === 'delete' &&
              Boolean(actionItemParams?.anchor)
            }
            anchorEl={actionItemParams?.anchor}
            icon={<Icons.Delete fontSize="large" />}
            title={'Delete User'}
            message={
              <Typography component={'span'}>
                Are you sure to delete
                <Typography component={'span'} fontWeight="bold">
                  {' '}
                  {actionItemParams?.user?.username}
                </Typography>
                <Typography component={'span'}>
                  {' '}
                  of {actionItemParams?.user?.organizationDetail?.name}
                </Typography>
                ?
              </Typography>
            }
            onClose={() => setActionItemParams(null)}
            onConfirm={() => delUser(actionItemParams?.user?.id)}
          />

          <ActionMenu
            open={
              acitonParams?.type === 'menu' && Boolean(acitonParams?.anchor)
            }
            anchorEl={acitonParams?.anchor}
            user={acitonParams?.data}
            onClose={() => setActionParams(null)}
            onDelete={(anchor, data) =>
              setActionItemParams({
                type: 'delete',
                anchor: anchor,
                user: data
              })
            }
          />

          <NewUser
            open={acitonParams?.type === 'new'}
            onSuccess={() => {
              refresh()
              setActionParams(null)
            }}
            onClose={() => setActionParams(null)}
          />

          <EditUser
            open={actionItemParams?.type === 'edit'}
            user={actionItemParams?.user}
            onClose={() => setActionItemParams(null)}
            onSuccess={(user) => {
              updateUser(user)
              setActionItemParams(null)
            }}
          />

          <Password
            open={
              actionItemParams?.type === 'password' &&
              Boolean(actionItemParams?.anchor)
            }
            anchorEl={actionItemParams?.anchor}
            user={{
              id: actionItemParams?.user?.id,
              __v: actionItemParams?.user?.__v
            }}
            onClose={() => setActionItemParams(null)}
            onSuccess={() => {
              setActionItemParams(null)
            }}
          />
          <AlertList
            sx={ManageAlertListStyle}
            onLocate={(treeId) => showTreeOnMap(treeId)}
          />
        </Body>
      </Layout>
    </RootLayout>
  )
}

export default Users

const getColums = ({
  onEdit,
  onPassword,
  onAction
}: {
  onEdit: (user: User) => void
  onPassword: (anchor: MenuProps['anchorEl'], user: User) => void
  onAction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    user: User
  ) => void
}): GridColDef<User>[] => {
  return [
    {
      field: 'username',
      headerName: 'User name',
      width: 280,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const username = params.value
        const isDefault = params?.row?.isDefault
        const isOrgDeletd = params?.row?.organizationDetail?.deleted
        const orgName = params?.row?.organizationDetail?.name
        const disabled = params?.row?.disabled
        return (
          <Stack alignItems={'center'} direction={'row'} spacing={0.5}>
            <Tooltip
              title={
                (isDefault || isOrgDeletd) && (
                  <>
                    {isDefault && (
                      <Typography
                        variant="body2"
                        {...(isOrgDeletd && { paragraph: true })}>
                        Default user of <strong>{orgName}</strong>
                      </Typography>
                    )}

                    {isOrgDeletd && (
                      <Typography paragraph variant="body2" sx={{ mb: 0 }}>
                        User's organization has been deleted
                      </Typography>
                    )}
                  </>
                )
              }
              placement="top">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icons.AccountCircle
                  sx={{
                    opacity: isDefault ? 1 : 0.35,
                    fontSize: 'small',
                    color: isDefault
                      ? isOrgDeletd
                        ? 'warning.main'
                        : 'primary.main'
                      : 'default'
                  }}
                />

                <Typography
                  fontSize="inherit"
                  color={
                    disabled
                      ? grey[400]
                      : isOrgDeletd
                      ? 'warning.main'
                      : 'primary.main'
                  }>
                  {username}
                </Typography>
              </Stack>
            </Tooltip>

            <CopyButton size="small" title="Username" value={username} />
          </Stack>
        )
      }
    },
    {
      field: 'nickname',
      headerName: 'Nick name',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'additionalEmails',
      headerName: 'Additional Emails',
      width: 320,
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'organizationDetail',
      headerName: 'Organization',
      width: 280,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isDeleted = params?.row?.organizationDetail?.deleted
        return (
          <Tooltip
            title={isDeleted && 'This organization has been deleted'}
            placement="top">
            <Typography
              variant="body2"
              color={params.value?.disabled ? 'warning' : 'default'}
              sx={{
                ...(isDeleted && { textDecoration: 'line-through' })
              }}>
              {params.value?.name}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'groupDetail',
      headerName: 'Group',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value?.name
      }
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 160,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography color={RoleNamesColors[params.value]} variant="body2">
            {RoleNames[params.value]}
          </Typography>
        )
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 160,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) =>
        moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 120,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton
            onClick={(event) => onEdit(params.row)}
            size="small"
            title="Edit">
            <Icons.ArticleOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={(event) => onPassword(event.currentTarget, params.row)}
            size="small"
            title="Edit">
            <Icons.VpnKeyOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={(event) => onAction(event, params.row)}
            size="small">
            <Icons.Menu fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  ]
}
