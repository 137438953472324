import React, { forwardRef } from 'react'
import { Box, Grid, GridProps, Stack, SxProps, Typography } from '@mui/material'
import biteSize from 'byte-size'
import { grey } from '@mui/material/colors'

interface FileInfoProps extends GridProps {
  file?: File
}

const FileInfo = forwardRef<typeof Grid, FileInfoProps>(
  ({ file, ...props }, ref) => {
    let fileSizeText = ''

    if (file) {
      const fileSize = biteSize(file?.size)
      fileSizeText = `${fileSize.value} ${fileSize.unit}`
    }

    return (
      <Grid container columnGap={2} wrap="nowrap" sx={{ width: 1 }}>
        <Grid item xs={6} sx={ItemBoxStyle}>
          <Item name="File" value={file?.name} />
        </Grid>
        <Grid item xs={6} sx={ItemBoxStyle}>
          <Item name="Size" value={fileSizeText} />
        </Grid>
      </Grid>
    )
  }
)

FileInfo.displayName = 'FileInfo'
export default FileInfo

const ItemBoxStyle: SxProps = {
  borderWidth: 1,
  borderColor: grey[200],
  borderStyle: 'solid',
  borderRadius: 1,
  bgcolor: grey[50]
}

const Item = (params: { name: string; value: string | number }) => {
  const { name, value } = params
  return (
    <Stack direction="row" sx={{ pl: 2 }} spacing={2} alignItems="center">
      <Typography variant="caption" color={grey[500]}>
        {name}
      </Typography>
      <Typography variant="caption" color={value ? 'primary.main' : grey[700]}>
        {value ? value : 'Please selet a file'}
      </Typography>
    </Stack>
  )
}
