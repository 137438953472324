import React from 'react'
import { Box, Divider, Stack, SxProps } from '@mui/material'

import { grey } from '@mui/material/colors'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'

const ZoomCtrlItemStyle: SxProps = {
  width: 30,
  height: 30,
  flexGrow: 1,
  color: grey[500],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  bgcolor: '#fff',
  '&:hover': {
    color: 'white',
    backgroundColor: 'primary.main'
  }
}

const ZoomCtrl = ({ onFullView, onZoomIn, onZoomOut }) => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: 999,
        top: 15,
        right: 15
      }}>
      <Box sx={{ ...ZoomCtrlItemStyle, mb: 1, borderRadius: '4px' }}>
        <TipIconBtn
          tip="Full map view"
          placement="left"
          onClick={() => onFullView()}>
          <Icons.Fullscreen fontSize="small" />
        </TipIconBtn>
      </Box>
      <Divider />

      <Box sx={{ ...ZoomCtrlItemStyle, borderRadius: '4px 4px 0 0 ' }}>
        <TipIconBtn tip="Zoom In" placement="left" onClick={() => onZoomIn()}>
          <Icons.Add fontSize="small" />
        </TipIconBtn>
      </Box>
      <Divider />
      <Box sx={{ ...ZoomCtrlItemStyle, borderRadius: '0 0 4px 4px' }}>
        <TipIconBtn tip="Zoom Out" placement="left" onClick={() => onZoomOut()}>
          <Icons.Remove fontSize="small" />
        </TipIconBtn>
      </Box>
    </Stack>
  )
}

export default ZoomCtrl
