import React, { useEffect, useState } from 'react'

import {
  alpha,
  Box,
  Stack,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
  Tooltip
} from '@mui/material'
import TreeGraph from '@/assets/images/home/trees-graphic.svg'
import { getDecimal } from '@/utils/tool'
import { getCarbonUptakes } from '@/service/home'
import { CardStyle } from './home.const'
import { Icons } from '@/assets/icons'
import GriImage from '@/assets/images/home/gri-standard.png'
import { grey } from '@mui/material/colors'

const HkexRef =
  'https://www.hkex.com.hk/-/media/hkex-market/listing/rules-and-guidance/environmental-social-and-governance/exchanges-guidance-materials-on-esg/app2_envirokpis'

export type CarbonType = {
  day: number
  month: number
  year: number
}

const Carbon = () => {
  const them = useTheme()
  const smallscreen = useMediaQuery(them.breakpoints.down('lg'))
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)

  const [carbonUptakes, setCarbonUptakes] = useState<CarbonType | null>(null)

  useEffect(() => {
    getCarbonUptakes().then((resp) => {
      if (resp?.code === 200) {
        setCarbonUptakes(resp.data)
      }
    })
  }, [])
  return (
    <Stack
      spacing={1}
      sx={{
        ...CardStyle,
        p: 2,
        ...(smallscreen && { display: 'none' }),
        '&:after': {
          zIndex: 0,
          content: '" "',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          pointerEvents: 'none',
          backgroundImage: `url("${TreeGraph}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: ' top 5px right 15px',
          backgroundSize: 'auto 32px'
        }
      }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant={'subtitle2'} sx={{ verticalAlign: 'baseline' }}>
          CARBON UPTAKE
        </Typography>
        <Tooltip
          title={'Click to view the calculation description'}
          placement="top">
          <Icons.HelpOutline
            fontSize="inherit"
            sx={{
              cursor: 'pointer',
              color: grey[600],
              '& :hover': {
                color: grey[800]
              }
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
        </Tooltip>
        <Popover
          id={'carbon-uptake-info'}
          // sx={{ pointerEvents: 'none' }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Stack sx={{ width: 640, p: 2 }}>
            <Typography paragraph variant="body1">
              A tree that meets the calculation criteria has a carbon absorption
              capacity of 23 kg/year. The calculation criteria define a
              qualifying tree as one with a height of at least 5 meters.
            </Typography>
            <Link sx={{ my: 1 }} href={HkexRef} rel="noopener" target="_blank">
              <img height="260px" src={GriImage} alt={'co2 removal'} />
            </Link>
            <Typography variant="caption" sx={{ opacity: 0.75 }}>
              Refer to standardard from{' '}
              <Link href={HkexRef} rel="noopener" target="_blank">
                HKEX Green Achievement Guidance
              </Link>{' '}
              on page 7.
            </Typography>
          </Stack>
        </Popover>
      </Stack>
      <Stack spacing={1} sx={{ pl: 1, pr: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Today</Typography>
          <Typography variant="body2">
            {getDecimal(carbonUptakes?.day, 2)} KG
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">This month</Typography>
          <Typography variant="body2">
            {getDecimal(carbonUptakes?.month, 2)} KG
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">This year</Typography>
          <Typography variant="body2">
            {getDecimal(carbonUptakes?.year, 2)} KG
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Carbon
