import React, { forwardRef, useState } from 'react'
import { Stack, StackProps, Typography, Button } from '@mui/material'
import { Tree } from '@/routes/manage/data/trees/trees.types'
import { grey } from '@mui/material/colors'
import { AssignmentType } from './AssignTreeList'
import {
  organizationAddTree,
  organizationRemoveTree
} from '@/service/manage/organizations'
import { LoadingButton } from '@mui/lab'

interface TreeItemProps extends StackProps {
  data: Tree
  orgId: string
  disabled?: boolean
  type: AssignmentType
  onSuccess: (tree: Tree) => void
}

const ButtonText = {
  available: 'Add',
  existence: 'Remove'
}

const AssignTreeItem = forwardRef<typeof Stack, TreeItemProps>(
  ({ data, disabled, type, orgId, onSuccess, ...props }, ref) => {
    const [processing, setProcessing] = useState(false)
    const onAction = async () => {
      setProcessing(true)
      const resp = await (type === 'available'
        ? organizationAddTree({
            treeId: data.treeId,
            organizationId: orgId
          })
        : organizationRemoveTree({
            treeId: data.treeId,
            organizationId: orgId
          }))

      if (resp?.code === 200) {
        onSuccess(data)
        setProcessing(false)
      }
    }
    return (
      <Stack
        component={'li'}
        sx={{
          width: 1,
          px: 2,
          py: 1,
          borderBottom: '1px solid #eeeeee',
          '&:hover': { backgroundColor: grey[100] }
        }}
        direction="row"
        justifyContent={'space-between'}
        alignItems="center">
        <Stack>
          <Typography variant="subtitle2">{data.treeId}</Typography>
          <Typography variant="body2">{data.sensorId || '-'}</Typography>
        </Stack>
        <LoadingButton size='small' loading={processing} onClick={() => onAction()}>
          {ButtonText[type]}
        </LoadingButton>
      </Stack>
    )
  }
)

AssignTreeItem.displayName = 'AssignTreeItem'
export default AssignTreeItem
