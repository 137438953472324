import React, { forwardRef } from 'react'
import {
  Box,
  FormLabel,
  Typography,
  FormControlProps,
  FormControl,
  MenuItem,
  Select,
  Stack
} from '@mui/material'
import { FormLabelWidth } from './form.cont'
import { FormOption } from './form.typs'
import { grey } from '@mui/material/colors'

interface TextInputProps extends FormControlProps {
  label: string
  value: string
  onValChange?: (string) => void
  tip?: string | React.ReactNode
  labelWidth?: number
  options: FormOption[]
}

const FormSelect = forwardRef<typeof Box, TextInputProps>(
  (
    {
      label,
      labelWidth,
      value,
      tip,
      error,
      onValChange,
      options,
      required,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        error={error}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
        <FormLabel
          sx={{ width: labelWidth ?? FormLabelWidth, mr: 2, flexShrink: 0 }}>
          {label}
        </FormLabel>

        <Stack sx={{ flexGrow: 1 }}>
          {options && options.length > 0 && (
            <Select
              error={error}
              size="small"
              displayEmpty
              value={value}
              MenuProps={{ sx: { maxHeight: 300 } }}
              onChange={(evt) => onValChange(evt.target.value)}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}

          {tip && (
            <Typography
              component="div"
              variant="caption"
              color={error ? 'error' : 'default'}
              ml={1}
              mr={1}>
              {tip}
            </Typography>
          )}
        </Stack>
      </FormControl>
    )
  }
)

FormSelect.displayName = 'FormSelect'
export default FormSelect
