import React, { forwardRef } from 'react'
import { Paper, PaperProps, Stack, Typography } from '@mui/material'
import { Icons } from '@/assets/icons'

export interface ViewDataLoadingProps extends PaperProps {
  small?: boolean
  placemment?: 'left-top' | 'right-top' | 'right-bottom'
  treeTotalCount?: number
  treeLoadedCount?: number
}

const PlacePosition: Record<
  ViewDataLoadingProps['placemment'],
  { left?: number; right?: number; top?: number; bottom?: number }
> = {
  'left-top': { left: 15, top: 15 },
  'right-top': { right: 15, top: 15 },
  'right-bottom': { right: 15, bottom: 20 }
}

const ViewDataLoading = forwardRef<typeof Paper, ViewDataLoadingProps>(
  (
    {
      small = false,
      placemment = 'left-top',
      treeTotalCount = 0,
      treeLoadedCount = 0,
      ...props
    },
    ref
  ) => {
    return (
      <Paper
        sx={{
          position: 'absolute',
          p: 1,
          height: small ? 30 : 40,
          zIndex: 999,
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...PlacePosition[placemment]
        }}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icons.Refresh
            sx={{
              color: 'primary.main',
              animation: 'spin 0.6s linear infinite'
            }}
            {...(small && { fontSize: 'small' })}
          />
          <Typography variant="body2">
            {treeLoadedCount}/{treeTotalCount}
          </Typography>
        </Stack>
      </Paper>
    )
  }
)

ViewDataLoading.displayName = 'ViewDataLoading'
export default ViewDataLoading
