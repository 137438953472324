import React, { forwardRef } from 'react'
import { Divider, Paper, PaperProps, Stack, Box } from '@mui/material'
import AccounItem from './AccounItem'
import PasswordItem from './PasswordItem'
import moment from 'moment'
import { Regions } from '@/configs/config.regions'
import { RoleNames } from '@/routes/manage/user/users.const'
import UserAvatar from './UserAvatar'
import { useAppSelector } from '@/store'

interface AccountProps extends PaperProps {}

const Account = forwardRef<typeof Paper, AccountProps>(({ ...props }, ref) => {
  const user = useAppSelector((state) => state.users.user)

  return (
    <Paper sx={{ height: 1, p: 4 }}>
      <Stack sx={{ width: 420 }} spacing={2}>
        <UserAvatar />
        <Stack spacing={1} sx={{ px: 2, py: 4, borderRadius: 2 }}>
          <AccounItem name="User name" value={user?.username} />
          <AccounItem name="Nick name" value={user?.nickname} />
          <PasswordItem />
          <Divider />

          <AccounItem
            name="Organization"
            value={user.organization?.fullname || ''}
          />
          <AccounItem name="Role" value={RoleNames[user.role]} />
          <AccounItem name="Group" value={user?.userGroup?.name || ''} />

          <Divider />

          <AccounItem
            name="Region"
            value={Regions.find((r) => r.id === user.regionId)?.name || ''}
          />

          <AccounItem name="Address" value={user.organization?.address || ''} />

          <Divider />
          <AccounItem
            name="Email"
            value={user?.email}
            // editable
            onEdit={() => {}}
          />
          <AccounItem
            name="Additional emails"
            value={user?.additionalEmails.join(', ')}
            // editable
            onEdit={() => {}}
          />
          <AccounItem
            name="Phone"
            value={user?.phone}
            // editable
            onEdit={() => {}}
          />
          <Divider />
          <AccounItem
            name="Updated At"
            value={moment(user.createdAt).format('YYYY-MM-DD HH:mm')}
          />
        </Stack>
      </Stack>
    </Paper>
  )
})

Account.displayName = 'Account'
export default Account
