import React, { forwardRef } from 'react'
import { grey } from '@mui/material/colors'
import { Stack, StackProps, Typography } from '@mui/material'

interface TiltingLevelItemProps extends StackProps {
  title: string
  value: string | number
  color?: string
}

const TiltingLevelItem = forwardRef<typeof Stack, TiltingLevelItemProps>(
  ({ title, value, color, onClick, ...props }, ref) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={1}
        sx={{
          pt: 0.25,
          pb: 0.25,
          pl: 1,
          pr: 1,
          transition: 'background-color 0.3s',
          backgroundColor: grey['A100'],
          '&:hover': { color: 'white', backgroundColor: color }
        }}
        onClick={onClick}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body2">{value}</Typography>
      </Stack>
    )
  }
)

TiltingLevelItem.displayName = 'TiltingLevelItem'
export default TiltingLevelItem
