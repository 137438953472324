import React, { forwardRef } from 'react'
import {
  Box,
  Popover,
  PopoverProps,
  Stack,
  Button,
  Typography,
  Skeleton
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { LoadingButton } from '@mui/lab'

export type ConfirmType<T, D = void> = {
  anchor?: PopoverProps['anchorEl']
  type?: T
  data?: D
}

interface ConfirmProps extends PopoverProps {
  icon?: React.ReactNode
  title?: string
  message?: string | React.ReactNode
  pending?: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const Confirm = forwardRef<typeof Popover, ConfirmProps>(
  (
    {
      pending,
      sx,
      open,
      anchorEl,
      icon = <Icons.CheckCircle fontSize="large" />,
      title = 'Confirm',
      message = 'Are you sure?',
      onConfirm,
      onClose,
      ...props
    },
    ref
  ) => {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        sx={{ ...sx }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={onClose}>
        <Stack sx={{ p: 2 }}>
          <Stack sx={{ width: 400 }} direction="row" spacing={2}>
            {open ? (
              <>
                <Box sx={{ color: 'warning.main', p: 1 }}>{icon}</Box>
                <Stack spacing={1} sx={{ pr: 3 }}>
                  <Typography variant="h6">{title}</Typography>
                  <Typography fontSize={'inherit'} paragraph>
                    {message}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <Skeleton>
                  <Box sx={{ color: 'warning.main', p: 1 }}>{icon}</Box>
                </Skeleton>
                <Stack spacing={1} sx={{ pr: 3 }}>
                  <Skeleton>
                    <Typography variant="h6">{title}</Typography>
                  </Skeleton>
                  <Skeleton>
                    <Typography fontSize={'inherit'} paragraph>
                      {message}
                    </Typography>
                  </Skeleton>
                </Stack>
              </>
            )}
          </Stack>

          <Stack sx={{ mt: 3 }} direction="row" spacing={2}>
            <LoadingButton
              loading={pending}
              size="small"
              variant={'contained'}
              onClick={() => onConfirm()}>
              Confirm
            </LoadingButton>
            <Button size="small" onClick={(event) => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Popover>
    )
  }
)

Confirm.displayName = 'Confirm'
export default Confirm
