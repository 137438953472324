import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import { Style, Icon, Text, Fill, Stroke } from 'ol/style'

import TreeLevel0 from '@/assets/images/map/tree-level-0.png'
import TreeLevel1 from '@/assets/images/map/tree-level-1.png'
import TreeLevel2 from '@/assets/images/map/tree-level-2.png'
import TreeLevel3 from '@/assets/images/map/tree-level-3.png'
import TreeLevel4 from '@/assets/images/map/tree-level-4.png'

import TreeLeve0M from '@/assets/images/map/tree-level-0-m.png'
import TreeLeve1M from '@/assets/images/map/tree-level-1-m.png'
import TreeLeve2M from '@/assets/images/map/tree-level-2-m.png'
import TreeLeve3M from '@/assets/images/map/tree-level-3-m.png'
import TreeLeve4M from '@/assets/images/map/tree-level-4-m.png'

import { FilterTypeEnum, MapTreeType } from '../map.types'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Map } from 'ol'
import { MediumBatteryThreshHold } from './view.const'

const TreeLevelIcons = {
  'level-0': TreeLevel0,
  'level-1': TreeLevel1,
  'level-2': TreeLevel2,
  'level-3': TreeLevel3,
  'level-4': TreeLevel4,
  'level-0-m': TreeLeve0M,
  'level-1-m': TreeLeve1M,
  'level-2-m': TreeLeve2M,
  'level-3-m': TreeLeve3M,
  'level-4-m': TreeLeve4M
}

export const getTreeFeatures = (trees: MapTreeType[]) => {
  let features = []
  for (let i = 0; i < trees.length; i++) {
    const tree = trees[i]
    const coordinates = fromLonLat([tree.coordinates[1], tree.coordinates[0]])
    // guard against bad data
    if (isNaN(coordinates[0]) || isNaN(coordinates[1])) {
      // console.log('bad data', tree.treeId, tree.coordinates)
      continue
    }
    features.push(new Feature({ ...tree, geometry: new Point(coordinates) }))
  }
  return features
}

export const getTreeLayerStyle = (feature) => {
  const tiltingLevel = feature.get('tiltingLevel') || 0
  const maintenance = feature.get('maintenance') ?? false
  const iconSrc =
    TreeLevelIcons[`level-${tiltingLevel}${maintenance ? '-m' : ''}`]

  return new Style({
    image: new Icon({
      opacity: 1,
      src: iconSrc,
      offset: [0, 0],
      scale: 0.7
    })
  })
}

export const filterTreesOnMap = (
  trees: MapTreeType[],
  filters: FilterTypeEnum[]
) => {
  const treesOnMap: MapTreeType[] = []
  const hasMaintenance = filters.includes(FilterTypeEnum.Maintenance)
  const hasLowPower = filters.includes(FilterTypeEnum.LowPower)

  const treesForMap = trees.filter((tree) => {
    if (hasMaintenance && hasLowPower) {
      return tree.maintenance && tree.voltage < MediumBatteryThreshHold
    } else if (hasMaintenance && !hasLowPower) {
      return tree.maintenance
    } else if (!hasMaintenance && hasLowPower) {
      return !tree.maintenance && tree.voltage < MediumBatteryThreshHold
    } else {
      return true
    }
  })

  if (filters.includes(FilterTypeEnum.Good)) {
    treesOnMap.push(...treesForMap.filter((t) => t.tiltingLevel === 1))
  }

  if (filters.includes(FilterTypeEnum.Fair)) {
    treesOnMap.push(...treesForMap.filter((t) => t.tiltingLevel === 2))
  }

  if (filters.includes(FilterTypeEnum.Poor)) {
    treesOnMap.push(...treesForMap.filter((t) => t.tiltingLevel === 3))
  }
  if (filters.includes(FilterTypeEnum.Emergency)) {
    treesOnMap.push(...treesForMap.filter((t) => t.tiltingLevel === 4))
  }
  if (filters.includes(FilterTypeEnum.Disconnection)) {
    treesOnMap.push(...treesForMap.filter((t) => t.tiltingLevel === 0))
  }

  return treesOnMap
}

export const activeTree = (params: {
  treeFeature: Feature<Geometry>
  activeTreeRef: React.MutableRefObject<Feature<Geometry>>
  activeLayer: VectorLayer<VectorSource<Feature<Geometry>>>
  map: Map
  zoomIn: boolean
}) => {
  const { treeFeature, activeTreeRef, activeLayer, map, zoomIn } = params
  const targetFeatureClone = treeFeature.clone()
  activeTreeRef.current = targetFeatureClone
  activeLayer.getSource().addFeature(targetFeatureClone)

  const geometry = treeFeature.getGeometry() as Point
  const coordinates = geometry.getCoordinates()
  const animateParams = zoomIn
    ? { center: coordinates, zoom: 18 }
    : { center: coordinates }
  const mapView = map.getView()
  mapView.animate(animateParams)
}
