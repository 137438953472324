import { SensorsPageListParams } from '@/routes/manage/data/sensors/Sensors'
import request from '../request'
import { Sensor } from '@/routes/manage/data/sensors/sensor.types'

export const getSensors = async (params: SensorsPageListParams) => {
  return request.get<{ sensors: Sensor[]; totalCount: number }>(
    'manage/sensor/sensors',
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params?.sensorId && { sensorId: params.sensorId })
      }
    }
  )
}
