import React, { forwardRef } from 'react'
import {
  Popover,
  PopoverProps,
  Stack,
  Typography,
  Button,
  MenuProps
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { removeUser } from '@/service/settings/users'
import { User } from '@/routes/manage/user/users.types'

interface DeleteUserProps extends PopoverProps {
  open: boolean
  anchorEl: MenuProps['anchorEl']
  user: User
  onClose: () => void
  onSuccess: (user: User) => void
}

const DeleteUser = forwardRef<typeof Popover, DeleteUserProps>(
  ({ open, user, anchorEl, onClose, onSuccess, ...props }, ref) => {
    const deleteUser = async () => {
      const resp = await removeUser(user.id)
      if (resp?.code === 200) {
        onSuccess(user)
      }
    }

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => onClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Stack sx={{ width: 400, p: 2 }} spacing={3}>
          <Typography variant="h6" color="primary.main">
            Delete User
          </Typography>
          <Stack direction="row" spacing={2}>
            <Icons.Delete sx={{ color: 'warning.main' }} />
            <Typography>Are you sure to delete this user?</Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => deleteUser()}>
              Confirm
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Stack>
        </Stack>
      </Popover>
    )
  }
)

DeleteUser.displayName = 'DeleteUser'
export default DeleteUser
