import React, { useState, forwardRef, useEffect } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Button,
  Divider
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { Controller, useForm } from 'react-hook-form'
import { DefaultNewTree } from '../trees.const'
import FormInput from '@/components/form/FormInput'
import {
  NewTree as NewTreeType,
  NewTreeOrgOption,
  NewTreeGroupOption,
  Tree,
  TreeSpecies
} from '../trees.types'
import FormTextArea from '@/components/form/FormTextArea'
import FormSelect from '@/components/form/FormSelect'

import { getOrganizations, getGroups } from '@/service/manage/users'
import { getTreeSpecies } from '@/service/manage/trees'

import { createTree } from '@/service/manage/trees'
import { useAppDispatch } from '@/store'
import { isNumber } from 'lodash'

import { globalSlice } from '@/store/reducers/global/global.slice'

interface NewTreeProps extends DrawerProps {
  onSuccess: (tree: Tree) => void
  onClose: () => void
}

const NewTree = forwardRef<typeof Drawer, NewTreeProps>(
  ({ open, onSuccess, onClose, ...props }, ref) => {
    const dispatch = useAppDispatch()
    const [orgList, setOrgList] = useState<NewTreeOrgOption[]>([])
    const [groupList, setGroupList] = useState<NewTreeGroupOption[]>([])
    const [speciesList, setSpeciesList] = useState<TreeSpecies[]>([])

    const { watch, control, reset, setValue, handleSubmit } =
      useForm<NewTreeType>({ defaultValues: DefaultNewTree })

    const watchOrgId = watch('organizationId')

    const fetchTreeSpeciesList = async () => {
      const resp = await getTreeSpecies()
      if (resp?.code === 200) {
        setSpeciesList(resp.data)
      }
    }
    const fetchOrgOptions = async () => {
      const resp = await getOrganizations()
      if (resp?.code === 200) {
        setOrgList(resp.data)
      }
    }
    const fetchGroupOptions = async (organizationId: string) => {
      const resp = await getGroups(organizationId)
      if (resp?.code === 200) {
        setGroupList(resp.data)
        if (resp.data.length === 1) {
          setValue('groupId', resp.data[0].id)
        }
      }
    }

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await createTree(values)
        if (resp?.code === 200) {
          dispatch(
            globalSlice.actions.setNotification({
              type: 'success',
              caption: 'The tree has been created successfully',
              anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
          )
          onSuccess(resp.data)
        }
      })()
    }

    useEffect(() => {
      if (open) {
        fetchOrgOptions()
        fetchTreeSpeciesList()
        reset(DefaultNewTree)
      }
    }, [open])

    useEffect(() => {
      if (watchOrgId) {
        fetchGroupOptions(watchOrgId)
      }
    }, [watchOrgId])

    useEffect(() => {}, [])

    return (
      <Drawer open={open} anchor="right" onClose={onClose}>
        <Stack sx={{ width: 540, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              Add New Trees
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
          <Stack
            sx={{ flexGrow: 1, pl: 3, pr: 2, pb: 3, pt: 1, overflow: 'auto' }}
            spacing={1.5}>
            <Typography
              sx={{ mt: 1, mb: 2 }}
              paragraph
              variant="subtitle1"
              color="primary.main">
              Basics
            </Typography>
            <Controller
              name="treeId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    helperText="Tree ID as the unique identity in LeafIot system is not changale, please make sure the tree ID follow the naming rules of the system"
                    label="Tree ID"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="userTreeId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    label="User Tree ID"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />

            <Controller
              name="sensorId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    helperText="Please make sure the sensor ID is registered in LeafIot system. The system will use this ID to sychronize its data"
                    label="Sensor ID"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="organizationId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Organization"
                  value={field.value}
                  options={orgList.map((opt) => ({
                    label: opt.fullname,
                    value: opt.id
                  }))}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="groupId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Group"
                  value={field.value}
                  options={groupList.map((opt) => ({
                    label: opt.name,
                    value: opt.id
                  }))}
                  tip={
                    !watchOrgId && (
                      <Typography variant="caption" color="warning.main">
                        The group options will display when organization's been
                        selected
                      </Typography>
                    )
                  }
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="species"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Species"
                  value={field.value}
                  options={speciesList.map((species) => ({
                    label: species.speciesEn,
                    value: species.speciesEn
                  }))}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="dbh"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label="DBH (mm)"
                  value={field.value === 0 ? '' : field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="height"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label={'Height (m)'}
                  value={field.value === 0 ? '' : field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="crownSpread"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label="Crown Spread (m)"
                  value={field.value === 0 ? '' : field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Divider sx={{ pt: 1 }} />

            <Typography
              sx={{ mt: 1, mb: 2 }}
              paragraph
              variant="subtitle1"
              color="primary.main">
              Location
            </Typography>
            <Controller
              name="coordinates"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const coordinates = value
                    .split(',')
                    .filter((c, idx) => idx < 2 && c !== '')
                    .map((c) => Number(c.trim()))
                  if (
                    coordinates.length !== 2 ||
                    !(isNumber(coordinates[0]) && isNumber(coordinates[1]))
                  ) {
                    return 'Invalid coordinates'
                  }

                  return undefined
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    label="Coordinates"
                    helperText={`${
                      fieldState?.error?.message
                        ? fieldState?.error?.message + '. '
                        : ''
                    }Format: lat,lng`}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="coordinatesHK1980"
              control={control}
              rules={{
                validate: (value) => {
                  if (value === '') {
                    return
                  }

                  const coordinates = value
                    .split(',')
                    .filter((c, idx) => idx < 2 && c !== '')
                    .map((c) => Number(c.trim()))
                  if (
                    coordinates.length !== 2 ||
                    !(isNumber(coordinates[0]) && isNumber(coordinates[1]))
                  ) {
                    return 'Invalid coordinates'
                  }

                  return
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    error={Boolean(fieldState.error)}
                    label="Coordinates"
                    labelTip="HK1980"
                    helperText={`${
                      fieldState?.error?.message
                        ? fieldState?.error?.message + '. '
                        : ''
                    }Format: lat,lng`}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="address"
              control={control}
              rules={{ maxLength: 240 }}
              render={({ field, fieldState }) => (
                <FormTextArea
                  height={120}
                  error={Boolean(fieldState.error)}
                  label="Address"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />
            <Divider sx={{ pt: 1 }} />

            <Typography
              sx={{ mt: 1, mb: 2 }}
              paragraph
              variant="subtitle1"
              color="primary.main">
              Reset
            </Typography>
            <Controller
              name="resetTiltingValue"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label={'Reset Tilting (°)'}
                  helperText={
                    'To offset the deviation caused by the initial angle during installation'
                  }
                  value={field.value === 0 ? '' : field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="resetAzimuthValue"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label={'Reset Azimuth (°)'}
                  helperText={
                    'To offset the deviation caused by the initial angle during installation'
                  }
                  value={field.value === 0 ? '' : field.value}
                  onValChange={field.onChange}
                />
              )}
            />
          </Stack>

          <Stack
            sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
            direction="row"
            flexShrink={0}
            spacing={2}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => onSubmit()}>
              Save
            </Button>
            <Button variant="text" onClick={() => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

NewTree.displayName = 'NewTree'
export default NewTree
