import React, { forwardRef, useEffect } from 'react'
import {
  Box,
  Stack,
  Typography,
  Drawer,
  DrawerProps,
  IconButton,
  Button,
  Alert,
  Divider
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { useForm, Controller } from 'react-hook-form'
import { DefaultNewOrganization } from '../organizaiton.const'
import { NewOrganization as NewOrganizationType } from '../organization.types'
import { createOrganization } from '@/service/manage/organizations'
import FormInput from '@/components/form/FormInput'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import FormDate from '@/components/form/FormDate'
import { Regions } from '@/configs/config.regions'
import moment from 'moment'

interface NewOrganizationProps extends DrawerProps {
  onClose: () => void
  onSuccess: () => void
}

const NewOrganization = forwardRef<typeof Box, NewOrganizationProps>(
  ({ open, onSuccess, onClose, ...props }, ref) => {
    const { control, handleSubmit, watch, reset } =
      useForm<NewOrganizationType>({
        defaultValues: DefaultNewOrganization
      })

    const lifytProWatch = watch('lifytPro')

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await createOrganization(values)
        if (resp?.code === 200) {
          onSuccess()
        }
      })()
    }

    useEffect(() => {
      if (!open) reset(DefaultNewOrganization)
    }, [open])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack sx={{ width: 580, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6" color="primary.main">
              New Organization
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Alert
            sx={{ mt: 1, mb: 1 }}
            severity="info"
            icon={<Icons.Info fontSize="inherit" />}>
            Please assign trees for the new organization after it has been
            created successfully
          </Alert>

          <Stack
            sx={{
              flexGrow: 1,
              width: 580,
              pt: 2,
              pb: 4,
              pl: 3,
              pr: 3,
              overflow: 'auto'
            }}>
            <form>
              <Stack spacing={2}>
                <Typography
                  sx={{ mt: 1, mb: 2 }}
                  paragraph
                  variant="subtitle1"
                  color="primary.main">
                  Basics
                </Typography>

                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      required
                      labelWidth={140}
                      label="Name"
                      helperText="Suggested to be the short name of this organization. Max length 120"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="fullname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      required
                      labelWidth={140}
                      label="Full name"
                      helperText="Organization's official name"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="regionId"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      required
                      labelWidth={140}
                      label="Region"
                      options={Regions.map((r) => ({
                        label: r.name,
                        value: r.id
                      }))}
                      value={field.value}
                      onValChange={field.onChange}></FormSelect>
                  )}
                />

                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Address"
                      labelWidth={140}
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="industry"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Industry"
                      labelWidth={140}
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
              </Stack>

              <Divider sx={{ mt: 2, mb: 2 }}></Divider>
              <Typography
                sx={{ mt: 1, mb: 2 }}
                paragraph
                variant="subtitle1"
                color="primary.main">
                Availability
              </Typography>

              <Controller
                name="expiredAt"
                control={control}
                render={({ field }) => (
                  <FormDate
                    labelWidth={140}
                    label="Expired At"
                    tip="The expired date of access to the LeafIot, default is 60 days after created. "
                    minDate={moment()}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="lifytPro"
                control={control}
                render={({ field }) => {
                  return (
                    <FormSwitch
                      sx={{ mb: 1 }}
                      labelWidth={140}
                      label="LIFY-T Pro"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )
                }}
              />

              {lifytProWatch && (
                <Controller
                  name="lifytProExpiredAt"
                  control={control}
                  rules={{ validate: (val) => !!val }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormDate
                        sx={{ mb: 1 }}
                        labelWidth={140}
                        label=""
                        minDate={moment()}
                        tip="Specify the expired date of LIFY-T Pro Service"
                        error={Boolean(fieldState.error)}
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )
                  }}
                />
              )}

              <Controller
                name="disabled"
                control={control}
                render={({ field }) => (
                  <FormSwitch
                    label="Disable"
                    tip="User access to the LeafIot system will be suspended when disabled is true"
                    labelWidth={140}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Divider sx={{ mt: 2, mb: 2 }}></Divider>

              <Typography
                sx={{ mt: 1, mb: 2 }}
                paragraph
                variant="subtitle1"
                color="primary.main">
                Contact
              </Typography>

              <Controller
                name="contactPerson"
                control={control}
                render={({ field }) => (
                  <FormInput
                    sx={{ mb: 2 }}
                    labelWidth={140}
                    label="Person"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="contactPhone"
                control={control}
                render={({ field }) => (
                  <FormInput
                    labelWidth={140}
                    sx={{ mb: 2 }}
                    label="Phone"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="contactEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    labelWidth={140}
                    sx={{ mb: 2 }}
                    label="Email"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Divider sx={{ mt: 2, mb: 2 }}></Divider>

              <Typography
                sx={{ mt: 1, mb: 2 }}
                paragraph
                variant="subtitle1"
                color="primary.main">
                Default User -
                <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                  The role of default user is Admin{' '}
                </Typography>
              </Typography>

              <Controller
                name="username"
                control={control}
                rules={{ required: true, maxLength: 120 }}
                render={({ field, fieldState }) => (
                  <FormInput
                    required
                    labelWidth={140}
                    label="User name"
                    helperText="Default user with full access of this organizations"
                    value={field.value}
                    error={Boolean(fieldState.error)}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="nickname"
                rules={{ required: true, maxLength: 120 }}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label="Nick name"
                    labelWidth={140}
                    helperText={
                      'Nick name will replace user name as display name to show in the system'
                    }
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <FormInput
                      required
                      password
                      labelWidth={140}
                      label="Password"
                      helperText="Max length 16, Special character support !@#$%^&*()_+"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  </>
                )}
              />

              <Controller
                name="userEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    labelWidth={140}
                    label="Email"
                    helperText="Email to receive notification from LeafIot"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="userPhone"
                control={control}
                render={({ field }) => (
                  <FormInput
                    labelWidth={140}
                    label="Phone"
                    helperText="Phone to receive alert massage from LeafIot"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="userAdditionalEmails"
                control={control}
                render={({ field }) => {
                  return (
                    <FormInput
                      labelWidth={140}
                      label="Additional Emails"
                      helperText="Besides user email, each admin accout can have one more email to receive daily report recipient"
                      value={field.value[0] ?? ''}
                      onValChange={(val) => field.onChange([val])}
                    />
                  )
                }}
              />
            </form>
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
            spacing={2}>
            <Button variant="contained" onClick={() => onSubmit()}>
              Confirm
            </Button>
            <Button variant="text" onClick={() => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

NewOrganization.displayName = 'NewOrganization'
export default NewOrganization
