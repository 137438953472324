import React, { forwardRef, ReactNode } from 'react'
import { Button, ButtonProps } from '@mui/material'

export const SubMenusStyle = {
  color: '#303030',
  mr: 1,
  ml: 1
}

export const ActiveSubmenuStyle = {
  color: 'primary.main',
  fontSize: 'inherit',
  backgroundColor: 'rgba(0, 184, 126, 0.08)'
}

interface MenuProps extends ButtonProps {
  active: boolean
  icon: ReactNode
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const Menu = forwardRef<typeof Button, MenuProps>(
  (
    { active, sx, icon, disabled = false, children, onClick, ...props },
    ref
  ) => {
    return (
      <Button
        size="small"
        disabled={disabled}
        sx={{ ...SubMenusStyle, ...(active && ActiveSubmenuStyle), ...sx }}
        startIcon={active && icon}
        onClick={onClick}>
        {children}
      </Button>
    )
  }
)

Menu.displayName = 'PageMenu'
export default Menu
