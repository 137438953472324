import { User, NewUser } from '@/routes/manage/user/users.types'
import request from '../request'
import {
  OrganizationOptions,
  GroupOptions
} from '@/routes/manage/user/users.types'
import { UserPageListParams } from '@/routes/manage/user/Users'
import { Group } from '@/routes/setting/users/groups/group.types'

export const getUsers = async (params: UserPageListParams) => {
  return request.get<{ users: User[]; totalCount: number }>(
    'manage/user/users',
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params.username && { username: params.username }),
        ...(params.organizationId && { organizationId: params.organizationId }),
        ...(params.groupId && { groupId: params.groupId }),
        ...(params.role && { role: params.role })
      }
    }
  )
}

export const createUser = async (params: NewUser) => {
  return request.post<User>('manage/user', { data: params })
}

export const updateUser = async (userId: string, params) => {
  return request.put<User>(`manage/user/${userId}`, {
    data: {
      ...params,
      ...(params.additionalEmails && {
        additionalEmails: params.additionalEmails.join(',')
      })
    }
  })
}

export const deleteUser = async (userId: string) => {
  return request.delete<boolean>(`manage/user/${userId}`)
}

export const changePassword = async (
  userId,
  params: { password: string; __v: number }
) => {
  return request.put<User>(`manage/user/${userId}/password`, { data: params })
}

export const getOrganizations = async () => {
  return request.get<OrganizationOptions[]>(
    `manage/organization/organizations/list`
  )
}

export const getGroups = async (organizationId: string) => {
  return request.get<GroupOptions[]>(
    `manage/organization/${organizationId}/groups`
  )
}


export const muteSubscriptionNotice = (userId: string) => {
  return request.put<boolean>(`manage/user/${userId}/mute-subscription-notice`)
}
