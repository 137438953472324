import React, { forwardRef } from 'react'
import {
  Button,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { Icons } from '@/assets/icons'

export interface FormDrawerProps extends DrawerProps {
  title: string
  onSubmit: () => void
  onClose: () => void
}

const FormDrawer = forwardRef<typeof Drawer, FormDrawerProps>(
  ({ open, title, children, onSubmit, onClose, ...props }, ref) => {
    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack
          sx={{ p: 2, borderBottom: '1px solid #ddd' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexShrink={0}>
          <Typography variant="h6" color="primary.main">
            {title}
          </Typography>
          <IconButton aria-label="close" onClick={() => onClose()}>
            <Icons.Close fontSize="small" />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
            width: 640,
            overflow: 'auto',
            px: 4,
            py: 3
          }}
          spacing={2}>
          {children}
        </Stack>
        <Stack
          //   sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
          sx={{ p: 2, borderTop: '1px solid #ddd' }}
          direction="row"
          flexShrink={0}
          spacing={2}>
          <Button type="submit" variant="contained" onClick={() => onSubmit()}>
            Confirm
          </Button>
          <Button variant="text" onClick={() => onClose()}>
            Cancel
          </Button>
        </Stack>
      </Drawer>
    )
  }
)

FormDrawer.displayName = 'FormDrawer'
export default FormDrawer
