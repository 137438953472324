import React, { forwardRef, useState, useEffect } from 'react'
import { MenuItem, Stack, StackProps, TextFieldProps } from '@mui/material'
import { getOrganizations, getGroups } from '@/service/manage/users'
import TextFieldC from './TextFieldC'

interface OrgGroupSelectorProps extends StackProps {
  variant?: TextFieldProps['variant']
  size?: TextFieldProps['size']
  organizationId: string
  groupId?: string
  disableGroup?: boolean
  onOrgChange?: (orgId: string) => void
  onGroupChange?: (orgId: string) => void
}

export type OrganizationOptions = { id: string; name: string; fullname: string }
export type GroupOptions = { id: string; name: string; isDefault: boolean }

const OrgGroupSelector = forwardRef<typeof Stack, OrgGroupSelectorProps>(
  (
    {
      size,
      variant = 'standard',
      organizationId = '',
      groupId = '',
      disableGroup = false,
      onOrgChange = () => {},
      onGroupChange = () => {},
      ...props
    },
    ref
  ) => {
    const [organizations, setOrganizations] = useState<OrganizationOptions[]>(
      []
    )
    const [groups, setGroups] = useState<GroupOptions[]>([])

    useEffect(() => {
      getOrganizations().then((resp) => {
        if (resp?.code === 200) {
          setOrganizations(resp.data)
        }
      })
    }, [])

    useEffect(() => {
      onGroupChange('')
    }, [organizationId])

    useEffect(() => {
      if (!disableGroup && organizationId) {
        getGroups(organizationId).then((resp) => {
          if (resp?.code === 200) {
            setGroups(resp.data)
          }
        })
      }
    }, [disableGroup, organizationId])

    return (
      <Stack direction={'row'} spacing={1}>
        <TextFieldC
          select
          size={size}
          sx={{ width: 200 }}
          value={organizationId}
          placeholder="Organization"
          SelectProps={{
            displayEmpty: true,
            MenuProps: { style: { maxHeight: 400 } }
          }}
          onChange={(evt) => {
            onOrgChange(evt.target.value)
          }}>
          <MenuItem key={'any-organization'} value={''}>
            Any Organization
          </MenuItem>
          {organizations.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.fullname}
            </MenuItem>
          ))}
        </TextFieldC>
        {!disableGroup && (
          <TextFieldC
            select
            size={size}
            sx={{ width: 200 }}
            value={groupId}
            placeholder="Group"
            disabled={!organizationId}
            SelectProps={{
              displayEmpty: true
            }}
            onChange={(evt) => onGroupChange(evt.target.value)}>
            <MenuItem key={'any-group'} value={''}>
              Any Group
            </MenuItem>
            {groups.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextFieldC>
        )}
      </Stack>
    )
  }
)

OrgGroupSelector.displayName = 'OrgGroupSelector'
export default OrgGroupSelector
