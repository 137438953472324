import React, { forwardRef } from 'react'
import { Box, Paper, Stack, Typography, PaperProps } from '@mui/material'
import { title } from 'process'

interface StatCardProps extends PaperProps {
  title: string
  icon: React.ReactNode
  value: number
}

const StatCard = forwardRef<typeof Paper, StatCardProps>(
  ({ title, icon, value, ...props }, ref) => {
    return (
      <Paper
        sx={{
          flex: 1,
          px: 2,
          py: 1,
          height: 80,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography>{title}</Typography>
          <Box>{icon}</Box>
        </Stack>
        <Typography variant="h6">{value}</Typography>
      </Paper>
    )
  }
)

StatCard.displayName = 'StatCard'
export default StatCard
