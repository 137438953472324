import React, { forwardRef, useMemo } from 'react'
import { Box, BoxProps, IconButton } from '@mui/material'
import { useAppSelector, useAppDispatch } from '@/store'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { useNavigate } from 'react-router-dom'
import { Icons } from '@/assets/icons'

interface NavModeSwitchProps extends BoxProps {}

const NavModeSwitch = forwardRef<typeof Box, NavModeSwitchProps>(
  ({ ...props }, ref) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const navigation = useAppSelector((state) => state.global.navigation)
    const user = useAppSelector((state) => state.users.user)
    const navMenuPath = useAppSelector((state) => state.global.navMenuPath)
    const isPublic = useMemo(() => {
      return navigation.type === 'public'
    }, [navigation])

    const isPrivate = useMemo(() => {
      return navigation.type === 'private'
    }, [navigation])

    const changeNavigationType = (type: 'public' | 'private') => {
      dispatch(globalSlice.actions.setNavigationType(type))
    }

    return (
      <Box ref={ref}>
        {user?.isLeafiotAdmin && (
          <>
            {isPrivate && (
              <IconButton
                onClick={() => {
                  changeNavigationType('public')
                  navigate(navMenuPath.public)
                }}>
                <Icons.Menu />
              </IconButton>
            )}
            {isPublic && (
              <IconButton
                onClick={() => {
                  changeNavigationType('private')
                  navigate(navMenuPath.private)
                }}>
                <Icons.Build fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )
  }
)

NavModeSwitch.displayName = 'NavModeSwitch'
export default NavModeSwitch
