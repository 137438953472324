import { AxiosError } from 'axios'
import { IValidationErrors } from './reducer.types'

export const CatchErrors = (error, rejectWithValue) => {
  const err: AxiosError<IValidationErrors> = error // cast the error for access
  if (!error.response) {
    throw error
  }
  // We got validation errors, let's return those so we can reference in our component and set form errors
  return rejectWithValue(err.response.data)
}
