import {
  HkoWeatherWarningSubtypeCodeEnum,
  HkoWeatherWarningStatementCodeEnum
} from '../mode/mode.types'

export enum NotificationTypeEnum {
  DailyReport = 'DAILY_REPORT',
  DailyReportResent = 'DAILY_REPORT_RESENT',
  Alert = 'ALERT',
  ModeChange = 'MODE_CHANGE'
}

export enum ChannelEnum {
  Email = 'EMAIL',
  SMS = 'SMS',
  APP = 'APP'
}

export type ModeChangeSignal = {
  recordedAt: Date
  mode: number
  interval: number
  warningCodes: (
    | HkoWeatherWarningSubtypeCodeEnum
    | HkoWeatherWarningStatementCodeEnum
  )[]
}

export type Notification = {
  id: string
  recordedAt: Date
  status: number
  channel: ChannelEnum
  type: string
  receipients: string[]
  content: string
  signals?: ModeChangeSignal
  data?: any
  error?: any
  auto: boolean
}
