import React, { forwardRef } from 'react'
import { Box, Stack, StackProps } from '@mui/material'

interface HeaderProps extends StackProps {}

const Header = forwardRef<typeof Box, HeaderProps>(
  ({ children, ...props }, ref) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: 1,
          bgcolor: '#fff',
          height: 60,
          borderBottom: '1px solid #ddd'
        }}>
        {children}
      </Stack>
    )
  }
)
Header.displayName = 'PageHeader'

export default Header
