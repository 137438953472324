import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import {
  Stack,
  StackProps,
  Avatar,
  Typography,
  Divider,
  Fade
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '@/store'
import { grey, red } from '@mui/material/colors'
import { Icons } from '@/assets/icons'
import { ErrorCode, useDropzone } from 'react-dropzone'
import TipIconBtn from '@/components/TipIconBtn'
import { updateUserAvatar } from '@/service/settings/users'
import { fileToBase64 } from '@/utils/tool'
import { UserStorage } from '@/configs/storage-dics'
import { usersSlice } from '@/store/reducers/user/user.slice'
interface AvatarProps extends StackProps {}

const UserAvatar = forwardRef<typeof Stack, AvatarProps>(
  ({ ...props }, ref) => {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state) => state.users?.user)
    const [avatarSrc, setAvatarSrc] = useState<string | null>(null)
    const [isUploading, setIsUploading] = useState(false)

    const { getRootProps, acceptedFiles, fileRejections, getInputProps } =
      useDropzone({
        accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
        maxFiles: 1,
        maxSize: 2 * 1024 * 1024
      })
    const fileRejection = fileRejections[0]
    const invalidFileType = fileRejection?.errors.find((err) => {
      return err.code === ErrorCode.FileInvalidType
    })
    const invalidFileSize = fileRejection?.errors.find((err) => {
      return err.code === ErrorCode.FileTooLarge
    })
    const updateAvatarFile = (file) => {
      setAvatarSrc(URL.createObjectURL(file))
    }

    const clearSelected = () => {
      acceptedFiles.splice(0, 1)
      setAvatarSrc(user?.avatar || null)
    }
    const updateUserInfo = (newavatar) => {
      const newUserInfo = { ...user }
      newUserInfo.avatar = newavatar

      localStorage.setItem(UserStorage, JSON.stringify(newUserInfo))
      dispatch(usersSlice.actions.setUser(newUserInfo))
    }

    const uploadAvatar = async () => {
      if (isUploading) {
        return
      }
      setIsUploading(true)
      const fileBase64 = await fileToBase64(acceptedFiles[0])
      const resp = await updateUserAvatar(user?.id, fileBase64)

      if (resp?.code === 200) {
        updateUserInfo(resp?.data?.avatar)
        acceptedFiles.splice(0, 1)
      }
      setIsUploading(false)
    }

    useEffect(() => {
      if (acceptedFiles[0]) {
        updateAvatarFile(acceptedFiles[0])
      } else {
        clearSelected()
      }
    }, [acceptedFiles])

    useEffect(() => {
      if (user?.avatar) {
        setAvatarSrc(user?.avatar)
      }
    }, [user])

    return (
      <Stack
        sx={{
          width: 1,
          px: 4,
          py: 2,
          height: 180,
          bgcolor: grey[50],
          borderRadius: 2
        }}
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={3}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack sx={{ pt: 3 }}>
          <Avatar
            sx={{
              width: 120,
              minHeight: 120,
              backgroundColor: grey[50],
              border: `12px solid #fff`,
              outline: `1px solid ${grey[200]}`
            }}
            src={avatarSrc}>
            {!avatarSrc && (
              <Icons.AddAPhoto fontSize="large" sx={{ color: grey[400] }} />
            )}
          </Avatar>
          <Fade
            in={acceptedFiles?.length === 1}
            exit={acceptedFiles?.length === 0}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <TipIconBtn
                tip="Upload"
                size="small"
                disabled={acceptedFiles?.length !== 1 || isUploading}
                onClick={(event) => {
                  event.stopPropagation()
                  uploadAvatar()
                }}>
                <Icons.FileUpload fontSize="inherit" />
              </TipIconBtn>
              <TipIconBtn
                tip="Clear"
                size="small"
                disabled={acceptedFiles?.length !== 1 || isUploading}
                onClick={(event) => {
                  event.stopPropagation()
                  clearSelected()
                }}>
                <Icons.Close fontSize="inherit" />
              </TipIconBtn>
            </Stack>
          </Fade>
        </Stack>

        <Stack spacing={1}>
          <Typography sx={{ m: 0 }} variant="caption" color={grey[600]}>
            Drop or click to update
          </Typography>
          <Divider />
          <Typography
            sx={{ m: 0 }}
            fontWeight={invalidFileType ? 'bolder' : 'normal'}
            variant={invalidFileType ? 'body2' : 'caption'}
            color={invalidFileType ? red[800] : grey[600]}>
            Allowed *.jpeg, *.jpg, *.png, *.gif
          </Typography>
          <Typography
            sx={{ m: 0 }}
            fontWeight={invalidFileSize ? 'bolder' : 'normal'}
            variant={invalidFileSize ? 'body2' : 'caption'}
            color={invalidFileSize ? red[800] : grey[600]}>
            Max size of 2 Mb
          </Typography>
        </Stack>
      </Stack>
    )
  }
)

UserAvatar.displayName = 'UserAvatar'
export default UserAvatar
