export const debounce = (fn: Function, delay = 500) => {
    let timer: ReturnType<typeof setTimeout> | null
    return function (...arg: any) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            // @ts-ignore
            fn.apply(this, arg)
            timer = null
        }, delay)
    }
}