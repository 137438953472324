import React, { forwardRef } from 'react'
import { Menu, MenuProps, MenuItem, Divider } from '@mui/material'
import { useAppSelector } from '@/store'
import { RolesEnum, User } from '@/routes/manage/user/users.types'
interface ActionMenuProps extends MenuProps {
  user: User
  onClose: () => void
  onChangePassword: (anchor: MenuProps['anchorEl'], data: User) => void
  onDelete: (anchor: MenuProps['anchorEl'], data: User) => void
}

const ActionMenu = forwardRef<typeof Menu, ActionMenuProps>(
  (
    { anchorEl, open, user, onClose, onDelete, onChangePassword, ...props },
    ref
  ) => {
    const operator = useAppSelector((state) => state.users.user)
    return (
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem
          key="password"
          onClick={() => onChangePassword(anchorEl, user)}>
          Password
        </MenuItem>
        <MenuItem
          disabled={operator.role !== RolesEnum.Admin}
          key="delete"
          onClick={(event) => onDelete(anchorEl, user)}>
          Delete
        </MenuItem>
      </Menu>
    )
  }
)

ActionMenu.displayName = 'ActionMenu'
export default ActionMenu
