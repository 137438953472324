import React, { forwardRef, useEffect, useMemo } from 'react'
import { Box, Stack, StackProps, Divider, Paper } from '@mui/material'

import { ReactComponent as LogoSvg } from '@/assets/images/logo/logo.svg'
import User from './User'
import { useAppSelector } from '@/store'
import { Icons } from '@/assets/icons'

import LeafIotAi from './LeafIotAi'
import Subscription from './Subscription'
import AlertStatus from './AlertStatus'

import FoldButton from './FoldButton'
import SignOutButton from './SignOutButton'
import { useAppDispatch } from '@/store'
import { globalSlice } from '@/store/reducers/global/global.slice'
import NavMenus from './NavMenus'
import NavModeSwitch from './NavModeSwitch'

export const NavigationWidth = {
  large: 170,
  medium: 120,
  small: 100
}

const getNavigationWidth = (
  showText: boolean,
  navigationType: 'private' | 'public'
) => {
  return showText
    ? navigationType === 'private'
      ? NavigationWidth.large
      : NavigationWidth.medium
    : NavigationWidth.small
}

const PublicMenus = [
  {
    icon: <Icons.Home />,
    text: 'Home',
    routePath: '/home',
    seletedPath: '/home/*'
  },
  {
    icon: <Icons.EsgSummary />,
    text: 'ESG',
    routePath: '/esg',
    seletedPath: '/esg/*'
  },
  {
    icon: <Icons.Map />,
    text: 'Map',
    routePath: '/map',
    seletedPath: '/map/*'
  },
  {
    icon: <Icons.Storage />,
    text: 'Data',
    routePath: '/data',
    seletedPath: '/data/*'
  },
  {
    icon: <Icons.Settings />,
    text: 'Setting',
    routePath: '/setting',
    seletedPath: '/setting/*'
  }
]

const PrivateMenus = [
  {
    icon: <Icons.MonitorHeartOutlined />,
    text: 'Overview',
    routePath: '/manage-overview',
    seletedPath: '/manage-overview/*'
  },
  {
    icon: <Icons.DatasetOutlined />,
    text: 'Data',
    routePath: '/manage-data',
    seletedPath: '/manage-data/*'
  },
  {
    icon: <Icons.SummarizeOutlined />,
    text: 'Report',
    routePath: '/manage-report',
    seletedPath: '/manage-report/*'
  },
  {
    icon: <Icons.PeopleOutlined />,
    text: 'Users',
    routePath: '/manage-users',
    seletedPath: '/manage-users/*'
  },
  {
    icon: <Icons.CorporateFare />,
    text: 'Organizations',
    routePath: '/manage-organizations',
    seletedPath: '/manage-organizations/*'
  }
]

interface NavigationProps extends StackProps {
  onWidthChange: (width: number) => void
}

const Navigation = forwardRef<typeof Box, NavigationProps>(
  ({ onWidthChange, ...props }, ref) => {
    const dispatch = useAppDispatch()
    const navigation = useAppSelector((state) => state.global.navigation)

    const isPublic = useMemo(() => {
      return navigation.type === 'public'
    }, [navigation])

    const setNavigationShowText = (showText) => {
      dispatch(globalSlice.actions.setNavigationShowText(showText))
    }

    const navigationWidth = getNavigationWidth(
      navigation.showText,
      navigation.type
    )

    useEffect(() => {
      onWidthChange(navigationWidth)
    }, [navigationWidth])

    return (
      <Paper
        sx={{
          position: 'absolute',
          zIndex: 99,
          left: 0,
          top: 0,
          width: navigationWidth,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexShrink: 0
        }}>
        <Stack spacing={1}>
          <LogoSvg />
          <Subscription />
          <Divider sx={{ width: 1, mb: 1, mt: 2 }} />
        </Stack>

        <Stack
          sx={{ py: 1 }}
          flex={1}
          justifyContent="space-between"
          alignItems="center">
          <AlertStatus />
          <NavMenus menus={isPublic ? PublicMenus : PrivateMenus} />
          <NavModeSwitch />
        </Stack>

        <Stack alignItems="center" sx={{ pb: 1 }} spacing={1}>
          <Divider sx={{ width: 1 }} />
          <User />
          <Divider sx={{ width: 1 }} />
          <LeafIotAi />
          <FoldButton
            showText={navigation.showText}
            onClick={() => setNavigationShowText(!navigation.showText)}
          />
          <SignOutButton />
        </Stack>
      </Paper>
    )
  }
)

Navigation.displayName = 'Navigation'
export default React.memo(Navigation)
