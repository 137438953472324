import React, { forwardRef, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { useAppSelector } from '@/store'
import { ExpiringNoticeStorage } from '@/configs/storage-dics'
import moment from 'moment'

interface ExpiringNoticeProps extends Omit<DialogProps, 'open'> {}

export type ExpiringNoticeType = {
  lifyt: boolean
  lifytPro: boolean
  expiredAt: number
}

export const DefaultExpiringNotice: ExpiringNoticeType = {
  lifyt: true,
  lifytPro: true,
  expiredAt: Date.now()
}

const ExpiringNotice = forwardRef<typeof Dialog, ExpiringNoticeProps>(
  ({ ...props }, ref) => {
    const expiringNoticeLocal = localStorage.getItem(ExpiringNoticeStorage)
    const expiringNotice = JSON.parse(expiringNoticeLocal) as ExpiringNoticeType

    const subscription = useAppSelector((state) => state.users.subscription)

    const lifytRemainingDays = subscription?.lifyt?.remainingDays
    const isLifytExpiring = 0 <= lifytRemainingDays && lifytRemainingDays < 30

    const lifytProRemainingDays = subscription?.lifytPro?.remainingDays
    const isLifytProExpird = lifytProRemainingDays < 0
    const isLifytProExpiring =
      0 <= lifytProRemainingDays && lifytProRemainingDays < 30

    const showLifytNotice = isLifytExpiring && expiringNotice?.lifyt
    const showLifytProNotice =
      isLifytProExpiring && !isLifytProExpird && expiringNotice?.lifytPro

    const showNotice = showLifytNotice || showLifytProNotice

    const isSignIn = useAppSelector((state) => state.users.isSignIn)
    const [open, setOpen] = useState(false)
    const [isMuted, setIsMuted] = useState(false)

    const handleClose = async () => {
      if (isMuted) {
        localStorage.setItem(
          ExpiringNoticeStorage,
          JSON.stringify({
            lifyt: isLifytExpiring && expiringNotice?.lifyt ? false : true,
            lifytPro: showLifytProNotice ? false : true,
            expiredAt: Date.now() + 7 * 24 * 60 * 60 * 1000
          })
        )
      }

      setOpen(false)
    }

    useEffect(() => {
      if (isSignIn) {
        setOpen(showNotice)
      } else {
        setOpen(false)
      }
    }, [isSignIn, showNotice])
    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <Icons.NotificationsActive
              fontSize="small"
              sx={{ verticalAlign: 'middle', color: 'warning.main' }}
            />
            <Typography fontSize="inherit" fontWeight="inherit">
              Subscription Expiring
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          {isLifytExpiring && lifytRemainingDays >= 1 && (
            <Typography>
              <i>LiFY-T</i> service will end in {lifytRemainingDays} day
              {lifytRemainingDays > 1 ? 's' : ''}.
            </Typography>
          )}
          {isLifytExpiring && lifytRemainingDays === 0 && (
            <Typography sx={{ minWidth: 360 }}>
              <i>LiFY-T</i> service will end on today 😨
            </Typography>
          )}

          {isLifytProExpiring && lifytProRemainingDays >= 1 && (
            <Typography>
              <i>LiFY-T Pro</i> service will end in {lifytProRemainingDays} day
              {lifytProRemainingDays > 1 ? 's' : ''}.
            </Typography>
          )}

          {!isLifytProExpird &&
            isLifytProExpiring &&
            lifytProRemainingDays === 0 && (
              <Typography paragraph sx={{ minWidth: 360 }}>
                <i>LiFY-T Pro</i> service will end on today.
              </Typography>
            )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isMuted}
                onChange={(event) => setIsMuted(event.target.checked)}
              />
            }
            label={"Don't show again"}></FormControlLabel>
        </DialogContent>
        <DialogActions sx={{ pl: 2, pr: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => handleClose()}>
            OK, Noted
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

ExpiringNotice.displayName = 'ExpiringNotice'
export default ExpiringNotice
