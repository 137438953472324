import React, { forwardRef } from 'react'
import { Chip, IconButton, Stack, StackProps, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Icons } from '@/assets/icons'

interface AccounItemProps extends StackProps {
  name: string
  value: string
  editable?: boolean
  onEdit?: () => void
}

const AccounItem = forwardRef<typeof Stack, AccounItemProps>(
  ({ name, value, editable, ...props }, ref) => {
    return (
      <Stack
        sx={{ width: 1 }}
        direction="row"
        spacing={2}
        alignItems={'center'}>
        <Typography sx={{ minWidth: 140 }} color={grey[500]}>
          {name}
        </Typography>
        {value ? (
          <Typography sx={{ pl: 1 }} variant="body2">
            {value}
          </Typography>
        ) : (
          <Chip sx={{ opacity: 0.3 }} size="small" label={'Empty'} />
        )}
        {editable && (
          <IconButton size="small" color="primary">
            <Icons.EditOutlined fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
    )
  }
)

AccounItem.displayName = 'AccounItem'
export default AccounItem
