import React, { forwardRef } from 'react'
import { Stack, Skeleton, StackProps, Typography, Badge } from '@mui/material'
import { grey } from '@mui/material/colors'

interface ItemProps extends StackProps {
  loading: boolean
  value: string | number
  name: string
  unit: string
  addition?: string
}

const Item = forwardRef<typeof Stack, ItemProps>(
  ({ loading = false, value, unit, addition, name, ...props }, ref) => {
    return (
      <Stack alignItems="center">
        {loading && (
          <>
            <Skeleton sx={{ width: 50, height: 50 }} animation="wave" />
            <Skeleton sx={{ width: 50, height: 20 }} animation="wave" />
          </>
        )}

        {!loading && (
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                right: addition?.length > 6 ? 6 : 18,
                fontSize: 12,
                bgcolor: grey[200]
              }
            }}
            badgeContent={addition}
            color={'secondary'}>
            <Stack>
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Typography variant="h6">{value}</Typography>
                <Typography variant="caption">{unit}</Typography>
              </Stack>

              <Typography
                variant="caption"
                paragraph
                sx={{ m: 0, textAlign: 'center' }}>
                {name}
              </Typography>
            </Stack>
          </Badge>
        )}
      </Stack>
    )
  }
)

Item.displayName = 'WeatherItem'
export default Item
