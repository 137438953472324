import React, { forwardRef, useEffect, useState } from 'react'
import { Box, BoxProps, Stack, Typography, alpha } from '@mui/material'
import { DailyReport as DailyReportType } from '@/routes/manage/report/report.types'
import { CardStyle } from './home.const'
import { Icons } from '@/assets/icons'
import { grey, lightGreen, red } from '@mui/material/colors'
import { getDailyReport } from '@/service/data/report'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

interface DailyReportProps extends BoxProps {}

const DailyReport = forwardRef<typeof Box, DailyReportProps>(
  ({ ...props }, ref) => {
    const navigate = useNavigate()
    const date = moment()
    const [data, setData] = useState<DailyReportType | null>(null)
    const totalAlertCount = data?.totalAlertCount || 0
    const hasAlert = totalAlertCount > 0

    useEffect(() => {
      getDailyReport({
        date: date,
        page: 1,
        limit: 1
      }).then((resp) => {
        if (resp?.code === 200) {
          setData(resp.data?.dailyReports?.[0] || null)
        }
      })
    }, [])

    return (
      <Stack
        sx={{
          ...CardStyle,
          ...(hasAlert ? { pt: 0.5, pb: 0.5, pl: 2, pr: 2 } : { p: 2 }),
          transition: 'border 0.6s, box-shadow 0.3s, background-color .6s',
          backgroundColor: hasAlert ? red[50] : lightGreen[50],
          cursor: 'pointer',
          border: '2px solid rgba(0, 0, 0, 0)',

          '&:hover': {
            backgroundColor: 'white',
            borderColor: grey[200],
            boxShadow: !hasAlert
              ? `0 0 16px 2px ${lightGreen['A100']}`
              : `0 0 18px 1px ${alpha(red['A400'], 0.5)}`
          }
        }}
        onClick={() => navigate('/data/daily-report')}>
        <Stack
          sx={{ mt: 1, mb: 1, pr: 2 }}
          direction={'row'}
          justifyContent="space-between"
          alignItems="center">
          <Stack>
            <Typography paragraph variant="caption" sx={{ m: 0 }}>
              Daily report on {date.format('DD MMM YYYY')}
            </Typography>
            <Typography paragraph variant="body2" sx={{ m: 0 }}>
              {getDisplayMessage(totalAlertCount)}
            </Typography>
          </Stack>
          {hasAlert ? (
            <Icons.DailyReportWithAlarmSmall />
          ) : (
            <Icons.DailyReportNoAlarmSmall />
          )}
        </Stack>

        
      </Stack>
    )
  }
)

DailyReport.displayName = 'DailyReport'
export default DailyReport

const Item = ({ title, icon, value }) => {
  return (
    <Stack sx={{ color: grey[700] }} alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0.5}>
        {icon}
        <Typography variant="caption">{title}</Typography>
      </Stack>
      <Typography variant="subtitle2" textAlign={'center'}>
        {value}
      </Typography>
    </Stack>
  )
}

const getDisplayMessage = (alertCount: number): React.ReactNode => {
  if (alertCount === 0) {
    return (
      <>
        <Typography variant="h6" component={'span'} sx={{ mr: 1 }}>
          No
        </Typography>
        alarm yesterday
      </>
    )
  }

  return (
    <>
      <Typography variant="h6" component={'span'} sx={{ mr: 1 }}>
        {alertCount}
      </Typography>
      alert{alertCount > 1 ? 's' : ''} detected yesterday
    </>
  )
}
