import {
  UpdatedOrganization,
  NewOrganization,
  Organization
} from '@/routes/manage/organization/organization.types'
import request from '../request'
import { encryptPassword } from '@/routes/login/login.helper'
import { ListParams } from '@/routes/manage/organization/assign-trees/AssignTreeList'
import { Tree } from '@/routes/manage/data/trees/trees.types'
import { Group } from '@/routes/setting/users/groups/group.types'
import { NewGroup } from '@/routes/manage/organization/manage-groups/AddGroup'
import { EditGroup } from '@/routes/manage/organization/manage-groups/EditGroup'

export const getOrganizations = async (params?: {
  page: number
  limit: number
  name?
}) => {
  return request.get<{ organizations: Organization[]; totalCount: number }>(
    'manage/organization/organizations',
    {
      data: {
        page: params?.page,
        limit: params?.limit,
        ...(params.name ? { name: params?.name } : {})
      }
    }
  )
}

export const getOrganizationGroups = async (orgId: string) => {
  return request.get<Group[]>(`manage/organization/${orgId}/groups-all`)
}

export const createGroupForOrganization = async (
  organizationId: string,
  group: NewGroup
) => {
  return request.post<Group>(
    `manage/organization/${organizationId}/new-group`,
    {
      data: group
    }
  )
}
export const editGroupForOrganization = async (
  organizationId: string,
  groupId:string,
  group: EditGroup
) => {
  return request.put<Group>(
    `manage/organization/${organizationId}/edit-group/${groupId}`,
    {
      data: group
    }
  )
}

export const deleteGroupForOrganization = async (
  organizationId: string,
  groupId: string
) => {
  return request.delete<boolean>(
    `manage/organization/${organizationId}/delete-group/${groupId}`
  )
}
export const createOrganization = async (params: NewOrganization) => {
  const data = {
    name: params.name,
    fullname: params.fullname,
    regionId: params.regionId,
    address: params.address,
    industry: params.industry,

    lifytPro: params.lifytPro,
    expiredAt: params.expiredAt.valueOf(),
    disabled: params.disabled,

    contactPerson: params.contactPerson,
    contactPhone: params.contactPhone,
    contactEmail: params.contactEmail,

    username: params.username,
    nickname: params.nickname,
    password: encryptPassword(params.password),
    userEmail: params.userEmail,
    userPhone: params.userPhone,
    userAdditionalEmails: params.userAdditionalEmails
  }

  if (params.lifytPro) {
    data['lifytProExpiredAt'] = params.lifytProExpiredAt?.valueOf()
  }

  return request.post<Organization>('manage/organization', { data })
}

export const updateOrganization = async (
  organizationId: string,
  params: UpdatedOrganization
) => {
  const updates = {
    fullname: params?.fullname,
    regionId: params?.regionId,
    address: params?.address,
    industry: params?.industry,

    expiredAt: params?.expiredAt?.valueOf(),
    lifytPro: params?.lifytPro,
    disabled: params?.disabled,

    contactPerson: params?.contactPerson,
    contactPhone: params?.contactPhone,
    contactEmail: params?.contactEmail
  }

  if (params.lifytPro) {
    updates['lifytProExpiredAt'] = params.lifytProExpiredAt?.valueOf()
  }

  return request.put<Organization>(`manage/organization/${organizationId}`, {
    data: updates
  })
}

export const disableOrganization = async (id: string, disabled: boolean) => {
  return request.put<Organization>(`manage/organization/${id}/disable`, {
    data: { disabled }
  })
}

export const deleteOrganization = async (organizationId: string) => {
  return request.delete<Organization>(`manage/organization/${organizationId}`)
}

export const getAvailableTrees = async (params: ListParams) => {
  return request.get<{ trees: Tree[]; totalCount: number }>(
    `manage/organization/available-trees`,
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params.treeId ? { treeId: params.treeId } : {})
      }
    }
  )
}

export const getOrganizationTrees = async (
  organizationId: string,
  params: ListParams
) => {
  return request.get<{
    trees: Tree[]
    totalCount: number
  }>(`manage/organization/${organizationId}/trees`, {
    data: {
      page: params.page,
      limit: params.limit,
      ...(params.treeId ? { treeId: params.treeId } : {})
    }
  })
}

export const organizationAddTree = async (params: {
  organizationId: string
  treeId: string
}) => {
  return request.put<{ trees: Tree[] }>(
    `manage/organization/${params.organizationId}/add/${params.treeId}`
  )
}

export const organizationRemoveTree = async (params: {
  organizationId: string
  treeId: string
}) => {
  return request.put<{ trees: Tree[] }>(
    `manage/organization/${params.organizationId}/remove/${params.treeId}`
  )
}

export const changeOrgDefUserPsw = async (
  organizationId: string,
  newPsw: string
) => {
  return request.put<Organization>(
    `organizations/${organizationId}/def-user-psw`,
    {
      data: { new: newPsw }
    }
  )
}
