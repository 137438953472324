import { EChartOption } from 'echarts'
import { Tiltings } from '@/store/reducers/home/home.types'
import { TiltingLevelColors } from './TiltingLevel'

export const getTiltingChartOptions = (values: Tiltings) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    // color: [
    //   TiltingLevelColors.level1,
    //   TiltingLevelColors.level2,
    //   TiltingLevelColors.level3,
    //   TiltingLevelColors.level4
    // ],
    series: [
      {
        name: 'Tilting Level',
        type: 'pie',
        radius: ['65%', '75%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        emphasis: { disabled: true },
        data: [
          {
            value: values.level1,
            name: 'Level 1',
            itemStyle: { color: TiltingLevelColors.level1 }
          },
          {
            value: values.level2,
            name: 'Level 2',
            itemStyle: { color: TiltingLevelColors.level2 }
          },
          {
            value: values.level3,
            name: 'Level 3',
            itemStyle: { color: TiltingLevelColors.level3 }
          },
          {
            value: values.level4,
            name: 'Level 4',
            itemStyle: { color: TiltingLevelColors.level4 }
          }
        ]
      }
    ]
  }
}
