import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { TiltingLevelColors } from '@/routes/home/tilting-level/TiltingLevel'
import { FieledNames } from '@/routes/map/details/details.const'

const TilingLevel = ({ value }) => {
  return (
    <Stack direction="row">
      <Typography sx={{ width: 160, flexShrink: 0 }} variant="body2">
        {FieledNames['tiltingLevel']}
      </Typography>
      <Stack
        direction="row"
        flexGrow={1}
        spacing={1}
        justifyContent="space-between"
        alignItems="center">
        {value === 0 && <Typography variant="body2">N/A</Typography>}
        {value > 0 && (
          <>
            <LevelItem
              level="G"
              bgcolor={value === 1 ? TiltingLevelColors.level1 : grey[300]}
            />
            <LevelItem
              level="F"
              bgcolor={value === 2 ? TiltingLevelColors.level2 : grey[300]}
            />
            <LevelItem
              level="P"
              bgcolor={value === 3 ? TiltingLevelColors.level3 : grey[300]}
            />
            <LevelItem
              level="E"
              bgcolor={value === 4 ? TiltingLevelColors.level4 : grey[300]}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default TilingLevel

const LevelItem: FC<{ level: string; bgcolor: string }> = ({
  level,
  bgcolor
}) => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        bgcolor,
        color: 'white',
        height: 22,
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Typography variant="caption">{level}</Typography>
    </Stack>
  )
}
