import React, { forwardRef, useRef, useEffect } from 'react'
import { Box, BoxProps } from '@mui/material'
import { EChartOption } from 'echarts/lib/echarts'
import * as echarts from 'echarts/core'

interface ChartBoxProps extends BoxProps {
  option: EChartOption
}

const ChartBox = forwardRef<typeof Box, ChartBoxProps>(
  ({ option, ...props }, ref) => {
    const chartElemRef = useRef(null)
    const chartInsRef = useRef(null)

    useEffect(() => {
      if (!chartInsRef.current) {
        chartInsRef.current = echarts.init(chartElemRef.current)
      }
    }, [])

    useEffect(() => {
      if (chartInsRef.current && option) {
        chartInsRef.current.setOption(option)
      }
    }, [option, chartInsRef])

    return (
      <Box
        ref={chartElemRef}
        sx={{
          width: 1,
          height: 1
        }}></Box>
    )
  }
)

ChartBox.displayName = 'ChartBox'
export default ChartBox
