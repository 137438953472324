import { Icons } from '@/assets/icons'
import { ChannelEnum, NotificationTypeEnum } from './notifications.types'

export const NotificationText = {
  [NotificationTypeEnum.DailyReport]: 'Daily Report',
  [NotificationTypeEnum.DailyReportResent]: 'Daily Report Resent',
  [NotificationTypeEnum.Alert]: 'Special Alert',
  [NotificationTypeEnum.ModeChange]: 'Mode Change'
}

export const Channels = {
  [ChannelEnum.Email]: {
    icon: Icons.Email,
    text: 'Sent by email'
  },
  [ChannelEnum.SMS]: {
    icon: Icons.CircleNotifications,
    text: 'Sent by SMS'
  },
  [ChannelEnum.APP]: {
    icon: Icons.CircleNotifications,
    text: 'Sent by APP'
  }
}
