import React, { forwardRef } from 'react'
import {
  Box,
  FormLabel,
  FormControlProps,
  FormControl,
  Stack,
  alpha
} from '@mui/material'
import { FormLabelWidth } from './form.cont'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'
import { grey } from '@mui/material/colors'
import { primary } from '@/configs/styles/colors'

interface FormTextAreaProps extends FormControlProps {
  height?: number
  label: string
  value: string
  password?: boolean
  autopass?: boolean
  onValChange?: (string) => void
  labelWidth?: number
  helperText?: string
  placeholder?: string
  disabled?: boolean
}

const FormTextArea = forwardRef<typeof Box, FormTextAreaProps>(
  (
    {
      height = 120,
      labelWidth,
      label,
      value,
      onValChange,
      password = false,
      autopass = true,
      helperText,
      required,
      placeholder,
      error,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
        <FormLabel
          sx={{ width: labelWidth ?? FormLabelWidth, mr: 2, flexShrink: 0 }}>
          {label}
        </FormLabel>

        <Stack direction="row" sx={{ flexGrow: 1 }}>
          <StyledTextareaAutosize
            value={value}
            autoComplete="off"
            style={{ width: '100%', height: height }}
            placeholder={placeholder}
            onChange={(event) => onValChange(event.target.value)}
          />
        </Stack>
      </FormControl>
    )
  }
)

FormTextArea.displayName = 'FormTextArea'
export default FormTextArea

const StyledTextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[300]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${primary};
  }

  &:focus {
    border-color: ${primary};
    box-shadow: 0 0 0 1px ${
      theme.palette.mode === 'dark' ? primary : alpha(primary, 0.6)
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)
