// Region ID: 1001
// First number - 1: No. of Country or District
// The reset number - 001: No. of aree that leafiot has launched order by launch date
export enum RegionEnum {
  HongKong = '1001'
}

export const Regions = [
  {
    id: RegionEnum.HongKong,
    name: 'Hong Kong'
  }
]
