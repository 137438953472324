export const AppEnvi = `${process.env.REACT_APP_ENVI}`
export const AppVersion = `2.3`
export const VideoPath = `${process.env.PUBLIC_URL}/videos`
export const TemplatePath = `${process.env.PUBLIC_URL}/templates`

export const SubMenusStyle = {
  color: '#303030',
  mr: 1,
  ml: 1,
  fontSize: 'small',
  transition: 'font-size .3s'
}

export const ActiveSubmenuStyle = {
  color: 'primary.main',
  fontSize: 'medium',
  backgroundColor: 'rgba(0, 184, 126, 0.08)'
}

export const TiltingThreshold = 15
