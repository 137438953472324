import React, { forwardRef, useEffect, useState } from 'react'
import { Paper, Stack, StackProps, Typography } from '@mui/material'
import { getBlockchainCount, getStorageSize } from '@/service/manage/map'

interface BlockchainRecordProps extends StackProps {}

const BlockchainRecord = forwardRef<typeof Stack, BlockchainRecordProps>(
  ({ ...props }, ref) => {
    const [count, setCount] = useState<number>(0)
    const getDataAmount = async () => {
      const resp = await getBlockchainCount()
      if (resp?.code === 200) {
        setCount(resp.data)
      }
    }
    useEffect(() => {
      getDataAmount()
    }, [])
    return (
      <Paper sx={{ width: 1, height: 1, px: 2, py: 1 }}>
        <Stack>
          <Typography sx={{ m: 0 }} paragraph variant="overline" noWrap>
            Blockchain Records
          </Typography>
          <Typography sx={{ mb: 0 }} paragraph variant="h6">
            {count}
          </Typography>
        </Stack>
      </Paper>
    )
  }
)

BlockchainRecord.displayName = 'BlockchainRecord'
export default BlockchainRecord
