import React, { useState, useEffect } from 'react'
import NotificationItem from './NotificationItem'
import { Stack, Typography } from '@mui/material'
import { CardStyle } from '../home.const'

import { Notification as NotificationType } from './notifications.types'
import { getNotifications } from '@/service/home'

const Notification = () => {
  const [selected, setSelected] = useState(null)
  const [notifications, setNotifications] = useState<NotificationType[]>([])

  useEffect(() => {
    getNotifications().then((resp) => {
      if (resp?.code === 200) {
        setNotifications(resp.data)
      }
    })
  }, [])

  return (
    <Stack sx={{ ...CardStyle, p: 2, flexGrow: 1 }}>
      <Stack
        direction="row"
        sx={{ width: 1 }}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant={'subtitle2'}>NOTIFICATIONS</Typography>{' '}
      </Stack>
      <Stack sx={{ pl: 1, pt: 2, flexGrow: 1 }} spacing={1}>
        {notifications.map((item) => {
          return (
            <NotificationItem
              key={item.id}
              active={selected === item}
              value={item}
              onClick={() => setSelected(item)}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
Notification.displayName = 'Notification'
export default Notification
