import React, { useEffect } from 'react'
// import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Theme } from './app.const'
import { useAppDispatch, useAppSelector } from '@/store'
import AppRoutes from './routes/Routes'
import Login from './routes/login/Login'
import { globalSlice } from './store/reducers/global/global.slice'
import Notification from './components/Notification'
import ExpiringNotice, {
  DefaultExpiringNotice,
  ExpiringNoticeType
} from './components/ExpiringNotice'
import { ExpiringNoticeStorage } from './configs/storage-dics'
import { getTreesAsync } from '@/store/reducers/map/map.thunks'

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(() => createTheme(Theme), []) // [prefersDarkMode]
  const isSignIn = useAppSelector((state) => state.users.isSignIn)
  const notification = useAppSelector((state) => state.global.notification)

  const dispatch = useAppDispatch()

  const intializeExpiringNotice = () => {
    const expiringNoticeLocal = localStorage.getItem(ExpiringNoticeStorage)
    if (!expiringNoticeLocal) {
      localStorage.setItem(
        ExpiringNoticeStorage,
        JSON.stringify(DefaultExpiringNotice)
      )
      return
    }

    // Reset the notice again after 7 days
    const expiringNotice = JSON.parse(expiringNoticeLocal) as ExpiringNoticeType
    if (expiringNotice.expiredAt < Date.now()) {
      localStorage.setItem(
        ExpiringNoticeStorage,
        JSON.stringify(DefaultExpiringNotice)
      )
    }
  }
  useEffect(() => {
    if (isSignIn) {
      intializeExpiringNotice()
      dispatch(getTreesAsync())
    }
  }, [isSignIn])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Notification
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(notification)}
        type={notification?.type}
        caption={notification?.caption}
        desc={notification?.desc}
        onClose={() => dispatch(globalSlice.actions.setNotification(null))}
      />
      {isSignIn ? <AppRoutes /> : <Login />}
      {/* <ExpiringNotice /> */}
    </ThemeProvider>
  )
}

export default App
