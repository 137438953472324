import React, { forwardRef } from 'react'
import { Paper, PaperProps } from '@mui/material'
import DataGridC from '@/components/DataGridC'
import { TreeBySpeciesItem } from './EsgSpaciesCarbon'
import LinearProgress from '@mui/material/LinearProgress'
interface SpeciesListProps extends PaperProps {
  loading: boolean
  data: TreeBySpeciesItem[]
}

const SpeciesList = forwardRef<typeof Paper, SpeciesListProps>(
  ({ loading, data, ...props }, ref) => {
    return (
      <DataGridC
        loading={loading}
        hideFooter
        rows={data}
        slots={{ loadingOverlay: LinearProgress }}
        getRowId={(row) => row.species}
        columns={[
          {
            field: 'species',
            headerName: 'Species',
            sortable: false,
            filterable: false,
            flex: 1,
            disableColumnMenu: true,
            valueFormatter: (params) =>
              params.value === '' ? 'Others' : params.value
          },
          {
            field: 'count',
            headerName: 'Quantity',
            sortable: false,
            filterable: false,
            flex: 1,
            disableColumnMenu: true
          }
        ]}
      />
    )
  }
)

SpeciesList.displayName = 'SpeciesList'
export default SpeciesList
