import React, { forwardRef, useEffect, useState } from 'react'
import {
  Box,
  Collapse,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { Organization } from '../organization.types'
import { Icons } from '@/assets/icons'
import { Group } from '@/routes/setting/users/groups/group.types'
import { getOrganizationGroups } from '@/service/manage/organizations'
import GroupListItem from './GroupListItem'
import AddGroup from './AddGroup'

interface ManageGoupsProps extends DrawerProps {
  org: Organization
  onClose: () => void
}

const ManageGoups = forwardRef<typeof Box, ManageGoupsProps>(
  ({ org, open, onClose, ...props }, ref) => {
    const [groups, setGroups] = useState<Group[]>()
    const [showAdd, setShowAdd] = useState(false)

    const getGroups = async () => {
      if (org?.id) {
        const resp = await getOrganizationGroups(org?.id)
        if (resp.code === 200) {
          setGroups(resp.data)
        }
      }
    }

    const removeGroupFromList = (group: Group) => {
      setGroups(groups.filter((groupListItem) => groupListItem.id !== group.id))
    }

    useEffect(() => {
      setGroups([])
      setShowAdd(false)
      getGroups()
    }, [org])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden'
          }}>
          <Stack
            sx={{ p: 2, borderBottom: '1px solid #ddd' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              Groups
            </Typography>
            <Stack direction="row">
              {!showAdd && (
                <IconButton aria-label="add" onClick={() => setShowAdd(true)}>
                  <Icons.Add fontSize="small" />
                </IconButton>
              )}
              <IconButton aria-label="close" onClick={() => onClose()}>
                <Icons.Close fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack sx={{ flexGrow: 1, width: 480, overflow: 'auto' }}>
            <Collapse in={showAdd} exit={!showAdd}>
              <AddGroup
                organizationId={org?.id}
                open={showAdd}
                onSuccess={(newGroup) => {
                  setShowAdd(false)
                  setGroups([newGroup, ...groups])
                }}
                onClose={() => setShowAdd(false)}
              />
            </Collapse>
            {groups?.map((group) => {
              return (
                <GroupListItem
                  key={group.name}
                  group={group}
                  onDeleted={() => removeGroupFromList(group)}
                  onEdited={() => getGroups()}
                />
              )
            })}
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

ManageGoups.displayName = 'ManageGoups'
export default ManageGoups
