import React, { forwardRef, useState } from 'react'
import {
  Button,
  PopoverProps,
  Stack,
  StackProps,
  Typography
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Password from '../users/actions/Password'
import { useAppSelector } from '@/store'

interface PasswordItemProps extends StackProps {}

const PasswordItem = forwardRef<typeof Stack, PasswordItemProps>(
  ({ ...props }, ref) => {
    const user = useAppSelector((state) => state.users.user)
    const [actionItemParams, setActionItemParams] = useState<{
      anchor: PopoverProps['anchorEl']
    }>(null)
    return (
      <>
        <Stack
          sx={{ width: 1 }}
          direction="row"
          spacing={2}
          alignItems={'center'}>
          <Typography sx={{ minWidth: 140 }} color={grey[500]}>
            Password
          </Typography>

          <Button
            size="small"
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={(event) =>
              setActionItemParams({ anchor: event.currentTarget })
            }>
            Change
          </Button>
        </Stack>
        <Password
          open={Boolean(actionItemParams?.anchor)}
          anchorEl={actionItemParams?.anchor}
          user={{ id: user?.id, __v: user?.__v }}
          onClose={() => setActionItemParams(null)}
          onSuccess={() => setActionItemParams(null)}
        />
      </>
    )
  }
)

PasswordItem.displayName = 'PasswordItem'
export default PasswordItem
