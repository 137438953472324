export const WeatherStation = 'Hong Kong Observatory'

const weatherInfoBase =
  'https://data.weather.gov.hk/weatherAPI/opendata/weather.php'

export const flw = `${weatherInfoBase}?dataType=flw&lang=en`
export const rhrRead = `${weatherInfoBase}?dataType=rhrread&lang=en`
export const warnSum = `${weatherInfoBase}?dataType=warnsum&lang=en`

export const getWeatherIconUri = (iconId) => {
  return `https://www.hko.gov.hk/images/wxicon/pic${iconId}.png`
}

export const WeatherIconDecs = {
  w50: 'Sunny',
  w51: 'Sunny Periods',
  w52: 'Sunny Intervals',
  w53: 'Sunny Periods with A Few Showers',
  w54: 'Sunny Intervals with Showers',
  w60: 'Cloudy',
  w61: 'Light Rain',
  w62: 'Rain',
  w63: 'Heavy Rain',
  w64: 'Thunderstorms',
  w65: 'Overcast',
  w70: 'Fine',
  w71: 'Fine',
  w72: 'Fine',
  w73: 'Fine',
  w74: 'Fine',
  w75: 'Fine',
  w76: 'Mainly Cloudy',
  w77: 'Mainly Fine',
  w80: 'Windy',
  w81: 'Dry',
  w82: 'Humid',
  w83: 'Fog',
  w84: 'Mist',
  w85: 'Haze',
  w90: 'Hot',
  w91: 'Warm',
  w92: 'Cool',
  w93: 'Cold'
}
