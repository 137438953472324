import React, { forwardRef } from 'react'
import { Divider, Menu, MenuItem, MenuProps, PopoverProps } from '@mui/material'
import { Icons } from '@/assets/icons'

interface ActionsProps extends MenuProps {
  onItemSelect: (
    type: 'maintenance' | 'edit' | 'delete' | 'reset' | 'images' | 'leafiPlate',
    anchor?: PopoverProps['anchorEl'],
    resetType?: 'tilting' | 'azimuth'
  ) => void
  onClose: () => void
}

const Actions = forwardRef<typeof Menu, ActionsProps>(
  ({ open, onClose, anchorEl, onItemSelect, ...props }, ref) => {
    return (
      <Menu open={open} anchorEl={anchorEl} onClick={onClose}>
        <MenuItem
          key={'maintenance'}
          onClick={() => onItemSelect('maintenance', anchorEl)}>
          Maintenance
        </MenuItem>

        <MenuItem
          key={'images'}
          onClick={() => onItemSelect('images', anchorEl)}>
          Images
        </MenuItem>
        <Divider />

        <MenuItem
          key={'reset-tilting'}
          onClick={() => onItemSelect('reset', anchorEl, 'tilting')}>
          Reset Tilting
        </MenuItem>
        <MenuItem
          key={'reset-azimuth'}
          onClick={() => onItemSelect('reset', anchorEl, 'azimuth')}>
          Reset Azimuth
        </MenuItem>
        <Divider />

        <MenuItem
          key={'leafiPlate'}
          onClick={() => onItemSelect('leafiPlate', anchorEl)}>
          LeafiPlate
        </MenuItem>
        <Divider />

        <MenuItem
          key={'delete-tree'}
          onClick={() => onItemSelect('delete', anchorEl)}>
          Delete
        </MenuItem>
      </Menu>
    )
  }
)

Actions.displayName = 'Actions'
export default Actions
