import React, {
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  useState,
  useImperativeHandle
} from 'react'
import {
  Stack,
  Box,
  StackProps,
  TextField,
  Typography,
} from '@mui/material'
import { debounce } from '@/hooks/useDebounce'
import {
  getAvailableTrees,
  getOrganizationTrees
} from '@/service/manage/organizations'
import { grey } from '@mui/material/colors'
import AssignmentItem from './AssignTreeItem'
import NoData from './NoData'
import { Tree } from '@/routes/manage/data/trees/trees.types'
import { Organization } from '../organization.types'
import { LoadingButton } from '@mui/lab'

export type AssignmentType = 'available' | 'existence'

interface AssignmentListListProps extends StackProps {
  type: AssignmentType
  org: Organization
  onTreeAdded?: (tree: Tree) => void
  onTreeRemoved?: (tree: Tree) => void
}

export interface HandleParams {
  addTree: (tree: Tree) => void
}

const TitleText = {
  available: 'Available Trees',
  existence: 'Trees in'
}

export type ListParams = {
  treeId?: string
  page: number
  limit: 10
}

type ListData = {
  data: Tree[]
  totalCount: number
}

const AssignTreeList = forwardRef<HandleParams, AssignmentListListProps>(
  ({ type, org, onTreeAdded, onTreeRemoved, ...props }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [hasTreeAssigned, setHasTreeAssigned] = useState(false)
    const [listParams, setListParams] = useState<ListParams>({
      page: 1,
      limit: 10
    })
    const [listData, setListData] = useState<ListData>({
      data: [],
      totalCount: 0
    })

    const fetchTrees = async (listParams: ListParams) => {
      const resp =
        type === 'available'
          ? await getAvailableTrees(listParams)
          : await getOrganizationTrees(org.id, listParams)
      if (resp?.code === 200) {
        const listPage = listParams.page
        setListData({
          data:
            listPage > 1
              ? [...listData?.data, ...resp?.data?.trees]
              : resp?.data?.trees,
          totalCount: resp?.data?.totalCount
        })
        if (listPage > 1 && containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight
        }
      }
      setIsLoading(false)
    }

    const getTrees = useCallback(debounce(fetchTrees), [
      containerRef,
      listParams,
      listData,
      isLoading
    ])

    const onItemActionSuccess = (tree: Tree) => {
      setHasTreeAssigned(true)
      setListData({
        data: listData.data.filter((t) => t.treeId !== tree.treeId),
        totalCount: listData.totalCount - 1
      })
      if (type === 'available') {
        onTreeAdded?.(tree)
      } else {
        onTreeRemoved?.(tree)
      }
    }

    const addTreeToList = (tree: Tree) => {
      setListData({
        ...listData,
        data: [tree, ...listData.data],
        totalCount: listData.totalCount + 1
      })
    }

    useEffect(() => {
      getTrees(listParams)
    }, [listParams.page])

    useEffect(() => {
      getTrees(listParams)
    }, [listParams.treeId])

    useImperativeHandle(ref, () => ({
      addTree: addTreeToList
    }))

    return (
      <Stack
        flex={1}
        sx={{
          border: `1px solid ${grey[300]}`,
          backgroundColor: grey[1300],
          borderRadius: 2,
          overflow: 'hidden'
        }}>
        <Stack>
          <Typography sx={{ pt: 2, pl: 2, pr: 2 }} variant="body2">
            {TitleText[type]}
            {type === 'existence' && (
              <Typography
                component="span"
                variant="body2"
                paragraph={false}
                color={'warning.main'}>{` ${org.name}`}</Typography>
            )}
          </Typography>
          <TextField
            sx={{ mt: 2, ml: 2, mr: 2 }}
            size={'small'}
            value={listParams?.treeId}
            placeholder={'Tree ID'}
            variant="standard"
            onChange={(evt) => {
              setListParams({
                ...listParams,
                treeId: evt.target.value,
                page: 1
              })
            }}
          />
        </Stack>
        <Stack flexGrow={1} sx={{ overflow: 'hidden' }}>
          <Box ref={containerRef} sx={{ flexGrow: 1, px: 2, overflow: 'auto' }}>
            {listData.data?.map((tree, idx) => (
              <AssignmentItem
                key={tree.treeId}
                type={type}
                data={tree}
                orgId={org.id}
                onSuccess={(targetTree) => onItemActionSuccess(targetTree)}
              />
            ))}
            {listData.data?.length === 0 && <NoData />}
          </Box>
          <LoadingButton
            sx={{ width: 'auto' }}
            loading={isLoading}
            disabled={
              listData.totalCount > 0 &&
              listData.totalCount === listData.data.length
            }
            onClick={() => {
              if (isLoading) return
              setIsLoading(true)

              if (hasTreeAssigned) {
                listParams.page === 1
                  ? fetchTrees(listParams)
                  : setListParams({ ...listParams, page: 1 })
                setHasTreeAssigned(false)
                return
              }
              setListParams({ ...listParams, page: listParams.page + 1 })
            }}>
            {hasTreeAssigned
              ? 'Refresh'
              : `Load more (${listData?.data?.length}/${listData.totalCount})`}
          </LoadingButton>
        </Stack>
      </Stack>
    )
  }
)

AssignTreeList.displayName = 'AssignTreeList'
export default AssignTreeList
