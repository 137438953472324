import React, { useState, forwardRef, useEffect } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Button
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { Controller, useForm } from 'react-hook-form'
import { DefaultUpdateTree } from '../trees.const'
import FormInput from '@/components/form/FormInput'
import { getTreeDetails, getTreeSpecies } from '@/service/manage/trees'
import { updateTreeDetails } from '@/service/manage/trees'
import {
  Tree,
  TreeUpdates,
  NewTreeOrgOption,
  NewTreeGroupOption,
  TreeSpecies
} from '../trees.types'
import FormTextArea from '@/components/form/FormTextArea'
import { isNumber } from 'lodash'
import FormSelect from '@/components/form/FormSelect'
import { getOrganizations, getGroups } from '@/service/manage/users'

interface UpdateTreeProps extends DrawerProps {
  treeId: string
  onSuccess: (tree: Tree) => void
  onClose: () => void
}

const UpdateTree = forwardRef<typeof Drawer, UpdateTreeProps>(
  ({ open, onClose, onSuccess, treeId, ...props }, ref) => {
    const { control, watch, reset, setValue, handleSubmit, formState } =
      useForm<TreeUpdates>({ defaultValues: DefaultUpdateTree })
    const [orgList, setOrgList] = useState<NewTreeOrgOption[]>([])
    const [groupList, setGroupList] = useState<NewTreeGroupOption[]>([])
    const [speciesList, setSpeciesList] = useState<TreeSpecies[]>([])
    const watchOrgId = watch('organizationId')

    const fetchTreeSpeciesList = async () => {
      const resp = await getTreeSpecies()
      if (resp?.code === 200) {
        setSpeciesList(resp.data)
      }
    }

    const fetchOrgOptions = async () => {
      const resp = await getOrganizations()
      if (resp?.code === 200) {
        setOrgList(resp.data)
      }
    }

    const fetchGroupOptions = async (organizationId: string) => {
      const resp = await getGroups(organizationId)
      if (resp?.code === 200) {
        setGroupList(resp.data)
        if (resp.data.length === 1) {
          setValue('groupId', resp.data[0].id)
        }
      }
    }

    const fetchTreeDetails = async (treeId: string) => {
      const resp = await getTreeDetails(treeId)
      if (resp?.code === 200) {
        const tree = resp.data
        reset({
          userTreeId: tree.userTreeId,
          sensorId: tree.sensorId,

          organizationId: tree.groups[0],
          groupId: tree.groups[1],

          dbh: tree?.details?.dbh,
          species: tree?.details?.species,
          height: tree?.details?.height,
          crownSpread: tree?.details?.crownSpread,

          coordinates: tree?.location?.coordinates?.join(','),
          coordinatesHK1980: tree?.location?.coordinatesHK1980?.join(','),
          address: tree?.location?.address
        })
      }
    }

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await updateTreeDetails(treeId, values)
        if (resp?.code === 200) {
          onSuccess(resp.data)
        }
      })()
    }

    useEffect(() => {
      if (watchOrgId) {
        fetchGroupOptions(watchOrgId)
      }
    }, [watchOrgId])

    useEffect(() => {
      if (open && treeId) {
        fetchOrgOptions()
        fetchTreeSpeciesList()
        fetchTreeDetails(treeId)
      }
    }, [treeId, open])

    return (
      <Drawer open={open} anchor="right" onClose={onClose}>
        <Stack sx={{ width: 540, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              {treeId}
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
          <Stack
            sx={{ flexGrow: 1, pl: 3, pr: 2, pb: 3, overflow: 'auto' }}
            spacing={1}>
            <Typography
              sx={{ mt: 1, mb: 2 }}
              paragraph
              variant="subtitle1"
              color="primary.main">
              Basics
            </Typography>

            <Controller
              name="userTreeId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    label="User Tree ID"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />

            <Controller
              name="sensorId"
              control={control}
              // rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormInput
                  required
                  error={Boolean(fieldState.error)}
                  helperText="Please make sure the sensor ID is registered in LeafIot system. The system will use this ID to sychronize its data"
                  label="Sensor ID"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="organizationId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Organization"
                  value={field.value}
                  options={orgList.map((opt) => ({
                    label: opt.fullname,
                    value: opt.id
                  }))}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="groupId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Group"
                  value={field.value}
                  options={groupList.map((opt) => ({
                    label: opt.name,
                    value: opt.id
                  }))}
                  tip={
                    !watchOrgId && (
                      <Typography variant="caption" color="warning.main">
                        The group options will display when organization's been
                        selected
                      </Typography>
                    )
                  }
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="species"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormSelect
                  required
                  error={Boolean(fieldState.error)}
                  label="Species"
                  value={field.value}
                  options={speciesList.map((species) => ({
                    label: species.speciesEn,
                    value: species.speciesEn
                  }))}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="height"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label={'Height'}
                  labelTip={'Unit: m'}
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="dbh"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label="DBH"
                  labelTip={'Unit: mm'}
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="crownSpread"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  number
                  error={Boolean(fieldState.error)}
                  label="Crown Spread"
                  labelTip={'Unit: mm'}
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Typography
              sx={{ mt: 1, mb: 2 }}
              paragraph
              variant="subtitle1"
              color="primary.main">
              Location
            </Typography>
            <Controller
              name="coordinates"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const coordinates = value
                    .split(',')
                    .filter((c, idx) => idx < 2 && c !== '')
                    .map((c) => Number(c.trim()))
                  if (
                    coordinates.length !== 2 ||
                    !(isNumber(coordinates[0]) && isNumber(coordinates[1]))
                  ) {
                    return 'Invalid coordinates'
                  }

                  return undefined
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    required
                    error={Boolean(fieldState.error)}
                    label="Coordinates"
                    helperText={`${
                      fieldState?.error?.message
                        ? fieldState?.error?.message + '. '
                        : ''
                    }Format: lat,lng`}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="coordinatesHK1980"
              control={control}
              rules={{
                validate: (value) => {
                  if (value === '') {
                    return
                  }

                  const coordinates = value
                    .split(',')
                    .filter((c, idx) => idx < 2 && c !== '')
                    .map((c) => Number(c.trim()))
                  if (
                    coordinates.length !== 2 ||
                    !(isNumber(coordinates[0]) && isNumber(coordinates[1]))
                  ) {
                    return 'Invalid coordinates'
                  }

                  return
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormInput
                    error={Boolean(fieldState.error)}
                    label="Coordinates"
                    labelTip="HK1980"
                    helperText={`${
                      fieldState?.error?.message
                        ? fieldState?.error?.message + '. '
                        : ''
                    }Format: lat,lng`}
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )
              }}
            />
            <Controller
              name="address"
              control={control}
              rules={{ maxLength: 240 }}
              render={({ field, fieldState }) => (
                <FormTextArea
                  height={120}
                  error={Boolean(fieldState.error)}
                  label="Address"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{ p: 2, boxShadow: 6 }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!formState.isDirty}
                variant="contained"
                onClick={() => onSubmit()}>
                Submit
              </Button>
              <Button variant="text" onClick={() => onClose()}>
                Cancel
              </Button>
            </Stack>
            {!formState.isDirty && (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                * Cannot submit because no content is modified
              </Typography>
            )}
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

UpdateTree.displayName = 'UpdateTree'
export default UpdateTree
