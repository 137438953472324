import { NewTree } from './trees.types'

export const DefaultUpdateTree = {
  userTreeId: '',
  sensorId: '',
  species: '',
  dbh: 0,
  height: 0,
  crownSpread: 0,

  coordinates: '',
  coordinatesHK1980: '',
  address: ''
}

export const DefaultNewTree: NewTree = {
  treeId: '',
  userTreeId: '',
  sensorId: '',
  organizationId: '',
  groupId: '',

  species: '',
  dbh: 0,
  height: 0,
  crownSpread: 0,

  coordinates: '',
  coordinatesHK1980: '',
  address: '',

  resetTiltingValue: 0,
  resetAzimuthValue: 0
}
