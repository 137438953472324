import React, { forwardRef, useEffect, useCallback, useState } from 'react'
import {
  Box,
  BoxProps,
  IconButton,
  Paper,
  Stack,
  Typography,
  Button,
  Menu,
  MenuItem
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { getDataCountOfMonth } from '@/service/manage/tilting-data'
import { Icons } from '@/assets/icons'
import { RepairPeriodEnum } from './tilting-data.types'
import { repairTiltingData } from '@/service/manage/tilting-data'
import { grey } from '@mui/material/colors'

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props
  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0

  return (
    <Badge
      key={props.day.toString()}
      color="error"
      overlap="circular"
      variant={isSelected ? 'dot' : 'standard'}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  )
}

export const Today = dayjs()

interface DataCalenderProps extends BoxProps {
  onDateChange: (date: Date) => void
  onUpdated: () => void
}

const DataCalender = forwardRef<typeof Box, DataCalenderProps>(
  ({ onDateChange, onUpdated, ...props }, ref) => {
    const [loading, setLoading] = useState(false)
    const [highlightedDays, setHighlightedDays] = useState([])
    const [date, setDate] = useState<Dayjs>(Today)
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>(null)
    const [selectedDate, setSelectedDate] = useState(Today)

    const fetchDataCountOfMonth = async () => {
      setLoading(true)
      const resp = await getDataCountOfMonth(date.toDate())
      if (resp?.code === 200) {
        const daysInMonth = date.daysInMonth()
        const noDataDays = []
        const dataDays = resp.data.map((dayDataItem) => Number(dayDataItem.day))
        const endDay =
          date.year() === Today.year() && date.month() === Today.month()
            ? date.date()
            : daysInMonth

        for (let i = 1; i <= endDay; i++) {
          if (!dataDays.includes(i)) {
            noDataDays.push(i)
          }
        }

        setHighlightedDays(noDataDays)
      }
      setLoading(false)
    }

    const handleMonthChange = (newDate: Dayjs) => {
      if (
        newDate.year() === selectedDate.year() &&
        newDate.month() === selectedDate.month()
      ) {
        setDate(selectedDate)
      } else {
        setDate(newDate)
      }

      setHighlightedDays([])
    }

    const handleYearChange = (newDate: Dayjs) => {
      if (
        newDate.year() === selectedDate.year() &&
        newDate.month() === selectedDate.month()
      ) {
        setDate(selectedDate)
      } else {
        setDate(newDate)
      }
      setHighlightedDays([])
    }

    const handleDateChange = (newDate: Dayjs) => {
      setSelectedDate(newDate)
      onDateChange(newDate.toDate())
    }

    const repair = useCallback(
      async (period: RepairPeriodEnum) => {
        const inputDate =
          date.year() === selectedDate.year() &&
          date.month() === selectedDate.month()
            ? selectedDate
            : date

        const resp = await repairTiltingData(period, inputDate.toDate())

        if (resp?.code === 200) {
          setMenuAnchor(null)
          onUpdated()
        }
      },
      [selectedDate, date]
    )

    useEffect(() => {
      fetchDataCountOfMonth()
    }, [date])

    return (
      <Paper>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{ width: 1 }}
              minDate={dayjs('2023-01-01')}
              maxDate={Today}
              disableFuture
              defaultValue={Today}
              loading={loading}
              onYearChange={handleYearChange}
              onMonthChange={handleMonthChange}
              onChange={handleDateChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{ day: ServerDay }}
              slotProps={{ day: { highlightedDays } as any }}
            />
          </LocalizationProvider>
          <Stack
            direction="row"
            sx={{ pb: 1, px: 2 }}
            justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Icons.Circle
                fontSize="inherit"
                color="error"
                sx={{ transform: 'scale(.5)' }}
              />
              <Typography variant="caption" color={grey[500]}>
                Data Missing
              </Typography>
            </Stack>

            <Stack direction="row">
              <Button
                size="small"
                sx={{ textTransform: 'none' }}
                // endIcon={<Icons.ArrowDropDown fontSize="inherit" />}
                onClick={(evt) => setMenuAnchor(evt.currentTarget)}>
                Repair
              </Button>
              <IconButton
                disabled={loading}
                size="small"
                onClick={() => fetchDataCountOfMonth()}>
                <Icons.Refresh
                  fontSize="inherit"
                  sx={{
                    ...(loading && { animation: 'spin 0.6s linear infinite' })
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}>
            <MenuItem onClick={() => repair(RepairPeriodEnum.Day)}>
              Selected Day
              <Typography sx={{ ml: 1 }} variant="caption" color={grey[400]}>
                {dayjs(selectedDate).format('YYYY-MM-DD')}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => repair(RepairPeriodEnum.Month)}>
              Current Month
              <Typography sx={{ ml: 1 }} variant="caption" color={grey[400]}>
                {date.format('YYYY-MM')}
              </Typography>
            </MenuItem>
            {/* <MenuItem onClick={() => repair(RepairPeriodEnm.Year)}>
              Year
              <Typography sx={{ ml: 1 }} variant="caption" color={grey[400]}>
                {date.format('YYYY')}
              </Typography>
            </MenuItem> */}
          </Menu>
        </Stack>
      </Paper>
    )
  }
)

DataCalender.displayName = 'DataCalender'
export default React.memo(DataCalender)
