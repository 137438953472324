import React, { forwardRef, useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Stack,
  IconButton,
  Button,
  StackProps,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { sendDailyReport } from '@/service/manage/map'
import { Icons } from '@/assets/icons'
import { LoadingButton } from '@mui/lab'

interface ResendDailyReportProps extends StackProps {}

const ResendDailyReport = forwardRef<typeof Stack, ResendDailyReportProps>(
  ({ ...props }, ref) => {
    const [isSending, setIsSending] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const resendDailyReport = async () => {
      setIsSending(true)
      await sendDailyReport()
      setIsSending(false)
      setShowConfirm(false)
    }

    return (
      <Paper sx={{ width: 1, height: 1, px: 2, py: 1 }}>
        <Stack justifyContent="space-between">
          <Typography variant="overline">Resend Daily Report</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => setShowConfirm(true)} color="primary">
              <Icons.Send />
            </IconButton>
          </Box>
        </Stack>
        <Dialog
          open={showConfirm}
          onClose={() => setShowConfirm(false)}
          aria-labelledby="confirm-resend-daily-report"
          aria-describedby="confirm-resend-daily-report">
          <DialogTitle>Resend daily report</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to re-send daily report to all user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={isSending}
              onClick={() => resendDailyReport()}>
              Confirm
            </LoadingButton>
            <Button onClick={() => setShowConfirm(false)} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
)

ResendDailyReport.displayName = 'ResendDailyReport'
export default React.memo(ResendDailyReport)
