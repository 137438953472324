import React, { forwardRef, useState } from 'react'

import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface CopyButtonProps extends IconButtonProps {
  title: string
  value: string
  iconColor?: string
  stopProp?: boolean
}

const CopyButton = forwardRef<typeof IconButton, CopyButtonProps>(
  ({ title, value, size, stopProp = false, iconColor, ...props }, ref) => {
    const originalTip = `Copy ${title}`
    const [tips, setTips] = useState(originalTip)
    const [isOpenTips, setIsOpenTips] = useState(false)

    const onCopy = async () => {
      const isCopied = await copyToClipboard(value)
      isCopied ? setTips(`${title} copied!`) : setTips(`Failed to copy`)
    }

    return (
      <Tooltip title={tips} open={isOpenTips} placement="top">
        <IconButton
          size={size}
          sx={{ fontSize: 'inherit' }}
          onMouseOver={() => setIsOpenTips(true)}
          onMouseOut={() => {
            setIsOpenTips(false)
            setTips(originalTip)
          }}
          onClick={(event) => {
            stopProp && event.stopPropagation()
            onCopy()
          }}>
          <ContentCopyIcon fontSize="inherit" sx={{ color: iconColor }} />
        </IconButton>
      </Tooltip>
    )
  }
)

CopyButton.displayName = 'CopyButton'
export default CopyButton

// const copyToClipboard = async (textToCopy: string) => {
//   // navigator clipboard 需要https等安全上下文
//   if (navigator.clipboard && window.isSecureContext && navigator.permissions) {
//     // navigator clipboard 向剪贴板写文本
//     try {
//       await navigator.clipboard.writeText(textToCopy)
//       return true
//     } catch (error) {
//       return false
//     }
//   } else {
//     // 创建text area
//     let textArea = document.createElement('textarea')
//     textArea.value = textToCopy
//     // 使text area不在viewport，同时设置不可见
//     textArea.style.position = 'absolute'
//     textArea.style.opacity = ''
//     textArea.style.left = '-999999px'
//     textArea.style.top = '-999999px'
//     document.body.appendChild(textArea)
//     textArea.focus()
//     textArea.select()

//     return new Promise<boolean>((res, rej) => {
//       // 执行复制命令并移除文本框
//       document.execCommand('copy') ? res(true) : rej(false)
//       textArea.remove()
//     })
//   }
// }

export const copyToClipboard = async (text) => {
  if (navigator && navigator.clipboard) {
    await navigator.clipboard.writeText(text)
    return true
  }
  // 动态创建 textarea 标签
  const textarea = document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = true
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  textarea.style.opacity = '0'
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  textarea.value = text
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea)
  // 选中值并复制
  textarea.select()
  const result = document.execCommand('Copy')
  document.body.removeChild(textarea)
  return result
}
