import React, { forwardRef, useState } from 'react'
import {
  StackProps,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  alpha,
  Chip,
  Box,
  PopoverProps
} from '@mui/material'
import { Group } from './group.types'
import moment from 'moment'
import { Icons } from '@/assets/icons'
import { green, grey } from '@mui/material/colors'

interface ItemProps extends StackProps {
  avtive: boolean
  value: Group
  isDefault
  onGroupSelected: (id: string) => void
  onManageTrees: (group: Group) => void
  onDeleteGroup: (anchor: PopoverProps['anchorEl'], group: Group) => void
  onEditGroup: (group: Group) => void
}

const GroupItem = forwardRef<typeof Stack, ItemProps>(
  (
    {
      value,
      avtive = false,
      isDefault,
      onGroupSelected,
      onEditGroup,
      onDeleteGroup,
      onManageTrees,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <Stack
          sx={{
            p: 1,
            pl: 2,
            borderBottom: '1px solid #ddd',
            color: avtive ? 'primary.main' : 'inherit',
            backgroundColor: avtive ? alpha(green[500], 0.1) : 'inherit',
            '&:hover': {
              backgroundColor: alpha(green[500], 0.05)
            }
          }}
          onClick={() => onGroupSelected(!avtive ? value.id : undefined)}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="subtitle2">{value.name}</Typography>{' '}
              <Tooltip
                title={
                  <Stack>
                    <Typography variant="caption">
                      Created At{' '}
                      {moment(value.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>

                    <Typography variant="caption">
                      {value.description}
                    </Typography>
                  </Stack>
                }
                placement="top">
                <Icons.NotesOutlined fontSize="inherit" sx={{ mt: -0.5 }} />
              </Tooltip>
              {value.disabled && (
                <Chip
                  label={'Disabled'}
                  size="small"
                  variant="outlined"
                  sx={{ height: 18 }}
                />
              )}
            </Stack>
            <Stack direction="row">
              <IconButton
                title="Edit Group"
                size="small"
                disabled={value.isDefault}
                sx={{ fontSize: 'inherit' }}
                onClick={(event) => {
                  event.stopPropagation()
                  onEditGroup(value)
                }}>
                <Icons.EditOutlined fontSize="inherit" />
              </IconButton>
              <IconButton
                title="Delete Group"
                size="small"
                disabled={value.isDefault}
                sx={{ fontSize: 'inherit' }}
                onClick={(event) => {
                  event.stopPropagation()
                  onDeleteGroup(event.currentTarget, value)
                }}>
                <Icons.DeleteOutlineOutlined fontSize="inherit" />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ color: grey[600] }}>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <Stack
                title={'Members in Group'}
                direction="row"
                alignItems="center"
                spacing={1}>
                <Icons.Group fontSize="inherit" />
                <Typography component="span" fontSize="inherit">
                  {value.menberCount}
                </Typography>
              </Stack>

              <Stack
                title={'Number of Trees'}
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { color: 'primary.main' }
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  !isDefault && onManageTrees(value)
                }}>
                <Icons.Nature fontSize="inherit" />
                <Typography
                  sx={{
                    textDecoration: isDefault ? 'inherit' : 'underline'
                  }}
                  component="span"
                  fontSize="inherit">
                  {value.treeCount}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </>
    )
  }
)

GroupItem.displayName = 'GroupItem'
export default GroupItem
