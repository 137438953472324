import React, { forwardRef } from 'react'
import { Stack, Typography } from '@mui/material'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'

const NoData = () => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: 1, color: grey[300] }}>
    <Icons.InboxOutlined fontSize="large" />
    <Typography>No data</Typography>
  </Stack>
)

NoData.displayName = 'NoData'
export default NoData
