import React, { FC, useMemo } from 'react'
import { useAppSelector } from '@/store'
import { Avatar, Typography, Stack, alpha, Tooltip, Chip } from '@mui/material'
import { minidenticon } from 'minidenticons'
import { DarkColorInLogo, primary } from '@/configs/styles/colors'
import { RoleNames, RoleNamesColors } from '../manage/user/users.const'
import { SecondaryMainColor } from '@/app.const'
import { grey } from '@mui/material/colors'

const User: FC<{ type?: string }> = ({ type }) => {
  const user = useAppSelector((state) => state.users.user)
  const name = user?.nickname || 'User name'

  const svgURI = useMemo(
    () => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(name)),
    [name]
  )

  return (
    <Tooltip title={name} placement="top">
      <Stack
        sx={{ width: 1 }}
        spacing={0.5}
        justifyContent="center"
        alignItems="center">
        <Avatar
          sx={{
            p: 0.1,
            ml: -5,
            width: 64,
            height: 64,
            backgroundColor: grey[50],
            border: `4px solid #fff`,
            outline: `1px solid ${grey[200]}`
          }}
          src={user?.avatar || svgURI}></Avatar>
        <Typography
          component="p"
          variant="body2"
          sx={{
            width: 1,
            pl: 1,
            pr: 1,
            color: DarkColorInLogo,
            textAlign: 'center'
          }}
          noWrap>
          {name}
        </Typography>
        <Chip
          size="small"
          variant="outlined"
          sx={{
            height: 18,
            fontSize: 15,
            borderColor: RoleNamesColors[user?.role]
          }}
          label={
            <Typography
              fontSize={'inherit'}
              color={RoleNamesColors[user?.role]}>
              {RoleNames[user?.role]}
            </Typography>
          }
        />
      </Stack>
    </Tooltip>
  )
}

export default User
