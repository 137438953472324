import { SxProps } from '@mui/material'

export const ManageAlertListStyle: SxProps = {
  position: 'absolute',
  left: 1,
  bottom: 2,
  zIndex: 999,
  height: 320,
  width: 790,
  boxShadow: 5
}
