import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Grid, Paper, Stack, StackProps, Typography } from '@mui/material'
import ChartBox from './ChartBox'
import { getAlertOption, getDataPackageOption } from './esg-data.helper'
import { getEsgAlertSummary, getEsgDataSummary } from '@/service/esg'
import { ReactComponent as LoadingAnimationIcon } from '@/assets/icons/loading.animation.svg'
import { Icons } from '@/assets/icons'

export type DataPackageSummaryItem = {
  month: number
  count: number
}

export type DataPackageSummary = {
  totalCount: number
  dataByMouth: DataPackageSummaryItem[]
}

export type AlertSummaryItem = {
  month: number
  tiltingAlert: number
  vibration: number
}
export type AlertSummary = {
  tiltingAlertTotalCount: number
  vibrationTotalCount: number
  alertByMouth: AlertSummaryItem[]
}

interface EsgDataProps extends StackProps {
  refreshFlag: number
}

const EsgData = forwardRef<typeof Stack, EsgDataProps>(
  ({ sx, refreshFlag, ...props }, ref) => {
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [dataSummary, setDataSummary] = useState<DataPackageSummary>({
      totalCount: 0,
      dataByMouth: []
    })

    const [isLoadingAlert, setIsLoadingAlert] = useState(false)
    const [alertSummary, setAlertSummary] = useState<AlertSummary>({
      tiltingAlertTotalCount: 0,
      vibrationTotalCount: 0,
      alertByMouth: []
    })

    const getDataSummary = async () => {
      setIsLoadingData(true)
      const resp = await getEsgDataSummary()
      if (resp?.code) {
        setDataSummary(resp?.data)
      }
      setIsLoadingData(false)
    }

    const getAlertSummary = async () => {
      setIsLoadingAlert(true)
      const resp = await getEsgAlertSummary()
      if (resp?.code) {
        setAlertSummary(resp?.data)
      }
      setIsLoadingAlert(false)
    }

    useEffect(() => {
      getDataSummary()
      // getAlertSummary()
    }, [refreshFlag])

    return (
      <Stack sx={{ ...sx }} spacing={2} direction="row">
        <Paper
          sx={{
            height: 1,
            flex: 1,
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Stack>
            <Stack direction="row" spacing={2}>
              <Typography>Data Package</Typography>
              <Typography fontWeight="bold">
                {dataSummary.totalCount.toLocaleString()}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {isLoadingData ? (
              <Stack>
                <LoadingAnimationIcon />
                <Typography variant="overline">Loading</Typography>
              </Stack>
            ) : (
              <ChartBox
                option={getDataPackageOption(dataSummary.dataByMouth)}
              />
            )}
          </Box>
        </Paper>

        <Paper
          sx={{
            height: 1,
            flex: 1,
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              <Typography>Alert</Typography>
              <Typography fontWeight="bold">
                {(
                  alertSummary.tiltingAlertTotalCount +
                  alertSummary.vibrationTotalCount
                ).toLocaleString()}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    borderRadius: '16px',
                    bgcolor: 'rgb(0, 186, 115)'
                  }}></Box>
                <Typography variant="body2">Tiliting Alert</Typography>
                <Typography>
                  {alertSummary.tiltingAlertTotalCount.toLocaleString()}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    borderRadius: '16px',
                    bgcolor: 'rgb(186, 229, 199)'
                  }}></Box>
                <Typography variant="body2">Vibration Alert</Typography>
                <Typography>
                  {alertSummary.vibrationTotalCount.toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {/* {isLoadingAlert ? (
              <Stack>
                <LoadingAnimationIcon />
                <Typography variant="overline">Loading</Typography>
              </Stack>
            ) : (
              <ChartBox option={getAlertOption(alertSummary?.alertByMouth)} />
            )} */}

            <Stack alignItems="center" style={{ opacity: 0.3 }}>
              <Icons.UnderMantainance />
              <Typography variant="overline">Under maintenance</Typography>
            </Stack>
          </Box>
        </Paper>
      </Stack>
    )
  }
)

EsgData.displayName = 'EsgData'
export default EsgData
