import React, { useEffect, useRef, useState } from 'react'
import MapView from './view/View'
import { useLocation } from 'react-router-dom'
import MapSearch from './MapSearch'
import Details from './details/Details'
import { Layout } from '@/components/page'
import AlertList from '@/components/AlertList'
import { FilterTypeEnum } from './map.types'
import { useAppSelector } from '@/store'
import RootLayout from '../../components/page/RootLayout'
import { useDispatch } from 'react-redux'
import { mapSlice } from '@/store/reducers/map/map.slice'
import { ManageAlertListStyle } from '../manage/manage.const'
import { PublicAlertListStyle } from '../public.const'

const Map = () => {
  const location = useLocation()
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const isLoadingTrees = useAppSelector((state) => state.map.isLoadingTrees)
  const treeTotalCount = useAppSelector((state) => state.map.treeTotalCount)
  const treeLoadedCount = useAppSelector((state) => state.map.treeLoadedCount)
  const trees = useAppSelector((state) => state.map.trees)
  const filters = useAppSelector((state) => state.map.filters)

  const locationState = location.state as { navTreeId?: string }
  const navTreeId = locationState?.navTreeId

  const mapIns = useRef(null)
  const [selectedTreeId, setSelectTreeId] = useState(null)

  const clearSearch = () => searchRef.current?.clear()
  const clearActiveTree = () => mapIns.current.clearActiveTree()

  const locationTreeById = (treeId) => {
    mapIns.current.locationTreeById(treeId, { zoomIn: true })
  }

  const showSelectedTree = (treeId) => {
    mapIns.current.locationTreeById(treeId, { zoomIn: true, popup: true })
  }

  const setFilters = (fitlers: FilterTypeEnum[]) => {
    dispatch(mapSlice.actions.setMapFilter(fitlers))
  }

  const closeDetails = () => {
    setSelectTreeId(null)
    clearActiveTree()
  }

  useEffect(() => {
    if (navTreeId) {
      setTimeout(() => showSelectedTree(navTreeId), 500)
    }
  }, [navTreeId, trees])

  return (
    <RootLayout>
      <Layout sx={{ position: 'relative' }}>
        <MapSearch
          ref={searchRef}
          maitenanceOn={!filters.includes(FilterTypeEnum.Maintenance)}
          onSearch={(treeId) => locationTreeById(treeId)}
          onClear={() => clearActiveTree()}
        />

        <MapView
          ref={mapIns}
          controls={{ filter: { show: true } }}
          loading={{
            status: isLoadingTrees,
            placement: 'right-top',
            treeTotalCount,
            treeLoadedCount
          }}
          trees={trees}
          filters={filters}
          onTreeSelected={(treeId) => setSelectTreeId(treeId)}
          onFilterChange={(filterUpdates) => setFilters(filterUpdates)}
          onClearActive={clearSearch}
        />

        <Details
          open={Boolean(selectedTreeId)}
          treeId={selectedTreeId}
          showTreeId={false}
          onClose={() => closeDetails()}
        />

        <AlertList
          sx={PublicAlertListStyle}
          onLocate={(treeId) => showSelectedTree(treeId)}
        />
      </Layout>
    </RootLayout>
  )
}

export default Map
