import React, { forwardRef } from 'react'
import { Box, Grid, GridProps, Paper, PaperProps } from '@mui/material'
import DataStorage from './DataStorage'
import BlockchainRecord from './BlockchainRecord'
import LatestDataTime from './LatestDataTime'
import TotalTrees from './TotalTrees'
import ResendDailyReport from './ResendDailyReport'

interface OverviewStatisticsProps extends PaperProps {}

const RowGridProps: GridProps = {
  container: true,
  item: true,
  xs: 12,
  columnGap: 1,
  flexWrap: 'nowrap'
}

const OverviewStatistics = forwardRef<typeof Paper, OverviewStatisticsProps>(({ ...props }, ref) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 15,
        right: 15,
        width: 420
      }}>
      <Grid container gap={1}>
        <Grid {...RowGridProps}>
          <Grid item xs={6}>
            <TotalTrees />
          </Grid>
          <Grid item xs={6}>
            <LatestDataTime />
          </Grid>
        </Grid>
        <Grid {...RowGridProps}>
          <Grid item xs={6}>
            <DataStorage />
          </Grid>
          <Grid item xs={6}>
            <BlockchainRecord />
          </Grid>
        </Grid>

        <Grid {...RowGridProps}>
          <Grid item xs={6}>
            <ResendDailyReport />
          </Grid>
          <Grid item xs={6}>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
})

OverviewStatistics.displayName = 'OverviewStatistics'
export default OverviewStatistics
