import React, { forwardRef } from 'react'
import { Box, BoxProps, Menu, Paper, Stack, Typography } from '@mui/material'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'
import WildfireImg from '@/assets/images/leafiot-ai/wildfire-detection.jpg'
import LandSlideImg from '@/assets/images/leafiot-ai/landslide-detection.png'

interface LeafIotAiProps extends BoxProps {}

const LeafIotAi = forwardRef<typeof Box, LeafIotAiProps>(
  ({ ...props }, ref) => {
    const [anchor, setAnchor] = React.useState(null)
    return (
      <>
        <TipIconBtn
          aria-label="LeafIot AI"
          tip="LeafIot AI"
          size="small"
          onClick={(event) => setAnchor(event.currentTarget)}>
          <Icons.SmartToy fontSize="inherit" />
        </TipIconBtn>

        <Menu
          sx={{ ml: 6 }}
          open={Boolean(anchor)}
          anchorEl={anchor}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => setAnchor(null)}>
          <Stack sx={{ p: 2, height: 600 }} spacing={2}>
            <Paper sx={{ width: 400, p: 2 }} elevation={2}>
              <Typography variant="h6">Landslide Detection</Typography>
              <Typography variant="body2" paragraph>
                Real-time 2d and 3d visualisations and integrated landslide
                detection can empower the user to the informed decisions and
                mitigate risks immediately
              </Typography>
              <img width="100%" src={LandSlideImg} alt="landslide" />
            </Paper>
            <Paper sx={{ width: 400, p: 2 }} elevation={2}>
              <Typography variant="h6">Fire-eye Detection</Typography>
              <Typography variant="body2" paragraph>
                Each sensor provides industry-grade accuracy, real-time
                surveillance, and early detection of emerging wildfire ,
                shifting the focus to efficient early suppression (for
                landslide: and debris flow risk) rather than becoming a
                threatening hazard to human life
              </Typography>
              <img width="100%" src={WildfireImg} alt="wild-fire" />
            </Paper>
          </Stack>
        </Menu>
      </>
    )
  }
)

LeafIotAi.displayName = 'LeafIotAi'
export default LeafIotAi
