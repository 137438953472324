import React, { forwardRef } from 'react'
import { Stack, StackProps, Typography, Tooltip, Chip } from '@mui/material'
import { AppEnvi, AppVersion } from '@/configs/config.const'

const EnviColor = {
  BETA: 'red',
  DEV: 'blue'
}
const EnviLetter = {
  BETA: 'B',
  DEV: 'D'
}
interface EnviProps extends StackProps {}

const Meta = forwardRef<typeof Stack, EnviProps>(({ ...props }, ref) => {
  return (
    <Tooltip
      title={
        <Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{ textAlign: 'center', m: 0 }}>
              Environment
            </Typography>
            <Typography
              variant="caption"
              fontWeight="bolder"
              sx={{ textAlign: 'center', m: 0 }}>
              {AppEnvi}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{ textAlign: 'center', m: 0 }}>
              Version
            </Typography>
            <Typography
              variant="caption"
              fontWeight="bolder"
              sx={{ textAlign: 'center', m: 0 }}>
              {AppVersion}
            </Typography>
          </Stack>
        </Stack>
      }>
      {AppEnvi !== 'PROD' && (
        <Chip
          sx={{
            position: 'absolute',
            zIndex: 9999,
            top: 0,
            left: 0,
            color: '#fff',
            borderRadius: 0,
            bgcolor: EnviColor[AppEnvi]
          }}
          size="small"
          label={EnviLetter[AppEnvi]}
        />
      )}
    </Tooltip>
  )
})

Meta.displayName = 'Meta'
export default Meta
