import { Alert } from '../data/data.types'
import {
  BlockchainRecordTypeEnum,
  TreeDetailsType,
  TreeReset
} from '../manage/data/trees/trees.types'

export type MapTreeType = {
  treeId: string
  userTreeId: string
  sensorId: string
  plateId: string
  // latest data reseted
  isDisconnected: boolean
  dataRecordedAt: Date
  tiltingLevel: number
  tiltingAngle: number
  azimuth: number
  voltage: number
  vibration: boolean
  // details
  coordinates: number[]
  address: string
  species: string
  muted: boolean
  maintenance: boolean
}

type SensorModeType = {
  sendInterval: number
  samplingInterval: number
  failureRetry: number
  updatedAt: Date
}

export type MapTreeDetailsType = {
  id: string
  treeId: string
  userTreeId: string
  plateId: string
  tiltingAngle: number
  azimuth: number
  coordinates: number[]
  address: string
  details: TreeDetailsType
  dataReceivedAt: Date
  level: number
  vibration: { status: boolean; at: Date }
  updatedAt: Date
  sensorId: string
  signalStrength: number
  voltage: number
  mode: { inSystem: SensorModeType; inSensor: SensorModeType }
  maintenance: number
  blockchain: {
    id: string
    type: BlockchainRecordTypeEnum
    createdAt: Date
  }
  resets: TreeReset[]
  alerts: Alert[]
}

export type TreesFilterType = {
  treeId?: String
  page?: number
  limit?: number
}

export enum FilterTypeEnum {
  All = 'ALL',
  Disconnection = 'DISCONNECTION',
  Good = 'GOOD',
  Fair = 'FAIR',
  Poor = 'POOR',
  Emergency = 'EMERGENCY',
  Maintenance = 'MAINTENANCE',
  LowPower = 'LOWPOWER'
}
