import React, { forwardRef, useEffect, useState } from 'react'
import { Box, BoxProps, Avatar, Skeleton } from '@mui/material'
import Lightbox from 'yet-another-react-lightbox'
import TreePanoramaImg from '@/assets/images/home/tree-panorama.png'
import { getTreeImages } from '@/service/manage/trees'

interface TreeTumbnailProps extends BoxProps {
  treeId: string
}

const TreeTumbnail = forwardRef<typeof Box, TreeTumbnailProps>(
  ({ treeId, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false)
    const [showLightBox, setShowLightBox] = useState(false)
    const [slides, setSlides] = useState([])
    const [imageThumbnail, setImageThumbnail] = useState('')

    const fetchTreeImageSmall = async () => {
      setIsLoading(true)
      const resp = await getTreeImages(treeId, 16)
      if (resp?.code === 200 && resp.data.length > 0) {
        setImageThumbnail(resp.data[0].image)
      }
      setIsLoading(false)
    }

    const fetchTreeImages = async () => {
      const resp = await getTreeImages(treeId)
      if (resp?.code === 200 && resp.data.length > 0) {
        const fullTreeImages = resp.data
        setSlides([{ src: fullTreeImages[0].image }])
      }
    }
    useEffect(() => {
      setImageThumbnail('')
      setSlides([])

      if (treeId) {
        fetchTreeImageSmall()
        fetchTreeImages()
      }
    }, [treeId])

    return (
      <Box>
        {isLoading ? (
          <Skeleton animation="wave" variant="rounded" width={24} height={24} />
        ) : (
          <Avatar
            variant="rounded"
            sx={{ width: 24, height: 24, cursor: 'pointer' }}
            src={imageThumbnail ? imageThumbnail : TreePanoramaImg}
            onClick={() => imageThumbnail && setShowLightBox(true)}
          />
        )}

        <Lightbox
          open={showLightBox}
          styles={{ container: { backgroundColor: 'rgba(0,0,0,.5)' } }}
          slides={slides}
          close={() => setShowLightBox(false)}></Lightbox>
      </Box>
    )
  }
)

TreeTumbnail.displayName = 'TreeTumbnail'
export default TreeTumbnail
