import React, { FC } from 'react'

import Item from './Item'
import TilingLevel from '@/routes/map/popup//TilingLevel'
import Voltage from '@/routes/map/popup/Battery'
import { MapTreeDetailsType } from '../map.types'
import { getDecimal } from '@/utils/tool'
import moment from 'moment'
import { Stack } from '@mui/material'
import { getTiltingLevel } from '../map.helper'
import { LowBatteryThreshHold } from '../view/view.const'

const Status: FC<{ tree: MapTreeDetailsType }> = ({ tree }) => {
  return (
    <Stack sx={{ pl: 3, pr: 3 }} spacing={1}>
      <Item
        type={'number'}
        name={'tiltingAngle'}
        value={
          tree?.voltage > LowBatteryThreshHold
            ? getDecimal(tree?.tiltingAngle, 2)
            : 'N/A'
        }
      />
      <Item
        type={'number'}
        name={'azimuth'}
        tip="The angle between the tree tilting and the north direction, measured clockwise from the north direction."
        value={
          tree?.voltage > LowBatteryThreshHold
            ? getDecimal(tree?.azimuth, 2)
            : 'N/A'
        }
      />

      <TilingLevel
        value={
          tree?.voltage > LowBatteryThreshHold
            ? getTiltingLevel(tree?.tiltingAngle)
            : 0
        }></TilingLevel>
      <Voltage
        name={'voltage'}
        value={getDecimal(
          tree?.voltage > 0 ? tree?.voltage / 1000 : tree?.voltage
        )}
      />
      <Item
        type={'date'}
        name={'dataReceivedAt'}
        value={
          tree?.dataReceivedAt &&
          moment(tree?.dataReceivedAt).format('YYYY-MM-DD HH:mm')
        }
      />
    </Stack>
  )
}

export default Status
