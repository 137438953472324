import React, { forwardRef, useEffect } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Button,
  Divider
} from '@mui/material'
import {
  UpdatedOrganization,
  Organization,
  ServiceTypeEnum
} from '../organization.types'
import { Icons } from '@/assets/icons'
import { useForm, Controller } from 'react-hook-form'
import { DefaultUpdatedOrganization } from '../organizaiton.const'
import { RegionEnum, Regions } from '@/configs/config.regions'
import moment from 'moment'
import FormInput from '@/components/form/FormInput'
import FormSelect from '@/components/form/FormSelect'
import FormDate from '@/components/form/FormDate'
import FormSwitch from '@/components/form/FormSwitch'
import { updateOrganization } from '@/service/manage/organizations'

interface EditProps extends DrawerProps {
  data: Organization
  onClose: () => void
  onSuccess: (data: Organization) => void
}

const EditOrganization = forwardRef<typeof Drawer, EditProps>(
  ({ open, onClose, onSuccess, data, ...props }, ref) => {
    const { control, handleSubmit, watch, reset, formState } =
      useForm<UpdatedOrganization>({
        defaultValues: DefaultUpdatedOrganization
      })

    const lifytProWatch = watch('lifytPro')

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await updateOrganization(data.id, values)
        if (resp?.code === 200) {
          onSuccess(resp.data)
        }
      })()
    }

    useEffect(() => {
      if (data) {
        const liftTSubs = data.subscriptions.find(
          (s) => s.serviceType === ServiceTypeEnum.LIFY_T
        )
        const liftTProSubs = data.subscriptions.find(
          (s) => s.serviceType === ServiceTypeEnum.LIFY_T_PRO
        )
        reset({
          fullname: data.fullname,
          regionId: RegionEnum.HongKong,
          address: data.address,
          industry: data.industry,

          lifytPro: !!liftTProSubs,
          lifytProExpiredAt: liftTProSubs?.expiredAt
            ? moment(liftTProSubs.expiredAt)
            : null,
          expiredAt: liftTSubs?.expiredAt ? moment(liftTSubs.expiredAt) : null,
          disabled: data.disabled,

          contactPerson: data?.contact?.person ?? '',
          contactPhone: data?.contact?.phone ?? '',
          contactEmail: data?.contact?.email ?? ''
        })
      }
    }, [data])

    return (
      <Drawer open={open} variant="temporary" anchor="right" onClose={onClose}>
        <Stack
          sx={{
            width: 540,
            height: 1,
            overflow: 'hidden'
          }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6" color="primary.main">
              {data?.name}
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexGrow: 1,
              pt: 2,
              pb: 2,
              pl: 3,
              pr: 3,
              width: '100%',
              overflow: 'auto'
            }}>
            {open && data && (
              <form>
                <Stack spacing={2}>
                  <Typography
                    sx={{ mt: 1, mb: 2 }}
                    paragraph
                    variant="subtitle1"
                    color="primary.main">
                    Basics
                  </Typography>

                  <Controller
                    name="fullname"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        required
                        label="Full name"
                        helperText="Organization's official name"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    name="regionId"
                    disabled={data.isLeafiot}
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        required
                        disabled={field.disabled}
                        label="Region"
                        options={Regions.map((r) => ({
                          label: r.name,
                          value: r.id
                        }))}
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label="Address"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label="Industry"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />

                  <Divider sx={{ mt: 2, mb: 2 }}></Divider>
                  <Typography
                    sx={{ mt: 1, mb: 2 }}
                    paragraph
                    variant="subtitle1"
                    color="primary.main">
                    Availability
                  </Typography>

                  <Controller
                    name="expiredAt"
                    disabled={data.isLeafiot}
                    control={control}
                    render={({ field }) => (
                      <FormDate
                        label="Expired At"
                        disabled={field.disabled}
                        minDate={moment()}
                        tip="The expired date of access to the LeafIot, default is 60 days after created. "
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    name="lifytPro"
                    control={control}
                    disabled={data.isLeafiot}
                    render={({ field }) => {
                      return (
                        <FormSwitch
                          sx={{ mb: 1 }}
                          disabled={field.disabled}
                          label="LIFY-T Pro"
                          value={field.value}
                          onValChange={field.onChange}
                        />
                      )
                    }}
                  />

                  {lifytProWatch && (
                    <Controller
                      name="lifytProExpiredAt"
                      control={control}
                      disabled={data.isLeafiot}
                      rules={{ validate: (val) => !!val }}
                      render={({ field, fieldState }) => {
                        return (
                          <FormDate
                            sx={{ mb: 1 }}
                            label=""
                            disabled={field.disabled}
                            minDate={moment()}
                            tip="Specify the expired date of LIFY-T Pro Service"
                            error={Boolean(fieldState.error)}
                            value={field.value}
                            onValChange={field.onChange}
                          />
                        )
                      }}
                    />
                  )}

                  <Controller
                    name="disabled"
                    control={control}
                    disabled={data.isLeafiot}
                    render={({ field }) => (
                      <FormSwitch
                        label="Disabled"
                        disabled={field.disabled}
                        tip="User access to the LeafIot system will be suspended when disabled is true"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />

                  <Divider sx={{ mt: 2, mb: 2 }} />

                  <Typography
                    sx={{ mt: 1, mb: 2 }}
                    paragraph
                    variant="subtitle1"
                    color="primary.main">
                    Contact
                  </Typography>

                  <Controller
                    name="contactPerson"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        sx={{ mb: 2 }}
                        label="Person"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        sx={{ mb: 2 }}
                        label="Phone"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        sx={{ mb: 2 }}
                        label="Email"
                        value={field.value}
                        onValChange={field.onChange}
                      />
                    )}
                  />
                </Stack>
              </form>
            )}
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 2, boxShadow: 6 }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!formState.isDirty}
                variant="contained"
                onClick={() => onSubmit()}>
                Submit
              </Button>
              <Button variant="text" onClick={() => onClose()}>
                Cancel
              </Button>
            </Stack>

            {!formState.isDirty && (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                * Cannot submit because no content is modified
              </Typography>
            )}
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

EditOrganization.displayName = 'EditOrganization'
export default EditOrganization
