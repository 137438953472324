import React, { FC, forwardRef } from 'react'

import { useAppSelector } from '@/store/'
import {
  Tooltip,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  StackProps
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'
import TiltingLevelItem from './TiltingLevelItem'
import Chart from './Chart'
import { CardStyle } from '../home.const'
import { PrimaryMainColor } from '@/app.const'
import { FilterTypeEnum } from '@/routes/map/map.types'

export const TiltingLevelColors = {
  level1: PrimaryMainColor,
  level2: '#a0d100',
  level3: '#FF5121',
  level4: '#b80000'
}

interface TiltingLevelProps extends StackProps {
  onItemSelect: (filters: FilterTypeEnum[]) => void
}

const TiltingLevel = forwardRef<typeof Stack, TiltingLevelProps>(
  ({ onItemSelect, ...props }, ref) => {
    const trees = useAppSelector((state) => state.map.trees || [])
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('lg'))

    const tiltings = trees.reduce(
      (initTilting, item) => {
        const level = item.tiltingLevel
        initTilting[`level${level}`] !== undefined
          ? ++initTilting[`level${level}`]
          : ++initTilting.nodata

        return initTilting
      },
      { level1: 0, level2: 0, level3: 0, level4: 0, nodata: 0 }
    )

    return (
      <Stack
        direction="row"
        sx={{ ...CardStyle, ...(smallScreen ? { mt: '0px !important' } : {}) }}>
        <Chart sx={{ p: 1, width: '40%' }} values={tiltings} />
        <Stack
          sx={{
            flexGrow: 1,
            pt: 0.5,
            pb: 0.5,
            pl: 2,
            pr: 2
          }}
          justifyContent="center"
          spacing={1}>
          <TiltingLevelItem
            title={'Good'}
            value={tiltings.level1}
            color={TiltingLevelColors.level1}
            onClick={() => onItemSelect([FilterTypeEnum.Good])}
          />
          <TiltingLevelItem
            title={'Fair'}
            value={tiltings.level2}
            color={TiltingLevelColors.level2}
            onClick={() => onItemSelect([FilterTypeEnum.Fair])}
          />
          <TiltingLevelItem
            title={'Poor'}
            value={tiltings.level3}
            color={TiltingLevelColors.level3}
            onClick={() => onItemSelect([FilterTypeEnum.Poor])}
          />
          <TiltingLevelItem
            title={'Emergency'}
            value={tiltings.level4}
            color={TiltingLevelColors.level4}
            onClick={() => onItemSelect([FilterTypeEnum.Emergency])}
          />
        </Stack>

        <Tooltip
          title={
            <Stack>
              <Stack direction="row">
                <Typography variant="body2" sx={{ width: 100, flexShrink: 1 }}>
                  Good
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {'0° ≤ Tilting < 6°'}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography variant="body2" sx={{ width: 100, flexShrink: 1 }}>
                  Fair
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {'6° ≤ Tilting < 15°'}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography variant="body2" sx={{ width: 100, flexShrink: 1 }}>
                  Poor
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {'15° ≤ Tilting < 70°'}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography variant="body2" sx={{ width: 100, flexShrink: 1 }}>
                  Emergency
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {'Tilting ≥ 70°'}
                </Typography>
              </Stack>
            </Stack>
          }>
          <Icons.HelpOutline
            fontSize="inherit"
            sx={{
              position: 'absolute',
              left: 10,
              top: 10,
              color: grey[400]
            }}
          />
        </Tooltip>
      </Stack>
    )
  }
)

export default TiltingLevel
