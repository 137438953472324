import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  Button,
  IconButton,
  Divider
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { DefaultUpdateUser } from '../users.const'
import { updateUser, getOrganizations, getGroups } from '@/service/manage/users'
import {
  User,
  UpdateUser,
  OrganizationOptions,
  GroupOptions
} from '../users.types'
import { Controller, useForm } from 'react-hook-form'
import { RolesEnum } from '../users.types'
import { Regions } from '@/configs/config.regions'
import { RoleNames } from '../users.const'
import FormInput from '@/components/form/FormInput'
import FormRadio from '@/components/form/FormRadio'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import { RoleTip } from './NewUser'
interface EditUserProps extends DrawerProps {
  user: User
  onClose: () => void
  onSuccess: (user?: User) => void
}

const EditUser = forwardRef<typeof Drawer, EditUserProps>(
  ({ open, user, onClose, onSuccess, ...props }, ref) => {
    const [groups, setGroups] = useState<GroupOptions[]>([])
    const [organizations, setOrganizations] = useState<OrganizationOptions[]>(
      []
    )

    const { control, reset, handleSubmit, watch, formState } =
      useForm<UpdateUser>({
        defaultValues: DefaultUpdateUser
      })
    const organizationId = watch('organizationId')

    const onSubmit = () => {
      handleSubmit(async (values: UpdateUser) => {
        const resp = await updateUser(user.id, {
          ...values,
          __v: user.__v
        })

        if (resp?.code === 200) {
          onSuccess(resp.data)
        }
      })()
    }

    useEffect(() => {
      if (user) {
        const updates: UpdateUser = {
          nickname: user?.nickname,
          email: user?.email,
          regionId: user?.regionId,
          organizationId: user?.group?.organizationId,
          groupId: user?.group?.groupId,
          phone: user?.phone,
          role: user?.role,
          disabled: user?.disabled
        }
        if (user?.isDefault) {
          updates.additionalEmails = user?.additionalEmails
        }
        reset(updates)
      }
    }, [user])

    useEffect(() => {
      if (open) {
        getOrganizations().then((resp) => {
          if (resp?.code === 200) {
            setOrganizations(resp.data)
          }
        })
      }
    }, [open])

    useEffect(() => {
      if (open && organizationId) {
        getGroups(organizationId).then((resp) => {
          if (resp?.code === 200) {
            setGroups(resp.data)
          }
        })
      }
    }, [open, organizationId])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden'
          }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              {user?.username}
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              flexGrow: 1,
              width: 640,
              pt: 2,
              pl: 4,
              pr: 4,
              pb: 2,
              overflow: 'auto'
            }}
            spacing={2}>
            {open && (
              <>
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Nick name"
                      helperText={
                        'Nick name will replace user name as display name to show in the system'
                      }
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Divider />

                <Controller
                  name="regionId"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      label="Region"
                      options={Regions.map((region) => ({
                        label: region.name,
                        value: region.id
                      }))}
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="organizationId"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      label="Organization"
                      options={organizations.map((g) => ({
                        label: g.name,
                        value: g.id
                      }))}
                      value={field.value}
                      onValChange={field.onChange}
                      tip={
                        <Typography variant="caption" color={'warning.main'}>
                          Please input this option carefully
                        </Typography>
                      }
                    />
                  )}
                />

                <Controller
                  name="groupId"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      label="Group"
                      options={groups.map((g) => ({
                        label: g.name,
                        value: g.id
                      }))}
                      value={field.value}
                      onValChange={field.onChange}
                      tip={
                        <Typography variant="caption" color={'warning.main'}>
                          Please input this option carefully
                        </Typography>
                      }
                    />
                  )}
                />
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <FormRadio
                      label="Role"
                      disabled={user?.isDefault}
                      options={[
                        {
                          label: RoleNames[RolesEnum.Admin],
                          value: RolesEnum.Admin
                        },
                        {
                          label: RoleNames[RolesEnum.Manager],
                          value: RolesEnum.Manager
                        },
                        {
                          label: RoleNames[RolesEnum.User],
                          value: RolesEnum.User
                        }
                      ]}
                      labelTip={<RoleTip />}
                      value={field.value}
                      tip={
                        user?.isDefault &&
                        'Role of default user cannot be changed'
                      }
                      onValChange={field.onChange}
                    />
                  )}
                />

                <Divider />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Email"
                      helperText="Email to receive notification from LeafIot"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />

                {user?.isDefault && (
                  <Controller
                    name="additionalEmails"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FormInput
                          label="Additional Emails"
                          helperText="Each account can 3 emails to receive notification from LeafIot. Spereate by ','"
                          value={field?.value?.join(',')}
                          onValChange={(val) => field.onChange(val.split(','))}
                        />
                      )
                    }}
                  />
                )}

                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Phone"
                      helperText="Phone to receive alert massage from LeafIot"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Divider />

                <Controller
                  name="disabled"
                  control={control}
                  render={({ field }) => (
                    <FormSwitch
                      label="Disable"
                      tip="User's access will be suspended after creation"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
              </>
            )}
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 2, boxShadow: 6 }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!formState.isDirty}
                variant="contained"
                onClick={() => onSubmit()}>
                Submit
              </Button>
              <Button variant="text" onClick={() => onClose()}>
                Cancel
              </Button>
            </Stack>
            {!formState.isDirty && (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                * Cannot submit because no content is modified
              </Typography>
            )}
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

EditUser.displayName = 'EditUser'
export default EditUser
