import { ChartColors } from './chart.const'
import { TiltingChartData } from './chart.types'
import { getDecimal } from '@/utils/tool'

export const getTiltingOptions = (data: TiltingChartData) => {
  return {
    grid: { top: 20, left: 50, right: 20, bottom: 40 },
    tooltip: { trigger: 'axis' },
    xAxis: {
      type: 'category',
      data: data.x,
      splitLine: { show: true, lineStyle: { type: 'dashed' } },
      axisLine: { lineStyle: { color: ChartColors.axisLine } },
      axisLabel: {
        show: true,
        fontSize: 12,
        align: 'left',
        padding: [0, 0, 0, -25],
        color: ChartColors.axisText,
        formatter: (value) => {
          const text = value.split(' ')
          return `${text[1]}\n${text[0]}`
        }
      }
    },
    yAxis: {
      type: 'value',
      max: Math.max(...data.tiltingAngle) * 1.2,
      splitLine: { show: true, lineStyle: { type: 'dashed' } },
      axisLabel: {
        formatter: function (value) {
          return `${getDecimal(value, 2)}°`
        }
      }
    },
    series: [
      {
        name: 'Tilting Angle Δ (°)',
        data: data.tiltingAngle,
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: { color: ChartColors.tiltingItem }
      }
    ]
  }
}
