import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import Navigation, { NavigationWidth } from '@/routes/navigation/Navigation'
import { grey } from '@mui/material/colors'
import Meta from './Meta'

export const RootLayout = ({ children, ...props }) => {
  const [navigationWidth, setNavigationWidth] = useState(NavigationWidth.medium)

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          height: '100vh',
          backgroundColor: grey[100],
          pl: `${navigationWidth}px`
        }}>
        <Navigation onWidthChange={(width) => setNavigationWidth(width)} />
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{children}</Box>
      </Stack>
      <Meta />
    </>
  )
}

export default RootLayout
