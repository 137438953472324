import React, { forwardRef, useEffect, useState } from 'react'
import {
  Popover,
  PopoverProps,
  Stack,
  Typography,
  Button,
  MenuProps
} from '@mui/material'
import { encryptPassword } from '@/routes/login/login.helper'
import { Controller, useForm } from 'react-hook-form'
import FormInput from '@/components/form/FormInput'
import { changePassword } from '@/service/settings/users'

interface ChangePasswordProps extends PopoverProps {
  open: boolean
  user: { id: string; __v: number }
  anchorEl: MenuProps['anchorEl']
  onClose: () => void
  onSuccess?: () => void
}

type FormType = { password: string }

const Password = forwardRef<typeof Popover, ChangePasswordProps>(
  ({ open, user, anchorEl, onClose, onSuccess, ...props }, ref) => {
    const { control, handleSubmit, reset } = useForm<FormType>({
      defaultValues: { password: '' }
    })

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await changePassword(user.id, {
          password: encryptPassword((values as FormType)?.password),
          __v: user.__v
        })

        if (resp?.code === 200) {
          onSuccess()
        }
      })()
    }

    useEffect(() => {
      reset({ password: '' })
    }, [open])

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => onClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Stack sx={{ width: 400, p: 2 }} spacing={3}>
          <Typography variant="h6" color="primary.main">
            Change Password
          </Typography>

          <Controller
            name="password"
            control={control}
            rules={{ required: true, minLength: 8, maxLength: 16 }}
            render={({ field, fieldState }) => (
              <FormInput
                password
                error={Boolean(fieldState.error)}
                placeholder="New Password"
                helperText={'Lenght: 8-16'}
                value={field.value}
                onValChange={field.onChange}
              />
            )}
          />

          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => onSubmit()}>
              Confirm
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Stack>
        </Stack>
      </Popover>
    )
  }
)

Password.displayName = 'Password'
export default Password
