import React, { FC } from 'react'
import Item from './Item'
import moment from 'moment'
import { MapTreeDetailsType } from '../map.types'
import { Stack } from '@mui/material'

interface IPropTypes {
  tree: MapTreeDetailsType
  showTreeId: boolean
}

const Attributes: FC<IPropTypes> = ({ tree, showTreeId, ...props }) => {
  return (
    <Stack sx={{ pl: 3, pr: 3 }} spacing={1}>
      {showTreeId && <Item name={'treeId'} value={tree?.treeId} copyble />}
      <Item name={'userTreeId'} value={tree?.userTreeId} copyble />
      <Item name={'treeLocation'} value={tree?.address} />
      <Item name={'species'} value={tree?.details?.species} />
      <Item
        type="number"
        name={'dbh'}
        tip={'Diameter at breast height'}
        value={tree?.details?.dbh}
      />
      <Item type="number" name={'height'} value={tree?.details?.height} />
      <Item
        type="number"
        name={'crownSpread'}
        value={tree?.details?.crownSpread}
      />
      <Item
        name={'coordinates'}
        value={tree?.coordinates?.join(', ')}
        copyble
      />
      <Item
        type="date"
        name={'updatedAt'}
        value={
          tree?.updatedAt && moment(tree?.updatedAt).format('YYYY-MM-DD HH:mm')
        }
      />
    </Stack>
  )
}

export default Attributes
