import React, { forwardRef } from 'react'
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  PopoverProps
} from '@mui/material'
import { Icons } from '@/assets/icons'

interface ResetActionsProps extends MenuProps {
  onItemSelect: (
    type: 'reset-tilting' | 'reset-azimuth',
    anchor?: PopoverProps['anchorEl']
  ) => void
  onClose: () => void
}

const ResetActions = forwardRef<typeof Menu, ResetActionsProps>(
  ({ open, onClose, anchorEl, onItemSelect, ...props }, ref) => {
    return (
      <Menu open={open} anchorEl={anchorEl} onClick={onClose}>
        <MenuItem
          key={'reset-tilting'}
          onClick={() => onItemSelect('reset-tilting', anchorEl)}>
          Reset Tilting
        </MenuItem>
        <MenuItem
          key={'reset-azimuth'}
          onClick={() => onItemSelect('reset-azimuth', anchorEl)}>
          Reset Azimuth
        </MenuItem>
      </Menu>
    )
  }
)

ResetActions.displayName = 'Actions'
export default ResetActions
