export enum TreeDataStatusEnum {
  Pending = 'PENDING',
  Updated = 'UPDATED',
  Processing = 'PROCESSING',
  Uploaded = 'UPLOADED',
  Failed = 'FAILED',
  Existed = 'EXISTED',
  Duplicated = 'DUPLICATED',
  BadData = 'BAD_DATA'
}

export type TreeDataItem = {
  treeId: string
  userTreeId: string
  organizationId: string
  tiltingReset: string
  azimuthReset: string
  status: TreeDataStatusEnum
}
