import React, { forwardRef } from 'react'
import { Stack, StackProps, Tooltip, Typography } from '@mui/material'
import { WeatherWarningIcons, WeatherWarningToText } from '../mode/mode.const'
import {
  HkoWeatherWarningStatementCodeEnum,
  HkoWeatherWarningSubtypeCodeEnum,
  WeatherWarningCodes
} from '../mode/mode.types'
import { grey } from '@mui/material/colors'

interface ModeChangeItemProps extends StackProps {
  warningCodes: (
    | HkoWeatherWarningStatementCodeEnum
    | HkoWeatherWarningSubtypeCodeEnum
  )[]
}

const ModeChangeItem = forwardRef<typeof Stack, ModeChangeItemProps>(
  ({ warningCodes, ...props }, ref) => {
    return (
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        {warningCodes
          ?.filter((code) => {
            return WeatherWarningIcons[code]
          })
          ?.map((code) => {
            return (
              <Tooltip
                key={code}
                title={
                  <Typography variant="body2">
                    {WeatherWarningToText[code]?.en}
                  </Typography>
                }>
                <img
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 24,
                    overflow: 'hidden',
                    border: `1px solid ${grey[300]}`
                  }}
                  src={WeatherWarningIcons[code]}
                />
              </Tooltip>
            )
          })}
      </Stack>
    )
  }
)

ModeChangeItem.displayName = 'ModeChangeItem'
export default ModeChangeItem
