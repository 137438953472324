import { RepairPeriodEnum, DataTaskStatusEnum } from './tilting-data.types'
import { SvgIconOwnProps } from '@mui/material'
export const RepairPeriodText = {
  [RepairPeriodEnum.Day]: 'day',
  [RepairPeriodEnum.Month]: 'month',
  [RepairPeriodEnum.Year]: 'year'
}

export const TaskStatusText = {
  [DataTaskStatusEnum.Success]: 'Success',
  [DataTaskStatusEnum.Running]: 'Running',
  [DataTaskStatusEnum.Failed]: 'Failed',
  [DataTaskStatusEnum.Suspended]: 'Suspended',
  [DataTaskStatusEnum.Terminated]: 'Terminated'
}

export const TaskStatusColor: Record<
  DataTaskStatusEnum,
  SvgIconOwnProps['color']
> = {
  [DataTaskStatusEnum.Success]: 'success',
  [DataTaskStatusEnum.Running]: 'info',
  [DataTaskStatusEnum.Suspended]: 'warning',
  [DataTaskStatusEnum.Failed]: 'error',
  [DataTaskStatusEnum.Terminated]: 'error'
}
