import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Subscription, UserStates } from './user.types'
import { getUsersAsync } from './user.thunks'
import { FullUserInfo, TokenInfo } from '@/routes/login/login.types'
import {
  AccessStorage,
  IsSignedStorage,
  RefreshStorage,
  SubscriptionStorage,
  UserStorage
} from '@/configs/storage-dics'

const userLocal = localStorage.getItem(UserStorage)
const accessToken = localStorage.getItem(AccessStorage)
const refreshToken = localStorage.getItem(RefreshStorage)
const subscription = localStorage.getItem(SubscriptionStorage)

const initialState = {
  isSignIn: Boolean(localStorage.getItem(IsSignedStorage)),
  user: userLocal && JSON.parse(userLocal),
  access: accessToken && JSON.parse(accessToken),
  refresh: refreshToken && JSON.parse(refreshToken),
  subscription: subscription && JSON.parse(subscription)
} as UserStates

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsSignedIn(state, action: PayloadAction<boolean>) {
      state.isSignIn = action.payload
    },
    setUser(state, action: PayloadAction<FullUserInfo>) {
      state.user = action.payload
    },
    setAccessToken(state, action: PayloadAction<TokenInfo>) {
      state.access = action.payload
    },
    setRefreshToken(state, action: PayloadAction<TokenInfo>) {
      state.refresh = action.payload
    },
    setSubscriptionExp(state, action: PayloadAction<Subscription>) {
      state.subscription = action.payload
    }
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getUsersAsync.fulfilled, (state, { payload }) => {
      // state.users = payload;
    })
    builder.addCase(getUsersAsync.rejected, (state, action) => {
      // console.log(state, action)
    })
  }
})

export default usersSlice.reducer
