import { lightGreen } from '@mui/material/colors'
import { ThemeOptions } from '@mui/material/styles'

export const PrimaryMainColor = '#00b87e'
export const SecondaryMainColor = lightGreen['400']

export const ThemePalette = {
  primary: {
    main: PrimaryMainColor,
    contrastText: '#ffffff'
  },
  secondary: {
    main: SecondaryMainColor
  }
}

export const Theme: ThemeOptions = {
  palette: {
    // mode: prefersDarkMode ? 'dark' : 'light',
    mode: 'light',
    ...ThemePalette
  }
}
