import React, { forwardRef, useRef, useState } from 'react'
import {
  Dialog,
  DialogProps,
  Stack,
  Typography,
  IconButton
} from '@mui/material'
import { Icons } from '@/assets/icons'

import { Group } from './group.types'
import ManageTreeList, { ManageTreesHandlers, TreeItem } from './ManageTreeList'

interface ManageTreesProps extends DialogProps {
  defaultGroupId: Group['id']
  group: Group
  onClose: () => void
}

const ManageTrees = forwardRef<typeof Dialog, ManageTreesProps>(
  ({ open, defaultGroupId, group, onClose, ...props }, ref) => {
    const availableListRef = useRef<ManageTreesHandlers>(null)
    const existingListRef = useRef<ManageTreesHandlers>(null)
    return (
      <Dialog fullWidth maxWidth="lg" open={open}>
        <Stack sx={{ width: 1, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ width: 1, p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              Manage Group Trees
            </Typography>

            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Stack
            sx={{
              height: 500,
              pl: 2,
              pr: 2,
              pb: 2,
              width: 1,
              flexGrow: 1
            }}
            direction={'row'}
            spacing={2}>
            {open && (
              <>
                <ManageTreeList
                  ref={availableListRef}
                  defaultGroupId={defaultGroupId}
                  group={group}
                  type="available"
                  onTreeAdded={(tree: TreeItem) => {
                    existingListRef.current &&
                      existingListRef.current.addTree(tree)
                  }}
                />
                <ManageTreeList
                  ref={existingListRef}
                  defaultGroupId={defaultGroupId}
                  group={group}
                  type="existing"
                  onTreeRemoved={(tree: TreeItem) => {
                    availableListRef.current &&
                      availableListRef.current.addTree(tree)
                  }}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Dialog>
    )
  }
)

ManageTrees.displayName = 'ManageTrees'
export default ManageTrees
