import { DailyReport } from '@/routes/manage/report/report.types'
import request from '../request'
import { PageParamsType as ManageDailyReportPageParams } from '@/routes/manage/report/daily-report/ManageDailyReport'
import { PageParamsType as ManageSpecialAlertPageParams } from '@/routes/manage/report/special-alert/ManageSpecialAlert'

export const getDailyReports = (
  params: Omit<ManageDailyReportPageParams, 'currentPage'>
) => {
  return request.get<{
    dailyReport: DailyReport[]
    totalCount: number
  }>(`manage/report/daily-reports`, {
    data: {
      page: params.page,
      limit: params.limit,
      ...(params.date && { date: params.date.format('YYYY-MM-DD') }),
      ...(params.reportId && { reportId: params.reportId }),
      ...(params.organizationId && { organizationId: params.organizationId }),
      ...(params.groupId && { groupId: params.groupId })
    }
  })
}

export const getSpecialAlerts = (
  params: Omit<ManageSpecialAlertPageParams, 'currentPage'>
) => {
  return request.get<{
    specialAlerts: DailyReport[]
    totalCount: number
  }>(`manage/report/special-alerts`, {
    data: {
      page: params.page,
      limit: params.limit,
      ...(params.date && { date: params.date.format('YYYY-MM-DD') }),
      ...(params.reportId && { reportId: params.reportId }),
      ...(params.organizationId && { organizationId: params.organizationId }),
      ...(params.groupId && { groupId: params.groupId })
    }
  })
}
