import React, { useState } from 'react'
import {
  Stack,
  Button,
  Paper,
  Switch,
  Chip,
  Typography,
  MenuProps,
  IconButton,
  PopoverProps
} from '@mui/material'
import NewOrganization from './actions/NewOrganization'
import { GridColDef } from '@mui/x-data-grid'
import { Organization, ServiceTypeEnum } from './organization.types'
import moment from 'moment'
import {
  deleteOrganization,
  disableOrganization,
  getOrganizations
} from '@/service/manage/organizations'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'
import Confirm, { ConfirmType } from '@/components/Confirm'
import Actions from './actions/Actions'
import EditOrganization from './actions/EditOrganization'
import AssignTrees from './assign-trees/AssignTrees'
import { useNavigate } from 'react-router-dom'
import AlertList from '@/components/AlertList'
import { ServiceTypeText } from './organizaiton.const'
import { Layout, Body } from '@/components/page'
import CopyButton from '@/components/CopyButton'
import DataGridC from '@/components/DataGridC'
import usePageList from '@/hooks/usePageList'
import RootLayout from '@/components/page/RootLayout'
import TextFieldC from '@/components/TextFieldC'
import ManageGoups from './manage-groups/ManageGoups'
import { ManageAlertListStyle } from '../manage.const'

type ActionsTypes = 'trees' | 'delete' | 'edit' | 'groups'

const Organizations = () => {
  const navigate = useNavigate()

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  } = usePageList<{ name?: string; organizationId?: string }, Organization>(
    'organizations',
    getOrganizations
  )

  const [isShowNew, setIsShowNew] = React.useState(false)
  const [selectRow, setSelectRow] = useState(null)
  const [confirmParams, setConfirmParams] =
    useState<ConfirmType<'disable' | 'delete', Organization>>()

  const showDisableComfirm = (
    anchor: PopoverProps['anchorEl'],
    row: Organization
  ) => {
    setSelectRow(row)
    setConfirmParams({ anchor, type: 'disable' })
  }

  const updateOrg = (org: Organization) => {
    const index = listData.findIndex((o) => o.id === org.id)
    index > -1 && updateDataByIdx(index, org)
  }

  const closeConfirm = () => {
    setSelectRow(null)
    setConfirmParams(null)
  }

  const changeDisableStatus = async (orgId: string, disabled: boolean) => {
    const resp = await disableOrganization(orgId, disabled)
    if (resp?.code === 200) {
      updateOrg(resp.data)
      closeConfirm()
    }
  }

  const [actionsParams, setActionsParams] = useState<{
    type?: ActionsTypes
    anchorEl?: MenuProps['anchorEl']
    data?: Organization
  }>({})

  const [actionItemParams, setActionItemParams] = useState<{
    type?: ActionsTypes
    anchor?: MenuProps['anchorEl']
    data?: Organization
  }>({})

  const deleteOrg = async (organizationId: string) => {
    const resp = await deleteOrganization(organizationId)
    if (resp?.code === 200) {
      closeConfirm()
      refresh()
    }
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/map', { state: { navTreeId: treeId } })
  }

  return (
    <RootLayout>
      <Layout>
        <Body>
          <Stack sx={{ py: 1, height: 1 }} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <TextFieldC
                  size={'small'}
                  value={filters?.name}
                  placeholder="Name"
                  onChange={(evt) => changeFilters({ name: evt.target.value })}
                />
              </Stack>
              <Stack direction="row">
                <TipIconBtn tip="Refresh" onClick={() => setIsShowNew(true)}>
                  <Icons.Add fontSize="small" />
                </TipIconBtn>
                <TipIconBtn tip="Refresh" onClick={() => refresh()}>
                  <Icons.Refresh fontSize="small" />
                </TipIconBtn>
              </Stack>
            </Stack>

            <Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <DataGridC
                loading={loading}
                getRowId={(row) => row.id}
                rows={listData}
                rowCount={totalCount}
                pageSizeOptions={[15, 50]}
                paginationModel={{
                  page: page > 0 ? page - 1 : 0,
                  pageSize: limit
                }}
                onPaginationModelChange={(params) => {
                  const isPageSizeChanged = limit !== params.pageSize
                  changePage({
                    page: isPageSizeChanged ? 1 : params.page + 1,
                    limit: params.pageSize
                  })
                }}
                columns={getColums({
                  onActions: (anchor, org) =>
                    setActionsParams({ anchorEl: anchor, data: org }),
                  onEdit: (anchor, org) =>
                    setActionItemParams({
                      type: 'edit',
                      anchor,
                      data: org
                    }),
                  onGroups: (anchor, org) =>
                    setActionItemParams({
                      type: 'groups',
                      anchor,
                      data: org
                    }),
                  onTrees: (anchor, org) =>
                    setActionItemParams({
                      type: 'trees',
                      anchor,
                      data: org
                    }),
                  onDisable: (event, row) => {
                    if (row.disabled) {
                      changeDisableStatus(row.id, !row.disabled)
                    } else {
                      showDisableComfirm(event, row)
                    }
                  }
                })}
              />
            </Paper>
          </Stack>

          <Actions
            open={Boolean(actionsParams.anchorEl)}
            anchorEl={actionsParams.anchorEl}
            data={actionsParams.data}
            onClose={() => setActionsParams({ data: null, anchorEl: null })}
            onDelete={(anchor, data) => {
              setConfirmParams({ type: 'delete', anchor, data })
            }}
          />

          <Confirm
            open={
              confirmParams?.type === 'disable' &&
              Boolean(confirmParams?.anchor)
            }
            anchorEl={confirmParams?.anchor}
            icon={<Icons.Block fontSize="large" />}
            title="Disable"
            message="This action will suspend access of all users of this organization to LeafIot. Are you sure?"
            onClose={() => closeConfirm()}
            onConfirm={() =>
              changeDisableStatus(selectRow.id, !selectRow.disabled)
            }
          />
          <NewOrganization
            open={isShowNew}
            onSuccess={() => {
              setIsShowNew(false)
              refresh()
            }}
            onClose={() => setIsShowNew(false)}
          />

          <EditOrganization
            open={
              actionItemParams?.type === 'edit' &&
              Boolean(actionItemParams.anchor)
            }
            data={actionItemParams?.data}
            onSuccess={(org) => {
              updateOrg(org)
              setActionItemParams(null)
            }}
            onClose={() => setActionItemParams(null)}
          />
          <AssignTrees
            open={
              actionItemParams?.type === 'trees' &&
              Boolean(actionItemParams?.anchor)
            }
            org={actionItemParams?.data}
            onClose={() => setActionItemParams(null)}
          />
          <ManageGoups
            open={
              actionItemParams?.type === 'groups' &&
              Boolean(actionItemParams?.anchor)
            }
            org={actionItemParams?.data}
            onClose={() => setActionItemParams(null)}
          />
          <Confirm
            open={
              confirmParams?.type === 'delete' && Boolean(confirmParams?.anchor)
            }
            anchorEl={confirmParams?.anchor}
            icon={<Icons.DeleteForever fontSize="large" />}
            title="Delete"
            message={
              <>
                All users and groups will be wiped out from the LeafIot system.
                <br />
                The trees of this organization will be transferred to the
                LeafIot Team.
              </>
            }
            onClose={() => closeConfirm()}
            onConfirm={() => deleteOrg(confirmParams.data.id)}
          />
          <AlertList
            sx={ManageAlertListStyle}
            onLocate={(treeId) => showTreeOnMap(treeId)}
          />
        </Body>
      </Layout>
    </RootLayout>
  )
}

export default Organizations

const getColums = ({
  onDisable,
  onEdit,
  onTrees,
  onGroups,
  onActions
}: {
  onDisable?: (event: MenuProps['anchorEl'], row: Organization) => void
  onEdit: (event: MenuProps['anchorEl'], row: Organization) => void
  onTrees: (event: MenuProps['anchorEl'], row: Organization) => void
  onGroups: (event: MenuProps['anchorEl'], row: Organization) => void
  onActions?: (event: MenuProps['anchorEl'], row: Organization) => void
}): GridColDef<Organization>[] => {
  return [
    {
      field: 'id',
      headerName: 'Organization ID',
      width: 260,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack alignItems={'center'} direction={'row'}>
            <Typography fontSize="inherit" color="primary.main">
              {params.value}
            </Typography>
            <CopyButton title="Organization ID" value={params.value} />
          </Stack>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 320,
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'fullname',
      headerName: 'Full Name',
      width: 320,
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    // {
    //   field: 'regionId',
    //   headerName: 'Region',
    //   sortable: false,
    //   filterable: false,
    //   flex: 1,
    //   valueFormatter: (params) =>
    //     Regions.find((r) => r.id === params.value)?.name || '-'
    // },
    {
      field: 'subscriptions',
      headerName: 'Subscriptions',
      sortable: false,
      filterable: false,
      width: 300,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value.length === 0) return ''

        return (
          <Stack spacing={1} direction="row">
            {params.value.map((subs) => {
              return (
                <Chip
                  sx={{ mr: 1 }}
                  key={`${subs.id}_${subs.type}`}
                  size="small"
                  label={
                    <Stack
                      direction={'row'}
                      spacing={1}
                      justifyContent="flex-start">
                      <Typography
                        fontSize="inherit"
                        color={
                          subs.serviceType === ServiceTypeEnum.LIFY_T_PRO
                            ? 'warning.main'
                            : 'info.main'
                        }>
                        {ServiceTypeText[subs.serviceType]}
                      </Typography>
                      <Typography fontSize="inherit">
                        {moment(subs.expiredAt).format('YYYY-MM-DD')}
                      </Typography>
                    </Stack>
                  }
                />
              )
            })}
          </Stack>
        )
      }
    },
    {
      field: 'userCount',
      headerName: 'Users',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'groupCount',
      headerName: 'Groups',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button onClick={(event) => onGroups(event.currentTarget, params.row)}>
            {params.value}
          </Button>
        )
      }
    },
    {
      field: 'treeCount',
      headerName: 'Trees',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button onClick={(event) => onTrees(event.currentTarget, params.row)}>
            {params.value}
          </Button>
        )
      }
    },
    {
      field: 'contactpPerson',
      headerName: 'Contact Person',
      sortable: false,
      filterable: false,
      width: 180,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row.contact.person
      }
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      sortable: false,
      filterable: false,
      width: 180,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row.contact.phone
      }
    },
    {
      field: 'contactEmail',
      headerName: 'Contact Email',
      sortable: false,
      filterable: false,
      width: 240,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row.contact.email
      }
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      width: 180,
      disableColumnMenu: true,
      valueFormatter: (params) =>
        moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
    },
    {
      field: 'disabled',
      headerName: 'Disabled',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Switch
            disabled={params.row.isLeafiot}
            checked={params.value}
            onChange={(event) => onDisable(event.currentTarget, params.row)}
          />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction={'row'}>
          <IconButton
            title="Edit"
            size="small"
            onClick={(event) => onEdit(event.currentTarget, params.row)}>
            <Icons.ArticleOutlined fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(event) => onActions(event.currentTarget, params.row)}>
            <Icons.Menu fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  ]
}
