import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback
} from 'react'

import 'ol/ol.css'
import { View as OlView, Map as OlMap } from 'ol'
import { fromLonLat } from 'ol/proj'
import Overlay from 'ol/Overlay'

import { Box } from '@mui/material'
import { Vector as VectorLayer, Tile as TileLayer } from 'ol/layer'
import { Vector as VectorSource, OSM } from 'ol/source'
import { defaults as DefControl } from 'ol/control'
import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import { Style, Icon } from 'ol/style'
import Point from 'ol/geom/Point'
import Filters, { DefaultFilters } from './Filters'

import {
  activeTree,
  filterTreesOnMap,
  getTreeFeatures,
  getTreeLayerStyle
} from '@/routes/map/view/view.helper'

import TreeActiveIconSrc from '@/assets/images/map/active-tree.png'
import { unByKey } from 'ol/Observable'
import {
  DefaultLoadingProps,
  MapOptions,
  PointAnimationDuration,
  PrimaryMapPointKey
} from './view.const'
import { FilterTypeEnum, MapTreeType } from '../map.types'
import Popup from '../popup/Popup'
import ZoomCtrl from './ZoomCtrl'
import MapLoading, { ViewDataLoadingProps } from './ViewDataLoading'

type MapLoadingProps = {
  status?: boolean
  size?: 'small'
  placement?: ViewDataLoadingProps['placemment']
  treeTotalCount?: number
  treeLoadedCount?: number
}

type MapViewPropTypes = {
  controls?: {
    zoom?: boolean
    popup?: boolean
    filter?: {
      show: boolean
      lowPower?: boolean
    }
  }
  loading?: MapLoadingProps
  trees: MapTreeType[]
  filters: FilterTypeEnum[]
  onClearActive?: () => void
  onTreeSelected?: (treeId: string) => void
  onFilterChange?: (filters: FilterTypeEnum[]) => void
}

interface MapViewHandlers {
  locationTreeById: (
    treeId: string,
    option?: { zoomIn: boolean; popup: boolean }
  ) => void
  clearActiveTree: (treeId: string) => void
  zoomIn: () => void
  zoomOut: () => void
  setZoom: (zoom: number) => void
  setFilters?: (filters: FilterTypeEnum[]) => void
  clearLayer: () => void
  refreshLayer: () => void
}

const MapView = forwardRef<MapViewHandlers, MapViewPropTypes>(
  (
    {
      controls,
      loading = DefaultLoadingProps as MapLoadingProps,
      trees,
      filters,
      onClearActive,
      ...props
    },
    ref
  ) => {
    const controlPopup = controls?.popup || false
    const controlFilter = controls?.filter || {
      show: false,
      lowPower: false
    }
    const controlZoom = controls?.zoom || false

    const onTreeSelected = props.onTreeSelected || (() => {})
    const onFilterChange = props.onFilterChange || (() => {})

    const mapElemRef = useRef(null)
    const mapInsRef = useRef<OlMap | undefined>()
    const treeLayerRef = useRef<
      VectorLayer<VectorSource<Feature<Geometry>>> | undefined
    >()
    const activeTreeLayerRef = useRef<
      VectorLayer<VectorSource<Feature<Geometry>>> | undefined
    >()
    const activeTreeRef = useRef<Feature | undefined>()
    const popupContainerRef = useRef(null)
    const popupOverlayRef = useRef<Overlay | undefined>()

    const [seletedTreeId, setSeletedTreeId] = useState('')
    const selectedTree = trees?.find((tree) => tree.treeId === seletedTreeId)

    const clearActiveTree = () => {
      if (activeTreeRef.current) {
        activeTreeLayerRef.current
          .getSource()
          .removeFeature(activeTreeRef.current)
        activeTreeRef.current = null
        onTreeSelected(null)
        onClearActive()
        closePopup()
      }
    }

    const closePopup = useCallback(() => {
      popupOverlayRef.current.setPosition(undefined)
    }, [])

    const mapFullView = () => {
      mapInsRef.current.getView().animate({
        center: fromLonLat(MapOptions.center),
        zoom: MapOptions.zoom,
        duration: 300
      })
    }

    const zoomInMap = () => {
      mapInsRef.current.getView().animate({
        zoom: mapInsRef.current.getView().getZoom() + 1,
        duration: 300
      })
    }

    const zoomOutMap = () => {
      mapInsRef.current.getView().animate({
        zoom: mapInsRef.current.getView().getZoom() - 1,
        duration: 300
      })
    }

    const locateTreeById = (
      treeId,
      options: { zoomIn: boolean; popup: boolean }
    ) => {
      clearActiveTree()

      const treeLayerSource = treeLayerRef.current.getSource()
      const targetFeatureOnMap = treeLayerSource
        .getFeatures()
        .find((feature) => feature.get(PrimaryMapPointKey) === treeId)

      if (targetFeatureOnMap) {
        activeTree({
          treeFeature: targetFeatureOnMap,
          activeTreeRef: activeTreeRef,
          activeLayer: activeTreeLayerRef.current,
          map: mapInsRef.current,
          zoomIn: options?.zoomIn
        })
        options?.popup && onTreeSelected(treeId)
        return
      }

      if (trees.length === 0) return

      const targetTreeInData = trees.find(
        (item) => item[PrimaryMapPointKey] === treeId
      )

      if (targetTreeInData) {
        const targetFeature = getTreeFeatures([targetTreeInData])[0]
        treeLayerSource.addFeature(targetFeature)
        activeTree({
          treeFeature: targetFeature,
          activeTreeRef: activeTreeRef,
          activeLayer: activeTreeLayerRef.current,
          map: mapInsRef.current,
          zoomIn: options?.zoomIn
        })
        options?.popup && onTreeSelected(treeId)
      }
    }

    const onMapClick = (evt) => {
      clearActiveTree()
      // console.log(mapInsRef.current.getView().calculateExtent(mapInsRef.current.getSize()))
      treeLayerRef.current.getFeatures(evt.pixel).then(function (features) {
        const feature = features[0]
        if (!feature) return

        const targetTreeIdFromFeature = feature.get(PrimaryMapPointKey)
        const tragetTreeFeature = activeTreeLayerRef.current
          .getSource()
          .getFeatures()
          .find((feature) => {
            return feature.get(PrimaryMapPointKey) === targetTreeIdFromFeature
          })

        if (tragetTreeFeature) {
          setSeletedTreeId(targetTreeIdFromFeature)
          onTreeSelected(tragetTreeFeature.get(PrimaryMapPointKey))

          if (controlPopup) {
            const geometry = tragetTreeFeature.getGeometry() as Point
            popupOverlayRef.current.setPosition(geometry.getCoordinates())
          }
          return
        }

        const newTargetFeature = feature.clone()
        activeTreeLayerRef.current
          .getSource()
          .addFeature(newTargetFeature as Feature<Geometry>)
        activeTreeRef.current = newTargetFeature as Feature<Geometry>

        const newTargetFeatureTreeId = newTargetFeature.get(PrimaryMapPointKey)
        setSeletedTreeId(newTargetFeatureTreeId)
        onTreeSelected(newTargetFeatureTreeId)

        if (controlPopup) {
          const geometry = newTargetFeature.getGeometry() as Point
          popupOverlayRef.current.setPosition(geometry.getCoordinates())
        }
      })
    }

    const filterMap = (filterItem: FilterTypeEnum) => {
      if (filters.includes(filterItem)) {
        onFilterChange(filters.filter((item) => item !== filterItem))
      } else {
        onFilterChange([...filters, filterItem])
      }
    }

    const init = () => {
      const basemap = new TileLayer({ source: new OSM() })
      const popupOverLayer = new Overlay({
        element: popupContainerRef.current,
        autoPan: { animation: { duration: 250 } }
      })

      const treeLayer = new VectorLayer({
        source: new VectorSource({}),
        style: function (feature) {
          return getTreeLayerStyle(feature)
        }
      })

      const activeLayerSource = new VectorSource()
      const activeTreeLayer = new VectorLayer({
        source: activeLayerSource,
        style: new Style({
          image: new Icon({
            opacity: 1,
            src: TreeActiveIconSrc,
            offset: [0, 0],
            scale: 0.7
          })
        })
      })

      const mapIns = new OlMap({
        target: mapElemRef.current,
        controls: DefControl({
          zoom: false,
          rotate: false
        }),
        view: new OlView({
          extent: MapOptions.extent,
          center: fromLonLat(MapOptions.center),
          zoom: MapOptions.zoom
        }),
        overlays: [popupOverLayer],
        layers: [basemap, treeLayer, activeTreeLayer]
      })

      mapIns.on('singleclick', (evt) => onMapClick(evt))

      mapInsRef.current = mapIns
      popupOverlayRef.current = popupOverLayer
      treeLayerRef.current = treeLayer
      activeTreeLayerRef.current = activeTreeLayer

      activeLayerSource.on('addfeature', function (evt) {
        const start = Date.now()
        const feature = evt.feature

        const listenerKey = activeTreeLayer.on('postrender', (event) => {
          const frameState = event.frameState
          const elapsed = frameState.time - start
          if (elapsed >= PointAnimationDuration) {
            unByKey(listenerKey)
            return
          }

          feature.setStyle(
            new Style({
              image: new Icon({
                src: TreeActiveIconSrc,
                displacement: [0, 5],
                scale: elapsed / PointAnimationDuration
              })
            })
          )
        })
      })
    }

    useImperativeHandle(ref, () => ({
      setZoom(zoom) {
        mapInsRef.current.getView().setZoom(zoom)
      },
      clearLayer() {
        treeLayerRef.current.getSource().clear()
      },
      refreshLayer() {
        treeLayerRef.current.getSource().refresh()
      },
      locationTreeById: (
        treeId,
        options: { zoomIn: boolean; popup: boolean }
      ) => locateTreeById(treeId, options),
      clearActiveTree: () => clearActiveTree(),
      zoomIn: () => zoomInMap(),
      zoomOut: () => zoomOutMap()
    }))

    useEffect(() => {
      if (mapElemRef.current && !mapInsRef.current) {
        init()
      }
    }, [mapElemRef, mapInsRef])

    useEffect(() => {
      if (trees) {
        const treeSource = treeLayerRef.current.getSource()
        treeSource.clear()
        const treesOnMap = filterTreesOnMap(trees, filters)
        treeSource.addFeatures(getTreeFeatures(treesOnMap))
      }
    }, [trees, filters])

    useEffect(() => {
      if (controlFilter) {
        onFilterChange(filters)
      }
    }, [filters])

    return (
      <Box sx={{ position: 'relative', width: 1, height: 1 }}>
        <div ref={mapElemRef} style={{ width: '100%', height: '100%' }}></div>

        {loading?.status && (
          <MapLoading
            small={loading?.size === 'small'}
            placemment={loading?.placement}
            treeTotalCount={loading?.treeTotalCount || 0}
            treeLoadedCount={loading?.treeLoadedCount || 0}
          />
        )}

        {controlZoom && (
          <ZoomCtrl
            onFullView={() => mapFullView()}
            onZoomIn={() => zoomInMap()}
            onZoomOut={() => zoomOutMap()}
          />
        )}

        {controlFilter?.show && (
          <Filters
            sx={{ position: 'absolute', left: 0, bottom: 0, zIndex: 999 }}
            value={filters}
            showLowPower={controlFilter.lowPower}
            onFilter={(type) => filterMap(type)}
            onAll={(status: boolean) =>
              onFilterChange(
                status
                  ? [
                      ...DefaultFilters,
                      ...filters.filter((item) => {
                        return !DefaultFilters.includes(item)
                      })
                    ]
                  : filters.filter((item) => {
                      return !DefaultFilters.includes(item)
                    })
              )
            }
          />
        )}

        {controlPopup && (
          <Popup
            ref={popupContainerRef}
            data={selectedTree}
            onClose={closePopup}
          />
        )}
      </Box>
    )
  }
)

MapView.displayName = 'MapView'
export default React.memo(MapView)
