import React, { useState, useRef, useEffect } from 'react'
import {
  Stack,
  Button,
  Paper,
  IconButton,
  MenuProps,
  Typography
} from '@mui/material'
import NewUser from './actions/NewUser'
import EditUser from './actions/EditUser'
import { getUsers } from '@/service/settings/users'

import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { Icons } from '@/assets/icons'
import Password from './actions/Password'
import TipIconBtn from '@/components/TipIconBtn'
import Groups, { GroupsRefHandlers } from './groups/Groups'
import { useAppSelector } from '@/store'

import { RoleNames, RoleNamesColors } from '@/routes/manage/user/users.const'

import ActionMenu from './actions/ActionMenu'
import DeleteUser from './actions/DeleteUser'
import ChangeGroup from './actions/ChangeGroup'
import { useNavigate } from 'react-router-dom'
import AlertList from '@/components/AlertList'
import { RolesEnum, User } from '@/routes/manage/user/users.types'
import usePageList from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import TextFieldC from '@/components/TextFieldC'
import { ManageAlertListStyle } from '@/routes/manage/manage.const'

const Users = () => {
  const navigate = useNavigate()

  const groupsRef = useRef<GroupsRefHandlers>(null)
  const user = useAppSelector((state) => state.users.user)

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  } = usePageList<{ username?: string; groupId?: string }, User>(
    'users',
    getUsers
  )

  const [acitonParams, setActionParams] = useState<{
    type: 'menu' | 'new' | 'group'
    anchor?: MenuProps['anchorEl']
    data?: User
  }>(null)

  const [actionItemParams, setActionItemParams] = useState<{
    type: 'edit' | 'delete' | 'password'
    anchor?: MenuProps['anchorEl']
    user?: User
  }>(null)

  const refreshGroup = () => groupsRef.current && groupsRef.current.refresh()

  const updateUser = (user) => {
    const index = listData.findIndex((u) => u.id === user.id)
    index > -1 && updateDataByIdx(index, user)
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/map', { state: { navTreeId: treeId } })
  }

  useEffect(() => {
    if (user?.role !== RolesEnum.Admin) {
      changeFilters({ groupId: user?.group?.groupId })
    }
  }, [user])

  return (
    <>
      <Stack sx={{ width: 1, height: 1 }} spacing={1}>
        <Stack
          sx={{ flexShrink: 0 }}
          direction="row"
          justifyContent="space-between">
          <Stack direction="row">
            <TextFieldC
              size={'small'}
              value={filters?.username}
              placeholder="User name"
              onChange={(evt) => changeFilters({ username: evt.target.value })}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <TipIconBtn
              tip="Add user"
              onClick={() => setActionParams({ type: 'new' })}>
              <Icons.Add fontSize="small" />
            </TipIconBtn>

            <TipIconBtn tip="Refresh" onClick={() => refresh()}>
              <Icons.Refresh fontSize="small" />
            </TipIconBtn>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ flexGrow: 1, overflow: 'hidden' }}
          spacing={1}>
          <Paper sx={{ flexGrow: 1, height: 1 }}>
            <DataGridC
              loading={loading}
              getRowId={(row) => row.id}
              rows={listData}
              rowCount={totalCount}
              pageSizeOptions={[15, 50]}
              paginationModel={{
                page: page > 0 ? page - 1 : 0,
                pageSize: limit
              }}
              onPaginationModelChange={(params) => {
                const isPageSizeChanged = limit !== params.pageSize
                changePage({
                  page: isPageSizeChanged ? 1 : params.page + 1,
                  limit: params.pageSize
                })
              }}
              columns={getColums({
                onChangeGroup: (anchor, data) =>
                  setActionParams({
                    type: 'group',
                    anchor,
                    data
                  }),
                onEdit: (anchor: MenuProps['anchorEl'], data: User) => {
                  setActionItemParams({
                    type: 'edit',
                    anchor,
                    user: data
                  })
                },
                onAction: (anchor: MenuProps['anchorEl'], data: User) =>
                  setActionParams({
                    type: 'menu',
                    anchor,
                    data
                  })
              }).filter((col) => {
                if (user.role === RolesEnum.Admin) {
                  return true
                }
                return col.field !== 'groupDetail'
              })}
            />
          </Paper>
          {user.role === RolesEnum.Admin && (
            <Paper
              sx={{
                width: 360,
                minWidth: 360,
                height: 1,
                border: '1px solid #dddddd',
                flexShrink: 0
              }}>
              <Groups
                ref={groupsRef}
                selectedGroupId={filters?.groupId}
                onGroupSelected={(groupId) => changeFilters({ groupId })}
                refreshUserList={() => refresh()}
              />
            </Paper>
          )}
        </Stack>
      </Stack>

      <ChangeGroup
        open={acitonParams?.type === 'group' && Boolean(acitonParams?.anchor)}
        groups={groupsRef.current?.getGroups()}
        anchorEl={acitonParams?.anchor}
        user={acitonParams?.data}
        onSuccess={(user) => {
          updateUser(user)
          refreshGroup()
          setActionParams(null)
        }}
        onClose={() => setActionParams(null)}
      />
      <ActionMenu
        open={acitonParams?.type === 'menu' && Boolean(acitonParams?.anchor)}
        anchorEl={acitonParams?.anchor}
        user={acitonParams?.data}
        onClose={() => setActionParams(null)}
        onChangePassword={(anchor, data) =>
          setActionItemParams({ type: 'password', anchor: anchor, user: data })
        }
        onDelete={(anchor, data) =>
          setActionItemParams({
            type: 'delete',
            anchor: anchor,
            user: data
          })
        }
      />
      <NewUser
        open={acitonParams?.type === 'new'}
        onSuccess={() => {
          refresh()
          refreshGroup()
          setActionParams(null)
        }}
        onClose={() => setActionParams(null)}
      />

      <EditUser
        open={actionItemParams?.type === 'edit'}
        user={actionItemParams?.user}
        onClose={() => setActionItemParams(null)}
        onSuccess={(user) => {
          updateUser(user)
          refreshGroup()
          setActionItemParams(null)
        }}
      />
      <DeleteUser
        open={
          actionItemParams?.type === 'delete' &&
          Boolean(actionItemParams?.anchor)
        }
        anchorEl={actionItemParams?.anchor}
        user={actionItemParams?.user}
        onClose={() => setActionItemParams(null)}
        onSuccess={(user) => {
          refresh()
          refreshGroup()
          setActionItemParams(null)
        }}
      />
      <Password
        open={
          actionItemParams?.type === 'password' &&
          Boolean(actionItemParams?.anchor)
        }
        anchorEl={actionItemParams?.anchor}
        user={actionItemParams?.user}
        onClose={() => setActionItemParams(null)}
        onSuccess={() => setActionItemParams(null)}
      />

      <AlertList
        sx={ManageAlertListStyle}
        onLocate={(treeId) => showTreeOnMap(treeId)}
      />
    </>
  )
}

export default Users

const getColums = ({
  onChangeGroup,
  onEdit,
  onAction
}: {
  onChangeGroup: (event: MenuProps['anchorEl'], user: User) => void
  onEdit: (event: MenuProps['anchorEl'], user: User) => void
  onAction: (event: MenuProps['anchorEl'], user: User) => void
}): GridColDef<User>[] => {
  return [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'nickname', headerName: 'Nickname', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography fontSize={'small'} color={RoleNamesColors[params.value]}>
            {RoleNames[params.value]}
          </Typography>
        )
      }
    },
    {
      field: 'groupDetail',
      headerName: 'Group',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            disabled={params.row.isDefault}
            sx={{ textTransform: 'none' }}
            onClick={(event) => onChangeGroup(event.currentTarget, params.row)}>
            {params.value?.name}
          </Button>
        )
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      flex: 1,
      valueFormatter: (params) =>
        moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
    },
    // {
    //   field: 'createdAt',
    //   headerName: 'Created At',
    //   flex: 1,
    //   valueFormatter: (params) =>
    //     moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
    // },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton
            disabled={params.row.isDefault}
            onClick={(event) => onEdit(event.currentTarget, params.row)}
            size="small">
            <Icons.ArticleOutlined fontSize="inherit" />
          </IconButton>
          <IconButton
            disabled={params.row.isDefault}
            onClick={(event) => onAction(event.currentTarget, params.row)}
            size="small">
            <Icons.Menu fontSize="inherit" />
          </IconButton>
        </Stack>
      )
    }
  ]
}
