import React, { forwardRef } from 'react'
import { Box, BoxProps, IconButton } from '@mui/material'
import { Icons } from '@/assets/icons'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Moment } from 'moment'

interface ListDatePickerProps extends BoxProps {
  date: Moment
  onDateChange: (data: any) => void
}

const ListDatePicker = forwardRef<typeof Box, ListDatePickerProps>(
  ({ date, onDateChange, ...props }, ref) => {
    return (
      <Box ref={ref}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            sx={{ backgroundColor: 'white' }}
            format={'DD/MM/YYYY'}
            value={date}
            onChange={(value, context) => {
              if (!context.validationError) {
                onDateChange(value)
              }
            }}
            slotProps={{
              inputAdornment: {
                position: 'start'
              },
              textField: {
                size: 'small',
                placeholder: 'Date',
                InputProps: {
                  ...(date && {
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => onDateChange(null)}>
                        <Icons.Close fontSize="inherit" />
                      </IconButton>
                    )
                  })
                }
              }
            }}
          />
        </LocalizationProvider>
      </Box>
    )
  }
)

ListDatePicker.displayName = 'ListDatePicker'
export default ListDatePicker
