import { ChartColors } from './chart.const'
import { TiltingChartData } from './chart.types'
import { getDecimal } from '@/utils/tool'

const VibrationSymbol =
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATJJREFUSEvVlU8OwUAUxr+yIAQ7RyDhEFg2YdGEkzgAPYCTkHRB0iUOQcIR7BDCgvISQ6edGdpkgtn073y/974388aA5mFo1sdvAPauU08CPZatB8zpPmta/XcOKDM4uA4JPIUlYrYKJAUcXWfqAfV3EdJ3A5hlTKsh+lcIiCLORGWQEEBky3m9xHY8fAaYKlWQLldB18AI2SUCeP5JJLybjIRO5ZttFFod7lvWtDhN7iEYPUW+GdjKMhS7PS6TK9DImdbMZ91rfhCgip7NIpsIIqsFl0GwuBQ9ZaEa/w8QFJpbSZxF1BISwJRZEqfI930nB5DwwXX0LdMHINR/KJPTasEVnNZ/rI1GEK2tgvkfBRK52TGI1nbt31wPELXlGnt/AWx/S5Btxt84Mj85dL6WwQ0ap6EZQt4XIwAAAABJRU5ErkJggg=='

export const getTiltingLevelOptions = (data: TiltingChartData) => {
  return {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      top: 20,
      left: 40,
      right: 20,
      bottom: 40
    },
    xAxis: {
      type: 'category',
      data: data.x,
      splitLine: {
        show: false,
        lineStyle: { type: 'dashed' }
      },
      axisLabel: {
        show: true,
        align: 'left',
        fontSize: 12,
        padding: [0, 0, 0, -25],
        color: ChartColors.axisText,
        formatter: (value) => {
          const text = value.split(' ')
          return `${text[1]}\n${text[0]}`
        }
      },
      axisLine: {
        lineStyle: { color: ChartColors.axisLine }
      }
    },
    yAxis: {
      type: 'value',
      max: 90,
      splitLine: { show: false },
      axisLabel: {
        color: ChartColors.axisText,
        formatter: (value) => `${getDecimal(value, 2)}°`
      },
      axisLine: {
        show: true,
        lineStyle: { color: ChartColors.axisLine }
      }
    },
    series: [
      {
        name: 'Tilting Angle Δ (°)',
        silent: true,
        data: data.tiltingAngle,
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: { color: '#00b87e' },
        lineStyle: { color: '#00b87e', width: 2 },
        markArea: {
          silent: true,
          data: [
            [
              {
                name: 'Good',
                yAxis: 0,
                itemStyle: {
                  color: '#00b87e',
                  opacity: 0.2
                },
                label: {
                  show: true,
                  position: 'insideLeft',
                  formatter: 'Good',
                  color: '#385723'
                }
              },
              { yAxis: 6 }
            ],
            [
              {
                name: 'Fair',
                yAxis: 6,
                itemStyle: {
                  color: '#a0d100',
                  opacity: 0.2
                },
                label: {
                  show: true,
                  position: 'insideLeft',
                  formatter: 'Fair',
                  color: '#00b87e'
                }
              },
              { yAxis: 15 }
            ],
            [
              {
                name: 'Poor',
                yAxis: 15,
                itemStyle: {
                  color: '#ff5121',
                  opacity: 0.1
                },
                label: {
                  show: true,
                  position: 'insideLeft',
                  formatter: 'Poor',
                  color: '#ff5121'
                }
              },
              { yAxis: 70 }
            ],
            [
              {
                name: 'Emergency',
                yAxis: 70,
                itemStyle: {
                  color: '#b80000',
                  opacity: 0.15
                },
                label: {
                  show: true,
                  position: 'insideLeft',
                  formatter: 'Emergency',
                  color: '#b80000'
                }
              },
              { yAxis: 90 }
            ]
          ]
        }
      },
      {
        name: 'Tilting Reset Value (°)',
        type: 'line',
        data: data.tiltingResetValue,
        smooth: true,
        showAllSymbol: true,
        symbolSize: 10,
        symbol: 'none',
        itemStyle: { color: '#ffffff' },
        lineStyle: { opacity: 0 }
      },
      {
        name: 'Vibration',
        type: 'line',
        data: data.vibraiton,
        smooth: true,
        showAllSymbol: true,
        symbolSize: 10,
        symbol: (value, params) => {
          if (value === 0) {
            return 'none'
          }
          return VibrationSymbol
        },
        itemStyle: {
          color: '#e10000'
        },
        lineStyle: {
          color: '#dddddd',
          opacity: 0
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => {
            return value === 0 ? 'No' : 'Yes'
          }
        }
      }
    ]
  }
}
