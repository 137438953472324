import React, { forwardRef, useEffect, useState } from 'react'
import { Paper, Stack, StackProps, Typography } from '@mui/material'
import { getLatestDataTime } from '@/service/manage/map'
import moment from 'moment'

interface LatestDataTimeProps extends StackProps {}

const LatestDataTime = forwardRef<typeof Stack, LatestDataTimeProps>(
  ({ ...props }, ref) => {
    const [date, setDate] = useState<Date>(null)
    const getTime = async () => {
      const resp = await getLatestDataTime()
      if (resp?.code === 200) {
        setDate(resp.data)
      }
    }

    useEffect(() => {
      getTime()
    }, [])

    return (
      <Paper sx={{ width: 1, height: 1, px: 2, py: 1 }}>
        <Stack justifyContent="space-between">
          <Typography variant="overline">The Latest Data Time</Typography>
          <Typography sx={{ m: 0 }} paragraph variant="subtitle2">
            {moment(date).format('YYYY-MM-DD HH:mm:ss')}
          </Typography>
        </Stack>
      </Paper>
    )
  }
)

LatestDataTime.displayName = 'LatestDataTime'
export default React.memo(LatestDataTime)
