import react, { useCallback, useEffect, useState } from 'react'
import { debounce } from '@/hooks/useDebounce'

type PageParams = { page: number; limit: number }

type PageCtrlParams = PageParams & { loadedPages: number[] }

export type PageListParams<T> = PageParams & T

type DataParams<F, R> = {
  loading: boolean
  filters: F | null
  listData: R[]
  totalCount: number
  refresh: () => void
  changePage: (params: PageParams) => void
  changeFilters: (params: F) => void
  updateDataByIdx: (targetIdx: number, dataItem: R) => void
} & PageParams

const DefPageParams = {
  loadedPages: [],
  page: 1,
  limit: 15
}
/**
 * @param dataField data field in response
 * @param func      function api to get data
 * @returns
 */
function usePageList<F, R>(dataField: string, func: any): DataParams<F, R> {
  const [loading, setLoading] = useState(false)

  const [pageCtrlParams, setPageCtrlParams] =
    useState<PageCtrlParams>(DefPageParams)
  const [filters, setFilters] = useState<F | null>(null)

  const [listData, setLisData] = useState<R[]>([])
  const [listTotalCount, setTotalCount] =
    useState<DataParams<F, R>['totalCount']>(0)

  const fetchData = useCallback(async () => {
    if (loading) {
      return
    }

    const { loadedPages, page, limit } = pageCtrlParams
    if (loadedPages.includes(page)) {
      return
    }

    setLoading(true)

    const resp = await func({
      page,
      limit,
      ...(filters && { ...filters })
    })

    if (resp?.code === 200) {
      const newData = (resp?.data[dataField] || []) as R[]
      const totalCount = resp?.data.totalCount

      if (loadedPages.length === 0) {
        setLisData(newData)
      } else {
        setLisData([...listData, ...newData])
      }

      setTotalCount(totalCount)
      setPageCtrlParams({
        ...pageCtrlParams,
        loadedPages: [...loadedPages, page]
      })
    }

    setLoading(false)
  }, [pageCtrlParams, filters, loading])

  const updateDataByIdx = (targetIdx: number, dataItem: R) => {
    const newData = [...listData]
    newData[targetIdx] = dataItem
    setLisData(newData)
  }

  const changeFilters = (params: F) => {
    setFilters({ ...filters, ...params })
  }

  const changePage = (params: PageParams) => {
    setPageCtrlParams({
      ...pageCtrlParams,
      ...(params.limit !== pageCtrlParams.limit && { loadedPages: [] }),
      ...(params.page && { page: params.page }),
      ...(params.limit && { limit: params.limit })
    })
  }

  const refresh = useCallback(
    debounce(() => {
      setPageCtrlParams(DefPageParams)
    }),
    [setPageCtrlParams]
  )

  useEffect(() => {
    fetchData()
  }, [pageCtrlParams])

  useEffect(() => {
    refresh()
  }, [filters])

  return {
    loading,
    filters,
    page: pageCtrlParams.page,
    limit: pageCtrlParams.limit,
    listData,
    totalCount: listTotalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  }
}

export default usePageList
