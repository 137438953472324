export const ChartColors = {
  axisText: '#303030',
  axisLine: '#dddddd',
  tiltingItem: '#00b87e',
  azimuthItem: '#00b87e',
  temperatureItem: '#b80000'
}


export const ChartTitles = {
    tilting: 'TILTING',
    azimuth: 'AZIMUTH',
    temperature: 'TEMPERATURE',
    'tilting-level': 'TILTING LEVEL'
  }
  