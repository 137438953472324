import React, { FC } from 'react'
import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'

const FoldButton: FC<{ showText: boolean; onClick: () => void }> = ({
  showText,
  onClick
}) => {
  return (
    <TipIconBtn
      sx={{ color: 'primary.main' }}
      aria-label="Fold Menu"
      tip={showText ? 'Fold menu' : 'Expand menu'}
      size="small"
      onClick={onClick}>
      {showText ? (
        <Icons.UnfoldMore
          sx={{ transform: 'rotate(90deg)' }}
          fontSize="inherit"
        />
      ) : (
        <Icons.UnfoldLess
          sx={{ transform: 'rotate(90deg)' }}
          fontSize="inherit"
        />
      )}
    </TipIconBtn>
  )
}

FoldButton.displayName = 'FoldButton'
export default FoldButton
