import React, { forwardRef, useState } from 'react'
import { Box, BoxProps, Stack } from '@mui/material'
import DataCalender, { Today } from './DataCalender'
import DataTasks from './DataTasks'
import DataList from './DataList'

interface DataProps extends BoxProps {}

const ManageTiltingDatas = forwardRef<typeof Box, DataProps>(
  ({ ...props }, ref) => {
    const [selectedDate, setSelectedDate] = useState<Date>(Today.toDate())
    const [refreshFlag, setRefreshFlag] = useState(+new Date())

    return (
      <>
        <Stack direction="row" sx={{ py: 1, height: 1 }} spacing={1}>
          <Stack
            sx={{ width: 360, height: 1, flexShrink: 0, overflow: 'hidden' }}
            spacing={1}>
            <DataCalender
              onDateChange={(selectedDate) => {
                setSelectedDate(selectedDate)
              }}
              onUpdated={() => {
                setRefreshFlag(+new Date())
              }}
            />
            <DataTasks
              refreshFlag={refreshFlag}
              date={selectedDate}
              sx={{ flexGrow: 1 }}
            />
          </Stack>
          <Stack sx={{ mb: 1, flexGrow: 1, overflow: 'hidden' }} flexGrow={1}>
            <DataList date={selectedDate} />
          </Stack>
        </Stack>
      </>
    )
  }
)

ManageTiltingDatas.displayName = 'ManageTiltingDatas'
export default ManageTiltingDatas
