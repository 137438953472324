import { Moment } from 'moment'
import { RegionEnum } from '@/configs/config.regions'

export enum ServiceTypeEnum {
  LIFY_T = 'LIFY_T',
  LIFY_T_PRO = 'LIFY_T_PRO',
  LIFY_G = 'LIFY_G'
}

export enum SubscriptionTypeEnum {
  INITIAL = 'I',
  EXTENSION = 'E'
}

export type Subscription = {
  id: string
  organizationId: string
  type: SubscriptionTypeEnum
  serviceType: ServiceTypeEnum
  expiringTipMuted: false
  expiredAt: Date
  createdAt: Date
}

export type Organization = {
  id: string
  name: string
  fullname?: string
  region: string
  address?: string
  industry?: string
  subscriptions: Subscription[]
  isLeafiot: boolean
  disabled?: boolean

  contact: {
    person: string
    phone: string
    email: string
  }

  createdAt?: Date
  updatedAt?: Date
}

export type NewOrganization = {
  name: string
  fullname: string
  regionId: RegionEnum
  address: string
  industry: string

  lifytPro: boolean
  lifytProExpiredAt: Moment | null
  expiredAt: Moment
  disabled: boolean

  contactPerson: string
  contactPhone: string
  contactEmail: string

  username: string
  nickname: string
  password: string
  userEmail: string
  userPhone: string
  userAdditionalEmails: string[]
}

export type UpdatedOrganization = {
  fullname: string
  regionId: RegionEnum
  address: string
  industry: string

  expiredAt: Moment
  lifytPro: boolean
  lifytProExpiredAt: Moment | null
  
  disabled: boolean

  contactPerson: string
  contactPhone: string
  contactEmail: string
}
