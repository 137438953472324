import React, { forwardRef } from 'react'
import { Box, BoxProps, Chip, Stack, Tooltip, Typography } from '@mui/material'
import { useAppSelector } from '@/store'
import moment from 'moment'
import { Icons } from '@/assets/icons'

interface SubscriptionProps extends BoxProps {}

const Subscription = forwardRef<typeof Box, SubscriptionProps>(
  ({ ...props }, ref) => {
    const subscription = useAppSelector((state) => state.users.subscription)
    const lifytProRemainingDays = subscription?.lifytPro?.remainingDays
    const isLifytProExpird = lifytProRemainingDays < 0
    const isLifytProExpiring =
      0 <= lifytProRemainingDays && lifytProRemainingDays < 30
    const lifytProExpiredAt = moment(subscription?.lifytPro?.expiredAt).format(
      'Do MMM YYYY'
    )
    return (
      <Box ref={ref}>
        {subscription?.lifytPro?.isValid && (
          <Tooltip
            placement="top"
            title={
              <>
                {isLifytProExpird && (
                  <Typography variant="caption">
                    LiFY-T Pro service is not available
                  </Typography>
                )}
                {!isLifytProExpird && (
                  <>
                    <Typography component="div" variant="caption">
                      LiFY-T Pro service expires in
                      {` ${lifytProRemainingDays} days on ${lifytProExpiredAt}`}
                      .
                    </Typography>
                  </>
                )}
              </>
            }>
            <Box sx={{ textAlign: 'center' }}>
              <Chip
                size="small"
                color={
                  isLifytProExpird
                    ? 'default'
                    : isLifytProExpiring
                    ? 'warning'
                    : 'primary'
                }
                sx={{ height: 18, opacity: isLifytProExpird ? 0.5 : 1 }}
                label={
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography fontSize="inherit">PRO</Typography>
                    {isLifytProExpiring && !isLifytProExpird && (
                      <Icons.Error fontSize="inherit" />
                    )}
                  </Stack>
                }
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    )
  }
)

Subscription.displayName = 'Subscription'
export default Subscription
