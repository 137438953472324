import React, { useState, forwardRef } from 'react'
import {
  Box,
  FormLabel,
  TextField,
  FormControlProps,
  FormControl,
  Stack,
  Typography
} from '@mui/material'
import { FormLabelWidth } from './form.cont'
import TipIconBtn from '../TipIconBtn'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'

interface TextInputProps extends FormControlProps {
  label?: string | React.ReactNode
  labelTip?: string | React.ReactNode
  value: string | number
  number?: boolean
  password?: boolean
  autopass?: boolean
  onValChange?: (string) => void
  labelWidth?: number
  helperText?: string | React.ReactNode
  placeholder?: string
  disabled?: boolean
}

const FormInput = forwardRef<typeof Box, TextInputProps>(
  (
    {
      sx,
      password = false,
      autopass = true,
      number = false,
      label,
      labelTip,
      labelWidth,
      value,
      onValChange,
      helperText,
      required,
      placeholder,
      error,
      disabled = false,
      onBlur,
      ...props
    },
    ref
  ) => {
    const [showPsw, setShowPsw] = useState(false)
    let inputType = 'text'

    if (showPsw) {
      inputType = 'text'
    } else {
      if (password) {
        inputType = 'password'
      } else if (number) {
        inputType = 'number'
      }
    }

    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        onBlur={onBlur}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          ...sx
        }}>
        {(label || labelTip) && (
          <Stack
            sx={{ width: labelWidth ?? FormLabelWidth, mr: 2, flexShrink: 0 }}>
            {label && <FormLabel>{label}</FormLabel>}
            <Typography variant="caption" color={grey[400]}>
              {labelTip}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" sx={{ flexGrow: 1 }}>
          <TextField
            disabled={disabled}
            sx={{ flexGrow: 1, '& .MuiFilledInput-root': { pr: 0 } }}
            autoComplete="off"
            size="small"
            inputProps={{
              type: inputType,
              style: { backgroundColor: '#fff' },
              ...(number && { step: 0.1 })
            }}
            error={error}
            InputProps={{
              endAdornment: password && autopass && (
                <Stack direction="row">
                  <TipIconBtn
                    tip={showPsw ? 'Hide password' : 'Show password'}
                    size="small"
                    onClick={() => setShowPsw(!showPsw)}>
                    {password && !showPsw ? (
                      <Icons.VisibilityOutlined fontSize="inherit" />
                    ) : (
                      <Icons.VisibilityOffOutlined fontSize="inherit" />
                    )}
                  </TipIconBtn>
                  <TipIconBtn
                    size="small"
                    tip={'Generate secure password'}
                    onClick={() =>
                      onValChange(Math.random().toString(36).slice(-9))
                    }>
                    <Icons.VpnKeyOutlined fontSize="inherit" />
                  </TipIconBtn>
                </Stack>
              )
            }}
            helperText={helperText}
            value={value || ''}
            placeholder={placeholder ?? ''}
            onChange={(event) => onValChange(event.target.value)}
          />
        </Stack>
      </FormControl>
    )
  }
)

FormInput.displayName = 'FormInput'
export default FormInput
