import { EChartOption } from 'echarts/lib/echarts'
import * as echarts from 'echarts/core'
import { CarbonSummaryItem } from './EsgSpaciesCarbon'

export const getCarbonOptions = (
  dataByMonth: CarbonSummaryItem[]
): EChartOption => {
  return {
    tooltip: {
      trigger: 'axis',
      //@ts-ignore
      valueFormatter: (value) => `${value} KG`
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: dataByMonth.map((item) => item.month)
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Carbon Uptake',
        type: 'line',
        stack: 'Total',
        smooth: true,
        itemStyle:{
          color: 'rgb(0, 186, 115)'
        },
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: 'rgb(186, 229, 199)'
            },
            {
              offset: 0,
              color: 'rgb(0, 186, 115)'
            }
          ])
        },
        data: dataByMonth.map((item) => item.count)
      }
    ]
  }
}
