import { store } from '@/store'
import {
  ServiceHost,
  DefaultHeader,
  ZipContentType,
  PublicService
} from './request.const'
import {
  HeadersType,
  ContentTypes,
  MethodEnum,
  IRequestParams,
  UnifiedRespose
} from './request.types'
import { ErrorHandler, ResponseHandler } from './request.helper'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { usersSlice } from '@/store/reducers/user/user.slice'
import { TokenInfo } from '@/routes/login/login.types'
import moment from 'moment'
import { AccessStorage } from '@/configs/storage-dics'

const requestFunc = async <ReqType, RespType>(
  menthd: MethodEnum,
  url: string,
  data?: ReqType,
  headers?: HeadersType | null,
  showTips = true
) => {
  const isPublicService = PublicService.includes(url)

  if (!isPublicService) {
    const access = store.getState().users?.access
    const refresh = store.getState().users?.refresh

    if (!access && !refresh) {
      return
    }

    if (moment(moment.unix(access?.exp)).diff(moment(), 'milliseconds') < 0) {
      if (
        moment(moment.unix(refresh?.exp)).diff(moment(), 'milliseconds') < 0
      ) {
        store.dispatch(usersSlice.actions.setIsSignedIn(false))
        store.dispatch(
          globalSlice.actions.setNotification({
            type: 'error',
            caption: 'Token expired, please login again'
          })
        )
      } else {
        const resp = await fetch(`${ServiceHost}/auth/refresh`, {
          method: MethodEnum.POST,
          headers: {
            ...DefaultHeader,
            Authorization: `Bearer ${store.getState().users?.refresh?.token}`
          }
        })
        const tokenResp = await resp.json()
        if (tokenResp?.code === 200) {
          const access = tokenResp.data as TokenInfo
          localStorage.setItem(AccessStorage, JSON.stringify(access))
          store.dispatch(usersSlice.actions.setAccessToken(access))
        } else {
          store.dispatch(usersSlice.actions.setIsSignedIn(false))
          store.dispatch(
            globalSlice.actions.setNotification({
              type: 'error',
              caption: 'Token expired, please login again'
            })
          )
        }
      }
    }
  }
  let reqUrl = `${ServiceHost}/${url}`
  const reqHeader: HeadersType = {
    ...(headers ?? DefaultHeader),
    Authorization: `Bearer ${store.getState().users?.access?.token}`
  }
  const reqParams: RequestInit = {
    method: menthd,
    headers: reqHeader
  }
  if (menthd === MethodEnum.GET) {
    const queryStr = new URLSearchParams(data ?? {})
    reqUrl = `${reqUrl}?${queryStr}`
  } else {
    reqParams.body = JSON.stringify(data || {})
  }

  const respone = fetch(reqUrl, reqParams)
    .then(async (resp) => {
      const headerContentType = reqHeader['Content-Type']
      let contentType: ContentTypes = 'json'

      if (headerContentType === ZipContentType) {
        contentType = 'zip'
      }

      return ResponseHandler(resp, { showTips, reqUrl }, contentType)
    })
    .catch((error) => ErrorHandler(error, reqUrl))

  return respone as RespType
}

export default {
  async get<RespType = any, ReqType = any>(
    url: string,
    params?: IRequestParams<ReqType>
  ) {
    return requestFunc<ReqType, UnifiedRespose<RespType>>(
      MethodEnum.GET,
      url,
      params?.data,
      params?.headers,
      params?.showTips
    )
  },
  async post<RespType = any, ReqType = any>(
    url: string,
    params?: IRequestParams<ReqType>
  ) {
    return requestFunc<ReqType, UnifiedRespose<RespType>>(
      MethodEnum.POST,
      url,
      params?.data,
      params?.headers,
      params?.showTips
    )
  },
  async put<RespType = any, ReqType = any>(
    url: string,
    params?: IRequestParams<ReqType>
  ) {
    return requestFunc<ReqType, UnifiedRespose<RespType>>(
      MethodEnum.PUT,
      url,
      params?.data,
      params?.headers,
      params?.showTips
    )
  },
  async patch<RespType = any, ReqType = any>(
    url: string,
    params?: IRequestParams<ReqType>
  ) {
    return requestFunc<ReqType, UnifiedRespose<RespType>>(
      MethodEnum.PATCH,
      url,
      params?.data,
      params?.headers,
      params?.showTips
    )
  },
  async delete<RespType = any, ReqType = any>(
    url: string,
    params?: IRequestParams<ReqType>
  ) {
    return requestFunc<ReqType, UnifiedRespose<RespType>>(
      MethodEnum.DELETE,
      url,
      params?.data,
      params?.headers,
      params?.showTips
    )
  }
}
