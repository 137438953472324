import React from 'react'
import { Stack, Chip, Typography, Tooltip } from '@mui/material'
import { Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom'
import { Icons } from '@/assets/icons'
import { useAppSelector } from '@/store'
import { RolesEnum } from '../manage/user/users.types'
import { Layout, Header, Body, Menu } from '@/components/page'
import RootLayout from '../../components/page/RootLayout'

const Setting = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.users.user)
  const subscription = useAppSelector((state) => state.users.subscription)

  return (
    <RootLayout>
      <Layout>
        <Header>
          <Stack direction="row">
            <Menu
              active={Boolean(
                matchPath(location?.pathname, '/setting/account')
              )}
              icon={<Icons.Person />}
              onClick={() => navigate('account')}>
              Account
            </Menu>
            {user?.role !== RolesEnum.User && (
              <Stack direction="row" alignItems="center">
                <Menu
                  sx={{ mr: 0 }}
                  disabled={!subscription?.lifytPro?.isValid}
                  active={Boolean(
                    matchPath(location?.pathname, '/setting/users')
                  )}
                  icon={<Icons.People />}
                  onClick={() => navigate('users')}>
                  Users
                </Menu>
                {!subscription?.lifytPro?.isValid && (
                  <Tooltip
                    title={
                      <Typography fontSize="inherit">
                        You can create mutiple accounts for your organization to
                        make your work more easily and efficiently with User
                        Management. <br /> Please consider subscribing to our
                        professional service if you have more advanced needs. 😊
                      </Typography>
                    }>
                    <Chip
                      sx={{ height: 16, cursor: 'pointer' }}
                      size="small"
                      color="primary"
                      variant="outlined"
                      label={'Pro'}
                    />
                  </Tooltip>
                )}
              </Stack>
            )}
          </Stack>
        </Header>
        <Body>
          <Outlet />
        </Body>
      </Layout>
    </RootLayout>
  )
}

export default Setting
