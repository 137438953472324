import React, { forwardRef } from 'react'
import { Menu, MenuProps, MenuItem, Tooltip, Typography } from '@mui/material'
import { User } from '../users.types'
import { Icons } from '@/assets/icons'

interface ActionMenuProps extends MenuProps {
  user: User
  onClose: () => void
  onDelete: (anchor: MenuProps['anchorEl'], data: User) => void
}

const ActionMenu = forwardRef<typeof Menu, ActionMenuProps>(
  ({ anchorEl, open, user, onClose, onDelete, ...props }, ref) => {
    return (
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <Tooltip
          title={
            'Default users cannot be deleted unless it orgnization does not exist anymore'
          }>
          <div>
            <MenuItem
              disabled={user?.isDefault && user?.organizationDetail?.deleted === false}
              key="assgin-trees"
              onClick={() => onDelete(anchorEl, user)}>
              Delete
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    )
  }
)

ActionMenu.displayName = 'ActionMenu'
export default ActionMenu
