import React, { forwardRef } from 'react'
import {
  Box,
  BoxProps,
  Chip,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'
import { GridColDef } from '@mui/x-data-grid'
import { WeatherWarning } from './weather-warning.types'
import moment, { Moment } from 'moment'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import ListDatePicker from '@/components/ListDatePicker'
import { getWeatherWarnings } from '@/service/manage/weather'
import { WeatherWarningToText } from '@/routes/home/mode/mode.const'

export type ManageWeatherWarningPageFilters = {
  date?: Moment
}
export type ManageWeatherWarningListParams =
  PageListParams<ManageWeatherWarningPageFilters>

interface WeatherWarningsProps extends BoxProps {}

const WarningWarnings = forwardRef<typeof Box, WeatherWarningsProps>(
  ({ ...props }, ref) => {
    const {
      loading,
      filters,
      page,
      limit,
      listData,
      totalCount,
      refresh,
      changePage,
      changeFilters
    } = usePageList<ManageWeatherWarningPageFilters, WeatherWarning>(
      'weatherWarnings',
      getWeatherWarnings
    )

    return (
      <>
        <Stack sx={{ height: 1 }} spacing={1}>
          <Stack
            sx={{ flexShrink: 0 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <ListDatePicker
                date={filters?.date}
                onDateChange={(date) => changeFilters({ date })}
              />
            </Stack>
            <Stack direction="row">
              <TipIconBtn tip="Refresh" onClick={() => refresh()}>
                <Icons.Refresh fontSize="small"></Icons.Refresh>
              </TipIconBtn>
            </Stack>
          </Stack>

          <Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <DataGridC
              columns={getColumns()}
              getRowId={(row) => row._id}
              rows={listData}
              rowCount={totalCount}
              loading={loading}
              paginationModel={{
                page: page > 0 ? page - 1 : 0,
                pageSize: limit
              }}
              pageSizeOptions={[15, 50]}
              onPaginationModelChange={(params) => {
                const isPageSizeChanged = limit !== params.pageSize
                changePage({
                  page: isPageSizeChanged ? 1 : params.page + 1,
                  limit: params.pageSize
                })
              }}
            />
          </Paper>
        </Stack>
      </>
    )
  }
)
WarningWarnings.displayName = 'WarningWarnings'
export default WarningWarnings

const getColumns: () => GridColDef<WeatherWarning>[] = () => {
  return [
    {
      field: 'recordedAt',
      headerName: 'Recorded At',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 240,
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      field: 'mode',
      headerName: 'Mode',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell(params) {
        return <Chip size="small" label={params.value} />
      }
    },
    {
      field: 'warningCodes',
      headerName: 'Warning Codes',
      headerAlign: 'left',
      width: 480,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        if (params.row.isEmpty) {
          return (
            <Typography fontStyle="italic" variant="caption">
              Empty Warning
            </Typography>
          )
        }

        return (
          <Stack direction={'row'}>
            {params.value
              ?.filter((warningCode) => {
                return WeatherWarningToText[warningCode]
              })
              .map((warningCode) => {
                return (
                  <Chip
                    size="small"
                    label={WeatherWarningToText[warningCode]?.en}></Chip>
                )
              })}
          </Stack>
        )
      }
    },
    {
      field: 'details',
      headerName: 'Details',
      headerAlign: 'left',
      flex: 1,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        if (params.row.isEmpty) {
          return (
            <Typography fontStyle="italic" variant="caption">
              Empty Warning
            </Typography>
          )
        }
        const detailText = []

        if (Array.isArray(params.value)) {
          params.value?.forEach((detailItem) => {
            detailItem?.contents?.forEach((content) => {
              detailText.push(content)
            })
          })
        } else {
          const warningWarningDetails = params.value
          if (warningWarningDetails?.contents) {
            detailText.push(...warningWarningDetails?.contents)
          }
          warningWarningDetails?.details?.forEach((detailItem) => {
            detailItem?.contents?.forEach((content) => {
              detailText.push(content)
            })
          })
        }

        return (
          <Tooltip
            placement="bottom-start"
            title={
              <Box sx={{ maxHeight: 240, overflow: 'auto' }}>
                {detailText.map((text) => (
                  <Typography paragraph variant="body2">
                    {text}
                  </Typography>
                ))}
              </Box>
            }>
            <Typography fontSize="inherit"> {detailText.join(' ')}</Typography>
          </Tooltip>
        )
      }
    }
  ]
}
