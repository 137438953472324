import React, { forwardRef } from 'react'
import { Stack, StackProps, Typography } from '@mui/material'
import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'

interface EsgHeaderProps extends StackProps {
  onFresh: () => void
}

const EsgHeader = forwardRef<typeof Stack, EsgHeaderProps>(
  ({ sx, onFresh, ...props }, ref) => {
    return (
      <Stack sx={{ ...sx }} direction="row" justifyContent="space-between">
        <Typography variant="h6">📜 ESG Summary</Typography>
        <Stack direction="row" spacing={1}>
          {/* <TipIconBtn size="small" tip="Download">
            <Icons.Download fontSize="inherit" />
          </TipIconBtn> */}
          <TipIconBtn
            size="small"
            tip="Refresh"
            sx={{ bgcolor: '#fff' }}
            onClick={() => onFresh()}>
            <Icons.Refresh fontSize="inherit" />
          </TipIconBtn>
        </Stack>
      </Stack>
    )
  }
)

EsgHeader.displayName = 'EsgHeader'
export default EsgHeader
