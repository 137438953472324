import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITreeStates, ITree, ITreePageParams } from './types'
import { getTreesAsync } from './thunks'

export const defTreePageParams = {
  pageIndex: 1,
  limit: 10,
  treeId: '',
  district: '',
  department: ''
}

const initialState = {
  pageParams: defTreePageParams,
  trees: [],
  totalCount: 0,
  isLoadingTrees: false
} as ITreeStates

export const treesSlice = createSlice({
  name: 'trees',
  initialState,
  reducers: {
    setPageParam(state, action: PayloadAction<ITreePageParams>) {
      state.pageParams = action.payload
    },
    setTrees(state, action: PayloadAction<ITree[]>) {
      state.trees = action.payload
    }
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
  }
})

export default treesSlice.reducer
