import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'
import { Layout, Header, Body, Menu } from '@/components/page'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { Icons } from '@/assets/icons'
import RootLayout from '@/components/page/RootLayout'

interface ManageReportProps extends BoxProps {}

const ManageReport = forwardRef<typeof Box, ManageReportProps>(
  ({ ...props }, ref) => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
      <RootLayout>
        <Layout ref={ref}>
          <Header>
            <Menu
              active={Boolean(
                matchPath(location?.pathname, '/manage-report/daily-report')
              )}
              icon={<Icons.EmailOutlined />}
              onClick={() => navigate('daily-report')}>
              Daily Report
            </Menu>
            <Menu
              active={Boolean(
                matchPath(location?.pathname, '/manage-report/special-alert')
              )}
              icon={<Icons.NotificationsActiveOutlined />}
              onClick={() => navigate('special-alert')}>
              Special Alert
            </Menu>
          </Header>
          <Body>
            <Outlet />
          </Body>
        </Layout>
      </RootLayout>
    )
  }
)

ManageReport.displayName = 'ManageReport'
export default ManageReport