import request from '../request'
import { MapTreeDetailsType, TreesFilterType } from '@/routes/map/map.types'
import {
  TitlingData,
  TitlingLevelData
} from '@/routes/map/details/charts/Charts'
import { MapTreeResp } from '@/store/reducers/map/map.types'

export const getTreeTotalcount = () => {
  return request.get<number>('map/tree-total-count')
}

export const getTrees = (params?: TreesFilterType) => {
  return request.get<{
    trees: MapTreeResp[]
    totalCount: number
  }>('map/trees', { data: params })
}

export const getTreeDetails = (treeId: string) => {
  return request.get<MapTreeDetailsType>(`map/tree/${treeId}/details`)
}

export const getTreeTiltingDataTotalCount = (
  treeId: string,
  dataRange: number[]
) => {
  return request.get<number>(`data/tree/${treeId}/tilting-data-totalcount`, {
    data: { period: dataRange }
  })
}

export const getTreeTiltingData = (params: {
  treeId: string
  period: number[]
  page: number
  limit: number
}) => {
  return request.get<{
    data: TitlingData[]
    totalCount: number
  }>(`data/tree/${params.treeId}/tilting-data`, {
    data: { period: params.period, page: params.page, limit: params.limit }
  })
}

export const getTiltingVibrationResetTotalCount = (
  treeId: string,
  dataRange: number[]
) => {
  return request.get<number>(
    `data/tree/${treeId}/tilting-vibration-reset-totalcount`,
    {
      data: { period: dataRange }
    }
  )
}

export const getTiltingVibrationReset = (params: {
  treeId: string
  period: number[]
  page: number
  limit: number
}) => {
  return request.get<{
    data: TitlingLevelData[]
    totalCount: number
  }>(`data/tree/${params.treeId}/tilting-vibration-reset`, {
    data: { period: params.period, page: params.page, limit: params.limit }
  })
}

export const exportTreeTiltingData = (
  treeId: string,
  userTreeId: string,
  dataRange: number[]
) => {
  return request.get(`data/tree/${treeId}/tilting-data/export`, {
    data: { userTreeId: userTreeId ? userTreeId : treeId, period: dataRange },
    headers: {
      'Content-Type': 'application/zip, application/octet-stream'
    }
  })
}
