import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  Button,
  IconButton,
  Divider
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { DefaultNewUser } from '../users.const'
import { Controller, useForm } from 'react-hook-form'
import { createUser, getOrganizations, getGroups } from '@/service/manage/users'
import {
  NewUser as NewUserType,
  RolesEnum,
  OrganizationOptions,
  GroupOptions
} from '../users.types'
import { encryptPassword } from '@/routes/login/login.helper'
import { useAppSelector } from '@/store'
import FormInput from '@/components/form/FormInput'
import FormRadio from '@/components/form/FormRadio'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import { Regions } from '@/configs/config.regions'
import { RoleNames } from '../users.const'
import FormDrawer from '@/components/FormDrawer'

interface NewUserProps extends DrawerProps {
  onClose: () => void
  onSuccess: () => void
}

const NewUser = forwardRef<typeof Drawer, NewUserProps>(
  ({ open, onClose, onSuccess, ...props }, ref) => {
    const user = useAppSelector((state) => state.users.user)
    const [groups, setGroups] = useState<GroupOptions[]>([])
    const [organizations, setOrganizations] = useState<OrganizationOptions[]>(
      []
    )

    const defaultNewUser = DefaultNewUser

    if (user.role !== RolesEnum.Admin) {
      defaultNewUser.role = RolesEnum.User
    }

    const { register, control, handleSubmit, reset, setValue, watch } =
      useForm<NewUserType>({
        defaultValues: { ...defaultNewUser }
      })

    const organizationId = watch('organizationId')

    const submitNewUser = () => {
      handleSubmit(async (values: NewUserType) => {
        const newUser = {
          ...values,
          password: encryptPassword(values.password)
        }
        const resp = await createUser(newUser)
        if (resp?.code === 200) {
          onSuccess()
          reset(defaultNewUser)
        }
      })()
    }

    useEffect(() => {
      if (open) {
        getOrganizations().then((resp) => {
          if (resp?.code === 200) {
            setOrganizations(resp.data)
          }
        })
      } else {
        reset(defaultNewUser)
      }
    }, [open])

    useEffect(() => {
      if (open && organizationId) {
        getGroups(organizationId).then((resp) => {
          if (resp?.code === 200) {
            setGroups(resp.data)
            if (resp?.data?.length === 1) {
              setValue('groupId', resp?.data?.[0].id)
            }
          }
        })
      }
    }, [open, organizationId])

    return (
      <FormDrawer
        title="New User"
        open={open}
        onSubmit={() => submitNewUser()}
        onClose={() => onClose()}>
        <Controller
          name="username"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            maxLength: 16
          }}
          render={({ field, fieldState }) => (
            <FormInput
              required
              error={Boolean(fieldState.error)}
              label="User name"
              helperText="Min length 8, max length 16"
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Controller
          name="nickname"
          control={control}
          render={({ field }) => (
            <FormInput
              label="Nick name"
              helperText={
                'Nick name will replace user name as display name to show in the system'
              }
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            maxLength: 16
          }}
          render={({ field, fieldState }) => (
            <FormInput
              required
              password
              error={Boolean(fieldState.error)}
              label="Password"
              helperText="Min lenght 8, max length 16, Special character support !@#$%^&*()_+"
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Divider />

        <Controller
          name="regionId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormSelect
              label="Region"
              options={Regions.map((region) => ({
                label: region.name,
                value: region.id
              }))}
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Controller
          name="organizationId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormSelect
              label="Organization"
              options={organizations.map((g) => ({
                label: g.name,
                value: g.id
              }))}
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />

        <Controller
          name="groupId"
          control={control}
          rules={{
            required: true,
            validate: (value) => {
              if (!value || value === '') {
                return 'Group is required'
              }
              return undefined
            }
          }}
          render={({ field, fieldState }) => (
            <FormSelect
              label="Group"
              error={Boolean(fieldState.error)}
              options={groups.map((g) => ({
                label: g.name,
                value: g.id
              }))}
              value={field.value}
              tip={Boolean(fieldState?.error) && 'Required'}
              onValChange={field.onChange}
            />
          )}
        />

        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormRadio
              label="Role"
              labelTip={<RoleTip />}
              options={[
                {
                  label: RoleNames[RolesEnum.Admin],
                  value: RolesEnum.Admin
                },
                {
                  label: RoleNames[RolesEnum.Manager],
                  value: RolesEnum.Manager
                },
                {
                  label: RoleNames[RolesEnum.User],
                  value: RolesEnum.User
                }
              ]}
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Divider />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormInput
              label="Email"
              helperText="Email to receive notification from LeafIot"
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <FormInput
              label="Phone"
              helperText="Phone to receive alert massage from LeafIot"
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
        <Divider />

        <Controller
          name="disabled"
          control={control}
          render={({ field }) => (
            <FormSwitch
              label="Disable"
              tip="User's access will be suspended after creation"
              value={field.value}
              onValChange={field.onChange}
            />
          )}
        />
      </FormDrawer>
    )
  }
)

NewUser.displayName = 'NewUser'
export default NewUser
export const RoleTip = () => {
  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="primary.main">
          Admin
        </Typography>
        <Typography variant="body2">
          Own top privalige to manage all resources of its organization
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="primary.main">
          Manager
        </Typography>
        <Typography variant="body2">
          Own access to manage the resources in its group of its organization
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="primary.main">
          User
        </Typography>
        <Typography variant="body2">
          Own access to view the resouces in the system
        </Typography>
      </Stack>
    </Stack>
  )
}
