import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHomeState } from './home.types'
import { getAlertTrunk } from './home.thunks'
import { AlertListItemType, AlertListType } from '@/components/AlertList'

const initialState = {
  isLoadingAlerts: false,
} as IHomeState

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    // Weathers
    // builder.addCase(getAlertTrunk.pending, (state) => {
    //   state.isLoadingAlerts = true
    // })
    // builder.addCase(getAlertTrunk.fulfilled, (state, { payload }) => {
    //   state.isLoadingAlerts = false
    //   state.alerts = payload
    // })
    // builder.addCase(getAlertTrunk.rejected, (state) => {
    //   state.isLoadingAlerts = false
    // })
  }
})

export default homeSlice.reducer
