import React from 'react'
import { Typography, Stack, Tooltip } from '@mui/material'
import { grey, orange, red, green } from '@mui/material/colors'
import { FieledNames } from '@/routes/map/details/details.const'
import { Icons } from '@/assets/icons'

const Battery = ({ name, value }) => {
  const isGood = value >= 3.5
  const isFair = 3.2 <= value && value < 3.5
  const isPoor = 0 < value && value < 3.2

  return (
    <Stack direction={'row'}>
      <Stack
        sx={{ width: 160, flexShrink: 0 }}
        direction="row"
        alignItems="center"
        spacing={1}>
        <Typography variant="body2">{FieledNames[name]}</Typography>
        <Tooltip
          title={
            <Stack>
              <Stack direction="row">
                <Typography
                  fontSize="inherit"
                  sx={{ width: 100, flexShrink: 1 }}>
                  H - High
                </Typography>
                <Typography fontSize="inherit" sx={{ flexGrow: 1 }}>
                  {'Voltage >= 3.5V'}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography
                  fontSize="inherit"
                  sx={{ width: 100, flexShrink: 1 }}>
                  M - Medium
                </Typography>
                <Typography fontSize="inherit" sx={{ flexGrow: 1 }}>
                  {'3.2V<= Voltage < 3.5V'}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography
                  fontSize="inherit"
                  sx={{ width: 100, flexShrink: 1 }}>
                  L - Low
                </Typography>
                <Typography fontSize="inherit" sx={{ flexGrow: 1 }}>
                  {'Voltage < 3.2V'}
                </Typography>
              </Stack>
            </Stack>
          }>
          <Icons.HelpOutline sx={{ color: grey[400] }} fontSize="inherit" />
        </Tooltip>
      </Stack>

      <Stack sx={{ width: 1, height: 18 }} direction="row" spacing={1}>
        {value === 0 && <Typography variant="body2">N/A</Typography>}
        {value > 0 && (
          <>
            <Typography
              sx={{
                m: 0,
                flex: 1,
                bgcolor: !isPoor ? grey[300] : red[900]
              }}
              variant="caption"
              textAlign="center">
              {isPoor && `L`}
            </Typography>
            <Typography
              sx={{
                m: 0,
                flex: 1,
                bgcolor: !isFair ? grey[300] : orange[500]
              }}
              variant="caption"
              textAlign="center">
              {isFair && `M`}
            </Typography>
            <Typography
              sx={{
                m: 0,
                flex: 1,
                bgcolor: !isGood ? grey[300] : green[500]
              }}
              variant="caption"
              textAlign="center">
              {isGood && `H`}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default Battery
