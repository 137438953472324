import React, { forwardRef, useEffect, useState } from 'react'
import { Paper, Stack, StackProps, Typography } from '@mui/material'
import { getLatestDataTime } from '@/service/manage/map'
import moment from 'moment'
import { useAppSelector } from '@/store'

interface TotalTreesProps extends StackProps {}

const TotalTrees = forwardRef<typeof Stack, TotalTreesProps>(
  ({ ...props }, ref) => {
    const overview = useAppSelector((state) => state.manage.overview)
    const isLoadingTrees = overview.isLoadingTrees
    const treeTotalCount = overview.treeTotalCount
    const treesLoadedCount = overview.treesLoadedCount

    return (
      <Paper sx={{ width: 1, height: 1, px: 2, py: 1 }}>
        <Stack justifyContent="space-between">
          <Typography variant="overline">Total Trees</Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end" spacing={1}>
            <Typography sx={{ m: 0 }} paragraph variant="h6">
              {treeTotalCount}
            </Typography>
            {isLoadingTrees && (
              <Typography sx={{ m: 0 }} paragraph variant="caption">
               / {treesLoadedCount} loaded
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>
    )
  }
)

TotalTrees.displayName = 'TotalTrees'
export default React.memo(TotalTrees)
