import React, { useEffect, useState } from 'react'
import { useRoutes, Navigate, RouteObject, useNavigate } from 'react-router-dom'

import Home from './home/Home'
import Map from './map/Map'

import Data from './data/Data'
import Trees from './data/trees/Trees'

import Setting from './setting/Setting'
import Users from './setting/users/Users'

import ManageOverview from './manage/overview/ManageOverview'
import ManageTrees from './manage/data/trees/Trees'
import ManageSensors from './manage/data/sensors/Sensors'
import ManageUsers from './manage/user/Users'
import Organizations from './manage/organization/Organizations'

import { RolesEnum } from './manage/user/users.types'
import Account from './setting/account/Account'
import ManageDatas from './manage/data/ManageData'
import ManageReport from './manage/report/ManageReport'
import ManageDailyReport from './manage/report/daily-report/ManageDailyReport'
import ManageSpecialAlert from './manage/report/special-alert/ManageSpecialAlert'
import ManageTiltingDatas from './manage/data/tilting-data/TiltingData'
import WeatherWarnings from './manage/data/weather-warnings/WeatherWarnings'
import DailyReport from './data/daily-reports/DailyReport'
import SpecialAlerts from './data/special-alerts/SpecialAlerts'
import {
  AccessStorage,
  IsSignedStorage,
  RefreshStorage,
  SubscriptionStorage,
  UserStorage
} from '@/configs/storage-dics'
import { useAppDispatch, useAppSelector } from '@/store'
import { usersSlice } from '@/store/reducers/user/user.slice'
import { mapSlice } from '@/store/reducers/map/map.slice'
import { globalSlice } from '@/store/reducers/global/global.slice'
import Esg from './esg/Esg'

export const getPublicRoutes = (): RouteObject[] => {
  return [
    { path: 'home', element: <Home /> },
    { path: 'map', element: <Map /> },
    { path: 'esg', element: <Esg /> },
    {
      path: 'data',
      element: <Data />,
      children: [
        { index: true, element: <Navigate to="trees" replace /> },
        { path: 'trees', element: <Trees /> },
        { path: 'daily-report', element: <DailyReport /> },
        { path: 'special-alert', element: <SpecialAlerts /> }
      ]
    },
    {
      path: 'setting',
      element: <Setting />,
      children: [
        { index: true, element: <Navigate to="account" replace /> },
        { path: 'account', element: <Account /> },
        { path: 'users', element: <Users /> }
      ]
    }
  ]
}

export const getPrivateRoutes = (): RouteObject[] => {
  return [
    { path: 'manage-overview', element: <ManageOverview /> },
    {
      path: 'manage-data',
      element: <ManageDatas />,
      children: [
        { index: true, element: <Navigate to="trees" replace /> },
        { path: 'trees', element: <ManageTrees /> },
        { path: 'sensors', element: <ManageSensors /> },
        { path: 'tilting-data', element: <ManageTiltingDatas /> },
        { path: 'weather-warning', element: <WeatherWarnings /> }
      ]
    },
    {
      path: 'manage-report',
      element: <ManageReport />,
      children: [
        { index: true, element: <Navigate to="daily-report" replace /> },
        { path: 'daily-report', element: <ManageDailyReport /> },
        { path: 'special-alert', element: <ManageSpecialAlert /> }
      ]
    },
    { path: 'manage-users', element: <ManageUsers /> },
    { path: 'manage-organizations', element: <Organizations /> }
  ]
}

const getRoutesPahts = (routes: RouteObject[]) => {
  return routes.reduce((acc, route) => {
    if (!route.path) {
      return
    }

    const routePath = `/${route.path}`
    if (!acc.includes(routePath)) {
      acc.push(routePath)
    }

    if (route?.children?.length > 0) {
      route.children.forEach((subRoute) => {
        if (!subRoute.path) {
          return
        }
        const subRoutePath = `/${route.path}/${subRoute.path}`
        if (!acc.includes(subRoutePath)) {
          acc.push(subRoutePath)
        }
      })
    }
    return acc
  }, [])
}

// Path for store the menu active status
// e.g. /home, /data/trees
export const PublicRoutesPaths = getRoutesPahts(getPublicRoutes())
export const PrivateRoutesPaths = getRoutesPahts(getPrivateRoutes())

const AppRoutes = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const subscription = useAppSelector((state) => state.users.subscription)
  const user = useAppSelector((state) => state.users.user)
  const [publicRoutes, setPublicRoutes] = useState<RouteObject[]>(() => {
    return getPublicRoutes()
  })
  const [privateRoutes, setPrivateRoutes] = useState<RouteObject[]>(() => {
    return getPrivateRoutes()
  })

  const signOut = () => {
    localStorage.removeItem(IsSignedStorage)
    localStorage.removeItem(UserStorage)
    localStorage.removeItem(AccessStorage)
    localStorage.removeItem(RefreshStorage)
    localStorage.removeItem(SubscriptionStorage)

    dispatch(usersSlice.actions.setUser(null))
    dispatch(usersSlice.actions.setAccessToken(null))
    dispatch(usersSlice.actions.setRefreshToken(null))
    dispatch(usersSlice.actions.setIsSignedIn(false))
    dispatch(usersSlice.actions.setSubscriptionExp(null))
    dispatch(globalSlice.actions.resetNavigation())

    dispatch(mapSlice.actions.setTrees([]))
    navigate('/home')
  }

  useEffect(() => {
    if (!subscription) {
      signOut()
    }
  }, [subscription])

  useEffect(() => {
    const publicRts = getPublicRoutes()
    if (user?.role === RolesEnum.User || !subscription?.lifytPro.isValid) {
      const settingPathIdx = publicRts.findIndex(
        ({ path }) => path === 'setting'
      )
      publicRts[settingPathIdx].children = publicRts[
        settingPathIdx
      ].children.filter(({ path }) => {
        return path !== 'users'
      })
    }

    setPublicRoutes(publicRts)

    if (user?.isLeafiotAdmin) {
      setPrivateRoutes(getPrivateRoutes())
    } else {
      setPrivateRoutes([])
    }
  }, [user, subscription])

  return useRoutes([
    { path: '*', element: <Navigate to="/home" replace /> },
    ...publicRoutes,
    ...privateRoutes
  ])
}

export default AppRoutes
