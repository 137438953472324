import React, { forwardRef, useEffect } from 'react'
import { Stack, StackProps, Button } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import FormInput from '@/components/form/FormInput'
import { grey } from '@mui/material/colors'
import FormTextArea from '@/components/form/FormTextArea'
import { Group } from '@/routes/setting/users/groups/group.types'
import { createGroupForOrganization } from '@/service/manage/organizations'
import { LoadingButton } from '@mui/lab'

export type NewGroup = {
  name: string
  description?: string
}

interface AddGroupProps extends StackProps {
  open: boolean
  organizationId: string
  onSuccess: (newGroup: Group) => void
  onClose: () => void
}

const AddGroup = forwardRef<typeof Stack, AddGroupProps>(
  ({ open, organizationId, onSuccess, onClose, ...props }, ref) => {
    const { control, handleSubmit, reset, formState } = useForm({
      defaultValues: { name: '', description: '' }
    })

    const onSubmit: SubmitHandler<NewGroup> = async (data) => {
      const resp = await createGroupForOrganization(organizationId, data)
      if (resp?.code === 200) {
        onSuccess(resp.data)
      }
    }

    useEffect(() => {
      reset({ name: '', description: '' })
    }, [open])

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{ p: 3, bgcolor: grey[50], borderBottom: '1px solid #ddd' }}
          spacing={1}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <FormInput
                  required
                  labelWidth={120}
                  label="Group Name"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  value={field.value}
                  onValChange={field.onChange}
                />
              )
            }}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => {
              return (
                <FormTextArea
                  required
                  labelWidth={120}
                  label="Description"
                  helperText="Suggested to be the short name of this organization. Max length 120"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )
            }}
          />
          <Stack
            direction="row"
            sx={{ mt: 2 }}
            spacing={2}
            justifyContent="space-between">
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!formState.isDirty}
              loading={formState.isSubmitting}>
              Submit
            </LoadingButton>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    )
  }
)

AddGroup.displayName = 'AddGroup'
export default AddGroup
