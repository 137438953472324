import React, { useState } from 'react'
import { Box, Stack, Typography, Alert, TextField, Button } from '@mui/material'
import { usersSlice } from '@/store/reducers/user/user.slice'
import { signIn } from '@/service/login'
import { useAppDispatch } from '@/store'
import { encryptPassword } from './login.helper'
import { VideoPath } from '@/configs/config.const'
import { ServiceTypeEnum } from '../manage/organization/organization.types'
import moment from 'moment'
import {
  AccessStorage,
  IsSignedStorage,
  RefreshStorage,
  SubscriptionStorage,
  UserStorage
} from '@/configs/storage-dics'

const Login = () => {
  const dispatch = useAppDispatch()
  const [tipMsg, setTipMsg] = useState('')
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const isShowTipMsg = Boolean(tipMsg)

  const clearTipMsg = () => setTipMsg('')

  const onEnterSubmit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    const encryptedPsw = encryptPassword(password)
    if (username === '' || password === '') {
      setTipMsg('Invalid username or passowrd')
      return
    }

    signIn({ username: username, password: encryptedPsw })
      .then((userResp) => {
        if (userResp?.code !== 200) {
          setTipMsg('Invalid username or passowrd')
          return
        }

        const { user, access, refresh } = userResp.data

        const lifytSubs = user.subscriptions.find(
          (s) => s.serviceType === ServiceTypeEnum.LIFY_T
        )
        const lifytRemainingDays = moment(lifytSubs?.expiredAt).diff(
          moment(),
          'days'
        )
        const lifytProSubs = user.subscriptions.find(
          (s) => s.serviceType === ServiceTypeEnum.LIFY_T_PRO
        )
        const lifytProRemainingDays = moment(lifytProSubs?.expiredAt).diff(
          moment(),
          'days'
        )

        const subscription = {
          lifyt: {
            isValid: Boolean(lifytSubs) && lifytRemainingDays >= 0,
            expiredAt: lifytSubs?.expiredAt,
            remainingDays: lifytRemainingDays
          },
          lifytPro: {
            isValid: Boolean(lifytProSubs) && lifytProRemainingDays >= 0,
            expiredAt: lifytProSubs?.expiredAt,
            remainingDays: lifytProRemainingDays
          }
        }

        localStorage.setItem(IsSignedStorage, 'true')
        localStorage.setItem(UserStorage, JSON.stringify(user))
        localStorage.setItem(AccessStorage, JSON.stringify(access))
        localStorage.setItem(RefreshStorage, JSON.stringify(refresh))
        localStorage.setItem(SubscriptionStorage, JSON.stringify(subscription))

        dispatch(usersSlice.actions.setUser(user))
        dispatch(usersSlice.actions.setAccessToken(access))
        dispatch(usersSlice.actions.setRefreshToken(refresh))
        dispatch(usersSlice.actions.setSubscriptionExp(subscription))
        dispatch(usersSlice.actions.setIsSignedIn(true))
      })
      .catch((error) => console.log(error))
  }

  return (
    <Stack
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
        // boxShadow: 'inset 0 0 400px 1px rgba(0,0,0,.06)'
      }}
      direction={'row'}>
      <Box
        style={{
          flexGrow: 1,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundSize: '88% auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.84
        }}>
        <video
          style={{ height: '70%' }}
          preload={'auto'}
          autoPlay
          playsInline
          muted>
          <source src={`${VideoPath}/leafiot_openning.mp4`} type="video/mp4" />
        </video>
      </Box>
      <Box
        style={{
          width: 600,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'inset 0 0 400px 1px rgba(0,184,126,.04)'
        }}
        onKeyUp={onEnterSubmit}>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Typography
            variant="h2"
            sx={{
              width: 360,
              textAlign: 'left',
              fontWeight: 'bolder',
              textShadow: '60px -20px 80px #FBDA61'
            }}
            color={'primary'}>
            LiFY T
          </Typography>
          <Typography
            variant="caption"
            color={'primary'}
            sx={{
              width: 360,
              marginBottom: 5,
              textAlign: 'left',
              textShadow: '60px -20px 80px #FBDA61'
            }}>
            LeafIot Product
          </Typography>
          <TextField
            sx={{ width: 360, marginBottom: 1 }}
            label="User name"
            variant="standard"
            onChange={(evt) => {
              setUserName(evt.target.value)
              clearTipMsg()
            }}
          />
          <TextField
            sx={{ width: 360, marginBottom: 2 }}
            id="outlined-basic"
            label="Password"
            type="password"
            variant="standard"
            onChange={(evt) => {
              setPassword(evt.target.value)
              clearTipMsg()
            }}
          />

          <Box
            sx={{
              width: 360,
              marginBottom: 2,
              opacity: isShowTipMsg ? 1 : 0
            }}>
            <Alert severity="error">{tipMsg || 'no error'}</Alert>
          </Box>

          {/* leafiotDEV@a01 */}
          <Button sx={{ width: 360 }} variant="contained" onClick={onSubmit}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

export default Login
