import React, { FC } from 'react'
import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'
import {
  AccessStorage,
  IsSignedStorage,
  RefreshStorage,
  SubscriptionStorage,
  UserStorage
} from '@/configs/storage-dics'
import { useAppDispatch } from '@/store'
import { usersSlice } from '@/store/reducers/user/user.slice'
import { mapSlice } from '@/store/reducers/map/map.slice'
import { useNavigate } from 'react-router-dom'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { DefaultFilters } from '../map/view/Filters'
import { manageSlice } from '@/store/reducers/manage/manage.slice'

const SignOutButton: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const signOut = () => {
    localStorage.removeItem(IsSignedStorage)
    localStorage.removeItem(UserStorage)
    localStorage.removeItem(AccessStorage)
    localStorage.removeItem(RefreshStorage)
    localStorage.removeItem(SubscriptionStorage)

    dispatch(usersSlice.actions.setUser(null))
    dispatch(usersSlice.actions.setAccessToken(null))
    dispatch(usersSlice.actions.setRefreshToken(null))
    dispatch(usersSlice.actions.setIsSignedIn(false))
    dispatch(usersSlice.actions.setSubscriptionExp(null))
    dispatch(globalSlice.actions.resetNavigation())

    dispatch(mapSlice.actions.setTrees([]))
    dispatch(mapSlice.actions.setTreeTotalcount(0))
    dispatch(mapSlice.actions.setTreeLoadedCount(0))
    
    dispatch(mapSlice.actions.setMapFilter(DefaultFilters))
    dispatch(
      globalSlice.actions.setAlertListStatus({
        show: false,
        type: 'any'
      })
    )

    dispatch(manageSlice.actions.setOverviewMapTrees([]))
    dispatch(manageSlice.actions.setOverviewMapTreeTotalCount(0))
    dispatch(manageSlice.actions.setOverviewMapTreeLoadedCount(0))
    navigate('/home')
  }

  return (
    <TipIconBtn
      sx={{ color: 'primary.main' }}
      aria-label="Sign out"
      tip="Sign out"
      size="small"
      onClick={() => signOut()}>
      <Icons.ExitToApp fontSize="inherit" />
    </TipIconBtn>
  )
}

SignOutButton.displayName = 'SignOutButton'
export default SignOutButton
