import React, { forwardRef, } from 'react'
import {
  Box,
  Stack,
  StackProps,
} from '@mui/material'

interface LayoutProps extends StackProps {}

 const Layout = forwardRef<typeof Box, LayoutProps>(
  ({ children, spacing = 0, ...props }, ref) => {
    return (
      <Stack
        spacing={spacing}
        sx={{
          height: 1,
          position: 'relative',
          overflowY: 'scrol'
        }}>
        {children}
      </Stack>
    )
  }
)

Layout.displayName = 'PageLayout'

export default Layout