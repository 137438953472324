import React, { forwardRef } from 'react'
import {
  Popover,
  PopoverProps,
  Stack,
  Typography,
  Box,
  Button
} from '@mui/material'
import { deleteGroup } from '@/service/settings/users'
import { Group } from './group.types'

interface DeleteGroupProps extends PopoverProps {
  group: Group
  onClose: () => void
  onSuccess?: () => void
}

const DeleteGroup = forwardRef<typeof Popover, DeleteGroupProps>(
  ({ group, open, anchorEl, onClose, onSuccess, ...props }, ref) => {
    const delGroup = () => {
      deleteGroup(group?.id).then((resp) => {
        if (resp?.code === 200) {
          onSuccess()
        }
      })
    }
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Stack sx={{ p: 2, width: 400 }} spacing={2}>
          <Typography paragraph variant="h6" color={'primary'}>
            Delete Group
          </Typography>

          <Box>
            <Typography variant="body2" paragraph>
              Trees and users of this group will be re-grouped to the default
              group of your organization.
            </Typography>
            <Typography variant="body2" paragraph>
              And users of this group will be disabled untill they are enabled
              manually again.
            </Typography>
            <Typography variant="body2" paragraph>
              Are you sure?
            </Typography>
          </Box>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" size="small" onClick={() => delGroup()}>
              Confirm
            </Button>
            <Button size="small" onClick={() => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Popover>
    )
  }
)

DeleteGroup.displayName = 'DeleteGroup'
export default DeleteGroup
