import React, { forwardRef, useEffect, useMemo } from 'react'
import { Box, BoxProps, Typography } from '@mui/material'
import TipIconBtn from '@/components/TipIconBtn'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { useAppDispatch, useAppSelector } from '@/store'
import { Icons } from '@/assets/icons'

interface AlertStatusProps extends BoxProps {}

const AlertStatus = forwardRef<typeof Box, AlertStatusProps>(
  ({ ...props }, ref) => {
    const dispatch = useAppDispatch()
    const navigation = useAppSelector((state) => state.global.navigation)
    const alertDistribution = useAppSelector(
      (state) => state.global.treeAlert.distribution
    )
    const userTrees = useAppSelector((state) => state.map.trees)
    const allTrees = useAppSelector((state) => state.manage.overview.trees)

    const isPublic = useMemo(() => {
      return navigation.type === 'public'
    }, [navigation])

    const trees = isPublic ? userTrees : allTrees

    const openAlertList = () => {
      dispatch(
        globalSlice.actions.setAlertListStatus({
          show: true,
          type: 'any'
        })
      )
    }

    const updateTreeAlertDistribution = (distribution) => {
      dispatch(globalSlice.actions.setTreeAlertDistribution(distribution))
    }

    useEffect(() => {
      const tiltingAlertsCount = trees?.filter((tree) => {
        return tree.tiltingLevel > 2
      }).length
      const vibrationAlertsCount = trees?.filter((tree) => {
        return tree.tiltingLevel > 2 && tree.vibration
      }).length
      const disconnectionCount = trees?.filter((tree) => {
        return tree.isDisconnected && !tree.maintenance
      }).length

      updateTreeAlertDistribution({
        total: tiltingAlertsCount + disconnectionCount,
        tilting: tiltingAlertsCount,
        vibration: vibrationAlertsCount,
        disconnection: disconnectionCount
      })
    }, [trees])

    return (
      <Box ref={ref}>
        <TipIconBtn
          size="small"
          sx={{
            flexShrink: 0,
            width: 40,
            height: 40,
            color:
              alertDistribution.total === 0 ? 'primary.main' : 'warning.main'
          }}
          aria-label="Alert"
          tip={
            alertDistribution.total === 0 ? (
              <Typography sx={{ m: 0 }}>No Alert 😄</Typography>
            ) : (
              <>
                <Typography sx={{ m: 0 }} paragraph variant="caption">
                  {alertDistribution.total} alerts in total
                </Typography>
                <Typography sx={{ m: 0 }} paragraph variant="caption">
                  {alertDistribution.tilting} titling alerts
                  {alertDistribution.vibration > 0 &&
                    `${alertDistribution.vibration} with vibration`}
                </Typography>
                <Typography sx={{ m: 0 }} paragraph variant="caption">
                  {alertDistribution.disconnection}
                  disconnections
                </Typography>
              </>
            )
          }
          onClick={() => openAlertList()}>
          {alertDistribution.total === 0 ? (
            <Icons.PrivacyTip />
          ) : (
            <Icons.ReportProblem />
          )}
        </TipIconBtn>
      </Box>
    )
  }
)

AlertStatus.displayName = 'AlertStatus'
export default React.memo(AlertStatus)
