import { Moment } from 'moment'
import { DataType, Alert } from '../../../data/data.types'

export type TreeBlockChain = {
  id: string
  type: BlockchainRecordTypeEnum
  createdAt: Date
}

export type TreeDetails = {
  species: string
  height: number
  dbh: number
  crownSpread: number
  contactPerson: string
  contactPost: string
  contactPhone: string
  leaningStatus: boolean
  leaningDirection: string
  leaningAngle: number
  leaningReason: string
}

export type TreeLocation = {
  coordinates: number[]
  coordinatesHK1980?: number[]
  district: number
  address: string
}

export type TreeLatestData = {
  recordedAt: Date
  treeId: string
  sensorId: string
  vibration: boolean
  tiltingAngle: number
  tiltingAngleP?: number
  azimuth: number
  azimuthP?: number
  voltage: number
  csq: number
  temperature: number
  treeForm: {
    pitch: number
    roll: number
    leaning: number
  }
  mode: {
    sendInterval: number
    samplingInterval: number
  }
  createdAt: Date
}

export enum TreeAlertStatusEnum {
  TiltingAlertActivated = 'TILTING_ALERT_ACTIVATED',
  TiltingAlertDismissed = 'TILTING_ALERT_DISSMISSED',
  VibrationAlertActivated = 'VIBRATION_ALERT_ACTIVATED',
  VibrationAlertDismissed = 'VIBRATION_ALERT_DISSMISSED',
  DisconnectionAlertActivated = 'DISCONNECTION_ALERT_ACTIVATED',
  DisconnectionAlertDismissed = 'DISCONNECTION_ALERT_DISSMISSED'
}

export type TreeAlertStatus = {
  at: Date
  type: TreeAlertStatusEnum
}

export type TreeStatus = {
  active: boolean
  at: Date
  operator: string
}

export enum BlockchainRecordTypeEnum {
  INITIAL = 'INITIAL',
  UPDATE = 'UPDATE'
}

export enum TreeResetTypeEnum {
  TILTING = 'TILTING',
  AZIMUTH = 'AZIMUTH'
}

export type TreeReset = {
  recordedAt: Date
  type: TreeResetTypeEnum
  value: number
}

export type TreeTiltingData = {
  _id?: string
  recordedAt: Date
  treeId: string
  sensorId: string
  maintenanceId: string
  vibration: boolean
  tiltingAngle: number
  tiltingAngleP?: number
  azimuth: number
  azimuthP?: number
  voltage: number
  csq: number
  temperature: number
  treeForm: {
    pitch: number
    roll: number
    leaning: number
  }
  mode: {
    sendInterval: number
    samplingInterval: number
  }
  createdAt: Date
}

export type Tree = {
  id: string
  treeId: string
  userTreeId: string
  plateId?: string
  sensorId: string
  txid?: string
  organizationId: string
  groups: string
  blockchain: TreeBlockChain
  location: TreeLocation
  contact: {
    person: string
    post: string
    phone: string
  }
  details: TreeDetails
  alerts: Alert[]
  resets: TreeReset[]
  tiltingData?: DataType
  status: {
    maintenance: TreeStatus
    muted: TreeStatus
    disabled: TreeStatus
  }
  updatedAt: Date
  createdAt: Date
  creatorId: string
  deletedBy: string
  deleted: boolean
  deletedAt: Date
}
export type ResetItem = { value: number; at: Date }

export type TreeDetailsType = {
  height: number
  crownSpread: number
  dbh: number
  species: string
}

export type TreeUpdates = {
  userTreeId: string
  sensorId: string

  organizationId: string
  groupId: string

  species?: string
  dbh?: number
  height?: number
  crownSpread?: number

  coordinates?: string
  coordinatesHK1980?: string
  address?: string
}

export type NewTree = {
  treeId: string
  userTreeId: string
  sensorId: string
  organizationId: string
  groupId: string

  species: string
  dbh: number
  height: number
  crownSpread: number

  coordinates: string
  coordinatesHK1980?: string
  address: string

  resetTiltingValue: number
  resetAzimuthValue: number

  muted?: boolean
  disabled?: boolean
}

export type NewTreeOrgOption = {
  id: string
  name: string
  fullname: string
}

export type NewTreeGroupOption = {
  id: string
  name: string
}

export enum TreeMaintenanceTypeEnum {
  'SENSOR_INSTALLATION' = 'SENSOR_INSTALLATION',
  'SENSOR_REPLACEMENT' = 'SENSOR_REPLACEMENT',
  'SENSOR_MALFUNCTION' = 'SENSOR_MALFUNCTION',
  'SENSOR_DAMAGE' = 'SENSOR_DAMAGE',
  'SENSOR_LOOSE' = 'SENSOR_LOOSE',
  'SENSOR_REINSTALLATION' = 'SENSOR_REINSTALLATION',
  'SENSOR_DISMANTLE' = 'SENSOR_DISMANTLE',
  'OTHERS' = 'OTHERS'
}

export type TreeMaintenance = {
  recordedAt?: Date
  type: TreeMaintenanceTypeEnum
  startedAt: Moment
  endedAt: Moment
  remarks: ''
  revoked?: boolean
  __v?: number
  _id?: string
}

export type TreeSpecies = {
  speciesEn: string
  speciesTc: string
  speciesSc: string
}

export type LeafiPlate = {
  plateId: string
  treeId: string
  image: string
  createdAt: string
  createdBy: string
  replacedAt: string
  replacedBy: string
}
