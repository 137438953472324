import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle
} from 'react'
import {
  Stack,
  Typography,
  StackProps,
  TextField,
  Chip,
  Box
} from '@mui/material'
import { debounce } from '@/hooks/useDebounce'
import {
  getTreesOfGroup,
  groupAddTree,
  groupRemoveTree
} from '@/service/settings/users'
import { LoadingButton } from '@mui/lab'
import { grey } from '@mui/material/colors'
import { Group } from './group.types'

export type TreeItemGroup = {
  id: string
  name: string
  isDefault: boolean
}
export type TreeItem = {
  treeId: string
  sensorId: string
  groups: TreeItemGroup[]
}

type TreeListPageParamsTypes = {
  loading: boolean
  treeId: string
  page: number
  limit: number
  data: TreeItem[]
  totalCount: number
}

const ButtonText = {
  available: 'Add',
  existing: 'Remove'
}
const TitleText = {
  available: 'Available trees',
  existing: 'Trees in'
}

interface ManageTreeListProps extends StackProps {
  type: 'available' | 'existing'
  defaultGroupId: Group['id']
  group: Group
  onTreeAdded?: (tree: TreeItem) => void
  onTreeRemoved?: (tree: TreeItem) => void
}

export interface ManageTreesHandlers {
  addTree: (tree: TreeItem) => void
}

const ManageTreeList = forwardRef<ManageTreesHandlers, ManageTreeListProps>(
  (
    { type, group, defaultGroupId, onTreeAdded, onTreeRemoved, ...props },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [processing, setProcessing] = useState(false)

    const [params, setParams] = useState<TreeListPageParamsTypes>({
      loading: false,
      treeId: '',
      page: 1,
      limit: 10,
      data: [],
      totalCount: 0
    })

    const getTrees = useCallback(
      debounce(async (updatedParams: TreeListPageParamsTypes) => {
        if (updatedParams.loading) return
        setParams({ ...updatedParams, loading: true })

        const reqParams = {
          page: updatedParams.page,
          limit: updatedParams.limit
        }
        if (type === 'available') {
          reqParams['excludesGroup'] = group?.id
        }

        if (updatedParams.treeId) {
          reqParams['treeId'] = updatedParams.treeId
        }
        const resp = await getTreesOfGroup(
          type === 'available' ? defaultGroupId : group?.id,
          reqParams
        )

        if (resp?.code === 200) {
          const newData =
            updatedParams.page > 1
              ? [...updatedParams.data, ...resp?.data?.trees]
              : resp?.data?.trees

          setParams({
            ...updatedParams,
            data: newData,
            totalCount: resp?.data?.totalCount,
            loading: false
          })

          if (updatedParams.page > 1 && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight
          }
        }
      }),
      [containerRef, type]
    )

    const addTree = async (treeId: string) => {
      setProcessing(true)
      const resp = await groupAddTree(group?.id, treeId)
      if (resp?.code === 200) {
        setParams({
          ...params,
          data: params.data.filter((t) => t.treeId !== resp.data.treeId),
          totalCount: params.totalCount - 1
        })
        onTreeAdded(resp.data)
      }
      setProcessing(false)
    }

    const removeTree = async (treeId: string) => {
      setProcessing(true)
      const resp = await groupRemoveTree(group?.id, treeId)
      if (resp?.code === 200) {
        setParams({
          ...params,
          data: params.data.filter((t) => t.treeId !== resp.data.treeId),
          totalCount: params.totalCount - 1
        })
        onTreeRemoved(resp.data)
      }
      setProcessing(false)
    }

    useImperativeHandle(ref, () => ({
      addTree: (tree) => {
        setParams({
          ...params,
          data: [tree, ...params.data],
          totalCount: params.totalCount + 1
        })
      }
    }))

    useEffect(() => {
      getTrees(params)
    }, [params.treeId, params.page])

    return (
      <Stack
        flex={1}
        justifyContent={'space-between'}
        sx={{
          border: `1px solid ${grey[300]}`,
          borderRadius: 2,
          overflow: 'hidden'
        }}>
        <Stack>
          <Typography sx={{ mt: 2, ml: 2, mr: 2 }} variant="subtitle1">
            {TitleText[type]}
            {type === 'existing' && (
              <Typography component={'span'} color={'warning.main'}>
                {` ${group.name}`}
              </Typography>
            )}
          </Typography>
          <TextField
            sx={{ mt: 2, ml: 2, mr: 2 }}
            size={'small'}
            value={params?.treeId}
            placeholder={'Tree ID'}
            variant="standard"
            onChange={(evt) => {
              setParams({ ...params, treeId: evt.target.value, page: 1 })
            }}
          />
        </Stack>
        <Stack flex={1} sx={{ overflow: 'auto' }}>
          {params.data.map((t) => {
            return (
              <Stack
                key={t.treeId}
                sx={{
                  ml: 2,
                  mr: 2,
                  pt: 1,
                  pb: 1,
                  borderBottom: '1px solid #dddddd',
                  '&:hover': {
                    backgroundColor: grey[100]
                  }
                }}
                direction="row"
                justifyContent={'space-between'}
                alignItems="center">
                <Stack flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <Stack direction="row" alignItems="center">
                      <Typography variant="subtitle2" sx={{ width: 180 }}>
                        {t.treeId}
                      </Typography>
                      <Stack direction="row">
                        {t.groups.map((g) => (
                          <Chip
                            key={g.id}
                            sx={{ height: 18, fontSize: 14 }}
                            color="default"
                            size="small"
                            label={g.name}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>

                  <Box>
                    {t.sensorId ? (
                      <Typography variant="body2" sx={{ width: 180 }}>
                        {t.sensorId}
                      </Typography>
                    ) : (
                      <Chip
                        sx={{ height: 18, fontSize: 14 }}
                        color="warning"
                        variant="outlined"
                        label="No Sensor"
                      />
                    )}
                  </Box>
                </Stack>

                <LoadingButton
                  disabled={processing}
                  loading={processing}
                  onClick={() =>
                    type === 'available'
                      ? addTree(t.treeId)
                      : removeTree(t.treeId)
                  }>
                  {ButtonText[type]}
                </LoadingButton>
              </Stack>
            )
          })}
        </Stack>
        <LoadingButton
          loading={params.loading}
          disabled={
            params.totalCount === params.data.length && params.totalCount > 0
          }
          onClick={() => {
            if (params.data.length === 0) {
              params.page === 1
                ? getTrees(params)
                : setParams({ ...params, page: 1 })
            } else {
              setParams({ ...params, page: params.page + 1 })
            }
          }}>
          Load more ({params.data.length}/{params.totalCount})
        </LoadingButton>
      </Stack>
    )
  }
)

ManageTreeList.displayName = 'ManageTreeList'
export default ManageTreeList
