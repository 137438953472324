import {
  DayDataItem,
  RepairPeriodEnum,
  StopRepairTaskAction,
  TiltingData,
  RepairTiltingDataTask
} from '@/routes/manage/data/tilting-data/tilting-data.types'
import request from '../request'
import moment from 'moment'

export const getDataCountOfMonth = (date: Date) => {
  return request.get<DayDataItem[]>(`manage/data/data-count-of-month`, {
    data: { date: moment(date).format('YYYY-MM-DD') }
  })
}
export const getTiltingDatas = (params: {
  page: number
  limit: number
  date: Date
}) => {
  return request.get<{
    tiltingDatas: TiltingData[]
    totalCount: number
  }>(`manage/data/tilting-datas`, {
    data: {
      ...params,
      date: moment(params.date).format('YYYY-MM-DD')
    }
  })
}

export const getRepairTasks = (params: {
  page: number
  limit: number
  date?: Date
}) => {
  return request.get<{ dataTasks: RepairTiltingDataTask[]; totalCount: number }>(
    `manage/data/task/repair-tilting-data`,
    {
      data: {
        ...params,
        ...(params.date && { date: moment(params.date).format('YYYY-MM-DD') })
      }
    }
  )
}

export const repairTiltingData = (
  repairPeriod: RepairPeriodEnum,
  date: Date
) => {
  return request.post<{ success: boolean }>(
    `manage/data/task/repair-tilting-data`,
    {
      data: {
        repairPeriod: repairPeriod,
        date: moment(date).format('YYYY-MM-DD')
      }
    }
  )
}

export const stopRepairTask = (
  taskId: RepairPeriodEnum,
  action: StopRepairTaskAction
) => {
  return request.put<boolean>(
    `manage/data/task/stop-repair-task/${taskId}/${action}`
  )
}
