import PersonIcon from '@mui/icons-material/Person'
import PeopleIcon from '@mui/icons-material/People'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import VibrationIcon from '@mui/icons-material/Vibration'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import HideSourceIcon from '@mui/icons-material/HideSource'
import CheckIcon from '@mui/icons-material/Check'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import NextPlanIcon from '@mui/icons-material/NextPlan'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import NatureIcon from '@mui/icons-material/Nature'
import GroupIcon from '@mui/icons-material/Group'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import BlockIcon from '@mui/icons-material/Block'
import EmailIcon from '@mui/icons-material/Email'
import ArticleIcon from '@mui/icons-material/Article'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SendIcon from '@mui/icons-material/Send'
import SubjectIcon from '@mui/icons-material/Subject'
import DownloadIcon from '@mui/icons-material/Download'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption'
import MenuIcon from '@mui/icons-material/Menu'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FilterFramesIcon from '@mui/icons-material/FilterFrames'
import HomeIcon from '@mui/icons-material/Home'
import MapIcon from '@mui/icons-material/Map'
import StorageIcon from '@mui/icons-material/Storage'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import WarningIcon from '@mui/icons-material/Warning'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PanToolAltIcon from '@mui/icons-material/PanToolAlt'
import BackHandIcon from '@mui/icons-material/BackHand'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import AddIcon from '@mui/icons-material/Add'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import BuildIcon from '@mui/icons-material/Build'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import KeyIcon from '@mui/icons-material/Key'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AodIcon from '@mui/icons-material/Aod'
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import EdgesensorLowIcon from '@mui/icons-material/EdgesensorLow'
import SpeakerIcon from '@mui/icons-material/Speaker'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import SurroundSoundOutlinedIcon from '@mui/icons-material/SurroundSoundOutlined'
import NatureOutlinedIcon from '@mui/icons-material/NatureOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined'
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TollIcon from '@mui/icons-material/Toll'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LegendToggleOutlinedIcon from '@mui/icons-material/LegendToggleOutlined'
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EventIcon from '@mui/icons-material/Event'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import PaymentIcon from '@mui/icons-material/Payment'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import RemoveIcon from '@mui/icons-material/Remove'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DoneIcon from '@mui/icons-material/Done'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';

import { ReactComponent as DailyReportTiltingIcon } from '../images/home/daily-report-tilting.svg'
import { ReactComponent as DailyReportWithAlarmSmallIcon } from '../images/home/daily-report-with-alarm-small.svg'
import { ReactComponent as DailyReportWithAlarmIcon } from '../images/home/daily-report-with-alarm.svg'
import { ReactComponent as DailyReportNoAlarmIcon } from '../images/home/daily-report-no-alarm.svg'
import { ReactComponent as DailyReportNoAlarmSmallIcon } from '../images/home/daily-report-no-alarm-small.svg'
import { ReactComponent as DailyReportNewAlarmIcon } from '../images/home/daily-report-new-alarm.svg'
import { ReactComponent as DailyReportDisconnectionIcon } from '../images/home/daily-report-disconnection.svg'
import { ReactComponent as DailyReportVibrationIcon } from '../images/home/daily-report-vibration.svg'
import { ReactComponent as BlockChainRegistrationIcon } from '../images/management/blockchain-registration.svg'
import { ReactComponent as BlockChainStatusOnIcon } from '../images/management/blockchain-status-on.svg'
import { ReactComponent as LeafiPlateOnIcon } from '../images/management/leafiplate-on.svg'
import { ReactComponent as LeafiPlateOffIcon } from '../images/management/leafiplate-off.svg'
import { ReactComponent as LeafiPlateGreyIcon } from '../images/management/leafiplate-thumbnail.svg'
import { ReactComponent as LeafiPlateBoldIcon } from '../images/management/leafiplate-off-bold.svg'

import { ReactComponent as CompassIcon } from '@/assets/images/map/tree-compass.svg'
import { ReactComponent as EsgSummaryIcon } from '@/assets/images//esg/esg-summary.svg'
import { ReactComponent as UnderMantainanceIcon } from './under-mantainance.svg'

export const Icons = {
  Person: PersonIcon,
  People: PeopleIcon,
  CorporateFare: CorporateFareIcon,
  Close: CloseIcon,
  Refresh: RefreshIcon,
  Edit: EditIcon,
  ErrorOutline: ErrorOutlineIcon,
  Vibration: VibrationIcon,
  Upgrade: UpgradeIcon,
  HideSource: HideSourceIcon,
  Check: CheckIcon,
  NotInterested: NotInterestedIcon,
  ReportProblem: ReportProblemIcon,
  MoreHoriz: MoreHorizIcon,
  HelpOutline: HelpOutlineIcon,
  ViewWeek: ViewWeekIcon,
  PhotoCamera: PhotoCameraIcon,

  Compass: CompassIcon,
  NextPlan: NextPlanIcon,
  CircleNotifications: CircleNotificationsIcon,
  Summarize: SummarizeIcon,
  TrendingUp: TrendingUpIcon,
  Nature: NatureIcon,
  Group: GroupIcon,
  GroupAdd: GroupAddIcon,
  Settings: SettingsIcon,
  Block: BlockIcon,
  Email: EmailIcon,
  Assignment: AssignmentIcon,
  Article: ArticleIcon,
  Send: SendIcon,
  Subject: SubjectIcon,
  Download: DownloadIcon,
  SentimentSatisfiedAlt: SentimentSatisfiedAltIcon,
  Info: InfoIcon,
  Error: ErrorIcon,
  EnhancedEncryption: EnhancedEncryptionIcon,
  Menu: MenuIcon,
  DeleteForever: DeleteForeverIcon,
  InboxOutlined: InboxOutlinedIcon,
  AccessTime: AccessTimeIcon,
  FilterFrames: FilterFramesIcon,
  SmartToy: SmartToyIcon,
  Storage: StorageIcon,
  Map: MapIcon,
  Home: HomeIcon,
  Warning: WarningIcon,
  ExitToApp: ExitToAppIcon,
  UnfoldMore: UnfoldMoreIcon,
  UnfoldLess: UnfoldLessIcon,
  EditNote: EditNoteIcon,
  Delete: DeleteIcon,
  ContactMail: ContactMailIcon,
  CheckCircle: CheckCircleIcon,
  PanToolAlt: PanToolAltIcon,
  BackHand: BackHandIcon,
  RotateRight: RotateRightIcon,
  Add: AddIcon,
  Remove: RemoveIcon,
  ArrowRightAlt: ArrowRightAltIcon,
  FiberManualRecord: FiberManualRecordIcon,
  ArticleOutlined: ArticleOutlinedIcon,
  Handyman: HandymanIcon,
  FmdGood: FmdGoodIcon,
  PlaceOutlined: PlaceOutlinedIcon,
  RecentActors: RecentActorsIcon,
  Build: BuildIcon,
  EditOutlined: EditOutlinedIcon,
  Key: KeyIcon,
  RemoveRedEye: RemoveRedEyeIcon,
  VisibilityOutlined: VisibilityOutlinedIcon,
  Aod: AodIcon,
  ForestOutlined: ForestOutlinedIcon,
  AvTimer: AvTimerIcon,
  SettingsBackupRestore: SettingsBackupRestoreIcon,
  PrivacyTip: PrivacyTipIcon,
  SystemUpdateAltOutlined: SystemUpdateAltOutlinedIcon,
  TextFieldsOutlined: TextFieldsOutlinedIcon,
  NotificationsActive: NotificationsActiveIcon,
  NotificationsOffOutlined: NotificationsOffOutlinedIcon,
  BuildOutlined: BuildOutlinedIcon,
  VisibilityOffOutlined: VisibilityOffOutlinedIcon,
  VpnKeyOutlined: VpnKeyOutlinedIcon,
  MonitorHeartOutlined: MonitorHeartOutlinedIcon,
  EdgesensorLow: EdgesensorLowIcon,
  Speaker: SpeakerIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  SurroundSoundOutlined: SurroundSoundOutlinedIcon,
  NatureOutlined: NatureOutlinedIcon,
  PeopleOutlined: PeopleOutlinedIcon,
  SendOutlined: SendOutlinedIcon,
  PeopleOutline: PeopleOutlineIcon,
  DownloadOutlined: DownloadOutlinedIcon,
  ReportProblemOutlined: ReportProblemOutlinedIcon,
  HandymanOutlined: HandymanOutlinedIcon,
  DateRangeOutlined: DateRangeOutlinedIcon,
  PodcastsOutlined: PodcastsOutlinedIcon,
  DriveFolderUploadOutlined: DriveFolderUploadOutlinedIcon,
  CloudUploadOutlined: CloudUploadOutlinedIcon,
  PublishOutlined: PublishOutlinedIcon,
  ImageOutlined: ImageOutlinedIcon,
  Toll: TollIcon,
  InfoOutlined: InfoOutlinedIcon,
  DescriptionOutlined: DescriptionOutlinedIcon,
  NotesOutlined: NotesOutlinedIcon,
  DeleteOutlineOutlined: DeleteOutlineOutlinedIcon,
  LegendToggleOutlined: LegendToggleOutlinedIcon,
  SyncProblemOutlined: SyncProblemOutlinedIcon,
  Circle: CircleIcon,
  ArrowDropDown: ArrowDropDownIcon,
  Event: EventIcon,
  EveStopCircleOutlinednt: StopCircleOutlinedIcon,
  PauseCircleOutlined: PauseCircleOutlinedIcon,
  AccountCircle: AccountCircleIcon,
  DatasetOutlined: DatasetOutlinedIcon,
  SummarizeOutlined: SummarizeOutlinedIcon,
  EmailOutlined: EmailOutlinedIcon,
  NotificationsActiveOutlined: NotificationsActiveOutlinedIcon,
  CloudQueue: CloudQueueIcon,
  AddAPhoto: AddAPhotoIcon,
  FileUpload: FileUploadIcon,
  Payment: PaymentIcon,
  ArrowLeft: ArrowLeftIcon,
  ArrowRight: ArrowRightIcon,
  Fullscreen: FullscreenIcon,
  Done: DoneIcon,
  CheckCircleOutline: CheckCircleOutlineIcon,
  StopOutlined: StopOutlinedIcon,
  HourglassTopOutlined: HourglassTopOutlinedIcon,

  DailyReportTilting: DailyReportTiltingIcon,
  DailyReportWithAlarm: DailyReportWithAlarmIcon,
  DailyReportNoAlarm: DailyReportNoAlarmIcon,
  DailyReportNewAlarm: DailyReportNewAlarmIcon,
  DailyReportDisconnection: DailyReportDisconnectionIcon,
  DailyReportVibration: DailyReportVibrationIcon,
  DailyReportNoAlarmSmall: DailyReportNoAlarmSmallIcon,
  DailyReportWithAlarmSmall: DailyReportWithAlarmSmallIcon,

  BlockChainRegistration: BlockChainRegistrationIcon,
  BlockChainStatusOn: BlockChainStatusOnIcon,
  LeafiPlateOn: LeafiPlateOnIcon,
  LeafiPlateOff: LeafiPlateOffIcon,
  LeafiPlateGrey: LeafiPlateGreyIcon,
  LeafiPlateBold: LeafiPlateBoldIcon,
  EsgSummary: EsgSummaryIcon,
  UnderMantainance: UnderMantainanceIcon
}
