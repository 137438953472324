import { SxProps } from '@mui/material'

export const PublicAlertListStyle: SxProps = {
  position: 'absolute',
  left: 0,
  bottom: 0,
  zIndex: 999,
  width: 720,
  height: 320,
  boxShadow: 5
}
