import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Grid, Paper, Stack, StackProps, Typography } from '@mui/material'
import { ReactComponent as TreeNumIcon } from '@/assets/images/home/tree-nums.svg'
import { ReactComponent as EsgSpeciesIcon } from '@/assets/images/esg/esg-species.svg'
import SpeciesList from './SpeciesList'
import StatCard from './StatCard'
import { getCarbonOptions } from './carbon.helper'
import ChartBox from '../ChartBox'
import { getEsgCarbonSummary, getEsgTreeSummary } from '@/service/esg'
import { ReactComponent as LoadingAnimationIcon } from '@/assets/icons/loading.animation.svg'

export type TreeBySpeciesItem = {
  species: string
  count: number
}
export type TreeSummary = {
  treesCount: number
  speciesCount: number
  treeBySpecies: TreeBySpeciesItem[]
}
export type CarbonSummaryItem = {
  month: number
  count: number
}

export type CarbonSummary = {
  totalCount: number
  dataByMonth: CarbonSummaryItem[]
}

interface EsgSpaciesCarbonProps extends StackProps {
  refreshFlag: number
}

const DefaultTreeSummary = {
  treesCount: 0,
  speciesCount: 0,
  treeBySpecies: []
}

const EsgSpaciesCarbon = forwardRef<typeof Stack, EsgSpaciesCarbonProps>(
  ({ sx, refreshFlag, ...props }, ref) => {
    const [isLoadingTrees, setIsLoadingTrees] = useState(false)
    const [treeSummary, setTreeSummary] = useState<TreeSummary>(DefaultTreeSummary)
    const [isLoadingCarbon, setIsLoadingCarbon] = useState(false)
    const [carbonSummary, setCarbonSummary] = useState<CarbonSummary>({
      totalCount: 0,
      dataByMonth: []
    })

    const getTreesSpecies = async () => {
      setTreeSummary(DefaultTreeSummary)
      setIsLoadingTrees(true)
      const resp = await getEsgTreeSummary()
      if (resp?.code === 200) {
        setTreeSummary(resp?.data)
      }
      setIsLoadingTrees(false)
    }
    const getCarbonSummary = async () => {
      setIsLoadingCarbon(true)
      const resp = await getEsgCarbonSummary()
      if (resp?.code === 200) {
        setCarbonSummary(resp?.data)
      }
      setIsLoadingCarbon(false)
    }

    useEffect(() => {
      getTreesSpecies()
      getCarbonSummary()
    }, [refreshFlag])

    return (
      <Stack sx={{ ...sx, overflow: 'auto' }} direction="row" spacing={2}>
        <Stack sx={{ width: '40%' }} spacing={1}>
          <Stack direction="row" spacing={2}>
            <StatCard
              title="No. of Trees"
              icon={<TreeNumIcon style={{ transform: 'scale(0.8)' }} />}
              value={treeSummary.treesCount}
            />
            <StatCard
              title="No. of Species"
              icon={<EsgSpeciesIcon style={{ transform: 'scale(0.8)' }} />}
              value={treeSummary.speciesCount}
            />
          </Stack>

          <Paper sx={{ flex: 1, overflow: 'hidden' }}>
            <SpeciesList loading={isLoadingTrees} data={treeSummary.treeBySpecies} />
          </Paper>
        </Stack>
        <Paper
          sx={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
          <Stack>
            <Stack direction="row" spacing={2}>
              <Typography>Carbon Uptake</Typography>
              <Typography fontWeight="bold">
                {carbonSummary?.totalCount.toLocaleString()} KG
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {isLoadingCarbon ? (
              <Stack>
                <LoadingAnimationIcon />
                <Typography variant="overline">Loading</Typography>
              </Stack>
            ) : (
              <ChartBox option={getCarbonOptions(carbonSummary.dataByMonth)} />
            )}
          </Box>
        </Paper>
      </Stack>
    )
  }
)

EsgSpaciesCarbon.displayName = 'EsgSpaciesCarbon'
export default EsgSpaciesCarbon
