import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Button
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { useForm, Controller } from 'react-hook-form'
import { DefNewGroup } from './group.constant'
import { NewGroup as NewGroupType } from './group.types'
import FormInput from '@/components/form/FormInput'
import FormTextArea from '@/components/form/FormTextArea'
import { createGroup } from '@/service/settings/users'
import FormMultipleSelect from '@/components/form/FormMultipleSelect'

interface NewGroupProps extends DrawerProps {
  onClose: () => void
  onSuccess: () => void
}

const NewGroup = forwardRef<typeof Drawer, NewGroupProps>(
  ({ open, onClose, onSuccess, ...props }, ref) => {
    const { control, handleSubmit, reset } = useForm<NewGroupType>({
      defaultValues: DefNewGroup
    })

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await createGroup(values)
        if (resp?.code === 200) {
          onSuccess()
        }
      })()
    }

    useEffect(() => {
      open && reset(DefNewGroup)
    }, [open])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack sx={{ width: 480, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              New Group
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              flexGrow: 1,
              pt: 2,
              pb: 2,
              pl: 3,
              pr: 3,
              overflow: 'auto'
            }}>
            {open && (
              <>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 24
                  }}
                  render={({ field, fieldState }) => (
                    <FormInput
                      required
                      labelWidth={120}
                      label="Name"
                      error={Boolean(fieldState.error)}
                      helperText="Max length 24"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <FormTextArea
                      labelWidth={120}
                      label="Description"
                      helperText="Suggested to be the short name of this organization. Max length 120"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
              </>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
          spacing={2}>
          <Button variant="contained" onClick={() => onSubmit()}>
            Confirm
          </Button>
          <Button onClick={() => onClose()}>Cancel</Button>
        </Stack>
      </Drawer>
    )
  }
)

NewGroup.displayName = 'NewGroup'
export default NewGroup
