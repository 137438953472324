import {
  LeafiPlate,
  Tree,
  TreeMaintenance,
  TreeResetTypeEnum,
  TreeSpecies
} from '@/routes/manage/data/trees/trees.types'
import request from '../request'
import { TreePageListParams } from '@/routes/manage/data/trees/Trees'
import { TreeUpdates, NewTree } from '@/routes/manage/data/trees/trees.types'
import { TreeDataStatusEnum } from '@/routes/manage/data/trees/menus/import-trees/import-trees.type'
import {
  TreeImage,
  TreeImgUploadingStatusEnum
} from '@/routes/manage/data/trees/actions/TreeImages'

export const getTrees = (param: TreePageListParams) => {
  return request.get<{ trees: Tree[]; totalCount: number }>(
    `manage/tree/trees`,
    {
      data: {
        page: param.page,
        limit: param.limit,
        ...(param?.treeId ? { treeId: param.treeId } : {}),
        ...(param?.userTreeId ? { userTreeId: param.userTreeId } : {}),
        ...(param?.organizationId
          ? { organizationId: param.organizationId }
          : {}),
        ...(param?.maintenanceOn ? { maintenanceOn: param.maintenanceOn } : {}),
        ...(param?.mutedOn ? { mutedOn: param.mutedOn } : {}),
        ...(param?.disabledOn ? { disabledOn: param.disabledOn } : {})
      }
    }
  )
}

export const getTreeSpecies = () => {
  return request.get<TreeSpecies[]>(`manage/tree/species`)
}

export const getTreeMaintenance = (treeId: string) => {
  return request.get<TreeMaintenance[]>(`manage/tree/${treeId}/maintenances`)
}

export const addMaintenanceRecord = (treeId: string, data: TreeMaintenance) => {
  return request.post<TreeMaintenance>(`manage/tree/${treeId}/maintenance`, {
    data
  })
}

export const revokeTreeMaintenance = (
  treeId: string,
  maintenanceId: string
) => {
  return request.put<TreeMaintenance>(
    `manage/tree/${treeId}/maintenance/${maintenanceId}/revoke`
  )
}
export const changeTreeStatus = (
  treeId: string,
  type: 'muted' | 'disabled' | 'maintenance',
  status: boolean
) => {
  return request.put<Tree>(`manage/tree/${treeId}/status/${type}`, {
    data: { status }
  })
}

export const createTree = (params: NewTree) => {
  const coordinatesHK1980 = params?.coordinatesHK1980

  return request.post<Tree>(`manage/tree`, {
    data: {
      ...params,
      coordinates: params.coordinates.split(',').map((c) => Number(c)),
      ...{
        coordinatesHK1980: coordinatesHK1980
          ? coordinatesHK1980.split(',').map((c) => Number(c))
          : []
      }
    }
  })
}

export const updateTreeDetails = (treeId: string, propeties: TreeUpdates) => {
  const coordinatesHK1980 = propeties?.coordinatesHK1980
  return request.put<Tree>(`manage/tree/${treeId}`, {
    data: {
      ...propeties,
      coordinates: propeties.coordinates.split(',').map((c) => Number(c)),
      ...{
        coordinatesHK1980: coordinatesHK1980
          ? coordinatesHK1980.split(',').map((c) => Number(c))
          : []
      }
    }
  })
}

export const deleteTree = (treeId: string) => {
  return request.delete<boolean>(`manage/tree/${treeId}`)
}
export const registrateTreeOnBlockchain = (treeId: string) => {
  return request.post<Tree>(`manage/tree/${treeId}/blockchain`)
}

export const resetTree = (
  treeId: string,
  data: { type: TreeResetTypeEnum; value: number }
) => {
  return request.post<Tree>(`manage/tree/${treeId}/reset`, {
    data
  })
}

export const getTreeDetails = (treeId: string) => {
  return request.get<Tree>(`manage/tree/${treeId}/details`)
}

export const importTree = (
  treeId: string,
  params: {
    userTreeId: string
    organizationId: string
    tiltingReset: string
    azimuthReset: string
  }
) => {
  return request.post<TreeDataStatusEnum>(`manage/tree/${treeId}/import`, {
    data: params
  })
}

export const getTreeImages = (treeId: string, size?: number) => {
  return request.get<TreeImage[]>(`manage/tree/${treeId}/images`, {
    data: {
      ...(size && { size })
    }
  })
}

export const uploadTreeImage = (
  treeId: string,
  imageIdx: number,
  imageSrc: string
) => {
  return request.post<{ recordedAt: Date; status: TreeImgUploadingStatusEnum }>(
    `manage/tree/${treeId}/image/upload`,
    {
      data: { imageIdx, image: imageSrc }
    }
  )
}
export const uploadLeafiPlate = (
  treeId: string,
  plateId: string,
  image: string
) => {
  return request.post<{
    plate: LeafiPlate
    tree: Tree
  }>(`manage/tree/${treeId}/plate`, {
    data: { image, plateId }
  })
}

export const getLeafiPlate = (treeId: string, plateId: string) => {
  return request.get<LeafiPlate>(`manage/tree/${treeId}/plate/${plateId}`)
}
