import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'
import { Icons } from '@/assets/icons'
import moment from 'moment'
import TipIcon from '@/components/TipIcon'
import { grey, red } from '@mui/material/colors'

interface TreeAlertStatusProps extends BoxProps {
  type: 'tilting' | 'vibration'
  active: boolean
  recordedAt?: Date
}

const TreeAlertStatus = forwardRef<typeof Box, TreeAlertStatusProps>(
  ({ type, active, recordedAt, ...props }, ref) => {
    let alertDesc = 'No status'
    let recordedAtStr = recordedAt
      ? moment(recordedAt).format('YYYY-MM-DD HH:mm:ss')
      : ''

    if (type === 'tilting') {
      alertDesc = 'No Alert'
    } else if (type === 'vibration') {
      alertDesc = 'No vibration'
    }

    if (type === 'tilting' && active) {
      alertDesc = `Threat detected at ${recordedAtStr}`
    }
    if (type === 'vibration' && active) {
      alertDesc = `Vibration detected at ${recordedAtStr}`
    }

    return (
      <>
        {type === 'tilting' && (
          <TipIcon placement="top" title={alertDesc}>
            <Icons.ReportProblemOutlined
              fontSize="inherit"
              sx={{
                color: active ? red[400] : grey[300],
                cursor: 'pointer'
              }}
            />
          </TipIcon>
        )}

        {type === 'vibration' && (
          <TipIcon placement="top" title={alertDesc}>
            <Icons.Vibration
              fontSize="inherit"
              sx={{
                color: active ? red[400] : grey[300],
                cursor: 'pointer'
              }}
            />
          </TipIcon>
        )}
      </>
    )
  }
)

TreeAlertStatus.displayName = 'TreeAlertStatus'
export default TreeAlertStatus
