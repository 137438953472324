import React, { forwardRef, useRef } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Dialog
} from '@mui/material'
import { Organization } from '../organization.types'
import { Icons } from '@/assets/icons'
import { Tree } from '@/routes/manage/data/trees/trees.types'
import { useAppDispatch } from '@/store'
import { getTreesAsync } from '@/store/reducers/map/map.thunks'

import AssignmentList, { HandleParams } from './AssignTreeList'

interface AssignTreesProps extends DrawerProps {
  org: Organization
  onClose: () => void
}

const AssignTrees = forwardRef<typeof Drawer, AssignTreesProps>(
  ({ open, onClose, org, ...props }, ref) => {
    const dispatch = useAppDispatch()

    const avaliableListRef = useRef<HandleParams>(null)
    const existenceListRef = useRef<HandleParams>(null)

    const onTreeAdded = (tree: Tree) => {
      existenceListRef.current?.addTree(tree)
    }
    const onTreeRemoved = (tree: Tree) => {
      avaliableListRef.current?.addTree(tree)
    }

    const refreshMap = () => {
      dispatch(getTreesAsync())
    }

    return (
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <Stack>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6" color="primary.main">
             Trees Allocation
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                onClose()
                refreshMap()
              }}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Stack
            sx={{
              height: 600,
              pb: 2,
              pl: 3,
              pr: 3,
              overflow: 'hidden'
            }}
            direction="row"
            spacing={2}>
            {open && (
              <>
                <AssignmentList
                  ref={avaliableListRef}
                  type="available"
                  org={org}
                  onTreeAdded={onTreeAdded}
                />
                <AssignmentList
                  ref={existenceListRef}
                  type="existence"
                  org={org}
                  onTreeRemoved={onTreeRemoved}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Dialog>
    )
  }
)

AssignTrees.displayName = 'AssignTrees'
export default AssignTrees
