import { PrimaryMainColor, SecondaryMainColor } from '@/app.const'
import { EChartOption } from 'echarts/lib/echarts'
import { AlertSummaryItem, DataPackageSummaryItem } from './EsgData'

export const getDataPackageOption = (
  dataByMouth: DataPackageSummaryItem[]
): EChartOption => {
  return {
    tooltip: { trigger: 'axis' },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: dataByMouth.map((item) => item.month)
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        type: 'bar',
        barWidth: 16,
        itemStyle: {
          color: PrimaryMainColor,
          barBorderRadius: 16
        },
        data: dataByMouth.map((item) => item.count)
      }
    ]
  }
}

export const getAlertOption = (
  alertByMouth: AlertSummaryItem[]
): EChartOption => {
  return {
    tooltip: { trigger: 'axis' },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: alertByMouth.map((item) => item.month)
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        type: 'bar',
        barWidth: 12,
        itemStyle: {
          color: PrimaryMainColor,
          barBorderRadius: 16
        },
        data: alertByMouth.map((item) => item.tiltingAlert)
      },
      {
        type: 'bar',
        barWidth: 12,
        itemStyle: {
          color: '#BAE6C8',
          barBorderRadius: 16
        },
        data: alertByMouth.map((item) => item.vibration)
      }
    ]
  }
}
