import moment from 'moment'
import { MapTreeResp } from './map.types'
import { MapTreeType } from '@/routes/map/map.types'

export const getMapTreesFromRes = (tree: MapTreeResp): MapTreeType => {
  let isDisconnected = false
  if (!tree?.latestTiltingData?.recordedAt) {
    isDisconnected = true
  } else {
    isDisconnected =
      moment().diff(moment(tree?.latestTiltingData?.recordedAt), 'hour') > 24
  }

  return {
    ...tree,
    isDisconnected,
    dataRecordedAt: tree?.latestTiltingData?.recordedAt || null,
    tiltingLevel: isDisconnected ? 0 : tree?.latestTiltingData?.tiltingLevel,
    tiltingAngle: tree?.latestTiltingData?.tilingReseted || 0,
    azimuth: tree?.latestTiltingData?.azimuthReseted || 0,
    voltage: tree?.latestTiltingData?.voltage || 0,
    vibration: Boolean(tree?.latestTiltingData?.vibration)
  }
}
