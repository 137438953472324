import { RegionEnum } from '@/configs/config.regions'
import moment from 'moment'

import { UpdatedOrganization, NewOrganization, SubscriptionTypeEnum, ServiceTypeEnum } from './organization.types'

export const DefaultNewOrganization: NewOrganization = {
  name: '',
  fullname: '',
  regionId: RegionEnum.HongKong,
  address: '',
  industry: '',

  lifytPro: false,
  lifytProExpiredAt: null,
  expiredAt: moment().add(3, 'month'),
  disabled: false,

  contactPerson: '',
  contactPhone: '',
  contactEmail: '',

  username: '',
  nickname: '',
  password: '',
  userEmail: '',
  userAdditionalEmails: [],
  userPhone: ''
}

export const DefaultUpdatedOrganization: UpdatedOrganization = {
  fullname: '',
  regionId: RegionEnum.HongKong,
  address: '',
  industry: '',

  lifytPro: false,
  lifytProExpiredAt: null,
  expiredAt: moment().add(3, 'month'),
  disabled: false,

  contactPerson: '',
  contactPhone: '',
  contactEmail: ''
}


export const ServiceTypeText = {
  [ServiceTypeEnum.LIFY_T]: 'Basic',
  [ServiceTypeEnum.LIFY_T_PRO]: 'Pro',
}