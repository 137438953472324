import React, { forwardRef, useState } from 'react'
import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { FilterTypeEnum } from '../map.types'
import { TiltingLevelColors } from '../../home/tilting-level/TiltingLevel'
import { brown } from '@mui/material/colors'

const FilterColor = {
  all: '#6a69f6',
  disconnected: '#909090',
  maintenance: '#fb9900',
  lowPower: brown[500],
  ...TiltingLevelColors
}

export const DefaultFilters = [
  FilterTypeEnum.Good,
  FilterTypeEnum.Fair,
  FilterTypeEnum.Poor,
  FilterTypeEnum.Emergency
]

const DefAllFilterStr = DefaultFilters.sort((a, b) => a.localeCompare(b)).join(
  ''
)

interface FiltersProps extends BoxProps {
  value: FilterTypeEnum[]
  showLowPower?: boolean
  onFilter?: (type: FilterTypeEnum, status?: boolean) => void
  onAll: (status: boolean) => void
}

const Filters = forwardRef<typeof Box, FiltersProps>(
  ({ sx, showLowPower = false, value, onFilter, onAll, ...props }, ref) => {
    const currFilterStr = value
      .filter((item) => {
        return ![
          FilterTypeEnum.Disconnection,
          FilterTypeEnum.Maintenance,
          FilterTypeEnum.LowPower
        ].includes(item)
      })
      .sort((a, b) => a.localeCompare(b))
      .join('')

    const isAll = currFilterStr === DefAllFilterStr

    return (
      <Paper sx={{ ...sx, borderRadius: '0 4px 0 4px', pr: 1.5 }}>
        <Stack
          direction="row"
          justifyContent={'center'}
          alignItems="center"
          spacing={0.5}>
          <FilterItem
            selected={isAll}
            type={FilterTypeEnum.All}
            text="All"
            color={FilterColor.all}
            onChange={(_, status) => onAll(status)}
          />

          <FilterItem
            selected={value.includes(FilterTypeEnum.Good)}
            type={FilterTypeEnum.Good}
            text="Good"
            color={FilterColor.level1}
            onChange={onFilter}
          />
          <FilterItem
            selected={value.includes(FilterTypeEnum.Fair)}
            type={FilterTypeEnum.Fair}
            text="Fair"
            color={FilterColor.level2}
            onChange={onFilter}
          />
          <FilterItem
            selected={value.includes(FilterTypeEnum.Poor)}
            type={FilterTypeEnum.Poor}
            text="Poor"
            color={FilterColor.level3}
            onChange={onFilter}
          />
          <FilterItem
            selected={value.includes(FilterTypeEnum.Emergency)}
            type={FilterTypeEnum.Emergency}
            text="Emergency"
            color={FilterColor.level4}
            onChange={onFilter}
          />
          <span style={{ marginLeft: '15px' }}>|</span>

          <FilterItem
            selected={value.includes(FilterTypeEnum.Maintenance)}
            type={FilterTypeEnum.Maintenance}
            text="Maintenance"
            color={FilterColor.maintenance}
            onChange={onFilter}
          />
          <FilterItem
            selected={value.includes(FilterTypeEnum.Disconnection)}
            type={FilterTypeEnum.Disconnection}
            text="Disconnected"
            color={FilterColor.disconnected}
            onChange={onFilter}
          />
          {showLowPower && (
            <FilterItem
              selected={value.includes(FilterTypeEnum.LowPower)}
              type={FilterTypeEnum.LowPower}
              text="L&M Battery"
              color={FilterColor.lowPower}
              onChange={onFilter}
            />
          )}
        </Stack>
      </Paper>
    )
  }
)

Filters.displayName = 'Filters'
export default Filters

const FilterItem = (params: {
  selected: boolean
  type: FilterTypeEnum
  text: string
  color: string
  onChange: (filterType: FilterTypeEnum, status: boolean) => void
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={params.selected}
          sx={{
            color: params.color,
            '&.Mui-checked': { color: params.color }
          }}
          onChange={(event) =>
            params.onChange(params.type, event.currentTarget.checked)
          }
        />
      }
      label={<Typography variant="body2">{params.text}</Typography>}
    />
  )
}
