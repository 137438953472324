import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import usersReducer from './reducers/user/user.slice'
import homeReducer from './reducers/home/home.slice'
import mapReducer from './reducers/map/map.slice'
import settingSlice from './reducers/settings/settingSlice'
import globalSlice from './reducers/global/global.slice'
import manageSlice from './reducers/manage/manage.slice'

export const store = configureStore({
  reducer: {
    global: globalSlice,
    users: usersReducer,
    home: homeReducer,
    map: mapReducer,
    setting: settingSlice,
    manage: manageSlice
  }
})

export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
