export const getTiltingLevel = (tiltingAngle: number) => {
  return 0 <= tiltingAngle && tiltingAngle < 6
    ? 1
    : 6 <= tiltingAngle && tiltingAngle < 15
    ? 2
    : 15 <= tiltingAngle && tiltingAngle < 70
    ? 3
    : tiltingAngle >= 70 && tiltingAngle <= 90
    ? 4
    : 0
}
