import request from '../request'

import {
  NewTreeOrgOption,
  NewTreeGroupOption,
  Tree,
  TreeUpdates
} from '@/routes/manage/data/trees/trees.types'
import { DataTreePageListParams } from '@/routes/data/trees/Trees'

export const getTrees = (param: DataTreePageListParams) => {
  return request.get<{ trees: Tree[]; totalCount: number }>(`data/trees`, {
    data: {
      page: param.page,
      limit: param.limit,
      ...(param?.treeId ? { treeId: param.treeId } : {}),
      ...(param?.userTreeId ? { userTreeId: param.userTreeId } : {})
    }
  })
}

export const getTreeDetails = (treeId: string) => {
  return request.get<Tree>(`trees/${treeId}/details`)
}

export const getTreeSpeciesList = () => {
  return request.get<string[]>(`trees/species/list`)
}

export const getOrgOptions = () => {
  return request.get<NewTreeOrgOption[]>(`organizations/list`)
}

export const getGroupOptions = (organizationId: string) => {
  return request.get<NewTreeGroupOption[]>(
    `organizations/${organizationId}/groups`
  )
}

export const deleteTree = (treeId: string) => {
  return request.delete<boolean>(`trees/${treeId}`)
}

export const changeTreeProperties = (
  treeId: string,
  propeties: TreeUpdates
) => {
  return request.put<Tree>(`trees/${treeId}`, {
    data: {
      ...propeties,
      ...(propeties?.coordinates
        ? {
            coordinates: propeties.coordinates.split(',').map((c) => Number(c))
          }
        : {})
    }
  })
}

export const changeTreeStatus = (
  treeId: string,
  status: { muted?: boolean; disabled?: boolean; maintenance?: boolean }
) => {
  return request.put<Tree>(`trees/${treeId}/status`, {
    data: status
  })
}
