import React, { useRef } from 'react'

import { Box, Stack, alpha } from '@mui/material'
import { grey } from '@mui/material/colors'

import Weather from './weather/Weather'
import TiltingLevel from './tilting-level/TiltingLevel'
import Map from './Map'
import Notifications from './notifications/Notifications'
import Card, { ThemeTypes } from './Card'
import Mode from './mode/Mode'
import { ReactComponent as TreeNums } from '@/assets/images/home/tree-nums.svg'
import { ReactComponent as TreeAlerts } from '@/assets/images/home/tree-alert.svg'
import { Icons } from '@/assets/icons'
import Carbon from './Carbon'
import DailyReport from './DailyReport'
import { useAppSelector, useAppDispatch } from '@/store'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { useNavigate } from 'react-router'
import RootLayout from '../../components/page/RootLayout'
import { AlertListType } from '@/components/AlertList'

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const mapRef = useRef(null)

  const trees = useAppSelector((state) => state.map.trees)
  const treeTotalCount = useAppSelector((state) => state.map.treeTotalCount)
  const alertCount = trees?.filter((tree) => tree.tiltingLevel > 2)?.length
  const disconnected = trees?.filter(
    (tree) => tree.isDisconnected && !tree.maintenance
  )?.length

  const openAlertList = (type: AlertListType) => {
    dispatch(globalSlice.actions.setAlertListStatus({ show: true, type }))
  }

  return (
    <RootLayout>
      <Stack
        direction="row"
        sx={{ p: 1, height: 1, backgroundColor: grey[100] }}
        spacing={2}>
        <Stack sx={{ flexGrow: 1 }}>
          <Stack direction="row" sx={{ width: 1, height: 140 }} spacing={1}>
            <Card
              theme={ThemeTypes.dark}
              title={'NO. OF TREES'}
              icon={<TreeNums />}
              value={treeTotalCount}
              onClick={() => navigate('/data/trees')}
            />
            <Card
              theme={ThemeTypes.light}
              title={'ALERTS'}
              icon={<TreeAlerts />}
              value={alertCount}
              onClick={() => openAlertList('tilting')}
            />
            <Card
              theme={ThemeTypes.dark}
              title={'DISCONNECTED'}
              icon={
                <Icons.HelpOutline
                  fontSize="inherit"
                  sx={{ color: alpha(grey[900], 0.5) }}
                />
              }
              onClick={() => openAlertList('disconnection')}
              value={disconnected}
              tip="Trees with data absence over 24 hours"
            />
            <Mode />
          </Stack>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Map ref={mapRef} />
          </Box>
        </Stack>
        <Stack
          spacing={1}
          sx={{
            minWidth: 320,
            maxWidth: 420,
            height: 1,
            pl: 1,
            pb: 1,
            width: '30%',
            flexShrink: 0
          }}>
          <Weather />
          <TiltingLevel
            onItemSelect={(filters) => {
              if (mapRef.current) {
                mapRef.current.setMapFilters(filters)
              }
            }}
          />
          <DailyReport />
          <Notifications />
          <Carbon />
        </Stack>
      </Stack>
    </RootLayout>
  )
}

export default Home
