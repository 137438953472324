import React, { useRef, forwardRef, useImperativeHandle } from 'react'

import { Box, Stack, StackProps } from '@mui/material'
import MapView from '@/routes/map/view/View'
import AlertList from '@/components/AlertList'
import { FilterTypeEnum } from '@/routes/map/map.types'
import { useAppDispatch, useAppSelector } from '@/store/'
import MapSearch from '@/routes/map/MapSearch'
import { mapSlice } from '@/store/reducers/map/map.slice'

interface TiltingLevelItemProps extends StackProps {}

const Map = forwardRef<
  {
    setMapFilters: (fitlers: FilterTypeEnum[]) => void
  },
  TiltingLevelItemProps
>(({ ...props }, ref) => {
  const dispatch = useAppDispatch()
  const trees = useAppSelector((state) => state.map?.trees)
  const treeTotalCount = useAppSelector((state) => state.map?.treeTotalCount)
  const treeLoadedCount = useAppSelector((state) => state.map?.treeLoadedCount)
  const filters = useAppSelector((state) => state.map?.filters)
  const isLoadingTrees = useAppSelector((state) => state.map.isLoadingTrees)

  const homeMap = useRef(null)
  const searchRef = useRef(null)

  const searchTreeByTreeId = (treeId) => {
    homeMap.current.locationTreeById(treeId, { zoomIn: true })
  }
  const clearSearch = () => {
    searchRef.current?.clear()
  }
  const clearActiveTree = () => {
    homeMap.current.clearActiveTree()
  }
  const setFilters = (fitlers: FilterTypeEnum[]) => {
    dispatch(mapSlice.actions.setMapFilter(fitlers))
  }

  useImperativeHandle(ref, () => ({
    setMapFilters: (fitlers: FilterTypeEnum[]) => setFilters(fitlers)
  }))

  return (
    <Stack sx={{ width: 1, height: 1, position: 'relative' }}>
      <Stack>
        <MapSearch
          ref={searchRef}
          plain
          maitenanceOn={!filters.includes(FilterTypeEnum.Maintenance)}
          onSearch={searchTreeByTreeId}
          onClear={clearActiveTree}
        />
      </Stack>
      <Box sx={{ width: 1, flexGrow: 1, borderRadius: 1, overflow: 'hidden' }}>
        <MapView
          ref={homeMap}
          controls={{ zoom: true, popup: true, filter: { show: true } }}
          loading={{
            status: isLoadingTrees,
            placement: 'left-top',
            size: 'small',
            treeTotalCount,
            treeLoadedCount
          }}
          trees={trees}
          filters={filters}
          onClearActive={clearSearch}
          onFilterChange={(filters) => setFilters(filters)}
        />

        <AlertList
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 999,
            width: 720,
            height: 320,
            boxShadow: 5
          }}
          onLocate={(treeId) => searchTreeByTreeId(treeId)}
        />
      </Box>
    </Stack>
  )
})

export default Map
