import React, { forwardRef } from 'react'
import { Box, BoxProps, Chip, Paper, Stack, Typography } from '@mui/material'

import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'
import { GridColDef } from '@mui/x-data-grid'
import { Sensor } from './sensor.types'
import { getSensors } from '@/service/manage/sensors'
import moment from 'moment'
import CopyButton from '@/components/CopyButton'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import TextFieldC from '@/components/TextFieldC'

export type SensorsPageFilters = {
  sensorId?: string
}
export type SensorsPageListParams = PageListParams<SensorsPageFilters>

interface SensorsProps extends BoxProps {}

const Sensors = forwardRef<typeof Box, SensorsProps>(({ ...props }, ref) => {
  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters
  } = usePageList<{ sensorId?: string }, Sensor>('sensors', getSensors)

  return (
    <>
      <Stack sx={{ height: 1 }} spacing={1}>
        <Stack
          sx={{ flexShrink: 0 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" spacing={1}>
            <TextFieldC
              size={'small'}
              value={filters?.sensorId}
              placeholder="Sensor ID"
              onChange={(evt) => changeFilters({ sensorId: evt.target.value })}
            />
          </Stack>
          <Stack direction="row">
            <TipIconBtn tip="Refresh" onClick={() => refresh()}>
              <Icons.Refresh fontSize="small"></Icons.Refresh>
            </TipIconBtn>
          </Stack>
        </Stack>

        <Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <DataGridC
            columns={getColumns()}
            rows={listData}
            rowCount={totalCount}
            loading={loading}
            paginationModel={{
              page: page > 0 ? page - 1 : 0,
              pageSize: limit
            }}
            pageSizeOptions={[15, 50]}
            onPaginationModelChange={(params) => {
              const isPageSizeChanged = limit !== params.pageSize
              changePage({
                page: isPageSizeChanged ? 1 : params.page + 1,
                limit: params.pageSize
              })
            }}
          />
        </Paper>
      </Stack>
    </>
  )
})

const getColumns: () => GridColDef<Sensor>[] = () => {
  return [
    {
      field: 'id',
      headerName: 'Sensor ID',
      width: 220,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip
              color={params.row.production ? 'primary' : 'warning'}
              sx={{ height: 16, p: 0 }}
              label={params.row.production ? 'P' : 'T'}
              size="small"
            />

            <Stack direction="row" alignItems="center">
              <Typography color={'primary'} fontSize="inherit" fontWeight="500">
                {params.value}
              </Typography>
              <CopyButton title="Sensor ID" value={params.value} />
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'deviceId',
      headerName: 'Device ID',
      headerAlign: 'center',
      width: 160,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'sendInterval',
      headerName: 'Send Interval',
      headerAlign: 'center',
      width: 140,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography color="primary" fontSize="inherit">
            {params.row.mode?.inSensor.samplingInterval}
          </Typography>
        )
      }
    },
    {
      field: 'samplingInterval',
      headerName: 'Sampling Interval',
      headerAlign: 'center',
      width: 140,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.mode?.inSensor.samplingInterval
      }
    },
    {
      field: 'failureRetry',
      headerName: 'Failure Retry',
      headerAlign: 'center',
      width: 140,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.mode?.inSensor.failureRetry
      }
    },
    {
      field: 'platform',
      headerName: 'Platform',
      width: 200,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography color="info.main">{params.value}</Typography>
      }
    },
    {
      field: 'codec',
      headerName: 'Codec',
      width: 160,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'voltage',
      headerName: 'Voltage',
      width: 100,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'strength',
      headerName: 'Strength',
      width: 100,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 200,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment(params.value).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  ]
}

Sensors.displayName = 'Sensors'
export default Sensors
