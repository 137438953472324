import React, { FC } from 'react'

import { Stack, Typography } from '@mui/material'
import moment from 'moment'
import { NotificationTypeEnum, Notification } from './notifications.types'
import { Channels, NotificationText } from './notifications.const'

import TipIcon from '@/components/TipIcon'
import ModeChangeItem from './ModeChangeItem'

interface IPropTypes {
  active?: boolean
  value?: Notification
  onClick?: () => void
}

const NotificationItem: FC<IPropTypes> = ({ active, value, onClick }) => {
  const ChannelIcon = Channels[value.channel].icon
  return (
    <Stack onClick={onClick}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          {value.channel && (
            <TipIcon title={Channels[value.channel]?.text}>
              <ChannelIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </TipIcon>
          )}
          <Typography variant="body2">
            {moment(value.recordedAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            pl: 0.25,
            pr: 1,
            borderRadius: 1
          }}
          alignItems="center">
          {value.type === NotificationTypeEnum.ModeChange && (
            <ModeChangeItem warningCodes={value?.signals?.warningCodes} />
          )}

          <Typography variant="body2">
            {value.type === NotificationTypeEnum.ModeChange &&
              `Mode ${value.signals?.mode} on`}
            {value.type !== NotificationTypeEnum.ModeChange &&
              NotificationText[value.type]}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

NotificationItem.displayName = 'NotificationItem'
export default NotificationItem
