import React, { FC } from 'react'
// import './sensorInfo.less'
import Item from './Item'
import { MapTreeDetailsType } from '../map.types'
import { ModeMap } from './details.const'
import { Stack } from '@mui/material'

interface IPropTypes {
  tree: MapTreeDetailsType
}

const SensorInfo: FC<IPropTypes> = ({ tree, ...props }) => {
  return (
    <Stack sx={{ pl: 3, pr: 3 }} spacing={1}>
      <Item name={'sensorId'} value={tree?.sensorId} copyble />
      <Item
        type="number"
        name={'mode'}
        value={ModeMap[tree?.mode?.inSensor.sendInterval]}
      />
      <Item
        type="number"
        name={'sendInterval'}
        value={tree?.mode?.inSensor.sendInterval}
      />
      <Item
        type="number"
        name={'samplingInterval'}
        value={tree?.mode?.inSensor.samplingInterval}
      />
      <Item
        type="number"
        name={'failureRetry'}
        value={tree?.mode?.inSensor.failureRetry}
      />
    </Stack>
  )
}

export default SensorInfo
