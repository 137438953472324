export enum AlertTypeEnum {
    Tilting = 'TILTING',
    Vibration = 'VIBRATION',
}


export type SpecialAlert = {
    reportId: string;
    emailStatus: boolean;
    totalAlertCount: number;
    activeCount: number;
    dismissedCount: number;
}