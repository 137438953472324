import React, { forwardRef, useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { Icons } from '@/assets/icons'
import TipIconBtn from '@/components/TipIconBtn'
import generatePdf, { Resolution } from 'react-to-pdf'
import { ReportTypeEnum } from '@/routes/manage/report/report.types'
import { getReportSnapshot } from '@/service/data/report'

interface EmailSnapshotProps extends DrawerProps {
  title?: string
  reportId: string
  type: ReportTypeEnum
  onClose: () => void
}

const EmailSnapshot = forwardRef<typeof Drawer, EmailSnapshotProps>(
  ({ title, type, open, reportId, onClose, ...props }, ref) => {
    const contentRef = useRef()
    const [content, setContent] = useState('')

    const getEmailSnapshot = async () => {
      const resp = await getReportSnapshot(reportId, type)
      if (resp?.code === 200) {
        setContent(resp.data)
      }
    }

    useEffect(() => {
      if (reportId && open) {
        getEmailSnapshot()
      }
    }, [reportId, open])

    return (
      <Dialog maxWidth="xl" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: 'primary.main' }}>
              <Icons.Article />
              <Typography variant="h6">{title}</Typography>
            </Stack>
            <Stack direction="row">
              <TipIconBtn
                tip="Download as PDF"
                onClick={() =>
                  generatePdf(contentRef, {
                    filename: title,
                    resolution: Resolution.HIGH
                  })
                }>
                <Icons.DownloadOutlined fontSize="small" />
              </TipIconBtn>
              <IconButton onClick={() => onClose()}>
                <Icons.Close fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'scroll' }}>
          <div
            ref={contentRef}
            style={{
              transform: 'scale(0.85)',
              margin: '0 auto',
              width: 1300,
              height: 1828,
              paddingTop: 1
            }}
            dangerouslySetInnerHTML={{ __html: content }}></div>
        </DialogContent>
      </Dialog>
    )
  }
)

EmailSnapshot.displayName = 'EmailSnapshot'
export default EmailSnapshot
