import React, { forwardRef, useState } from 'react'
import { Box, BoxProps } from '@mui/material'
import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'
import LeafiPlate from '@/routes/manage/data/trees/actions/LeafiPlate'

interface LeafiPlateStatusProps extends BoxProps {
  treeId: string
  plateId: string
}

const LeafiPlateStatus = forwardRef<typeof Box, LeafiPlateStatusProps>(
  ({ treeId, plateId, ...props }, ref) => {
    const [showPlate, setShowPlate] = useState(false)

    return (
      <Box ref={ref}>
        <TipIconBtn
          size="small"
          tip={
            plateId
              ? `LeafiPlate ID: ${plateId}`
              : 'LeafiPlate is not available yet'
          }
          onClick={() => {
            if (!plateId) {
              return
            }
            setShowPlate(true)
          }}>
          {plateId ? (
            <Icons.LeafiPlateOn style={{ cursor: 'pointer' }} />
          ) : (
            <Icons.LeafiPlateOff style={{ cursor: 'pointer' }} />
          )}
        </TipIconBtn>

        <LeafiPlate
          viewOnly
          open={showPlate}
          treeId={treeId}
          plateId={plateId}
          onClose={() => setShowPlate(false)}
        />
      </Box>
    )
  }
)

LeafiPlateStatus.displayName = 'LeafiPlateStatus'
export default LeafiPlateStatus
