import React from 'react'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { Layout, Header, Body, Menu } from '@/components/page'
import { Icons } from '@/assets/icons'
import withRootLayout from '@/components/page/RootLayout'
import RootLayout from '@/components/page/RootLayout'

const ManageDatas = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <RootLayout>
      <Layout>
        <Header>
          <Menu
            active={Boolean(
              matchPath(location?.pathname, '/manage-data/trees')
            )}
            icon={<Icons.NatureOutlined />}
            onClick={() => navigate('trees')}>
            Trees
          </Menu>
          <Menu
            active={Boolean(
              matchPath(location?.pathname, '/manage-data/sensors')
            )}
            icon={
              <Icons.SurroundSoundOutlined
                sx={{ transform: 'rotate(90deg)' }}
              />
            }
            onClick={() => navigate('sensors')}>
            Sensors
          </Menu>
          <Menu
            active={Boolean(
              matchPath(location?.pathname, '/manage-data/tilting-data')
            )}
            icon={<Icons.LegendToggleOutlined />}
            onClick={() => navigate('tilting-data')}>
            Tilting Data
          </Menu>
          <Menu
            active={Boolean(
              matchPath(location?.pathname, '/manage-data/weather-warning')
            )}
            icon={<Icons.CloudQueue />}
            onClick={() => navigate('weather-warning')}>
            Weather Warning
          </Menu>
        </Header>
        <Body>
          <Outlet />
        </Body>
      </Layout>
    </RootLayout>
  )
}
ManageDatas.displayNanme = 'ManageDatas'
export default ManageDatas
