import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'

interface BodyProps extends BoxProps {}

const Body = forwardRef<typeof Box, BodyProps>(
  ({ children, sx, ...props }, ref) => {
    return (
      <Box ref={ref} sx={{ flex: 1, py: 1, px: 2, overflow: 'hidden', ...sx }}>
        {children}
      </Box>
    )
  }
)

Body.displayName = 'PageBody'
export default Body
