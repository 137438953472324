import React, { useEffect, useState, forwardRef, FC, useMemo } from 'react'
import {
  Stack,
  Typography,
  Tooltip,
  Menu,
  MenuProps,
  IconButton,
  Paper,
  Box,
  Fade,
  MenuItem,
  Select,
  Chip
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { MapTreeType } from '@/routes/map/map.types'
import CopyButton from '@/components/CopyButton'
import { getDecimal } from '@/utils/tool'
import { useAppDispatch, useAppSelector } from '@/store'
import { globalSlice } from '@/store/reducers/global/global.slice'
import { Icons } from '@/assets/icons'
import moment from 'moment'
import { grey, red } from '@mui/material/colors'
import { AlertTypeEnum } from '@/routes/data/special-alerts/special-alerts.types'
import { getTreeAlerts } from '@/service/home'

export type AlertListType = 'any' | 'tilting' | 'disconnection'

export type AlertListItemType = {
  treeId: string
  species: string
  address: string
  isDisconnected: boolean
  tiltingAlert: boolean
  tiltingAlertRecorededAt: Date
  tiltingAngleResetted: number
  azimuthResetted: number
  vibration: boolean
  vibrationRecordedAt: Date
}

interface alertListProps extends Omit<MenuProps, 'open'> {
  trees?: MapTreeType[]
  onLocate: (treeId: string) => void
}

const AlertList = forwardRef<typeof Menu, alertListProps>(
  ({ onLocate, sx, anchorEl, ...props }, ref) => {
    const dispatch = useAppDispatch()
    const navigation = useAppSelector((state) => state.global.navigation)
    const treeAlert = useAppSelector((state) => state.global.treeAlert)
    const userTrees = useAppSelector((state) => state.map.trees)
    const allTrees = useAppSelector((state) => state.manage.overview.trees)
    const isShowList = treeAlert.showList
    const [listData, setListData] = useState([])

    const isPublic = useMemo(() => {
      return navigation.type === 'public'
    }, [navigation])

    const trees = isPublic ? userTrees : allTrees

    const [alertDistribution, setAlertDistribution] = useState({
      total: 0,
      tilting: 0,
      vibration: 0,
      disconnection: 0
    })

    const close = () => {
      dispatch(
        globalSlice.actions.setAlertListStatus({
          show: false,
          type: treeAlert.showListType
        })
      )
    }

    useEffect(() => {
      const tiltingAlertTrees = trees?.filter((tree) => {
        return tree.tiltingLevel > 2
      })

      const disconnectedTrees = trees?.filter((tree) => {
        return tree.isDisconnected
      })

      if (treeAlert.showListType === 'any') {
        setListData([...tiltingAlertTrees, ...disconnectedTrees])
      } else if (treeAlert.showListType === 'tilting') {
        setListData(tiltingAlertTrees)
      } else if (treeAlert.showListType === 'disconnection') {
        setListData(disconnectedTrees)
      }
    }, [trees, treeAlert])

    useEffect(() => {
      const tiltingAlertTrees = trees?.filter((tree) => {
        return tree.tiltingLevel > 2
      })

      const tiltingAlertsCount = tiltingAlertTrees.length

      const vibrationAlertsCount = trees?.filter(
        (tree) => tree.vibration
      ).length

      const disconnectedTrees = trees?.filter((tree) => {
        return tree.isDisconnected
      })
      const disconnectionCount = disconnectedTrees.length

      setAlertDistribution({
        total: tiltingAlertsCount + disconnectionCount,
        tilting: tiltingAlertsCount,
        vibration: vibrationAlertsCount,
        disconnection: disconnectionCount
      })
    }, [trees])

    return (
      <Fade in={isShowList} exit={!isShowList}>
        <Paper
          sx={{
            ...sx,
            border: '1px solid #ddd',
            overflow: 'hidden',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column'
          }}
          elevation={12}>
          <Stack
            sx={{ p: 1, flexShrink: 0 }}
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}>
            <Stack direction="row" sx={{ color: 'warning.main' }} spacing={1}>
              <Icons.Warning fontSize="small" />
              <Typography variant="subtitle2">ALERTS</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Select
                size="small"
                variant="standard"
                displayEmpty
                value={treeAlert.showListType}
                sx={{
                  '& .MuiInputBase-input': { pl: 1, pr: 2, pt: 1 }
                }}
                onChange={(evt) => {
                  dispatch(
                    globalSlice.actions.setAlertListStatus({
                      show: isShowList,
                      type: evt.target.value as AlertListType
                    })
                  )
                }}>
                <MenuItem value={'any'}>
                  <Stack
                    sx={{ pr: 1 }}
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <Typography variant="caption">Any</Typography>
                    <Chip
                      size="small"
                      sx={{ height: 18 }}
                      label={alertDistribution?.total}></Chip>
                  </Stack>
                </MenuItem>
                <MenuItem value={'tilting'}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="caption">Tilting</Typography>
                    <Chip
                      size="small"
                      label={alertDistribution?.tilting}></Chip>
                  </Stack>
                </MenuItem>
                <MenuItem value={'disconnection'}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="caption">Disconnection</Typography>
                    <Chip
                      size="small"
                      label={alertDistribution?.disconnection}></Chip>
                  </Stack>
                </MenuItem>
              </Select>
              <span>
                <IconButton size="small" onClick={close}>
                  <Icons.Close fontSize="inherit" />
                </IconButton>
              </span>
            </Stack>
          </Stack>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            {isShowList && (
              <DataGrid
                columns={getColumns().filter((col) => {
                  if (isPublic) {
                    return col.field !== 'treeId'
                  }
                  return col
                })}
                getRowId={(row) => row.treeId}
                density="compact"
                rows={listData}
                rowCount={listData?.length}
                pagination
                onRowClick={(params) =>
                  params?.id && onLocate(params.id.toString())
                }
                sortModel={[
                  {
                    field: 'tiltingAngle',
                    sort: 'desc'
                  }
                ]}
                hideFooter={listData?.length < 25}
              />
            )}
          </Box>
        </Paper>
      </Fade>
    )
  }
)

AlertList.displayName = 'AlertList'
export default AlertList

const TreeIdItem: FC<{ treeId: string; userTreeId: string }> = ({
  userTreeId,
  treeId,
  ...props
}) => {
  const [alerts, setAlerts] = useState([])
  const tiltingAlert = alerts?.find(
    (alert) => alert.type === AlertTypeEnum.Tilting
  )
  const vibrationAlert = alerts?.find(
    (alert) => alert.type === AlertTypeEnum.Vibration
  )
  const fetchTreeAlerts = async (treeId) => {
    const resp = await getTreeAlerts(treeId)
    if (resp?.code) {
      setAlerts(resp?.data)
    }
  }
  useEffect(() => {
    if (treeId) {
      fetchTreeAlerts(treeId)
    }
  }, [treeId])

  return (
    <>
      <Tooltip
        placement="top"
        title={
          vibrationAlert?.active && (
            <Typography fontSize="inherit">
              Vibration detected at{' '}
              {moment(vibrationAlert?.recordedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          )
        }>
        <Icons.Vibration
          fontSize="inherit"
          sx={{
            color: vibrationAlert?.active ? red[400] : grey[300],
            verticalAlign: 'top'
          }}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        title={
          tiltingAlert?.active && (
            <Typography fontSize="inherit">
              Alert detected at{' '}
              {moment(tiltingAlert?.recordedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          )
        }>
        <Typography
          color={tiltingAlert?.active ? 'error.main' : 'inherit'}
          fontSize="inherit">
          {userTreeId}
        </Typography>
      </Tooltip>
    </>
  )
}

const getColumns: () => GridColDef<MapTreeType>[] = () => {
  return [
    {
      field: 'treeId',
      headerName: 'Tree ID',
      width: 180,
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const value = params.value
        const treeId = params.row.treeId
        return (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <TreeIdItem treeId={treeId} userTreeId={value} />
            <CopyButton stopProp size="small" title="Tree ID" value={value} />
          </Stack>
        )
      }
    },
    {
      field: 'userTreeId',
      headerName: 'User Tree ID',
      width: 180,
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const value = params.value
        const treeId = params.row.treeId
        return (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <TreeIdItem treeId={treeId} userTreeId={value} />
            <CopyButton
              stopProp
              size="small"
              title="User Tree ID"
              value={value}
            />
          </Stack>
        )
      }
    },
    {
      field: 'species',
      headerName: 'Species',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.value}>
            <Typography fontSize="inherit" noWrap>
              {params?.value}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'tiltingAngle',
      headerName: 'Tilting Angle',
      headerAlign: 'left',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return getDecimal(params.value, 2) + '°'
      }
    },
    {
      field: 'azimuth',
      headerName: 'Azimuth',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'center',
      renderCell: (params) => {
        return getDecimal(params.value, 2) + '°'
      }
    },
    {
      field: 'address',
      headerName: 'Location',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.value}>
            <Typography fontSize="inherit" noWrap>
              {params?.value}
            </Typography>
          </Tooltip>
        )
      }
    }
  ]
}
