import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  MenuProps,
  Chip,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Divider
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/store'

import {
  changeTreeStatus,
  deleteTree,
  getTrees,
  registrateTreeOnBlockchain
} from '@/service/manage/trees'

import CopyButton from '@/components/CopyButton'
import { getDecimal } from '@/utils/tool'
import { Icons } from '@/assets/icons'
import Reset, { ResetType } from './Reset'
import {
  BlockchainRecordTypeEnum,
  Tree,
  TreeResetTypeEnum
} from './trees.types'
import Confirm, { ConfirmType } from '@/components/Confirm'
import { grey, red } from '@mui/material/colors'
import TipIconBtn from '@/components/TipIconBtn'
import AlertList from '@/components/AlertList'
import TipIcon from '@/components/TipIcon'
import Actions from './actions/Actions'
import TreeDetails from '@/routes/map/details/Details'
import EditTree from './actions/EditTree'

import NewTree from './menus/NewTree'
import { useAppSelector } from '@/store'
import { TiltingThreshold } from '@/configs/config.const'
import Maintenance from './actions/Maintenance'
import { AlertTypeEnum } from '@/routes/data/special-alerts/special-alerts.types'
import ImportTrees from './menus/import-trees/ImportTrees'
import TreeImages from './actions/TreeImages'
import { getTreesAsync } from '@/store/reducers/map/map.thunks'
import { globalSlice } from '@/store/reducers/global/global.slice'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import TextFieldC from '@/components/TextFieldC'
import OrgGroupSelector from '@/components/OrgGroupSelector'
import LeafiPlate from './actions/LeafiPlate'
import { ManageAlertListStyle } from '../../manage.const'

export type TreesPageFilters = {
  userTreeId?: string
  treeId?: string
  organizationId?: string
  maintenanceOn?: boolean
  mutedOn?: boolean
  disabledOn?: boolean
}

export type TreePageListParams = PageListParams<TreesPageFilters>

export type ActionType =
  | 'maintenance'
  | 'reset'
  | 'blockchain'
  | 'muted'
  | 'disabled'
  | 'delete'

export type MenuActions = 'add' | 'import' | 'export' | 'batch-add'

const StatusList = ['Maintenace On', 'Muted On', 'Disabled On']

type ConfirmData = { tree: Tree; resetType?: ResetType }

const Trees = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.users.user)

  const [isConfirmPending, setIsConfirmPending] = useState(false)

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  } = usePageList<TreesPageFilters, Tree>('trees', getTrees)

  const selectedStatusFilter = [
    filters?.maintenanceOn && 'Maintenace On',
    filters?.mutedOn && 'Muted On',
    filters?.disabledOn && 'Disabled On'
  ].filter((item) => !!item)

  const [menuAction, setMenuAction] = useState<MenuActions | null>()

  const [actionParams, setActionParams] = useState<{
    anchor: MenuProps['anchorEl']
    data: Tree
  }>()

  const [actionItemParams, setActionItemParams] = useState<{
    type:
      | 'details'
      | 'edit'
      | 'delete'
      | 'maintenance'
      | 'images'
      | 'leafiPlate'
    anchor?: MenuProps['anchorEl']
    data: Tree
  }>()

  const [confirmParams, setConfirmParams] = useState<ConfirmType<
    ActionType,
    ConfirmData
  > | null>()

  const handleStatusFilterChange = (status) => {
    switch (status) {
      case '': {
        changeFilters({
          maintenanceOn: false,
          mutedOn: false,
          disabledOn: false
        })
        break
      }
      case 'Maintenace On': {
        if (selectedStatusFilter.indexOf('Maintenace On') > -1) {
          changeFilters({ maintenanceOn: false })
        } else {
          changeFilters({ maintenanceOn: true })
        }
        break
      }
      case 'Muted On': {
        if (selectedStatusFilter.indexOf('Muted On') > -1) {
          changeFilters({ mutedOn: false })
        } else {
          changeFilters({ mutedOn: true })
        }
        break
      }
      case 'Disabled On': {
        if (selectedStatusFilter.indexOf('Disabled On') > -1) {
          changeFilters({ disabledOn: false })
        } else {
          changeFilters({ disabledOn: true })
        }
        break
      }
      default: {
      }
    }
  }

  const closeConfirm = () => setConfirmParams(null)

  const updateTree = (tree: Tree) => {
    const index = listData.findIndex((t) => t.treeId === tree.treeId)
    index > -1 && updateDataByIdx(index, tree)
  }

  const updateTreeStatus = async (
    treeId: string,
    type: 'muted' | 'disabled' | 'maintenance',
    status: boolean
  ) => {
    const resp = await changeTreeStatus(treeId, type, status)
    if (resp?.code === 200) {
      updateTree(resp.data)
      closeConfirm()
    }
  }

  const onConfirm = (
    actionType: ActionType,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    confirmData?: ConfirmData
  ) => {
    switch (actionType) {
      case 'muted':
      case 'disabled':
      case 'maintenance': {
        if (confirmData?.tree?.status[actionType].active) {
          updateTreeStatus(confirmData?.tree?.treeId, actionType, false)
        } else {
          setConfirmParams({
            anchor: event.currentTarget,
            type: actionType,
            data: confirmData
          })
        }
        break
      }
      default: {
        setConfirmParams({
          anchor: event.currentTarget,
          type: actionType,
          data: confirmData
        })
      }
    }
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/manage-overview', { state: { navTreeId: treeId } })
  }

  const removeTree = async (treeId) => {
    const resp = await deleteTree(treeId)
    if (resp?.code === 200 && resp.data) {
      setConfirmParams(null)
      refresh()
    } else {
    }
  }

  const registrateOnBlockChain = async (treeId) => {
    setIsConfirmPending(true)
    const resp = await registrateTreeOnBlockchain(treeId)
    if (resp?.code === 200 && resp?.data) {
      dispatch(
        globalSlice.actions.setNotification({
          type: 'success',
          desc: 'The resource has been registered on blockchain successfully'
        })
      )
      updateTree(resp.data)
      setConfirmParams(null)
      refresh()
    }
    setIsConfirmPending(false)
  }

  useEffect(() => {
    return () => {
      dispatch(getTreesAsync())
    }
  }, [])

  return (
    <>
      <Stack sx={{ height: 1 }} spacing={1}>
        <Stack
          sx={{ flexShrink: 0 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" spacing={1}>
            <TextFieldC
              size={'small'}
              value={filters?.treeId}
              placeholder="Tree ID"
              onChange={(evt) => changeFilters({ treeId: evt.target.value })}
            />
            <TextFieldC
              size={'small'}
              value={filters?.userTreeId}
              placeholder="User Tree ID"
              onChange={(evt) =>
                changeFilters({ userTreeId: evt.target.value })
              }
            />
            <OrgGroupSelector
              size="small"
              disableGroup
              organizationId={filters?.organizationId}
              onOrgChange={(organizationId) =>
                changeFilters({ organizationId })
              }
            />
            <Select
              multiple
              displayEmpty
              size="small"
              sx={{ bgcolor: '#ffffff' }}
              value={selectedStatusFilter}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <Typography>Any Tree Status</Typography>
                }
                return (
                  <Stack direction="row" spacing={0.5}>
                    {selected.map((value) => (
                      <Chip size="small" key={value} label={value} />
                    ))}
                  </Stack>
                )
              }}>
              <MenuItem value={''} onClick={() => handleStatusFilterChange('')}>
                <Checkbox checked={selectedStatusFilter.length === 0} />
                <ListItemText>Any Status</ListItemText>
              </MenuItem>
              <Divider />
              {StatusList.map((status) => {
                return (
                  <MenuItem
                    key={status}
                    value={status}
                    onClick={() => handleStatusFilterChange(status)}>
                    <Checkbox
                      size="small"
                      checked={selectedStatusFilter.includes(status)}
                    />
                    <ListItemText>{status}</ListItemText>
                  </MenuItem>
                )
              })}
            </Select>
          </Stack>

          <Stack direction="row">
            {user?.isLeafiotAdmin && (
              <TipIconBtn
                tip="Add new tree"
                onClick={() => setMenuAction('add')}>
                <Icons.Add fontSize="small" />
              </TipIconBtn>
            )}

            {user?.isLeafiotAdmin && (
              <TipIconBtn
                tip="Add new tree"
                onClick={() => setMenuAction('import')}>
                <Icons.DriveFolderUploadOutlined fontSize="small" />
              </TipIconBtn>
            )}

            <TipIconBtn tip="Refresh" onClick={() => refresh()}>
              <Icons.Refresh fontSize="small"></Icons.Refresh>
            </TipIconBtn>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Paper sx={{ height: 1 }}>
            <DataGridC
              loading={loading}
              getRowId={(row) => row.treeId}
              rows={listData}
              rowCount={totalCount}
              pageSizeOptions={[15, 50]}
              paginationModel={{
                page: page > 0 ? page - 1 : 0,
                pageSize: limit
              }}
              onPaginationModelChange={(params) => {
                const isPageSizeChanged = limit !== params.pageSize
                changePage({
                  page: isPageSizeChanged ? 1 : params.page + 1,
                  limit: params.pageSize
                })
              }}
              columns={getColumns({
                onConfirm,
                onMap: showTreeOnMap,
                onBlockchain: (anchor, data) => {
                  setConfirmParams({
                    type: 'blockchain',
                    anchor,
                    data: { tree: data }
                  })
                },
                onEdit: (anchor, data) => {
                  setActionItemParams({ type: 'edit', anchor, data })
                },
                onDetails: (anchor, data) => {
                  setActionItemParams({ type: 'details', anchor, data })
                },
                onLeafiotPlate: (anchor, data) => {
                  setActionItemParams({ type: 'leafiPlate', anchor, data })
                },
                onActions: (anchor, data) => {
                  setActionParams({ anchor, data })
                }
              })}
            />
          </Paper>
        </Box>
      </Stack>

      <Actions
        open={Boolean(actionParams?.anchor)}
        anchorEl={actionParams?.anchor}
        onItemSelect={(type, anchor, resetType) => {
          if (type === 'delete') {
            setConfirmParams({
              type,
              anchor,
              data: { tree: actionParams?.data }
            })
            return
          } else if (type === 'reset') {
            setConfirmParams({
              type,
              anchor,
              data: { tree: actionParams?.data, resetType }
            })
            return
          }
          setActionItemParams({ type, data: actionParams?.data })
        }}
        onClose={() => setActionParams(null)}
      />

      <NewTree
        open={menuAction === 'add'}
        onSuccess={() => {
          setMenuAction(null)
          refresh()
        }}
        onClose={() => setMenuAction(null)}
      />

      <ImportTrees
        open={menuAction === 'import'}
        onSuccess={() => {
          setMenuAction(null)
          refresh()
        }}
        onClose={() => setMenuAction(null)}
      />

      <TreeImages
        open={actionItemParams?.type === 'images'}
        treeId={actionItemParams?.data?.treeId}
        onSuccess={(tree) => {
          updateTree(tree)
          setActionItemParams(null)
        }}
        onClose={() => setActionItemParams(null)}
      />

      <LeafiPlate
        open={actionItemParams?.type === 'leafiPlate'}
        treeId={actionItemParams?.data?.treeId}
        plateId={actionItemParams?.data?.plateId}
        onSuccess={(tree) => updateTree(tree)}
        onClose={() => setActionItemParams(null)}
      />

      <EditTree
        open={actionItemParams?.type === 'edit'}
        treeId={actionItemParams?.data?.treeId}
        onSuccess={(tree) => {
          updateTree(tree)
          setActionItemParams(null)
        }}
        onClose={() => setActionItemParams(null)}
      />

      <Maintenance
        open={actionItemParams?.type === 'maintenance'}
        treeId={actionItemParams?.data?.treeId}
        onSuccess={() => {}}
        onClose={() => setActionItemParams(null)}
      />

      <TreeDetails
        open={actionItemParams?.type === 'details'}
        treeId={actionItemParams?.data?.treeId}
        onClose={() => setActionItemParams(null)}
        onEdit={() =>
          setActionItemParams({ type: 'edit', data: actionItemParams?.data })
        }
      />

      <Reset
        type={
          confirmParams?.data?.resetType === 'tilting'
            ? TreeResetTypeEnum.TILTING
            : TreeResetTypeEnum.AZIMUTH
        }
        open={Boolean(confirmParams?.anchor) && confirmParams?.type === 'reset'}
        anchorEl={confirmParams?.anchor}
        tree={confirmParams?.data?.tree}
        onSuccess={(updatedTree) => updateTree(updatedTree)}
        onClose={() => closeConfirm()}
      />

      <Confirm
        pending={isConfirmPending}
        open={
          Boolean(confirmParams?.anchor) && confirmParams?.type === 'blockchain'
        }
        anchorEl={confirmParams?.anchor}
        icon={
          confirmParams?.data?.tree?.blockchain ? (
            <Icons.BlockChainStatusOn
              fill="currentColor"
              style={{ width: 40 }}
            />
          ) : (
            <Icons.BlockChainRegistration
              fontSize="large"
              fill="currentColor"
              style={{ width: 40 }}
            />
          )
        }
        title={
          confirmParams?.data?.tree?.blockchain
            ? 'Update Tree on Blockchain'
            : 'Registrate on Blockchain'
        }
        message="Please confirm that you want to update this tree on blockchain."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          registrateOnBlockChain(confirmParams?.data?.tree?.treeId)
        }}
      />

      <Confirm
        open={Boolean(confirmParams?.anchor) && confirmParams?.type === 'muted'}
        anchorEl={confirmParams?.anchor}
        icon={<Icons.NotificationsOffOutlined fontSize="large" />}
        title="Mute Tree"
        message="The system will not send any notification about this tree after it has been muted."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(confirmParams?.data?.tree?.treeId, 'muted', true)
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) && confirmParams?.type === 'disabled'
        }
        anchorEl={confirmParams?.anchor}
        title="Disable Tree"
        icon={<Icons.NotInterested fontSize="large" />}
        message="Disable this tree will hide its data everywhere in the system. But you can still find it historical data on the data page."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(confirmParams?.data?.tree?.treeId, 'disabled', true)
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) &&
          confirmParams?.type === 'maintenance'
        }
        anchorEl={confirmParams?.anchor}
        title="Turn on Maintenance"
        icon={<Icons.Handyman fontSize="large" />}
        message="The maintenance mark will display in the dashboard and notifications when maintenance status is on"
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(
            confirmParams?.data?.tree?.treeId,
            'maintenance',
            true
          )
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) && confirmParams?.type === 'delete'
        }
        anchorEl={confirmParams?.anchor}
        title="Delete"
        icon={<Icons.Delete fontSize="large" />}
        message="Are you sure you want to delete this tree? This action cannot be undone."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          removeTree(confirmParams?.data?.tree?.treeId)
        }}
      />
      <AlertList
        sx={ManageAlertListStyle}
        onLocate={(treeId) => showTreeOnMap(treeId)}
      />
    </>
  )
}

export default React.memo(Trees)

const getColumns: (params: {
  onMap: (treeId: string) => void
  onConfirm: (
    actionType: ActionType,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.ChangeEvent<HTMLInputElement>,
    confirmData?: ConfirmData
  ) => void
  onLeafiotPlate: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onBlockchain: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onEdit: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onDetails: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onActions: (anchor: MenuProps['anchorEl'], data: Tree) => void
}) => GridColDef<Tree>[] = ({
  onLeafiotPlate,
  onBlockchain,
  onEdit,
  onMap,
  onConfirm,
  onDetails,
  onActions
}) => {
  return [
    {
      field: 'treeId',
      headerName: 'Tree ID',
      width: 240,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params.value
        // @ts-ignore
        const vibration = params.row?.alerts?.find(
          (s) => s.type === AlertTypeEnum.Vibration
        )
        const tiltingReset = params.row?.resets?.find(
          (r) => r.type === TreeResetTypeEnum.TILTING
        )
        const tiltingResetValue = tiltingReset?.value ?? 0
        const tilting = params.row?.tiltingData?.tiltingAngle
        const tiltingChange = Math.abs(
          tilting ? tilting - tiltingResetValue : 0
        )

        return (
          <Stack direction="row" alignItems="center">
            <TipIcon
              title={
                <Typography fontSize="inherit">
                  {Boolean(vibration?.active)
                    ? `Vibration detected at
                  ${
                    vibration?.recordedAt
                      ? moment(vibration?.recordedAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : 'N/A'
                  }`
                    : 'No vibration detected'}
                </Typography>
              }
              placement="top">
              <Icons.Vibration
                fontSize="inherit"
                sx={{
                  color: Boolean(vibration?.active) ? red[400] : grey[300],
                  verticalAlign: 'top'
                }}
              />
            </TipIcon>
            <Button
              size="small"
              sx={{ textTransform: 'none' }}
              color={tiltingChange > TiltingThreshold ? 'error' : 'primary'}
              onClick={(event) => onDetails(event.currentTarget, params.row)}>
              {value}
            </Button>
            <TipIconBtn
              size="small"
              tip="Locate on Map"
              onClick={() => onMap(params.row.treeId)}>
              <Icons.PlaceOutlined fontSize="inherit" />
            </TipIconBtn>
            <CopyButton size="small" title="Tree ID" value={value} />
          </Stack>
        )
      }
    },
    {
      field: 'userTreeId',
      headerName: 'User Tree ID',
      width: 200,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params.value

        return (
          <Stack direction="row" alignItems="center">
            <Typography fontSize="inherit">{value}</Typography>
            <CopyButton
              size="small"
              title="User Tree ID"
              value={value}></CopyButton>
          </Stack>
        )
      }
    },
    {
      field: 'plateId',
      headerName: 'LeafiPlate ID',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const plateId = params.value
        return (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontSize="inherit">{plateId}</Typography>
            <TipIconBtn
              tip={'View and manage LeafiPlate'}
              size="small"
              onClick={(event) =>
                onLeafiotPlate(event.currentTarget, params.row)
              }>
              {plateId ? (
                <Icons.LeafiPlateOn fontSize="inherit" />
              ) : (
                <Icons.LeafiPlateBold fontSize="inherit" />
              )}
            </TipIconBtn>
            {plateId && (
              <CopyButton
                size="small"
                title="LeafiPlate ID"
                value={plateId}></CopyButton>
            )}
          </Stack>
        )
      }
    },
    {
      field: 'sensorId',
      headerName: 'Sensor ID',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params.value
        return (
          value && (
            <Stack direction="row" alignItems="center">
              <Typography fontSize="inherit">{value}</Typography>
              <CopyButton size="small" title="Sensor ID" value={value} />
            </Stack>
          )
        )
      }
    },

    {
      field: 'organization',
      headerName: 'Organization',
      width: 240,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => params.value.fullname
    },
    {
      field: 'blockchain',
      headerName: 'Blockchain ID',
      headerAlign: 'center',
      width: 220,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const blockchain = params.value
        const blockchainId = blockchain?.id

        return (
          blockchain && (
            <Stack direction="row" alignItems="center">
              <Tooltip
                title={
                  <>
                    <Typography paragraph variant="body2">{`${
                      blockchain.type === BlockchainRecordTypeEnum.INITIAL
                        ? 'Registrated'
                        : 'Updated'
                    } at ${moment(blockchain.createdAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}`}</Typography>
                    <Typography component="code" fontSize="inherit">
                      {blockchainId}
                    </Typography>
                  </>
                }
                placement="top">
                <Chip
                  size="small"
                  sx={{ height: 16 }}
                  label={
                    <Typography
                      color="warning.main"
                      fontSize="inherit"
                      sx={{ width: 170 }}
                      noWrap>
                      {blockchainId}
                    </Typography>
                  }
                />
              </Tooltip>

              <CopyButton
                size="small"
                title="Blockchain ID"
                value={blockchainId}
              />
            </Stack>
          )
        )
      }
    },
    {
      field: 'latestRecordTime',
      headerName: 'Latest Data Recorded At',
      headerAlign: 'center',
      width: 200,
      filterable: false,
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.tiltingData?.recordedAt,
      valueFormatter: ({ value }) =>
        value ? moment(value)?.format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      field: 'lastestVibrationTime',
      headerName: 'Latest Vibration Time',
      headerAlign: 'center',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell(params) {
        const vibrationAlert = params?.row?.alerts?.find(
          ({ type }) => type === AlertTypeEnum.Vibration
        )
        return vibrationAlert?.endedAt
          ? moment(vibrationAlert?.endedAt)?.format('YYYY-MM-DD HH:mm:ss')
          : '-'
      }
    },
    {
      field: 'tiltingAngle',
      headerName: 'Tilting Angle Δ Delta',
      headerAlign: 'center',
      width: 180,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const tiltingReset = params.row?.resets?.find(
          (r) => r.type === TreeResetTypeEnum.TILTING
        )
        const tiltingResetValue = tiltingReset?.value ?? 0
        const tiltinghResetAt = tiltingReset?.recordedAt

        const tilting = params.row?.tiltingData?.tiltingAngle

        const tiltingChange = Math.abs(
          tilting ? tilting - tiltingResetValue : 0
        )

        return (
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <Typography>{getDecimal(tilting, 2) + '°'}</Typography>
            <Stack direction="row">
              {tiltingChange !== 0 && (
                <Typography
                  variant="caption"
                  color={tiltingChange === 0 ? 'inherit' : 'error'}>
                  Δ
                </Typography>
              )}
              <Tooltip
                placement="top"
                title={
                  <>
                    <Typography
                      variant="caption"
                      paragraph={Boolean(tiltinghResetAt)}>
                      Tilting Reset Value: {getDecimal(tiltingResetValue, 2)}°
                    </Typography>
                    {tiltinghResetAt && (
                      <Typography variant="caption">
                        Recorded at{' '}
                        {moment(tiltinghResetAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>
                    )}
                  </>
                }>
                <Typography
                  variant="caption"
                  color={tiltingChange === 0 ? 'inherit' : 'error'}>
                  {tiltingChange !== 0
                    ? getDecimal(tiltingChange, 2) + '°'
                    : '-'}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'azimuth',
      headerName: 'Azimuth Δ Delta',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const azimuthReset = params.row?.resets?.find(
          (r) => r.type === TreeResetTypeEnum.AZIMUTH
        )
        const azimuthResetValue = azimuthReset?.value ?? 0
        const azimuthResetAt = azimuthReset?.recordedAt
        const azimuth = params.row?.tiltingData?.azimuth

        const azimuthChange = Math.abs(
          azimuth ? azimuth - azimuthResetValue : 0
        )

        return (
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <Typography>{getDecimal(azimuth, 2) + '°'}</Typography>
            <Stack direction="row">
              {azimuthChange !== 0 && (
                <Typography
                  variant="caption"
                  color={azimuthChange === 0 ? 'inherit' : 'error'}>
                  Δ
                </Typography>
              )}
              <Tooltip
                placement="top"
                title={
                  <>
                    <Typography
                      variant="caption"
                      paragraph={Boolean(azimuthResetAt)}>
                      Azimuth Reset Value: {getDecimal(azimuthResetValue, 2)}°
                    </Typography>
                    {azimuthResetAt && (
                      <Typography variant="caption">
                        Recorded at{' '}
                        {moment(azimuthResetAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>
                    )}
                  </>
                }>
                <Typography
                  variant="caption"
                  sx={{ cursor: 'pointer' }}
                  color={azimuthChange === 0 ? 'inherit' : 'error'}>
                  {azimuthChange !== 0
                    ? getDecimal(azimuthChange, 2) + '°'
                    : '-'}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'temperature',
      headerName: 'Temperature',
      headerAlign: 'center',
      width: 120,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const temperatureValue = params.row?.tiltingData?.temperature
        if (!temperatureValue) return '-'
        const temperature = temperatureValue / 100
        return temperature > 0 ? `${getDecimal(temperature)}°C` : 0
      }
    },
    {
      field: 'species',
      headerName: 'Species',
      flex: 1,
      filterable: false,
      sortable: false,
      minWidth: 200,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.species
      },
      renderCell: (params) => {
        return (
          <Tooltip title={`${params.value}`} placement="top-start">
            <Typography width={200} noWrap fontSize="inherit">
              {params.value}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'crownSpread',
      headerName: 'Crown Spread(m)',
      width: 160,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.crownSpread
      }
    },
    {
      field: 'height',
      headerName: 'Height(m)',
      width: 90,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.height
      }
    },
    {
      field: 'dbh',
      headerName: 'DBH(mm)',
      width: 90,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.dbh
      }
    },

    {
      field: 'blockchainUpdateTime',
      headerName: 'Blockchain Updated At',
      width: 180,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.blockchain?.createdAt,
      valueFormatter: ({ value }) =>
        value ? moment(value)?.format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      field: 'status',
      headerName: 'Maintenance | Muted | Disabled',
      headerAlign: 'center',
      width: 240,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isMuted = params.row?.status?.muted?.active
        const isDisabled = params.row?.status?.disabled?.active
        const isMaintenance = params.row?.status?.maintenance?.active
        return (
          <Stack direction="row">
            <Button
              size="small"
              title={'Maintenance on'}
              onClick={(event) =>
                onConfirm('maintenance', event, { tree: params.row })
              }>
              <Chip
                color={isMaintenance ? 'warning' : 'default'}
                size="small"
                label={isMaintenance ? 'ON' : 'OFF'}
              />
            </Button>

            <Button
              size="small"
              title={'Muted Status'}
              onClick={(event) =>
                onConfirm('muted', event, { tree: params.row })
              }>
              <Chip
                color={isMuted ? 'warning' : 'default'}
                size="small"
                label={isMuted ? 'ON' : 'OFF'}
              />
            </Button>

            <Button
              size="small"
              title={'Disabled Status'}
              onClick={(event) =>
                onConfirm('disabled', event, { tree: params.row })
              }>
              <Chip
                color={isDisabled ? 'warning' : 'default'}
                size="small"
                label={isDisabled ? 'ON' : 'OFF'}
              />
            </Button>
          </Stack>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 140,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const blockchain = params.row?.blockchain
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              title={
                blockchain ? 'Update to blockchain' : 'Register on blockchain'
              }
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={(event) =>
                onBlockchain(event.currentTarget, params.row)
              }>
              {blockchain ? (
                <Icons.BlockChainStatusOn
                  fill="currentColor"
                  style={{ width: 13 }}
                />
              ) : (
                <Icons.BlockChainRegistration
                  fill="currentColor"
                  style={{ width: 13 }}
                />
              )}
            </IconButton>

            <IconButton
              title="Edit Tree Details"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={(event) => onEdit(event.currentTarget, params.row)}>
              <Icons.ArticleOutlined fontSize="inherit" />
            </IconButton>

            <IconButton
              title="More Actions"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={(event) => onActions(event.currentTarget, params.row)}>
              <Icons.Menu fontSize="inherit" />
            </IconButton>
          </Stack>
        )
      }
    }
  ]
}
