import React, { useState, forwardRef, useEffect } from 'react'
import {
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  alpha,
  Alert
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'
import { getTreeImages, uploadTreeImage } from '@/service/manage/trees'
import { Tree } from '../trees.types'
import { styled } from '@mui/material/styles'

import PanoramaImage from '@/assets/images/management/tree-images/panorama.png'
import TrunkImage from '@/assets/images/management/tree-images/trunk.png'
import RootImage from '@/assets/images/management/tree-images/root.png'

import imageCompression from 'browser-image-compression'
import { useAppDispatch } from '@/store'
import { globalSlice } from '@/store/reducers/global/global.slice'
import moment from 'moment'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export type TreeImage = {
  recordedAt: Date
  image: string
}

export enum TreeImgUploadingStatusEnum {
  Waiting = 'WAITING',
  Pending = 'PENDING',
  Uploaded = 'UPLOADED',
  Failed = 'FAILED'
}

const UploadStatusText = {
  [TreeImgUploadingStatusEnum.Waiting]: 'Please select',
  [TreeImgUploadingStatusEnum.Pending]: 'Click to upload',
  [TreeImgUploadingStatusEnum.Uploaded]: 'Please select',
  [TreeImgUploadingStatusEnum.Failed]: 'Please select'
}

export type TreeImageItem = {
  recordedAt?: Date
  title: string
  status?: TreeImgUploadingStatusEnum
  src?: string
}

interface UploadImagesProps extends DialogProps {
  treeId: string
  onSuccess: (tree: Tree) => void
  onClose: () => void
}

export const getDefImages = (): TreeImageItem[] => {
  return [
    {
      title: 'Panorama',
      src: PanoramaImage,
      status: TreeImgUploadingStatusEnum.Waiting
    },
    {
      title: 'Trunk',
      src: TrunkImage,
      status: TreeImgUploadingStatusEnum.Waiting
    },
    {
      title: 'Root',
      src: RootImage,
      status: TreeImgUploadingStatusEnum.Waiting
    }
  ]
}

const TreeImages = forwardRef<typeof Dialog, UploadImagesProps>(
  ({ open, treeId, onSuccess, onClose, ...props }, ref) => {
    const dispatch = useAppDispatch()
    const [imagesInSystem, setImagesInSystem] = useState<TreeImage[]>([])
    const [newImages, setNewImages] = useState<TreeImageItem[]>(getDefImages())
    const [uploadingIndexes, setIsUploadingIndexes] = useState<number[]>([])

    const updateImageStatus = (
      idx: number,
      properties: {
        status?: TreeImgUploadingStatusEnum
        src?: string
        recordedAt?: Date
      }
    ) => {
      setNewImages((prevImages) => {
        const images = [...prevImages]
        images[idx] = {
          ...prevImages[idx],
          ...(properties.status && { status: properties.status }),
          ...(properties.src && { src: properties.src }),
          ...(properties.recordedAt && { recordedAt: properties.recordedAt })
        }
        return images
      })
    }

    const fetchTreeImages = async () => {
      const resp = await getTreeImages(treeId)
      if (resp?.code === 200) {
        setImagesInSystem(resp.data)
      }
    }

    const uploadImage = async (idx) => {
      setIsUploadingIndexes([...uploadingIndexes, idx])
      const resp = await uploadTreeImage(treeId, idx, newImages[idx].src)
      if (resp?.code === 200) {
        dispatch(
          globalSlice.actions.setNotification({
            type: 'success',
            caption: 'Image uploaded successfull'
          })
        )
        updateImageStatus(idx, { ...resp.data })
      }
      setIsUploadingIndexes(uploadingIndexes.filter((item) => item !== idx))
    }

    const addImages = async (idx, imageFile: File) => {
      if (!imageFile) {
        return
      }
      const compressedFile = await imageCompression(imageFile, {
        maxSizeMB: 1,
        useWebWorker: true
      })

      const fileReader = new FileReader()
      fileReader.readAsDataURL(compressedFile)
      fileReader.onload = (e) => {
        const imageUrl = e.target.result
        updateImageStatus(idx, {
          src: imageUrl as string,
          status: TreeImgUploadingStatusEnum.Pending
        })
      }
    }

    const revertTreeImage = (idx) => {
      updateImageStatus(idx, {
        src: imagesInSystem[idx]
          ? imagesInSystem[idx]?.image
          : getDefImages()[idx]?.src,
        recordedAt: imagesInSystem[idx]?.recordedAt,
        status: TreeImgUploadingStatusEnum.Uploaded
      })
    }

    useEffect(() => {
      setNewImages(getDefImages())
      setIsUploadingIndexes([])
      if (open) {
        fetchTreeImages()
      }
    }, [open])

    useEffect(() => {
      imagesInSystem.forEach((imageItem, idx) => {
        updateImageStatus(idx, {
          src: imageItem.image,
          recordedAt: imageItem.recordedAt,
          status: TreeImgUploadingStatusEnum.Uploaded
        })
      })
    }, [imagesInSystem])

    return (
      <Dialog open={open} maxWidth={'lg'} onClose={onClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              Tree Images
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            Please note that the size of each image should be less than 2 Mb
          </Alert>
          <ImageList sx={{ width: 800, height: 400 }} cols={3} rowHeight={400}>
            {newImages.map((item, idx) => {
              return (
                <ImageListItem key={idx} sx={{ bgcolor: grey[100] }}>
                  <img
                    style={{ width: '100%', marginTop: -20 }}
                    src={`${item?.src}`}></img>
                  <ImageListItemBar
                    title={item.title}
                    subtitle={
                      item.recordedAt
                        ? moment(item.recordedAt).format('YYYY-MM:DD HH:mm')
                        : UploadStatusText[item.status]
                    }
                    actionIcon={
                      uploadingIndexes.includes(idx) ? (
                        <IconButton
                          component="label"
                          sx={{
                            mr: 2,
                            color: 'white',
                            bgcolor: alpha(grey[500], 0.5)
                          }}>
                          <Icons.RotateRight
                            sx={{
                              animation: 'spin 0.6s linear infinite',
                              color: grey[300]
                            }}
                          />
                        </IconButton>
                      ) : (
                        <>
                          {[
                            TreeImgUploadingStatusEnum.Waiting,
                            TreeImgUploadingStatusEnum.Uploaded
                          ].includes(item.status) && (
                            <IconButton
                              component="label"
                              sx={{
                                mr: 2,
                                color: 'white',
                                bgcolor: alpha(grey[500], 0.5)
                              }}>
                              <Icons.ImageOutlined />
                              <VisuallyHiddenInput
                                type="file"
                                onChange={(evt) =>
                                  addImages(idx, evt.target.files[0])
                                }
                              />
                            </IconButton>
                          )}

                          {item.status ===
                            TreeImgUploadingStatusEnum.Pending && (
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                component="label"
                                sx={{
                                  color: 'white',
                                  bgcolor: alpha(grey[500], 0.5)
                                }}
                                onClick={() => uploadImage(idx)}>
                                <Icons.PublishOutlined />
                              </IconButton>
                              <IconButton
                                component="label"
                                sx={{ mr: 2, color: 'white' }}
                                onClick={() => revertTreeImage(idx)}>
                                <Icons.Close />
                              </IconButton>
                            </Stack>
                          )}
                        </>
                      )
                    }
                  />
                </ImageListItem>
              )
            })}
          </ImageList>
        </DialogContent>
      </Dialog>
    )
  }
)

TreeImages.displayName = 'TreeImages'
export default TreeImages
