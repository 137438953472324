import React, { forwardRef, useState, useImperativeHandle } from 'react'
import {
  Box,
  BoxProps,
  TextField,
  Autocomplete,
  Paper,
  Stack,
  Typography,
  Tooltip,
  MenuItem
} from '@mui/material'
import { useAppSelector } from '@/store'
import { createFilterOptions } from '@mui/material/Autocomplete'

import { Icons } from '@/assets/icons'
import AlertStatus from '../navigation/AlertStatus'
import { MapTreeType } from './map.types'

export type MapSearchOption = {
  id: string
  label: string
  isValidCoords: boolean
  maintenance: boolean
}

interface MapSearchHandlers {
  clear: () => void
}

interface MapSearchProps extends BoxProps {
  plain?: boolean
  trees?: MapTreeType[]
  maitenanceOn: boolean
  onSearch: (treeId: string) => void
  onClear: () => void
}

const MapSearch = forwardRef<MapSearchHandlers, MapSearchProps>(
  (
    { plain = false, trees, maitenanceOn, onSearch, onClear, ...props },
    ref
  ) => {
    let treesOnMap = useAppSelector((state) => state.map?.trees || [])

    if (trees) {
      treesOnMap = trees
    }
    const [value, setValue] = useState<MapSearchOption | null>(null)
    const clearValue = () => setValue(null)

    useImperativeHandle(ref, () => ({
      clear: clearValue
    }))

    const options = treesOnMap.map((t) => {
      const isValidCoords =
        t?.coordinates?.length === 2 &&
        t?.coordinates?.every((coord) => !isNaN(coord))
      return {
        id: t?.treeId,
        label: t?.userTreeId,
        isValidCoords,
        maintenance: t?.maintenance
      }
    })

    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          ...(!plain && {
            position: 'absolute',
            zIndex: 99,
            top: 15,
            left: 15
          })
        }}>
        <Paper
          sx={{
            width: 300,
            ...(plain && { bgcolor: 'transparent', boxShadow: 0 })
          }}>
          <Autocomplete
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={createFilterOptions({ limit: 10 })}
            options={options}
            renderOption={(props, option: MapSearchOption) => {
              return (
                <Tooltip
                  key={option.id}
                  title={
                    ((option.maintenance && !maitenanceOn) ||
                      !option.isValidCoords) && (
                      <Typography variant="body2">
                        {option.maintenance &&
                          !maitenanceOn &&
                          `Tree maintenance status On. Please turn on the maintenance
                        layer to location this tree on the map`}

                        {!option.isValidCoords && (
                          <>
                            {option.maintenance && !maitenanceOn && <br />}
                            This tree cannot be found on map because of its
                            coordinate issue
                          </>
                        )}
                      </Typography>
                    )
                  }>
                  <MenuItem
                    disabled={
                      !option.isValidCoords ||
                      (option.maintenance && !maitenanceOn)
                    }
                    component="li"
                    onClick={props.onClick}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>{option.label}</Typography>
                      {option.maintenance && (
                        <Icons.Build sx={{ opacity: 0.8 }} fontSize="inherit" />
                      )}
                      {!option.isValidCoords && (
                        <Icons.ErrorOutline fontSize="inherit" />
                      )}
                    </Stack>
                  </MenuItem>
                </Tooltip>
              )
            }}
            onChange={(evt, value, reason) => {
              if (reason === 'clear') {
                setValue(null)
                onClear()
                return
              }
              setValue(value)
              onSearch(value.id)
            }}
            renderInput={(params) => (
              <TextField
                variant={plain ? 'standard' : 'outlined'}
                placeholder="User Tree ID"
                size="small"
                margin={plain ? 'normal' : 'none'}
                {...params}
              />
            )}
          />
        </Paper>

        {!plain && (
          <Paper sx={{ p: 1 }}>
            <AlertStatus />
          </Paper>
        )}
      </Stack>
    )
  }
)

MapSearch.displayName = 'MapSearch'
export default MapSearch
