import { ChartColors } from './chart.const'
import { TemporatureChartData } from './chart.types'

export const getTemperatureOption = (data: TemporatureChartData) => {
  return {
    grid: {
      top: 20,
      left: 50,
      right: 20,
      bottom: 40
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: data.x,
      splitLine: {
        show: true,
        lineStyle: { type: 'dashed' }
      },
      axisLine: {
        lineStyle: { color: ChartColors.axisLine }
      },
      axisLabel: {
        show: true,
        align: 'left',
        fontSize: 12,
        padding: [0, 0, 0, -35],
        color: ChartColors.axisText,
        formatter: (value) => {
          const text = value.split(' ')
          return `${text[1]} \n${text[0]} `
        }
      }
    },
    yAxis: {
      type: 'value',
      max: Math.max(...data.y) * 1.2,
      splitLine: {
        show: true,
        lineStyle: { type: 'dashed' }
      },
      axisLabel: {
        color: '#303030',
        formatter: (value) => `${Math.round(value)}°C`
      }
    },
    series: [
      {
        name: 'Temperature (°C)',
        data: data.y,
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: {
          color: ChartColors.temperatureItem
        }
      }
    ]
  }
}
