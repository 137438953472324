import React, { forwardRef } from 'react'
import {
  Alert,
  Snackbar,
  SnackbarProps,
  Stack,
  Typography,
  Box
} from '@mui/material'

interface NotificationProps extends SnackbarProps {
  type: 'success' | 'error' | 'info' | 'warning'
  caption?: string | React.ReactNode
  desc?: string | React.ReactNode
  onClose?: () => void
}

const Notification = forwardRef<typeof Snackbar, NotificationProps>(
  (
    {
      open,
      anchorOrigin = { vertical: 'top', horizontal: 'right' },
      type,
      caption,
      desc,
      onClose,
      ...props
    },
    ref
  ) => {
    return (
      <Snackbar
        open={open}
        anchorOrigin={anchorOrigin}
        autoHideDuration={3000}
        onClose={onClose}
        ref={ref}>
        <Box sx={{ maxWidth: 420 }}>
          {open && (
            <Alert severity={type} sx={{ boxShadow: 3 }}>
              <Stack>
                {caption && <Typography variant="body2">{caption}</Typography>}
                {desc && (
                  <Typography sx={{ opacity: 0.6 }} variant="body2" noWrap>
                    {desc}
                  </Typography>
                )}
              </Stack>
            </Alert>
          )}
        </Box>
      </Snackbar>
    )
  }
)

Notification.displayName = 'Notification'
export default Notification
