import React, { forwardRef } from 'react'
import {
  Box,
  FormLabel,
  TextField,
  FormControlProps,
  FormControl
} from '@mui/material'
import { FormLabelWidth } from './form.cont'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'

interface FormDateProps extends FormControlProps {
  label: string
  value: Moment
  minDate?: Moment
  password?: boolean
  onValChange?: (string) => void
  labelWidth?: number
  tip?: string
  disabled?: boolean
}

const FormDate = forwardRef<typeof Box, FormDateProps>(
  (
    {
      sx,
      labelWidth,
      label,
      value,
      onValChange,
      minDate,
      tip,
      required,
      disabled = false,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          ...sx
        }}>
        <FormLabel
          sx={{ width: labelWidth ?? FormLabelWidth, mr: 2, flexShrink: 0 }}>
          {label}
        </FormLabel>

        <Box sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled={disabled}
              value={value}
              minDate={minDate}
              slotProps={{
                textField: { size: 'small', helperText: tip, error: error }
              }}
              onChange={(date) => onValChange(date)}
            />
          </LocalizationProvider>
        </Box>
      </FormControl>
    )
  }
)

FormDate.displayName = 'FormDate'
export default FormDate
