import React, { forwardRef } from 'react'
import {
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  FormControlProps,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { FormLabelWidth } from './form.cont'
import { FormOption } from './form.typs'
import { Icons } from '@/assets/icons'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3]
  }
}))

interface FormRadioProps extends FormControlProps {
  label: string
  labelTip?: string | React.ReactNode
  labelWidth?: number
  value: string
  tip?: string | React.ReactNode
  options: FormOption[]
  onValChange?: (string) => void
}

const FormRadio = forwardRef<typeof FormControl, FormRadioProps>(
  (
    {
      label,
      labelWidth,
      value,
      onValChange,
      required,
      tip,
      options,
      labelTip,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
        <FormLabel
          focused={false}
          sx={{ width: labelWidth ?? FormLabelWidth, mr: 2, flexShrink: 0 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{label}</Typography>
            {labelTip && (
              <LightTooltip
                title={
                  <Typography
                    component="div"
                    variant="caption"
                    color={'rgba(0, 0, 0, 0.6)'}>
                    {labelTip}
                  </Typography>
                }>
                <Icons.HelpOutline fontSize="inherit" />
              </LightTooltip>
            )}
          </Stack>
        </FormLabel>
        <Stack sx={{ flexGrow: 1 }}>
          <RadioGroup
            row
            value={value}
            onChange={(event) => onValChange(event.target.value)}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio size="small" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {tip && (
            <Typography
              component="div"
              variant="caption"
              color={'rgba(0, 0, 0, 0.6)'}
              ml={1}
              mr={1}>
              {tip}
            </Typography>
          )}
        </Stack>
      </FormControl>
    )
  }
)

FormRadio.displayName = 'FormRadio'
export default FormRadio
