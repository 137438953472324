import React from 'react'

import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { Layout, Header, Body, Menu } from '@/components/page'
import TipIcon from '@/components/TipIcon'
import { Icons } from '@/assets/icons'
import RootLayout from '../../components/page/RootLayout'

const Data = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <RootLayout>
      <Layout>
        <Header>
          <Box>
            <Menu
              active={Boolean(matchPath(location?.pathname, '/data/trees'))}
              icon={<Icons.Nature />}
              onClick={() => navigate('trees')}>
              Trees
            </Menu>
            <Menu
              active={Boolean(
                matchPath(location?.pathname, '/data/daily-report')
              )}
              icon={<Icons.Email />}
              onClick={() => navigate('daily-report')}>
              Daily Report
              <sup style={{ marginLeft: 1 }}>
                <TipIcon
                  title={
                    <Typography variant="body2">
                      The automated daily report reveals the general information
                      of the sensor status, including tilting angle alert,
                      sensor vibration and sensor connecting status over the
                      past 24 hours. The angle alerts will be analysed to
                      indicate if there is any repeated or disappeared data,
                      helping you out to monitor tree status more easily by the
                      click-to-go platform. The report will be generated in
                      every 8AM where you could review the report in the
                      registered emails by anytimes and anywhere.
                    </Typography>
                  }>
                  <Icons.HelpOutline fontSize="inherit" />
                </TipIcon>
              </sup>
            </Menu>
            <Menu
              active={Boolean(
                matchPath(location?.pathname, '/data/special-alert')
              )}
              icon={<Icons.NotificationsActive />}
              onClick={() => navigate('special-alert')}>
              Special Alert
            </Menu>
          </Box>
        </Header>
        <Body>
          <Outlet />
        </Body>
      </Layout>
    </RootLayout>
  )
}

Data.displayName = 'Data'
export default Data
