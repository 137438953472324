import React, { FC } from 'react'
import { Stack, Typography, Tooltip, Box } from '@mui/material'
import moment from 'moment'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'
import { FieledNames } from '@/routes/map/details/details.const'

interface IPropTypes {
  name: string
  value: string | number
  tip?: string
  type?: 'number' | 'string' | 'date'
}

const InfoItem: FC<IPropTypes> = ({ name, value, type = 'string', tip }) => {
  return (
    <Stack direction="row">
      <Stack
        direction="row"
        sx={{ width: 160, flexShrink: 0 }}
        alignItems="center"
        spacing={1}>
        <Typography variant="body2">{FieledNames[name]}</Typography>
        {tip && (
          <Tooltip title={tip}>
            <Icons.HelpOutline sx={{ color: grey[400] }} fontSize="inherit" />
          </Tooltip>
        )}
      </Stack>

      <Stack
        sx={{ flexGrow: 1 }}
        direction="row"
        alignItems="center"
        color={value === undefined ? grey[400] : 'inherit'}>
        <Typography variant="body2">
          {type === 'date' &&
            (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-')}
          {type === 'number' && value}
          {type === 'string' &&
            (value?.toString().length < 30 ? (
              value
            ) : (
              <Tooltip title={value}>
                <span>{value?.toString().substring(0, 27) + '...'}</span>
              </Tooltip>
            ))}
          {Units[name]}
        </Typography>

        {name === 'azimuth' && (
          <Icons.Compass style={{ transform: `rotate(${value}deg)` }} />
        )}
      </Stack>
    </Stack>
  )
}

export default InfoItem

export const Units = {
  height: 'm',
  dbh: 'm',
  crownSpread: 'm',
  voltage: 'V',
  tiltingAngle: '°',
  tiltingAngleSmp: '°',
  azimuth: '°'
}
