import React, { forwardRef, useState } from 'react'
import { Box, BoxProps } from '@mui/material'
import TipIconBtn from '@/components/TipIconBtn'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'
import { exportTreeTiltingData } from '@/service/map'
import { downloadFromFile } from '@/utils/tool'
import moment from 'moment'

interface DownloadProps extends BoxProps {
  treeId: string
  userTreeId: string
  period: [Date, Date]
}

const Download = forwardRef<typeof Box, DownloadProps>(
  ({ treeId, userTreeId, period, ...props }, ref) => {
    const [downloading, setDownloading] = useState(false)

    const download = async () => {
      if (downloading) {
        return
      }
      const resp = (await exportTreeTiltingData(
        treeId,
        userTreeId,
        period.map((p) => p.getTime())
      )) as unknown as Response

      downloadFromFile(
        await resp.blob(),
        `${userTreeId ? userTreeId : treeId}_Tilting-Data_${moment(
          period[0]
        ).format('YYYYMMDD')}_to_${moment(period[1]).format('YYYYMMDD')}.zip`
      )
      setDownloading(false)
    }
    return (
      <TipIconBtn
        tip={downloading ? 'Downloading' : 'Download data in selected period'}
        onClick={download}
        disabled={downloading}>
        {downloading ? (
          <Icons.RotateRight
            fontSize="small"
            sx={{
              animation: 'spin 0.6s linear infinite',
              color: grey[300]
            }}
          />
        ) : (
          <Icons.DownloadOutlined fontSize="small" />
        )}
      </TipIconBtn>
    )
  }
)

Download.displayName = 'Download'
export default Download
