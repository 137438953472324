export type HeadersType = Record<string, any>

export type ContentTypes = 'json' | 'zip'

export enum MethodEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}


export interface IError {
    name: string,
    message: string,
    expiredAt: string
}


export interface IRequestParams<T> {
    data?: T,
    headers?: HeadersType,
    showTips?: boolean
}

export interface UnifiedRespose<T> {
    code: number,
    data?: T,
    error?: string
    message?: string
}