import React, { forwardRef } from 'react'
import { Menu, MenuProps, MenuItem, Typography } from '@mui/material'
import { Group } from '../groups/group.types'
import { changeGroup } from '@/service/settings/users'
import { User } from '@/routes/manage/user/users.types'

interface ActionMenuProps extends MenuProps {
  user: User
  groups: Group[]
  onClose: () => void
  onSuccess: (data: User) => void
}

const ChangeGroup = forwardRef<typeof Menu, ActionMenuProps>(
  ({ anchorEl, open, user, groups, onClose, onSuccess, ...props }, ref) => {
    const updateGroup = async (group: Group) => {
      const resp = await changeGroup(user.id, group.id)
      if (resp?.code === 200) {
        onSuccess(resp.data)
      }
    }
    return (
      <Menu
        sx={{ maxHeight: 360 }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => onClose()}>
        {groups?.map((g) => {
          return (
            <MenuItem
              disabled={user?.group.groupId === g.id}
              key={g.id}
              onClick={() => updateGroup(g)}>
              <Typography
                color={
                  g.id === user?.group.groupId ? 'primary.main' : 'inherit'
                }>
                {g.name}
              </Typography>
            </MenuItem>
          )
        })}
      </Menu>
    )
  }
)

ChangeGroup.displayName = 'ChangeGroup'
export default ChangeGroup
