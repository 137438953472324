import {
  HkoWeatherWarningStatementCodeEnum,
  HkoWeatherWarningSubtypeCodeEnum,
  WeatherWarningCodes
} from './mode.types'

export const WeatherWarningToText: Record<
  HkoWeatherWarningSubtypeCodeEnum | HkoWeatherWarningStatementCodeEnum,
  { en: string; tc: string }
> = {
  [WeatherWarningCodes.WTCSGNL]: {
    en: 'Tropical Cyclone Warning Signals',
    tc: '熱帶氣旋警告信號'
  },
  [WeatherWarningCodes.WMSGNL]: {
    en: 'Strong Monsoon Signal',
    tc: '強烈季候風信號'
  },
  [WeatherWarningCodes.WTS]: {
    en: 'Thunderstorm Warning',
    tc: '雷暴警告'
  },
  [WeatherWarningCodes.WL]: {
    en: 'Landslip Warning',
    tc: '山泥傾瀉警告'
  },
  [WeatherWarningCodes.WFIRE]: {
    en: 'Fire Danger Warning',
    tc: '火災危險警告'
  },
  [WeatherWarningCodes.WFIREY]: {
    en: 'Yellow Fire Danger Warning',
    tc: '黃色火災危險警告'
  },
  [WeatherWarningCodes.WFIRER]: {
    en: 'Red Fire Danger Warning',
    tc: '紅色火災危險警告'
  },
  [WeatherWarningCodes.WHOT]: {
    en: 'Very Hot Weather Warning',
    tc: '	酷熱天氣警告'
  },
  [WeatherWarningCodes.WCOLD]: {
    en: 'Cold Weather Warning',
    tc: '	寒冷天氣警告'
  },
  [WeatherWarningCodes.WFROST]: {
    en: 'Frost Warning',
    tc: '霜凍警告'
  },
  [WeatherWarningCodes.WTCPRE8]: {
    en: 'Pre-no.8 Special Announcement',
    tc: '預警八號熱帶氣旋警訊之特別報告'
  },
  [WeatherWarningCodes.WTMW]: {
    en: 'Tsunami Warning',
    tc: '海嘯警告'
  },
  [WeatherWarningCodes.CANCEL]: {
    en: 'Cancel All Signals',
    tc: '取消所有熱帶氣旋警訊'
  },

  // Mode 2
  [WeatherWarningCodes.TC1]: {
    en: 'Typhoon Signal No.1',
    tc: '一號戒備信號'
  },
  [WeatherWarningCodes.WRAINA]: {
    en: 'Amber Rainstorm Warning Signal',
    tc: '黃色暴雨警告信號'
  },
  [WeatherWarningCodes.WFNTSA]: {
    en: 'Specail Announcement On Flooding In Northern New Teritorries',
    tc: '新界北部水浸特別報告'
  },

  // Mode 3
  [WeatherWarningCodes.TC3]: {
    en: 'Typhoon Signal No.3',
    tc: '三號強風信號'
  },
  [WeatherWarningCodes.WRAINR]: {
    en: 'Red Rainstrom Warning Signal',
    tc: '紅色暴雨警告信號'
  },

  // Mode 4
  [WeatherWarningCodes.TC8NE]: {
    en: 'No. 8 Northeast Gale or Storm SignalTropical Cyclone Warning Signal',
    tc: '八號東北烈風或暴風信號'
  },
  [WeatherWarningCodes.TC8SE]: {
    en: 'No. 8 Southeast Gale or Storm SignalTropical Cyclone Warning Signal',
    tc: '八號東南烈風或暴風信號'
  },
  [WeatherWarningCodes.TC8SW]: {
    en: 'No. 8 Southwest Gale or Storm SignalTropical Cyclone Warning Signal',
    tc: '八號西南烈風或暴風信號'
  },
  [WeatherWarningCodes.TC8NW]: {
    en: 'No. 8 Northwest Gale or Storm SignalTropical Cyclone Warning Signal',
    tc: '八號西北烈風或暴風信號'
  },
  [WeatherWarningCodes.TC9]: {
    en: 'Increasing Gale Or Storm Signal No. 9',
    tc: '九號烈風或暴風風力增強信號'
  },
  [WeatherWarningCodes.TC10]: {
    en: 'Hurricane Signal No. 10',
    tc: '十號颶風信號'
  },
  [WeatherWarningCodes.WRAINB]: {
    en: 'Black Rainstrom Warning Signal',
    tc: '黑色暴雨警告信號'
  }
}

export const WeatherWarningIcons = {
  [WeatherWarningCodes.WFIREY]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/firey.gif',
  [WeatherWarningCodes.WFIRE]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/firer.gif',
  [WeatherWarningCodes.WFIRER]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/firer.gif',
  [WeatherWarningCodes.WFROST]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/frost.gif',
  [WeatherWarningCodes.WHOT]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/vhot.gif',
  [WeatherWarningCodes.WCOLD]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/cold.gif',
  [WeatherWarningCodes.WFNTSA]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/ntfl.gif',
  [WeatherWarningCodes.WRAINA]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/raina.gif',
  [WeatherWarningCodes.WRAINR]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/rainr.gif',
  [WeatherWarningCodes.WRAINB]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/rainb.gif',
  [WeatherWarningCodes.WMSGNL]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/sms.gif',
  [WeatherWarningCodes.WL]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/landslip.gif',
  [WeatherWarningCodes.TC1]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc1.gif',
  [WeatherWarningCodes.TC3]:
    'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc3.gif',
  [WeatherWarningCodes.TC8NE]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc8ne.gif',
  [WeatherWarningCodes.TC8SE]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc8b.gif',
  [WeatherWarningCodes.TC8SW]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc8c.gif',
  [WeatherWarningCodes.TC8NW]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc8d.gif',
  [WeatherWarningCodes.TC9]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc9.gif',
  [WeatherWarningCodes.TC10]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tc10.gif',
  [WeatherWarningCodes.WTMW]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/tsunami-warn.gif',
  [WeatherWarningCodes.WTS]:
    'http://www.hko.gov.hk/wxinfo/dailywx/images_e/ts.gif'
}
