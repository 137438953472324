import requestRaw from 'axios'
import { rhrRead, warnSum } from '@/routes/home/weather/weather.const'
import request from '../request'
import { ModeInfo } from '@/routes/home/mode/mode.types'
import { CarbonType } from '@/routes/home/Carbon'
import { Notification } from '@/routes/home/notifications/notifications.types'
import { WindSpeed } from '@/routes/home/weather/weather.types'
import { Alert, AlertResp } from '@/store/reducers/home/home.types'

export const getRhrReadRead = () => {
  return requestRaw.get(rhrRead)
}
export const getFlw = () => {
  return requestRaw.get(warnSum)
}

export const getWindSpeed = () => {
  return request.get<WindSpeed>('home/wind-speed')
}

export const getNotifications = () => {
  return request.get<Notification[]>('home/notifications')
}

export const getCarbonUptakes = () => {
  return request.get<CarbonType>('home/carbon-uptake')
}

export const getMode = () => {
  return request.get<ModeInfo>('home/mode')
}

export const getAlerts = () => {
  return request.get<AlertResp[]>('home/alerts')
}

export const getTreeAlerts = (treeId?: string) => {
  return request.get<Alert[]>('home/tree-alerts', { data: { treeId } })
}
