import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  IconButton,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Select,
  Box,
  PopoverProps,
  Tooltip
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { Controller, useForm } from 'react-hook-form'
import {
  addMaintenanceRecord,
  getTreeMaintenance,
  revokeTreeMaintenance
} from '@/service/manage/trees'
import { TreeMaintenance, TreeMaintenanceTypeEnum } from '../trees.types'
import FormTextArea from '@/components/form/FormTextArea'
import FormSelect from '@/components/form/FormSelect'
import FormDate from '@/components/form/FormDate'
import TipIconBtn from '@/components/TipIconBtn'
import moment from 'moment'
import { grey } from '@mui/material/colors'
import Confirm from '@/components/Confirm'

const TreeMaintenanceTypes = [
  {
    value: TreeMaintenanceTypeEnum.SENSOR_INSTALLATION,
    label: 'New Installation'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_REPLACEMENT,
    label: 'Sensor Replacement'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_MALFUNCTION,
    label: 'Sensor Malfunction'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_DAMAGE,
    label: 'Sensor Damage'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_LOOSE,
    label: 'Sensor Loose'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_REINSTALLATION,
    label: 'Sensor Re-installation'
  },
  {
    value: TreeMaintenanceTypeEnum.SENSOR_DISMANTLE,
    label: 'Sensor Dismantle'
  },
  {
    value: TreeMaintenanceTypeEnum.OTHERS,
    label: 'Others'
  }
]
const DefaultMaintenance: TreeMaintenance = {
  type: null,
  startedAt: null,
  endedAt: null,
  remarks: ''
}

interface MaintenanceProps extends DrawerProps {
  treeId: string
  onSuccess: () => void
  onClose: () => void
}

const Maintenance = forwardRef<typeof Drawer, MaintenanceProps>(
  ({ open, onClose, onSuccess, treeId, ...props }, ref) => {
    const { control, reset, setValue, watch, handleSubmit, formState } =
      useForm<TreeMaintenance>({
        defaultValues: DefaultMaintenance
      })

    const [confirmParams, setConfirmParams] = useState<{
      anchor: PopoverProps['anchorEl']
      maitenance: TreeMaintenance
    }>(null)

    const [maintenances, setMaintenances] = useState<TreeMaintenance[]>([])

    const startedAtWatch = watch('startedAt')

    const updateMaintenance = (maintenance: TreeMaintenance) => {
      const newMaintenance = [...maintenances]
      const index = newMaintenance.findIndex((m) => m._id === maintenance._id)

      if (index > -1) {
        newMaintenance[index] = maintenance
        setMaintenances(newMaintenance)
      }
    }

    const fetchMaitenance = async () => {
      const resp = await getTreeMaintenance(treeId)
      if (resp?.code === 200) {
        setMaintenances(resp.data)
      }
    }

    const revoke = async (maintenanceId: string) => {
      const resp = await revokeTreeMaintenance(treeId, maintenanceId)
      if (resp?.code === 200) {
        updateMaintenance(resp.data)
        setConfirmParams(null)
      }
    }

    const onSubmit = () => {
      handleSubmit(async (values) => {
        const resp = await addMaintenanceRecord(treeId, values)
        if (resp?.code === 200) {
          setMaintenances([resp.data, ...maintenances])
          reset()
          onSuccess()
        }
      })()
    }

    useEffect(() => {
      if (open && treeId) {
        fetchMaitenance()
        reset(DefaultMaintenance)
      }
    }, [treeId, open])

    return (
      <Drawer open={open} anchor="right" onClose={onClose}>
        <Stack sx={{ width: 580, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2, flexShrink: 0 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              {treeId} Maintenance Record
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Stack
            sx={{ overflow: 'auto' }}
            flexGrow={1}
            justifyContent="space-between"
            spacing={1}>
            <Divider />
            <Stack
              flexGrow={1}
              justifyContent="flex-start"
              sx={{ width: 1, overflow: 'auto', px: 2 }}>
              {maintenances.map((item) => {
                return (
                  <Stack
                    key={item?._id}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      borderBottom: '1px solid #dddddd',
                      '&:hover': { bgcolor: grey[100] },
                      transition: 'background-color 0.3s ease-in-out'
                    }}>
                    <Stack>
                      <Typography variant="caption" color={grey[400]}>
                        Recorded At{' '}
                        {moment(item.recordedAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>

                      <Stack direction="row" alignItems={'center'} spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="subtitle1">
                            {
                              TreeMaintenanceTypes.find(
                                (m) => m?.value === item?.type
                              )?.label
                            }
                          </Typography>

                          <Typography variant="caption" color={grey[600]}>
                            Period {moment(item.startedAt).format('YYYY-MM-DD')}~{moment(item.endedAt).format('YYYY-MM-DD')}
                          </Typography>
                        </Stack>

                        {item.remarks && (
                          <Tooltip title={item.remarks}>
                            <Icons.InfoOutlined
                              fontSize="inherit"
                              sx={{ color: grey[600] }}
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    </Stack>

                    <Button
                      size="small"
                      disabled={item.revoked}
                      onClick={(event) =>
                        setConfirmParams({
                          anchor: event.currentTarget,
                          maitenance: item
                        })
                      }>
                      {item.revoked ? 'Revoked' : 'Revoke'}
                    </Button>
                  </Stack>
                )
              })}
            </Stack>
            <Divider />
            <Stack sx={{ pb: 1, pl: 3, pr: 3 }} spacing={1}>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <FormSelect
                    required
                    labelWidth={200}
                    error={Boolean(fieldState.error)}
                    label="Maintenance Type"
                    value={field.value || ''}
                    options={TreeMaintenanceTypes}
                    onValChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="startedAt"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <FormDate
                    required
                    labelWidth={200}
                    error={Boolean(fieldState.error)}
                    label="Start Day"
                    value={field.value}
                    onValChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="endedAt"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems={'center'}>
                    <FormDate
                      required
                      labelWidth={200}
                      error={Boolean(fieldState.error)}
                      label="End Day"
                      value={field.value}
                      onValChange={field.onChange}
                    />

                    {startedAtWatch && (
                      <Box>
                        <TipIconBtn
                          tip="Use start day"
                          size="small"
                          onClick={() => setValue('endedAt', startedAtWatch)}>
                          <Icons.Toll fontSize="inherit" />
                        </TipIconBtn>
                      </Box>
                    )}
                  </Stack>
                )}
              />
              <Controller
                name="remarks"
                control={control}
                rules={{ maxLength: 240 }}
                render={({ field, fieldState }) => (
                  <FormTextArea
                    height={80}
                    labelWidth={200}
                    error={Boolean(fieldState.error)}
                    label="Remark"
                    value={field.value}
                    placeholder="Optional"
                    onValChange={field.onChange}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{ pl: 2, pr: 2, pb: 2, flexShrink: 0 }}
              spacing={2}
              justifyContent="space-between"
              alignItems="center">
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={!formState.isDirty}
                  variant="contained"
                  onClick={() => onSubmit()}>
                  Submit
                </Button>
                <Button variant="text" onClick={() => onClose()}>
                  Cancel
                </Button>
              </Stack>
              {!formState.isDirty && (
                <Typography variant="caption" sx={{ opacity: 0.7 }}>
                  * Cannot submit because no content is modified
                </Typography>
              )}
            </Stack>
          </Stack>

          <Confirm
            open={Boolean(confirmParams?.anchor)}
            anchorEl={confirmParams?.anchor}
            message={
              'This operation is permenent. Are you sure to revoke this maintenance record?'
            }
            onConfirm={() => revoke(confirmParams?.maitenance?._id)}
            onClose={() => setConfirmParams(null)}
          />
        </Stack>
      </Drawer>
    )
  }
)

Maintenance.displayName = 'Maintenance'
export default Maintenance
