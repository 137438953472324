import { createAsyncThunk } from '@reduxjs/toolkit'
import { CatchErrors } from '../reducer.helper'
import { getTrees, getTreeTotalcount } from '@/service/map'
import { TimeValidationError } from '@mui/x-date-pickers'
import { MapTreeType } from '@/routes/map/map.types'
import { mapSlice } from './map.slice'
import { getMapTreesFromRes } from './map.helper'

export const getTreesAsync = createAsyncThunk<
  MapTreeType[],
  void,
  { rejectValue: TimeValidationError }
>('map/getTreesAsync', async (params, { dispatch, rejectWithValue }) => {
  try {
    const mapTrees = []
    let totalCount = 0
    let limit = 5
    const totalCountResp = await getTreeTotalcount()

    if (totalCountResp?.code !== 200) {
      return []
    }
    totalCount = totalCountResp?.data
    dispatch(mapSlice.actions.setTreeTotalcount(totalCount))

    const totalPage = Math.ceil(totalCount / limit)
    for (let i = 0; i < totalPage; i++) {
      const page = i + 1
      const respItem = await getTrees({ page, limit })
      if (respItem?.code !== 200) {
        continue
      }
      mapTrees.push(
        ...respItem?.data?.trees.map((tree) => getMapTreesFromRes(tree))
      )
      dispatch(mapSlice.actions.setTreeLoadedCount(mapTrees.length))
    }
    return mapTrees
  } catch (error) {
    return CatchErrors(error, rejectWithValue)
  }
})
