import React, { forwardRef } from 'react'
import { Box, Tooltip, BoxProps, Typography, alpha } from '@mui/material'
import moment from 'moment'
import { ModeInfo } from './mode.types'
import { Icons } from '@/assets/icons'
import { WeatherWarningToText } from './mode.const'

interface CurrentWarningProps extends BoxProps {
  mode: ModeInfo
}

const CurrentWarning = forwardRef<typeof Tooltip, CurrentWarningProps>(
  ({ mode, ...props }, ref) => {
    return (
      <Tooltip
        title={
          <Box sx={{ padding: '5px' }}>
            <Typography sx={{ m: 0 }} variant="body2" paragraph>
              {`Updated at ${moment(mode?.recordedAt).format(
                'YYYY-MM-DD HH:mm:ss'
              )} `}
            </Typography>
            <Typography sx={{ m: 0 }} variant="body2" paragraph>
              {mode?.warningCodes?.length === 0 && `No warning signals`}
              {mode?.warningCodes?.length > 0 && (
                <>
                  Weather signals
                  {mode?.warningCodes?.map((code) => {
                    return (
                      <Typography
                        sx={{
                          ml: 0.5,
                          mr: 0.5,
                          textDecoration: 'underline'
                        }}
                        variant="body2"
                        component={'i'}
                        key={code}>
                        {WeatherWarningToText[code]?.en}
                      </Typography>
                    )
                  })}
                </>
              )}
            </Typography>
          </Box>
        }>
        {mode?.warningCodes?.length === 0 ? (
          <Icons.SentimentSatisfiedAlt></Icons.SentimentSatisfiedAlt>
        ) : (
          <Icons.Error fontSize="small" sx={{ color: alpha('#ffffff', 0.8) }} />
        )}
      </Tooltip>
    )
  }
)

CurrentWarning.displayName = 'CurrentWarning'
export default CurrentWarning
