import React, { forwardRef } from 'react'
import { Divider, Menu, MenuItem, MenuProps, PopoverProps } from '@mui/material'
import { Organization } from '../organization.types'
import { useAppSelector } from '@/store'

interface ActionsProps extends MenuProps {
  data: Organization
  onClose: () => void
  onDelete: (anchor: PopoverProps['anchorEl'], data: Organization) => void
}

const Actions = forwardRef<typeof Menu, ActionsProps>(
  ({ anchorEl, open, data, onClose, onDelete, ...props }, ref) => {
    const user = useAppSelector((state) => state.users.user)
    return (
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem disabled={data?.isLeafiot} key="delete" onClick={() => onDelete(anchorEl, data)}>
          Delete
        </MenuItem>
      </Menu>
    )
  }
)

Actions.displayName = 'Actions'
export default Actions
