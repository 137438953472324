import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  Button,
  IconButton
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { DefaultNewUser, RoleNames } from '@/routes/manage/user/users.const'
import { useForm, Controller } from 'react-hook-form'
import { createUser } from '@/service/settings/users'
import { encryptPassword } from '@/routes/login/login.helper'
import FormInput from '@/components/form/FormInput'
import FormRadio from '@/components/form/FormRadio'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import {
  RolesEnum,
  NewUser as NewUserType,
  GroupOptions
} from '@/routes/manage/user/users.types'
import { getGroups } from '@/service/settings/users'

interface NewUserProps extends DrawerProps {
  onClose: () => void
  onSuccess: () => void
}

const NewUser = forwardRef<typeof Drawer, NewUserProps>(
  ({ open, onClose, onSuccess, ...props }, ref) => {
    const [groups, setGroups] = useState<GroupOptions[]>([])

    const { control, setValue, handleSubmit, watch, reset } = useForm({
      defaultValues: { ...DefaultNewUser }
    })

    const watchRole = watch('role')

    const submitNewUser = () => {
      handleSubmit(async (values: NewUserType) => {
        const newUser = {
          ...values,
          password: encryptPassword(values.password)
        }
        const resp = await createUser(newUser)
        if (resp?.code === 200) {
          onSuccess()
          reset(DefaultNewUser)
        }
      })()
    }
    useEffect(() => {
      if (open) {
        getGroups().then((resp) => {
          if (resp?.code === 200) {
            const groupOptions = resp.data.map((g) => ({
              name: g.name,
              id: g.id,
              isDefault: g.isDefault
            }))
            setGroups(groupOptions)

            if (groupOptions?.length === 1) {
              setValue('groupId', groupOptions[0].id)
            }
          }
        })
      } else {
        setGroups([])
      }
    }, [open])

    useEffect(() => {
      if (watchRole === RolesEnum.Admin && groups?.length > 0) {
        const defaultGroup = groups.find((g) => g.isDefault)
        setValue('groupId', defaultGroup.id)
      }
    }, [watchRole, groups])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack sx={{ width: 1, height: 1, overflow: 'hidden' }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              New User
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
          {open && (
            <Stack sx={{ flexGrow: 1, p: 2, maxWidth: 600, overflow: 'auto' }}>
              <Stack spacing={2}>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                    maxLength: 16
                  }}
                  render={({ field, fieldState }) => (
                    <FormInput
                      required
                      error={Boolean(fieldState.error)}
                      label="User name"
                      helperText="Min length 8, max length 16"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Nick name"
                      helperText={
                        'Nick name will replace user name as display name to show in the system'
                      }
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                    maxLength: 16
                  }}
                  render={({ field, fieldState }) => (
                    <FormInput
                      required
                      password
                      error={Boolean(fieldState.error)}
                      label="Password"
                      helperText="Min lenght 8, max length 16, Special character support !@#$%^&*()_+"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="role"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormRadio
                      label="Role"
                      options={[
                        {
                          label: RoleNames[RolesEnum.Admin],
                          value: RolesEnum.Admin
                        },
                        {
                          label: RoleNames[RolesEnum.Manager],
                          value: RolesEnum.Manager
                        },
                        {
                          label: RoleNames[RolesEnum.User],
                          value: RolesEnum.User
                        }
                      ]}
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="groupId"
                  control={control}
                  rules={{ required: true }}
                  disabled={watchRole === RolesEnum.Admin}
                  render={({ field }) => (
                    <FormSelect
                      label="Group"
                      disabled={field.disabled}
                      options={groups.map((g) => ({
                        label: g.name,
                        value: g.id
                      }))}
                      value={field.value}
                      onValChange={field.onChange}
                      tip={
                        watchRole === RolesEnum.Admin && (
                          <Typography color={'warning.main'} variant="caption">
                            Admin user can only be assigned to default group
                          </Typography>
                        )
                      }
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Email"
                      helperText="Email to receive notification from LeafIot"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Phone"
                      helperText="Phone to receive alert massage from LeafIot"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="disabled"
                  control={control}
                  render={({ field }) => (
                    <FormSwitch
                      label="Disable"
                      tip="User's access will be suspended after creation"
                      value={field.value}
                      onValChange={field.onChange}
                    />
                  )}
                />
              </Stack>
            </Stack>
          )}
          <Stack
            sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
            direction="row"
            flexShrink={0}
            spacing={2}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => submitNewUser()}>
              Confirm
            </Button>
            <Button variant="text" onClick={() => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

NewUser.displayName = 'NewUser'
export default NewUser
