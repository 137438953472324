import React, { forwardRef, useEffect, useState } from 'react'
import {
  Popover,
  PopoverProps,
  Stack,
  TextField,
  Tooltip,
  Button,
  Typography,
  Box
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { LoadingButton } from '@mui/lab'
import { Tree, TreeResetTypeEnum } from './trees.types'
import { getDecimal } from '@/utils/tool'
import { grey } from '@mui/material/colors'
import moment from 'moment'
import { resetTree } from '@/service/manage/trees'

export type ResetType = 'tilting' | 'azimuth'

export const ResetTitle = {
  [TreeResetTypeEnum.TILTING]: 'Tilting',
  [TreeResetTypeEnum.AZIMUTH]: 'Azimuth'
}

interface ResetProps extends PopoverProps {
  type: TreeResetTypeEnum
  tree?: Tree
  onClose: () => void
  onSuccess: (tree: Tree) => void
}

const Reset = forwardRef<typeof Popover, ResetProps>(
  ({ type, open, anchorEl, tree, onSuccess, onClose, ...props }, ref) => {
    const currReset = tree?.resets?.find((r) => r.type === type)
    const [resetValue, setResetValue] = useState<number>(0)
    const [isResetting, setIsResseting] = useState(false)

    const updateReset = async () => {
      setIsResseting(true)
      const resp = await resetTree(tree.treeId, { type, value: resetValue })
      if (resp?.code === 200) {
        onSuccess(resp.data)
        onClose()
      }
      setIsResseting(false)
    }

    useEffect(() => {
      if (open) {
        const newResetValue =
          type === TreeResetTypeEnum.TILTING
            ? tree?.tiltingData?.tiltingAngle
            : tree?.tiltingData?.azimuth
        setResetValue(getDecimal(newResetValue || 0, 2))
      } else {
        setResetValue(0)
        setIsResseting(false)
      }
    }, [open, tree])

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onClose}>
        <Stack
          direction="row"
          alignItems={'center'}
          sx={{ pl: 2, pt: 2, pr: 2 }}
          spacing={1}>
          <Typography variant="h6">{ResetTitle[type]} Reset</Typography>

          <Tooltip
            title={
              <Typography fontSize="inherit">
                Reset action is to change the inital leaning angle of tree. The
                auto-filled value is the current tilting angle of selected tree.
                You can input any value propriate to make the tilting angle
                right.
              </Typography>
            }>
            <Box sx={{ color: grey[500] }}>
              <Icons.ErrorOutline
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}></Icons.ErrorOutline>
            </Box>
          </Tooltip>
        </Stack>

        <Stack spacing={2} sx={{ p: 2 }} direction="row">
          <TextField
            label={<Typography variant="h6">Current reset value</Typography>}
            variant="standard"
            value={getDecimal(currReset?.value, 2)}
            inputProps={{ disabled: true }}
            helperText={
              currReset?.recordedAt &&
              `Updated at ${moment(currReset.recordedAt).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`
            }
          />

          <TextField
            inputRef={(input) => input && input.focus()}
            label={<Typography variant="h6">New reset value</Typography>}
            variant="standard"
            type="number"
            inputProps={{ step: 0.1 }}
            value={parseFloat(resetValue.toString())}
            onFocus={(event) => event.target.select()}
            onChange={(event) => {
              const targetStr = event.target.value

              const pointIdx = targetStr.indexOf('.')
              let targetValue = parseFloat(
                pointIdx > -1 ? targetStr.substring(0, pointIdx + 2) : targetStr
              )

              if (type === TreeResetTypeEnum.TILTING) {
                targetValue > 90 && (targetValue = 90)
              }
              if (type === TreeResetTypeEnum.AZIMUTH) {
                targetValue > 360 && (targetValue = 360)
              }

              setResetValue(targetValue)
            }}
            helperText={`Value range: ${
              type === TreeResetTypeEnum.TILTING ? '0 ~ 90' : '0 ~ 360'
            }`}
          />
        </Stack>

        <Stack
          sx={{ p: 2 }}
          direction="row"
          spacing={2}
          justifyContent={'flex-end'}>
          <LoadingButton
            loading={isResetting}
            size="small"
            variant={'contained'}
            onClick={(event) => updateReset()}>
            Confirm
          </LoadingButton>
          <Button size="small" onClick={(event) => onClose()}>
            Cancel
          </Button>
        </Stack>
      </Popover>
    )
  }
)

Reset.displayName = 'Reset'
export default Reset
