import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  MenuProps,
  IconButton,
  Chip
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { changeTreeStatus, deleteTree, getTrees } from '@/service/data/trees'

import { PaginationParamsType } from '@/configs/config.types'
import CopyButton from '@/components/CopyButton'
import { getDecimal } from '@/utils/tool'
import { Icons } from '@/assets/icons'
import Reset, { ResetType } from '@/routes/manage/data/trees/Reset'
import {
  BlockchainRecordTypeEnum,
  Tree,
  TreeResetTypeEnum
} from '@/routes/manage/data/trees/trees.types'
import Confirm, { ConfirmType } from '@/components/Confirm'
import { grey, red, yellow } from '@mui/material/colors'
import TipIconBtn from '@/components/TipIconBtn'
import AlertList from '@/components/AlertList'
import TipIcon from '@/components/TipIcon'
import TreeDetails from '@/routes/map/details/Details'
import NewTree from './menus/NewTree'
import ResetActions from './actions/ResetActions'
import { AlertTypeEnum } from '../special-alerts/special-alerts.types'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import TextFieldC from '@/components/TextFieldC'
import { useAppSelector } from '@/store'
import { RolesEnum } from '@/routes/manage/user/users.types'
import LeafiPlate from '@/routes/manage/data/trees/actions/LeafiPlate'
import { PublicAlertListStyle } from '@/routes/public.const'

export type DataTreesPageFilters = {
  userTreeId?: string
  treeId?: string
}

export type DataTreePageListParams = PageListParams<DataTreesPageFilters>
export type ActionType =
  | 'reset'
  | 'muted'
  | 'disabled'
  | 'delete'
  | 'maintenance'

export type MenuActions = 'add' | 'export' | 'batch-add'

type ConfirmData = { tree: Tree; resetType?: ResetType }

const Trees = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.users.user)

  const {
    loading,
    filters,
    page,
    limit,
    listData,
    totalCount,
    refresh,
    changePage,
    changeFilters,
    updateDataByIdx
  } = usePageList<{ userTreeId?: string; organizationId?: string }, Tree>(
    'trees',
    getTrees
  )

  const [menuAction, setMenuAction] = useState<MenuActions | null>()

  // @ts-ignore
  const [actionParams, setActionParams] = useState<{
    type: 'actions' | 'reset' | 'leafiPlate'
    anchor: MenuProps['anchorEl']
    data: Tree
  }>()

  const [actionItemParams, setActionItemParams] = useState<{
    type: 'details' | 'reset-tilting' | 'reset-azimuth' | 'leafiPlate'
    anchor?: MenuProps['anchorEl']
    data: Tree
  }>()

  const [confirmParams, setConfirmParams] = useState<ConfirmType<
    ActionType,
    ConfirmData
  > | null>()

  const closeConfirm = () => setConfirmParams(null)

  const updateTree = (tree: Tree) => {
    const index = listData.findIndex((t) => t.treeId === tree.treeId)
    index > -1 && updateDataByIdx(index, tree)
  }

  const updateTreeStatus = async (
    treeId: string,
    type: ActionType,
    status: boolean
  ) => {
    const resp = await changeTreeStatus(treeId, { [type]: status })
    if (resp?.code === 200) {
      updateTree(resp.data)
      closeConfirm()
    }
  }

  const showTreeOnMap = async (treeId: string) => {
    navigate('/map', { state: { navTreeId: treeId } })
  }

  const removeTree = async (treeId) => {
    const resp = await deleteTree(treeId)
    if (resp?.code === 200 && resp.data) {
      setConfirmParams(null)
      refresh()
    }
  }

  return (
    <>
      <Stack sx={{ height: 1 }} spacing={1}>
        <Stack
          sx={{ flexShrink: 0 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <TextFieldC
            size={'small'}
            value={filters?.userTreeId}
            placeholder="User Tree ID"
            onChange={(evt) => changeFilters({ userTreeId: evt.target.value })}
          />

          <Stack direction="row">
            <TipIconBtn tip="Refresh" onClick={() => refresh()}>
              <Icons.Refresh fontSize="small"></Icons.Refresh>
            </TipIconBtn>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Paper sx={{ height: '100%' }}>
            <DataGridC
              loading={loading}
              getRowId={(row) => row.id}
              rows={listData}
              rowCount={totalCount}
              pageSizeOptions={[15, 50]}
              paginationModel={{
                page: page > 0 ? page - 1 : 0,
                pageSize: limit
              }}
              onPaginationModelChange={(params) => {
                const isPageSizeChanged = limit !== params.pageSize
                changePage({
                  page: isPageSizeChanged ? 1 : params.page + 1,
                  limit: params.pageSize
                })
              }}
              columns={getColumns({
                onMap: showTreeOnMap,
                onReset: (anchor, data) => {
                  setActionParams({ type: 'reset', anchor, data })
                },
                onLeafiotPlate: (anchor, data) => {
                  setActionParams({ type: 'leafiPlate', anchor, data })
                },
                onDetails: (anchor, data) => {
                  setActionItemParams({ type: 'details', anchor, data })
                }
              }).filter((col) => {
                if (user.role === RolesEnum.User) {
                  return col.field !== 'actions'
                }
                return col
              })}
            />
          </Paper>
        </Box>
      </Stack>

      <ResetActions
        open={Boolean(actionParams?.anchor) && actionParams?.type === 'reset'}
        anchorEl={actionParams?.anchor}
        onItemSelect={(type, anchor) => {
          setActionItemParams({ type, anchor, data: actionParams?.data })
        }}
        onClose={() => setActionParams(null)}
      />

      <LeafiPlate
        viewOnly
        open={actionParams?.type === 'leafiPlate'}
        treeId={actionParams?.data?.treeId}
        plateId={actionParams?.data?.plateId}
        onClose={() => setActionParams(null)}
      />

      <NewTree
        open={menuAction === 'add'}
        onSuccess={() => {
          setMenuAction(null)
          refresh()
        }}
        onClose={() => setMenuAction(null)}
      />

      <TreeDetails
        open={actionItemParams?.type === 'details'}
        treeId={actionItemParams?.data?.treeId}
        showTreeId={false}
        onClose={() => setActionItemParams(null)}
      />

      <Reset
        type={
          actionItemParams?.type === 'reset-tilting'
            ? TreeResetTypeEnum.TILTING
            : TreeResetTypeEnum.AZIMUTH
        }
        open={
          Boolean(actionItemParams?.anchor) &&
          ['reset-tilting', 'reset-azimuth'].includes(actionItemParams?.type)
        }
        anchorEl={actionItemParams?.anchor}
        tree={actionItemParams?.data}
        onSuccess={(updatedTree) => updateTree(updatedTree)}
        onClose={() => setActionItemParams(null)}
      />

      <Confirm
        open={Boolean(confirmParams?.anchor) && confirmParams?.type === 'muted'}
        anchorEl={confirmParams?.anchor}
        icon={<Icons.HideSource fontSize="large" />}
        title="Mute Tree"
        message="The system will not send any notification about this tree after it has been muted."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(confirmParams?.data?.tree?.treeId, 'muted', true)
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) && confirmParams?.type === 'disabled'
        }
        anchorEl={confirmParams?.anchor}
        title="Disable Tree"
        icon={<Icons.NotInterested fontSize="large" />}
        message="Disable this tree will hide its data everywhere in the system. But you can still find it historical data on the data page."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(confirmParams?.data?.tree?.treeId, 'disabled', true)
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) &&
          confirmParams?.type === 'maintenance'
        }
        anchorEl={confirmParams?.anchor}
        title="Turn on Maintenance"
        icon={<Icons.NotInterested fontSize="large" />}
        message="The maintenance mark will display in the dashboard and notifications when maintenance status is on"
        onClose={() => closeConfirm()}
        onConfirm={() => {
          updateTreeStatus(
            confirmParams?.data?.tree?.treeId,
            'maintenance',
            true
          )
        }}
      />
      <Confirm
        open={
          Boolean(confirmParams?.anchor) && confirmParams?.type === 'delete'
        }
        anchorEl={confirmParams?.anchor}
        title="Delete"
        icon={<Icons.Delete fontSize="large" />}
        message="Are you sure you want to delete this tree? This action cannot be undone."
        onClose={() => closeConfirm()}
        onConfirm={() => {
          removeTree(confirmParams?.data?.tree?.treeId)
        }}
      />
      <AlertList
        sx={PublicAlertListStyle}
        onLocate={(treeId) => showTreeOnMap(treeId)}
      />
    </>
  )
}

const getColumns: (params: {
  onMap: (treeId: string) => void
  onLeafiotPlate: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onReset: (anchor: MenuProps['anchorEl'], data: Tree) => void
  onDetails: (anchor: MenuProps['anchorEl'], data: Tree) => void
}) => GridColDef<Tree>[] = ({ onReset, onMap, onLeafiotPlate, onDetails }) => {
  return [
    {
      field: 'userTreeId',
      headerName: 'User Tree ID',
      width: 240,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params.value
        const tiltingAlert = params.row?.alerts?.find(
          ({ type }) => type === AlertTypeEnum.Tilting
        )
        const vibrationAlert = params.row?.alerts?.find(
          ({ type }) => type === AlertTypeEnum.Vibration
        )

        const latestData = params.row?.tiltingData
        const coordinates = params?.row?.location?.coordinates
        const isValidCoords =
          coordinates?.length === 2 &&
          coordinates?.every((coord) => !isNaN(coord))

        const blockchain = params.row.blockchain

        return (
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <TipIcon
                title={
                  blockchain ? (
                    <>
                      <Typography paragraph variant="body2">{`${
                        blockchain?.type === BlockchainRecordTypeEnum.INITIAL
                          ? 'Registrated on Blockchain'
                          : 'Updated on Blockchain'
                      } at ${moment(blockchain?.createdAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}`}</Typography>
                      <Typography component="code" fontSize="inherit">
                        {blockchain?.id}
                      </Typography>
                    </>
                  ) : (
                    'Tree has not been registered on blockchain as Green Asset yet.'
                  )
                }
                placement="top">
                <Icons.BlockChainStatusOn
                  fill={blockchain ? yellow[800] : grey[300]}
                  style={{ width: 12 }}
                />
              </TipIcon>

              <TipIcon
                title={
                  vibrationAlert ? (
                    <Typography fontSize="inherit">
                      Vibration detected at{' '}
                      {moment(vibrationAlert?.recordedAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </Typography>
                  ) : (
                    'No vibration detected'
                  )
                }
                placement="top">
                <Icons.Vibration
                  fontSize="inherit"
                  sx={{
                    color: vibrationAlert?.active ? red[400] : grey[300],
                    verticalAlign: 'top'
                  }}
                />
              </TipIcon>
            </Stack>
            <Button
              color={tiltingAlert?.active ? 'error' : 'primary'}
              sx={{
                textTransform: 'none',
                ...(tiltingAlert?.active && { fontWeight: 'bolder' })
              }}
              onClick={(event) => onDetails(event.currentTarget, params.row)}>
              {value}
            </Button>
            <TipIconBtn
              size="small"
              disabled={!isValidCoords}
              tip={
                isValidCoords
                  ? 'Locate on Map'
                  : `Coordinate issue detected, tree does't display on map`
              }
              onClick={() => onMap(params.row.treeId)}>
              <Icons.PlaceOutlined fontSize="inherit" />
            </TipIconBtn>
            <CopyButton size="small" title="Tree ID" value={value} />
          </Stack>
        )
      }
    },
    {
      field: 'plateId',
      headerName: 'LeafiPlate ID',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const plateId = params.value
        return (
          plateId && (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography fontSize="inherit">{plateId}</Typography>
              <TipIconBtn
                tip={'View LeafiPlate'}
                size="small"
                onClick={(event) =>
                  onLeafiotPlate(event.currentTarget, params.row)
                }>
                <Icons.LeafiPlateOn fontSize="inherit" />
              </TipIconBtn>
              <CopyButton
                size="small"
                title="LeafiPlate ID"
                value={plateId}></CopyButton>
            </Stack>
          )
        )
      }
    },
    {
      field: 'tiltinDataTime',
      headerName: 'Latest Record Time',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        const recordedAt = params?.row?.tiltingData?.recordedAt
        return recordedAt
          ? moment(recordedAt)?.format('YYYY-MM-DD HH:mm:ss')
          : '-'
      }
    },
    {
      field: 'lastestVibrationTime',
      headerName: 'Latest Vibration Time',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        const vibrationAlert = params?.row?.alerts?.find(
          ({ type }) => type === AlertTypeEnum.Vibration
        )
        return vibrationAlert?.endedAt
          ? moment(vibrationAlert?.endedAt)?.format('YYYY-MM-DD HH:mm:ss')
          : '-'
      }
    },
    {
      field: 'tiltingAngle',
      headerName: 'Tilting Angle Δ Delta',
      headerAlign: 'center',
      width: 180,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const tiltingReset = params.row?.resets?.find(
          (r) => r.type === TreeResetTypeEnum.TILTING
        )
        const tiltingResetValue = tiltingReset?.value ?? 0
        const tiltinghResetAt = tiltingReset?.recordedAt

        const tilting = params.row?.tiltingData?.tiltingAngle

        const tiltingChange = Math.abs(
          tilting ? tilting - tiltingResetValue : 0
        )

        return (
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <Typography>{getDecimal(tilting, 2) + '°'}</Typography>
            <Stack direction="row">
              {tiltingChange !== 0 && (
                <Typography
                  variant="caption"
                  color={tiltingChange === 0 ? 'inherit' : 'error'}>
                  Δ
                </Typography>
              )}
              <Tooltip
                placement="top"
                title={
                  <>
                    <Typography
                      variant="caption"
                      paragraph={Boolean(tiltinghResetAt)}>
                      Tilting Reset Value: {getDecimal(tiltingResetValue, 2)}°
                    </Typography>
                    {tiltinghResetAt && (
                      <Typography variant="caption">
                        Reset at{' '}
                        {moment(tiltinghResetAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>
                    )}
                  </>
                }>
                <Typography
                  variant="caption"
                  color={tiltingChange === 0 ? 'inherit' : 'error'}>
                  {tiltingChange !== 0
                    ? getDecimal(tiltingChange, 2) + '°'
                    : '-'}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'azimuth',
      headerName: 'Azimuth Δ Delta',
      headerAlign: 'center',
      width: 180,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const azimuthReset = params.row?.resets?.find(
          (r) => r.type === TreeResetTypeEnum.AZIMUTH
        )
        const azimuthResetValue = azimuthReset?.value ?? 0
        const azimuthResetAt = azimuthReset?.recordedAt
        const azimuth = params.row?.tiltingData?.azimuth

        const azimuthChange = Math.abs(
          azimuth ? azimuth - azimuthResetValue : 0
        )

        return (
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <Typography>{getDecimal(azimuth, 2) + '°'}</Typography>
            <Stack direction="row">
              {azimuthChange !== 0 && (
                <Typography
                  variant="caption"
                  color={azimuthChange === 0 ? 'inherit' : 'error'}>
                  Δ
                </Typography>
              )}
              <Tooltip
                placement="top"
                title={
                  <>
                    <Typography
                      variant="caption"
                      paragraph={Boolean(azimuthResetAt)}>
                      Azimuth Reset Value: {getDecimal(azimuthResetValue, 2)}°
                    </Typography>
                    {azimuthResetAt && (
                      <Typography variant="caption">
                        Reset at{' '}
                        {moment(azimuthResetAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>
                    )}
                  </>
                }>
                <Typography
                  variant="caption"
                  sx={{ cursor: 'pointer' }}
                  color={azimuthChange === 0 ? 'inherit' : 'error'}>
                  {azimuthChange !== 0
                    ? getDecimal(azimuthChange, 2) + '°'
                    : '-'}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'temperature',
      headerName: 'Temperature',
      headerAlign: 'center',
      width: 140,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const temperatureValue = params.row?.tiltingData?.temperature
        if (!temperatureValue) return '-'
        const temperature = temperatureValue / 100
        return temperature > 0 ? `${getDecimal(temperature, 2)}°C` : 0
      }
    },
    {
      field: 'species',
      headerName: 'Species',
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Tooltip title={params.row?.details?.species} placement="top-start">
            <Typography width={200} noWrap fontSize="inherit">
              {params.row?.details?.species}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'crownSpread',
      headerName: 'Crown Spread(m)',
      width: 130,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.crownSpread
      }
    },
    {
      field: 'height',
      headerName: 'Height(m)',
      width: 90,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.height
      }
    },
    {
      field: 'dbh',
      headerName: 'DBH(mm)',
      width: 90,
      isNumber: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter(params) {
        return params.row?.details?.dbh
      }
    },

    {
      field: 'location',
      headerName: 'Location',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 220,
      renderCell: (params) => {
        const district = params.value?.district
          ? params.value?.district + ','
          : ''
        return (
          <Tooltip
            title={`${district} ${params.value.address}`}
            placement="bottom-start">
            <Stack direction="row">
              {params.value.district && (
                <Typography fontSize="inherit" sx={{ mr: 1 }}>
                  {params.value.district}
                </Typography>
              )}
              <Typography sx={{ width: 200 }} noWrap fontSize="inherit">
                {params.value.address}
              </Typography>
            </Stack>
          </Tooltip>
        )
      }
    },

    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 100,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              title="Reset"
              size="small"
              onClick={(event) => onReset(event.currentTarget, params.row)}>
              <Icons.SettingsBackupRestore
                style={{ width: 18 }}
                fontSize="inherit"
              />
            </IconButton>
          </Stack>
        )
      }
    }
  ]
}

export default React.memo(Trees)
