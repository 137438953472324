import { store } from '@/store'

import { ContentTypes } from './request.types'

import { globalSlice } from '@/store/reducers/global/global.slice'

export const ResponseHandler = async (
  respone: Response,
  params: { showTips: boolean; reqUrl: string },
  contentType: ContentTypes
) => {
  const showTips = params?.showTips ?? true
  const reqUrl = params?.reqUrl
  if (!respone.ok) {
    const respError = await respone.json()
    if (showTips) {
      store.dispatch(
        globalSlice.actions.setNotification({
          type: 'error',
          caption: respError.message,
          desc: reqUrl
        })
      )
    }
    return {}
  }

  if (contentType === 'json') {
    const respJson = await respone.json()
    if (respJson.error) {
      showTips &&
        store.dispatch(
          globalSlice.actions.setNotification({
            type: 'error',
            caption: respJson.message,
            desc: reqUrl
          })
        )
      return
    }
    return respJson
  } else if (contentType === 'zip') {
    return respone
  }
}

export const ErrorHandler = (error: Error, url: string) => {
  if (error) {
    store.dispatch(
      globalSlice.actions.setNotification({
        type: 'error',
        caption: error.message,
        desc: url
      })
    )
  }
}
