export function image2Base64(img) {
  var canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  var ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  var dataURL = canvas.toDataURL('image/png')
  return dataURL
}

// encrypt a password

export function getDecimal(value: number | undefined, decimal?: number) {
  if (value === undefined || value === null) {
    return 0
  }
  const decimalRegEx = new RegExp('^-?\\d+(?:.\\d{0,' + (decimal || 1) + '})?')
  const decimalNum = value?.toString()?.match(decimalRegEx) || ['0']
  return decimalNum ? parseFloat(decimalNum[0]) : 0
}

export function downloadFromFile(blob: Blob, filename: string) {
  const href = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename) //or any other extension
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function downloadFromUrl(fileUrl: string, filename: string) {
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', filename) //or any other extension
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
  })
}

export const wait = async (delay: number) => {
  return new Promise((resolve) => {
    return setTimeout(() => resolve(true), delay)
  })
}
