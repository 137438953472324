import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ManageState } from './manage.types'
import { MapTreeType } from '@/routes/map/map.types'
import { getManageMapTreesTrunk } from './manage.thunks'

const initialState = {
  overview: {
    isLoadingTrees: false,
    trees: [],
    treeTotalCount: 0,
    treesLoadedCount: 0
  }
} as ManageState

export const manageSlice = createSlice({
  name: 'trees',
  initialState,
  reducers: {
    setOverviewMapTrees(state, action: PayloadAction<MapTreeType[]>) {
      state.overview.trees = action.payload
    },
    setOverviewMapTreeTotalCount(state, action: PayloadAction<number>) {
      state.overview.treeTotalCount = action.payload
    },
    setOverviewMapTreeLoadedCount(state, action: PayloadAction<number>) {
      state.overview.treesLoadedCount = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getManageMapTreesTrunk.pending, (state) => {
      state.overview.isLoadingTrees = true
    })
    builder.addCase(getManageMapTreesTrunk.fulfilled, (state, { payload }) => {
      state.overview.isLoadingTrees = false
      state.overview.trees = payload
    })
    builder.addCase(getManageMapTreesTrunk.rejected, (state) => {
      state.overview.isLoadingTrees = false
    })
  }
})

export default manageSlice.reducer
