import {
  WeatherStation,
  getWeatherIconUri,
  WeatherIconDecs
} from './weather.const'
import { WeatherReponseData, WeatherData, WeatherItem } from './weather.types'

export const getTemporatureAndHumindity = (
  data: WeatherReponseData
): WeatherData => {
  const temperature = data.temperature.data.find(
    (item) => item.place === WeatherStation
  )
  const humidity = data.humidity.data.find(
    (item) => item.place === WeatherStation
  )
  return {
    desc: data.icon
      .map((iconCode) => {
        return WeatherIconDecs[`w${iconCode}`]
      })
      .join(','),
    updateTime: data.updateTime,
    icons: data.icon.map((iconCode) => {
      return getWeatherIconUri(iconCode)
    }),
    humidity: {
      updateTime: data.humidity.recordedAt,
      data: humidity
    },
    temperature: {
      updateTime: data.temperature.recordedAt,
      data: temperature
    }
  }
}
