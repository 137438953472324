import { styled } from '@mui/material'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'

const DataGridStyled = styled(DataGrid)(() => {
  return {
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'center !important'
    }
  }
})

const DataGridC = (props: DataGridProps) => {
  return (
    <DataGridStyled
      {...props}
      pagination
      hideFooterSelectedRowCount
      hideFooterPagination={props.loading}
    />
  )
}

export default DataGridC
