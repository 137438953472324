export type DailyReportData = {
  treeId: string
  sensorId: string
  dataCount: number
  alertCount: number
  tiltingLevel: number
  vibration: {
    status: boolean
    at: Date | null
  }
  tiltingAlert: {
    status: boolean
    at: Date | null
  }
}

export type DailyReport = {
  reportId: string
  recordedAt: Date
  reportDate: Date
  emailStatus: boolean
  totalAlertCount: number
  disconnectionCount: number
  tiltingAlertCount: number
  vibrationCount: number
}
export type SpecialAlert = {
  reportId: string
  recordedAt: Date
  reportDate: Date
  emailStatus: boolean
  totalAlertCount: number
  tiltingAlertCount: number
  vibrationCount: number
}

export enum ReportTypeEnum {
  Daily = 'DAILY',
  Alert = 'ALERT'
}
