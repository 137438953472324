import React, { forwardRef, useEffect } from 'react'
import { Stack, StackProps, Button } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import FormInput from '@/components/form/FormInput'
import { grey } from '@mui/material/colors'
import FormTextArea from '@/components/form/FormTextArea'
import { Group } from '@/routes/setting/users/groups/group.types'
import {
  createGroupForOrganization,
  editGroupForOrganization
} from '@/service/manage/organizations'
import { LoadingButton } from '@mui/lab'

interface EditGroupProps extends StackProps {
  open: boolean
  group: Group
  onSuccess: (newGroup: Group) => void
  onClose: () => void
}

export type EditGroup = {
  name: string
  description?: string
}

const EditGroup = forwardRef<typeof Stack, EditGroupProps>(
  ({ open, group, onSuccess, onClose, ...props }, ref) => {
    const { control, handleSubmit, reset, formState } = useForm({
      defaultValues: { name: '', description: '' }
    })

    const onSubmit: SubmitHandler<EditGroup> = async (data) => {
      console.log(group.id)
      const resp = await editGroupForOrganization(
        group.organizationId,
        group.id,
        data
      )
      if (resp?.code === 200) {
        onSuccess(resp.data)
      }
    }

    useEffect(() => {
      reset({ name: group.name, description: group.description })
    }, [open])

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ p: 2, mt: 1, bgcolor: grey[50] }} spacing={1}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <FormInput
                  required
                  labelWidth={120}
                  label="Group Name"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  value={field.value}
                  onValChange={field.onChange}
                />
              )
            }}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => {
              return (
                <FormTextArea
                  required
                  labelWidth={120}
                  label="Description"
                  helperText="Suggested to be the short name of this organization. Max length 120"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )
            }}
          />
          <Stack
            direction="row"
            sx={{ mt: 2 }}
            spacing={2}
            justifyContent="space-between">
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!formState.isDirty}
              loading={formState.isSubmitting}>
              Submit
            </LoadingButton>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    )
  }
)

EditGroup.displayName = 'EditGroup'
export default EditGroup
