import React, { forwardRef } from 'react'
import { Box, Stack, StackProps, Typography } from '@mui/material'
import { lightGreen, teal } from '@mui/material/colors'
import TipIcon from '@/components/TipIcon'

export enum ThemeTypes {
  light = 'light',
  dark = 'dark'
}

interface StatisticCardProps extends StackProps {
  theme: ThemeTypes.light | ThemeTypes.dark
  icon: React.ReactElement
  title: string
  value: number | string
  tip?: string
  onClick?: () => void
}

const StatisticCard = forwardRef<typeof Stack, StatisticCardProps>(
  ({ theme, icon, title, value, tip, onClick, sx, ...props }, ref) => {
    return (
      <Stack
        sx={{
          position: 'relative',
          p: 1,
          border: '2px solid rgba(0, 0, 0, 0)',
          borderRadius: 2,
          flex: 1,
          cursor: 'pointer',
          color: 'white',
          backgroundColor:
            theme === ThemeTypes.light ? 'secondary.main' : 'primary.main',
          transition: 'border 0.6s, box-shadow 0.3s',
          ...(onClick && {
            '&:hover': {
              borderColor:
                theme === ThemeTypes.light ? lightGreen['A400'] : teal['A200'],
              boxShadow:
                theme === ThemeTypes.light
                  ? `0 0 16px 4px ${lightGreen['A100']}`
                  : `0 0 12px 2px ${teal['A400']}`
            }
          }),
          ...sx
        }}
        onClick={() => onClick && onClick()}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
            {title}
          </Typography>
          {tip ? (
            <TipIcon title={<Typography variant="body2">{tip}</Typography>}>
              {icon}
            </TipIcon>
          ) : (
            icon
          )}
        </Stack>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:after': {
              content: '" "',
              position: 'absolute',
              bottom: 25,
              width: '60px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: ' rgba(0, 0, 0, 0.2)'
            }
          }}>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: '-15px' }}>
            {value}
          </Typography>
        </Box>
      </Stack>
    )
  }
)

export default StatisticCard
