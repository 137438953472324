import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Search from '@/routes/map/MapSearch'
import Details from '@/routes/map/details/Details'
import MapView from '@/routes/map/view/View'
import { FilterTypeEnum } from '@/routes/map/map.types'
import AlertList from '@/components/AlertList'
import { Layout } from '@/components/page'
import RootLayout from '@/components/page/RootLayout'
import { useAppDispatch, useAppSelector } from '@/store'
import { getManageMapTreesTrunk } from '@/store/reducers/manage/manage.thunks'
import { DefaultFilters } from '@/routes/map/view/Filters'
import OverviewStatistics from './statistics/Statistics'
import { ManageAlertListStyle } from '../manage.const'

const ManageOverview = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const overview = useAppSelector((state) => state.manage.overview)
  const treeTotalCount = overview.treeTotalCount
  const trees = overview.trees

  const searchRef = useRef(null)
  const locationState = location.state as { navTreeId?: string }
  const navTreeId = locationState?.navTreeId
  const [filters, setFilters] = useState<FilterTypeEnum[]>(DefaultFilters)

  const mapIns = useRef(null)
  const [selectedTreeId, setSelectTreeId] = useState(null)

  const clearSearch = () => {
    searchRef.current?.clear()
  }

  const locationTreeById = (treeId) => {
    mapIns.current.locationTreeById(treeId, { zoomIn: true })
  }

  const showSelectedTree = (treeId) => {
    mapIns.current.locationTreeById(treeId, { zoomIn: true, popup: true })
  }

  const clearActiveTree = () => mapIns.current.clearActiveTree()

  const closeDetails = () => {
    setSelectTreeId(null)
    clearActiveTree()
  }

  useEffect(() => {
    if (navTreeId) {
      setTimeout(() => showSelectedTree(navTreeId), 500)
    }
  }, [navTreeId, trees])

  useEffect(() => {
    if (treeTotalCount === 0) {
      dispatch(getManageMapTreesTrunk())
    }
  }, [])

  return (
    <RootLayout>
      <Layout sx={{ position: 'relative' }}>
        <Search
          ref={searchRef}
          trees={trees}
          maitenanceOn={!filters.includes(FilterTypeEnum.Maintenance)}
          onSearch={(treeId) => locationTreeById(treeId)}
          onClear={() => clearActiveTree()}
        />

        <OverviewStatistics />

        <MapView
          ref={mapIns}
          controls={{ filter: { show: true, lowPower: true } }}
          trees={trees}
          filters={filters}
          onTreeSelected={(treeId) => setSelectTreeId(treeId)}
          onFilterChange={(filterUpdates) => setFilters(filterUpdates)}
          onClearActive={clearSearch}
        />

        <Details
          open={Boolean(selectedTreeId)}
          treeId={selectedTreeId}
          onClose={() => closeDetails()}
        />

        <AlertList
          sx={ManageAlertListStyle}
          trees={trees}
          onLocate={(treeId) => showSelectedTree(treeId)}
        />
      </Layout>
    </RootLayout>
  )
}

export default ManageOverview
