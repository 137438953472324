import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  StackProps,
  Stack,
  Box,
  Button,
  Typography,
  Chip,
  PopoverProps
} from '@mui/material'
import { getGroups } from '@/service/settings/users'
import GroupItem from './GroupItem'
import { Group } from './group.types'
import { ServiceTypeEnum } from '@/routes/manage/organization/organization.types'
import NewGroup from './NewGroup'
import { useAppSelector } from '@/store'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'
import moment from 'moment'
import ManageTrees from './ManageTrees'
import DeleteGroup from './DeleteGroup'
import EditGroup from './EditGroup'

export type GroupsRefHandlers = {
  refresh: () => void
  getGroups: () => Group[]
}
interface GroupsProps extends StackProps {
  selectedGroupId: string
  refreshUserList: () => void
  onGroupSelected: (id: string) => void
}

const Groups = forwardRef<GroupsRefHandlers, GroupsProps>(
  ({ selectedGroupId, onGroupSelected, refreshUserList, ...props }, ref) => {
    const [groups, setGroups] = useState([])
    const [defaultGroupId, setDefaultGroupId] = useState<string | null>(null)
    const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
    const [actionParams, setActionParams] = useState<{
      type: 'edit' | 'delete' | 'new'
      anchor?: PopoverProps['anchorEl']
      group?: Group
    }>()

    const user = useAppSelector((state) => state.users.user)
    const isLifytProValid = user.subscriptions.find((s) => {
      return (
        s.serviceType === ServiceTypeEnum.LIFY_T_PRO &&
        moment().isBefore(s.expiredAt)
      )
    })

    const fetchGroups = () => {
      getGroups().then((resp) => {
        if (resp?.code === 200) {
          setGroups(resp.data)
          setDefaultGroupId(resp.data.find((g) => g.isDefault)?.id)
        }
      })
    }

    useImperativeHandle(ref, () => ({
      refresh: () => fetchGroups(),
      getGroups: () => groups
    }))

    useEffect(() => {
      fetchGroups()
    }, [])

    return (
      <>
        <Stack sx={{ width: 1, height: 1 }}>
          <Stack flexGrow={1} sx={{ overflow: 'auto' }}>
            {groups.map((item) => {
              return (
                <GroupItem
                  key={item.id}
                  avtive={selectedGroupId === item.id}
                  isDefault={item.isDefault}
                  value={item}
                  onGroupSelected={onGroupSelected}
                  onManageTrees={(group) => setSelectedGroup(group)}
                  onEditGroup={(group) =>
                    setActionParams({
                      type: 'edit',
                      group
                    })
                  }
                  onDeleteGroup={(anchor, group) =>
                    setActionParams({
                      type: 'delete',
                      anchor,
                      group
                    })
                  }
                />
              )
            })}
          </Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            sx={{ pt: 1.5, pb: 1.5, borderTop: `1px solid ${grey[300]}` }}>
            <Button
              variant="outlined"
              size='small'
              disabled={!isLifytProValid}
              startIcon={<Icons.GroupAdd fontSize="small" />}
              onClick={() => setActionParams({ type: 'new' })}>
              Create new group
            </Button>

            <ManageTrees
              defaultGroupId={defaultGroupId}
              group={selectedGroup}
              open={Boolean(selectedGroup)}
              onClose={() => {
                fetchGroups()
                refreshUserList()
                setSelectedGroup(null)
              }}
            />

            {!isLifytProValid && (
              <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                <Typography
                  component={'div'}
                  variant="body2"
                  sx={{ textAlign: 'justify' }}>
                  <Chip label="Pro" size="small" color="primary" variant='outlined' />
                  <strong> Groups </strong> aims to help our users to manage
                  their resources more efficiently. You can create{' '}
                  <strong> four more</strong> groups besides the
                  <em> Default Group</em>. Please consider subscribing to our
                  professional service if you have more advanced needs for
                  managing your resouces. 😊
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>

        <DeleteGroup
          open={actionParams?.type === 'delete'}
          anchorEl={actionParams?.anchor}
          group={actionParams?.group}
          onClose={() => {
            fetchGroups()
            setActionParams(null)
          }}
          onSuccess={() => {
            fetchGroups()
            refreshUserList()
            setActionParams(null)
          }}
        />

        <EditGroup
          open={actionParams?.type === 'edit'}
          group={actionParams?.group}
          onClose={() => setActionParams(null)}
          onSuccess={() => {
            fetchGroups()
            refreshUserList()
            setActionParams(null)
          }}
        />

        <NewGroup
          open={actionParams?.type === 'new'}
          onClose={() => setActionParams(null)}
          onSuccess={() => {
            fetchGroups()
            setActionParams(null)
          }}
        />
      </>
    )
  }
)

Groups.displayName = 'Groups'
export default Groups
