import React, { FC, forwardRef, useRef, useState } from 'react'
import { Popover, Box, Typography, BoxProps, Input, Stack } from '@mui/material'
import { DateRange, DayPicker } from 'react-day-picker'
import moment from 'moment'
import 'react-day-picker/dist/style.css'
import { lightGreen, lightBlue, deepOrange, green } from '@mui/material/colors'
import { ThemePalette } from '@/app.const'

interface DayPickerProps extends BoxProps {
  dates: Date[]
  onDatesChange: (dates: [Date, Date]) => void
}

const DatesPicker = forwardRef<typeof Box, DayPickerProps>(
  ({ dates, sx, onDatesChange, ...props }, ref) => {
    const defaultSelected: DateRange = { from: dates[0], to: dates[1] }
    const [dateRange, setDateRange] = useState<DateRange | undefined>(
      defaultSelected
    )
    const [popoverAnchol, setPopoverAnchol] = useState<HTMLElement | null>(null)
    const isOpen = Boolean(popoverAnchol)

    const closePopover = () => {
      setPopoverAnchol(null)
      if (!dateRange?.to || !dateRange?.from) {
        return
      }
      onDatesChange([
        moment(dateRange?.from).startOf('day').toDate(),
        moment(dateRange?.to).endOf('day').toDate()
      ])
    }

    return (
      <Box ref={ref} sx={{ ...sx }}>
        <Stack
          sx={{ width: 240 }}
          direction={'row'}
          alignItems={'center'}
          onClick={(evt) => setPopoverAnchol(evt.currentTarget)}>
          <Input
            placeholder="Start day"
            value={
              dateRange?.from
                ? moment(dateRange?.from).format('YYYY-MM-DD')
                : ''
            }></Input>
          <Typography sx={{ ml: 1, mr: 1 }} variant={'caption'}>
            TO
          </Typography>
          <Input
            placeholder="End day"
            value={
              dateRange?.to ? moment(dateRange?.to).format('YYYY-MM-DD') : ''
            }></Input>
        </Stack>

        <Popover
          open={isOpen}
          anchorEl={popoverAnchol}
          onClose={() => closePopover()}
          sx={{ mt: 1 }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <DayPicker
            mode="range"
            selected={dateRange}
            weekStartsOn={1}
            fromDate={moment().subtract(12, 'months').toDate()}
            toDate={new Date()}
            styles={{
              caption: { color: ThemePalette.primary.main }
            }}
            modifiersStyles={{
              today: {
                backgroundColor: lightGreen[300]
              },
              selected: {
                backgroundColor: ThemePalette.primary.main
              }
            }}
            onSelect={(range) => setDateRange(range)}
          />
        </Popover>
      </Box>
    )
  }
)
DatesPicker.displayName = 'DatesPicker'
export default DatesPicker
