import React, { forwardRef, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  Button,
  IconButton
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { DefaultNewUser } from '@/routes/manage/user/users.const'

import { Controller, useForm } from 'react-hook-form'
import { updateUser } from '@/service/settings/users'
import { User, UpdateUser } from '@/routes/manage/user/users.types'
import { getGroups } from '@/service/settings/users'
import { RolesEnum, GroupOptions } from '@/routes/manage/user/users.types'
import FormInput from '@/components/form/FormInput'
import FormRadio from '@/components/form/FormRadio'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'

import {
  DefaultUpdateUser,
  RoleNames
} from '@/routes/manage/user/users.const'
import { useAppSelector } from '@/store'

interface EditUserProps extends DrawerProps {
  user: User
  onClose: () => void
  onSuccess: (updatedUser?: User) => void
}

const EditUser = forwardRef<typeof Drawer, EditUserProps>(
  ({ open, user, onClose, onSuccess, ...props }, ref) => {
    const operator = useAppSelector((state) => state.users.user)
    const { control, setValue, reset, handleSubmit } = useForm<UpdateUser>({
      defaultValues: DefaultUpdateUser
    })
    const [groups, setGroups] = useState<GroupOptions[]>([])

    const onSubmit = () => {
      handleSubmit(async (values: UpdateUser) => {
        const resp = await updateUser(user.id, {
          ...values,
          __v: user.__v
        })
        if (resp?.code === 200) {
          onSuccess(resp.data)
        }
      })()
    }

    useEffect(() => {
      if (user) {
        reset({
          nickname: user.nickname,
          groupId: user.group.groupId,
          regionId: user.regionId,
          email: user.email,
          phone: user.phone,
          role: user.role,
          disabled: user.disabled
        })
      }
    }, [user])

    useEffect(() => {
      if (open) {
        getGroups().then((resp) => {
          if (resp?.code === 200) {
            const groupOptions = resp.data.map((g) => ({
              name: g.name,
              id: g.id,
              isDefault: g.isDefault
            }))
            setGroups(groupOptions)

            if (groupOptions?.length === 1) {
              setValue('groupId', groupOptions[0].id)
            }
          }
        })
      } else {
        setGroups([])
      }
    }, [open])

    return (
      <Drawer open={open} variant="temporary" anchor="right">
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden'
          }}>
          <Stack
            sx={{ p: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              {user?.username}
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>

          <Stack
            sx={{ flexGrow: 1, p: 2, maxWidth: 600, overflow: 'auto' }}
            spacing={2}>
            <Controller
              name="nickname"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Nick name"
                  helperText={
                    'Nick name will replace user name as display name to show in the system'
                  }
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="role"
              disabled={operator?.role !== RolesEnum.Admin}
              control={control}
              render={({ field }) => (
                <FormRadio
                  disabled={field.disabled}
                  label="Role"
                  options={[
                    {
                      label: RoleNames[RolesEnum.Admin],
                      value: RolesEnum.Admin
                    },
                    {
                      label: RoleNames[RolesEnum.Manager],
                      value: RolesEnum.Manager
                    },
                    {
                      label: RoleNames[RolesEnum.User],
                      value: RolesEnum.User
                    }
                  ].filter((r) => {
                    if (user?.role === RolesEnum.Admin) {
                      return true
                    }
                    return r.value !== RolesEnum.Admin
                  })}
                  value={field.value}
                  onValChange={field.onChange}
                  tip={
                    user?.role === RolesEnum.Admin && (
                      <Typography color={'warning.main'} variant="caption">
                        Admin users are not allow to change its role
                      </Typography>
                    )
                  }
                />
              )}
            />

            <Controller
              name="groupId"
              control={control}
              disabled={operator?.role !== RolesEnum.Admin}
              render={({ field }) => (
                <FormSelect
                  label="Group"
                  disabled={field.disabled}
                  options={groups.map((g) => ({
                    label: g.name,
                    value: g.id
                  }))}
                  value={field.value}
                  onValChange={field.onChange}
                  tip={
                    user?.role === RolesEnum.Admin && (
                      <Typography color={'warning.main'} variant="caption">
                        Admin users are not allow to change its group
                      </Typography>
                    )
                  }
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Email"
                  helperText="Email to receive notification from LeafIot"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Phone"
                  helperText="Phone to receive alert massage from LeafIot"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />

            <Controller
              name="disabled"
              disabled={operator.role !== RolesEnum.Admin}
              control={control}
              render={({ field }) => (
                <FormSwitch
                  disabled={field.disabled}
                  label="Disable"
                  tip="User's access will be suspended after creation"
                  value={field.value}
                  onValChange={field.onChange}
                />
              )}
            />
          </Stack>

          <Stack
            sx={{ p: 2, boxShadow: '0 -2px 10px 2px rgba(0,0,0,.05)' }}
            direction="row"
            flexShrink={0}
            spacing={2}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => onSubmit()}>
              Confirm
            </Button>
            <Button variant="text" onClick={() => onClose()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    )
  }
)

EditUser.displayName = 'EditUser'
export default EditUser
