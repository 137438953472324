import React, { forwardRef, FC } from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Typography,
  Stack,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { Icons } from '@/assets/icons'
import {
  HkoWeatherWarningStatementCodeEnum,
  HkoWeatherWarningSubtypeCodeEnum,
  WeatherWarningCodes
} from './mode.types'
import { WeatherWarningIcons, WeatherWarningToText } from './mode.const'

interface ModeDescProps extends DialogProps {
  onClose: () => void
}

const ModeDesc = forwardRef<typeof Dialog, ModeDescProps>(
  ({ open, onClose, ...props }, ref) => {
    return (
      <Dialog open={open} maxWidth="xl" fullWidth onClose={() => onClose()}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography fontSize="inherit" fontWeight="inherit">
              Ⓜ️ Mode Details
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <Icons.Close fontSize="inherit"></Icons.Close>
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ ml: 1 }}>
            The monitoring system could adjust as varying sampling frequency
            modes wherein the high sampling frequency modes are activated in a
            typhoon season or during an adverse weather condition, and the low
            sampling frequency mode is activated in a normal weather condition
            to maximize data capture for an unusual pattern visualization.
            <br />
            <br />
            Each self-checking interval lets the communication sensors stay
            connected with the regular check-up on tilting angle to capture just
            if there is any unusual tree movement between the pre-set sampling
            frequencies.
            <br />
            <br />
            In every uplink interval, the data will be uploaded to the cloud
            database for real-time analysis which can be seen from the
            visualized dashboard.
          </Typography>
          <Grid
            container
            rowSpacing={3}
            sx={{
              pt: 1,
              pb: 1,
              pl: 2,
              pr: 2,
              mt: 2,
              backgroundColor: grey[100],
              borderRadius: 2
            }}>
            <Grid container item columns={16} columnSpacing={1}>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Monitoring Mode
              </Grid>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Mode 1
              </Grid>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Mode 2
              </Grid>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Mode 3
              </Grid>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Mode 4
              </Grid>
            </Grid>

            <Grid container item columns={16} columnSpacing={1}>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Self-checking Interval
              </Grid>
              <Grid item md>
                3 hours
              </Grid>
              <Grid item md>
                1 hour
              </Grid>
              <Grid item md>
                30 minutes
              </Grid>
              <Grid item md>
                15 minutes
              </Grid>
            </Grid>
            <Grid container item columns={16} columnSpacing={1}>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Uplink Interval
              </Grid>
              <Grid item md>
                3 hours
              </Grid>
              <Grid item md>
                1 hour
              </Grid>
              <Grid item md>
                30 minutes
              </Grid>
              <Grid item md>
                15 minutes
              </Grid>
            </Grid>

            <Grid container item columns={16} columnSpacing={1}>
              <Grid item md sx={{ fontWeight: 'bold' }}>
                Connected warning
                <Typography variant="caption" paragraph>
                  issued by the Hong Kong Observatory
                </Typography>
              </Grid>
              <Grid item md>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 0.5, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.WHOT} />
                  <WeatherWarningItem code={WeatherWarningCodes.WFIREY} />
                  <WeatherWarningItem code={WeatherWarningCodes.WFIRER} />
                  <WeatherWarningItem code={WeatherWarningCodes.WCOLD} />
                  <WeatherWarningItem code={WeatherWarningCodes.WFROST} />
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 1, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.WTMW} />
                  <WeatherWarningItem code={WeatherWarningCodes.WTS} />
                  <WeatherWarningItem code={WeatherWarningCodes.WL} />
                  <WeatherWarningItem code={WeatherWarningCodes.WMSGNL} />
                </Stack>
                <Typography sx={{ mt: 1 }} paragraph fontStyle="italic">
                  {WeatherWarningToText[WeatherWarningCodes.WTCSGNL]?.en}
                </Typography>
                <Typography paragraph fontStyle="italic">
                  {WeatherWarningToText[WeatherWarningCodes.WTCPRE8]?.en}
                </Typography>
                <Typography paragraph fontStyle="italic">
                  {WeatherWarningToText[WeatherWarningCodes.CANCEL]?.en}
                </Typography>
                <Typography paragraph fontStyle="italic">
                  And when no warning signal
                </Typography>
              </Grid>
              <Grid item md>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 0.5, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.TC1} />
                  <WeatherWarningItem code={WeatherWarningCodes.WRAINA} />
                  <WeatherWarningItem code={WeatherWarningCodes.WFNTSA} />
                </Stack>
              </Grid>
              <Grid item md>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 0.5, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.TC3} />
                  <WeatherWarningItem code={WeatherWarningCodes.WRAINR} />
                </Stack>
              </Grid>
              <Grid item md>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 0.5, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.TC8NE} />
                  <WeatherWarningItem code={WeatherWarningCodes.TC8SE} />
                  <WeatherWarningItem code={WeatherWarningCodes.TC8SW} />
                  <WeatherWarningItem code={WeatherWarningCodes.TC8NW} />
                  <WeatherWarningItem code={WeatherWarningCodes.TC9} />
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: 1, py: 1, justifyContent: 'flex-start' }}
                  flexWrap="wrap"
                  spacing={1}>
                  <WeatherWarningItem code={WeatherWarningCodes.TC10} />
                  <WeatherWarningItem code={WeatherWarningCodes.WRAINB} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
)

ModeDesc.displayName = 'ModeDesc'
export default ModeDesc

const WeatherWarningItem: FC<{
  code: HkoWeatherWarningStatementCodeEnum | HkoWeatherWarningSubtypeCodeEnum
}> = ({ code, ...props }) => {
  return (
    <Tooltip title={WeatherWarningToText[code]?.en} placement="top">
      <img
        width={40}
        height={40}
        style={{ padding: 1, borderRadius: 4, border: '1px solid #909090' }}
        src={WeatherWarningIcons[code]}
        alt={WeatherWarningToText[code]?.en}
      />
    </Tooltip>
  )
}
