import request from '../request'
import { FullUserInfo, SignInTypes, TokenInfo } from '@/routes/login/login.types'

export const signIn = (userInfo: SignInTypes) => {
  return request.post<
    {
      user: FullUserInfo
      access: TokenInfo
      refresh: TokenInfo
    },
    SignInTypes
  >('auth/login', { data: userInfo })
}
