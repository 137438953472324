import { HeadersType, ContentTypes } from "./request.types"

export const ServiceHost = process.env.REACT_APP_SERVICE_HOST;

export const JsonContentType = "application/json"
export const ZipContentType = "application/zip, application/octet-stream"

export const DefaultHeader: HeadersType = {
    "Content-Type": JsonContentType,
    'cache-control': 'no-cache'
}

export const PublicService = ["auth/login"]
