import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Chip, Paper, Stack, StackProps, Typography } from '@mui/material'
import { useAppSelector } from '@/store'
import { getEsgStatusSummary } from '@/service/esg'
import moment from 'moment'

import EsgTiltingIcon from '@/assets/images/esg/esg-tilting-badge.svg'
import EsgGreenAssetsIcon from '@/assets/images/esg/esg-green-badge.svg'
import EsgTraIcon from '@/assets/images/esg/esg-tra-badge.svg'

export type StatusSumary = {
  time: {
    startAt: Date
    days: number
  }
  status: {
    treeTiltingMonitoring: boolean
    greenAssets: boolean
    traManagement: boolean
  }
}
interface EsgTimeSumProps extends StackProps {
  refreshFlag: number
}

const EsgTimeSum = forwardRef<typeof Stack, EsgTimeSumProps>(
  ({ sx, refreshFlag, ...props }, ref) => {
    const user = useAppSelector((state) => state.users.user)
    const [statusSumary, setStatusSumary] = useState<StatusSumary | null>(null)

    const getStatusSummary = async () => {
      const resp = await getEsgStatusSummary()
      if (resp?.code === 200) {
        setStatusSumary(resp?.data)
      }
    }

    useEffect(() => {
      getStatusSummary()
    }, [refreshFlag])

    return (
      <Stack
        sx={{ ...sx }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Stack sx={{ width: '40%' }}>
          <Typography variant="h6">For {user.organization.fullname}</Typography>
          <Typography>
            ESG journey starts on{' '}
            <b>
              {statusSumary?.time?.startAt
                ? moment(statusSumary?.time?.startAt).format('DD/MM/YYYY')
                : '-'}
            </b>
            . We have been going for <b>{statusSumary?.time?.days}</b> days 😁
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ flexGrow: 1, height: 100 }} spacing={2}>
          <Paper
            sx={{
              flex: 1,
              height: 1,
              borderRadius: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundImage: `url(${EsgTiltingIcon})`,
              backgroundRepeat: 'no-repeat'
            }}>
            <Stack sx={{ ml: '100px', mr: 2 }}>
              <Typography variant="h6" fontWeight="bold">
                Tree Tilt Monitoring
              </Typography>
              <Box>
                <Chip size="small" color="primary" label={'Achieved'}></Chip>
              </Box>
            </Stack>
          </Paper>
          <Paper
            sx={{
              flex: 1,
              height: 1,
              borderRadius: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundImage: `url(${EsgGreenAssetsIcon})`,
              backgroundRepeat: 'no-repeat',
              ...(!statusSumary?.status?.greenAssets && {
                filter: 'grayscale(1)'
              })
            }}>
            <Stack sx={{ ml: '100px', mr: 2 }}>
              <Typography variant="subtitle2" fontWeight="bold">
                Green Assets Tokenization <br />& Tree Portfolio
              </Typography>
              <Box>
                <Chip
                  size="small"
                  color="primary"
                  label={
                    statusSumary?.status?.greenAssets
                      ? 'Achieved'
                      : 'To be achieved'
                  }></Chip>
              </Box>
            </Stack>
          </Paper>
          <Paper
            sx={{
              flex: 1,
              height: 1,
              borderRadius: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundImage: `url(${EsgTraIcon})`,
              backgroundRepeat: 'no-repeat',
              ...(!statusSumary?.status?.traManagement && {
                filter: 'grayscale(1)'
              })
            }}>
            <Stack sx={{ ml: '100px', mr: 2 }}>
              <Typography variant="h6" fontWeight="bold">
                TRA Management
              </Typography>
              <Box>
                <Chip
                  size="small"
                  color="primary"
                  label={
                    statusSumary?.status?.traManagement
                      ? 'Achieved'
                      : 'To be achieved'
                  }></Chip>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    )
  }
)

EsgTimeSum.displayName = 'EsgTimeSum'
export default EsgTimeSum
