import React, { forwardRef } from 'react'
import {
  Box,
  FormLabel,
  FormControlProps,
  FormControl,
  Typography,
  Switch,
  Stack
} from '@mui/material'
import { FormLabelWidth } from './form.cont'

interface TextInputProps extends FormControlProps {
  label: string
  value: boolean
  disabled?: boolean
  onValChange?: (string) => void
  labelWidth?: number
  tip?: string
}

const FormSwitch = forwardRef<typeof Box, TextInputProps>(
  (
    {
      label,
      labelWidth,
      value,
      tip,
      onValChange,
      required,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        required={required}
        disabled={disabled}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
        <FormLabel
          focused={false}
          sx={{ width: labelWidth ?? FormLabelWidth, mr: 1, flexShrink: 0 }}>
          {label}
        </FormLabel>

        <Stack sx={{ flexGrow: 1 }}>
          <Switch checked={value} onChange={(event, val) => onValChange(val)} />
          {tip && (
            <Typography
              variant="caption"
              color={'rgba(0, 0, 0, 0.6)'}
              ml={'14px'}
              mr={'14px'}>
              {tip}
            </Typography>
          )}
        </Stack>
      </FormControl>
    )
  }
)

FormSwitch.displayName = 'FormSwitch'
export default FormSwitch
