import React, { useState, forwardRef, useEffect } from 'react'
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Divider
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { useDropzone } from 'react-dropzone'
import { grey } from '@mui/material/colors'
import PapaParser from 'papaparse'
import { importTree } from '@/service/manage/trees'
import FileInfo from './FileInfo'
import {
  TreeDataItem as TreeDataItemType,
  TreeDataStatusEnum
} from './import-trees.type'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TreeDataStatusColor, TreeDataStatusText } from './import-trees.const'
import TipIconBtn from '@/components/TipIconBtn'
import { downloadFromUrl } from '@/utils/tool'
import { TemplatePath } from '@/configs/config.const'

interface ImportTreesProps extends DialogProps {
  onSuccess: () => void
  onClose: () => void
}

const ImportTrees = forwardRef<typeof Dialog, ImportTreesProps>(
  ({ open, onSuccess, onClose, ...props }, ref) => {
    const [isUploading, setIsUploading] = useState(false)
    const [newTrees, setNewTrees] = useState<TreeDataItemType[]>([])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: { 'text/csv': ['.csv'] },
      maxFiles: 1
    })

    const updateTreeStatus = (idx: number, status: TreeDataStatusEnum) => {
      setNewTrees((nv) => {
        const updatedTrees = [...nv]
        const treeUpdated = { ...nv[idx], status }
        updatedTrees[idx] = treeUpdated
        return updatedTrees
      })
    }

    const downloadImportTemplate = () => {
      downloadFromUrl(
        `${TemplatePath}/import-trees-template-v2.0.csv`,
        'import-trees-template-v2.0.csv'
      )
    }

    const onSubmit = async () => {
      setIsUploading(true)

      for (let i = 0; i < newTrees.length; i++) {
        const treeItem = newTrees[i]
        if (treeItem.status === TreeDataStatusEnum.Duplicated) {
          continue
        }
        updateTreeStatus(i, TreeDataStatusEnum.Processing)

        const treeId = treeItem.treeId
        const resp = await importTree(treeId, {
          userTreeId: treeItem.userTreeId,
          organizationId: treeItem.organizationId,
          tiltingReset: treeItem.tiltingReset,
          azimuthReset: treeItem.azimuthReset
        })

        if (resp?.code === 200) {
          updateTreeStatus(i, resp.data)
        } else {
          updateTreeStatus(i, TreeDataStatusEnum.Failed)
        }
      }
      setIsUploading(false)
    }

    useEffect(() => {
      const file = acceptedFiles[0]
      if (!file) {
        setNewTrees([])
        return
      }

      PapaParser.parse(file, {
        delimiter: ',',
        header: true,
        complete: (results, file) => {
          const fileDatas = results.data
          const treeDatas = []
          for (let idx = 0; idx < fileDatas.length; idx++) {
            const dataRow = fileDatas[idx]

            const isBadData = !dataRow.treeId || !dataRow.userTreeId
            if (isBadData) {
              continue
            }

            const firstIndex = fileDatas.findIndex(
              ({ treeId }) => dataRow.treeId === treeId
            )
            const isDuplicated = firstIndex !== idx
            let status = TreeDataStatusEnum.Pending

            if (isDuplicated) {
              status = TreeDataStatusEnum.Duplicated
            }

            treeDatas.push({
              treeId: dataRow.treeId,
              userTreeId: dataRow.userTreeId,
              tiltingReset: dataRow.tiltingReset,
              azimuthReset: dataRow.azimuthReset,
              ...(dataRow.organizationId && {
                organizationId: dataRow.organizationId
              }),
              status: status
            })
          }
          setNewTrees(treeDatas)
        },
        error: () => {}
      })
    }, [acceptedFiles])

    useEffect(() => {
      if (!open) {
        setIsUploading(false)
        setNewTrees([])
      }
    }, [open])

    return (
      <Dialog open={open} maxWidth={'lg'} fullWidth onClose={onClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              Import Trees
            </Typography>
            <Stack direction="row">
              <TipIconBtn
                tip="Download template CSV file"
                onClick={() => downloadImportTemplate()}>
                <Icons.DownloadOutlined fontSize="small" />
              </TipIconBtn>
              <IconButton aria-label="close" onClick={() => onClose()}>
                <Icons.Close fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ height: 800 }}>
          <Stack sx={{ height: 1, overflow: 'hidden' }} spacing={1}>
            <Stack
              sx={{ width: 1, overflow: 'auto' }}
              flexGrow={1}
              alignItems="center"
              justifyContent={newTrees.length === 0 ? 'center' : 'flex-start'}>
              <DataGrid
                sx={{ width: 1 }}
                getRowId={(row) => row.treeId}
                columns={getTreeDataCols()}
                rows={newTrees}
                hideFooter
              />
            </Stack>

            <Box
              {...getRootProps({ className: 'dropzone' })}
              sx={{
                p: 2,
                height: 140,
                flexShrink: 0,
                border: `2px dashed ${grey[300]}`,
                borderRadius: 2,
                bgcolor: grey[100]
              }}>
              <input {...getInputProps()} />
              <Typography variant="subtitle2" color={grey[600]} sx={{ mb: 1 }}>
                Drag 'n' drop the tree CSV file here, or click to select one.
                <Typography variant="caption" color={grey[500]}>
                  {' '}
                  Only *.csv will be accepted
                </Typography>
              </Typography>
              <Divider />
              <Typography
                paragraph
                variant="body2"
                color={grey[600]}
                sx={{ mt: 1, mb: 0 }}>
                Reset fill in date: resetValue will be set to the first
                tiltingData.tiltingAngle on the day of fill in date, resetDate
                is the date of the current operation
              </Typography>
              <Typography
                paragraph
                variant="body2"
                color={grey[600]}
                sx={{ mb: 0 }}>
                Reset fills in the value: resetValue will be set to fill in the
                value, resetDate is the date of the current operation
              </Typography>
              <Typography
                paragraph
                variant="body2"
                color={grey[600]}
                sx={{ mb: 0 }}>
                Reset fills in CURRENT: resetValue will be set to the value
                saved in Leafiot 1.0, resetDate is the date saved in Leafiot 1.0
                for the current operation
              </Typography>
            </Box>
            <FileInfo file={acceptedFiles[0]} />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mb: 1, pr: 2 }}>
          <Button
            disabled={isUploading || newTrees.length === 0}
            type="submit"
            variant="contained"
            onClick={() => onSubmit()}>
            Confirm
          </Button>
          <Button
            variant="text"
            sx={{ ml: 2, mr: 2 }}
            onClick={() => onSuccess()}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

ImportTrees.displayName = 'ImportTrees'
export default ImportTrees

const getTreeDataCols = (): GridColDef<TreeDataItemType>[] => {
  return [
    {
      field: 'treeId',
      headerName: 'Tree ID',
      width: 190,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'userTreeId',
      headerName: ' User Tree Id',
      width: 190,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'organizationId',
      headerName: ' Organization ID',
      width: 240,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'tiltingReset',
      headerName: 'Tilting Reset',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'azimuthReset',
      headerName: 'Azimuth Reset',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value === TreeDataStatusEnum.Processing ? (
          <Icons.RotateRight
            fontSize="small"
            sx={{
              animation: 'spin 0.6s linear infinite',
              color: grey[300]
            }}
          />
        ) : (
          <Chip
            size="small"
            variant="outlined"
            color={TreeDataStatusColor[params.value]}
            label={TreeDataStatusText[params.value]}
          />
        )
      }
    }
  ]
}
