import React, { useState, forwardRef, useEffect } from 'react'
import {
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Button,
  Alert,
  Skeleton,
  Box
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { LeafiPlate as LeafiPlateType, Tree } from '../trees.types'

import { grey, red } from '@mui/material/colors'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { fileToBase64 } from '@/utils/tool'
import { getLeafiPlate, uploadLeafiPlate } from '@/service/manage/trees'
import moment from 'moment'

interface LeafiPlateProps extends DialogProps {
  viewOnly?: boolean
  treeId: string
  plateId?: string
  onSuccess?: (tree: Tree) => void
  onClose: () => void
}

const LeafiPlate = forwardRef<typeof Dialog, LeafiPlateProps>(
  (
    { open, viewOnly = false, treeId, plateId, onSuccess, onClose, ...props },
    ref
  ) => {
    const [leafiPlate, setLeafiPlate] = useState<LeafiPlateType | null>(null)
    const [newLeafiPlateId, setNewLeafiPlateId] = useState('')
    const [newPlateImage, setNewPlateImage] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { getRootProps, acceptedFiles, fileRejections, getInputProps } =
      useDropzone({
        accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
        maxFiles: 1,
        maxSize: 2 * 1024 * 1024
      })
    const fileRejection = fileRejections[0]
    const invalidFileType = fileRejection?.errors.find((err) => {
      return err.code === ErrorCode.FileInvalidType
    })
    const invalidFileSize = fileRejection?.errors.find((err) => {
      return err.code === ErrorCode.FileTooLarge
    })

    const getPlate = async () => {
      setIsLoading(true)
      const resp = await getLeafiPlate(treeId, plateId)
      if (resp?.code === 200) {
        setLeafiPlate(resp?.data)
      }
      setIsLoading(false)
    }

    const updateSelectedImage = (file) => {
      const plateId = file.name.split('.')[0].split('_')[1]
      setNewLeafiPlateId(plateId)
      setNewPlateImage(URL.createObjectURL(file))
    }
    const clearSelectedImage = () => {
      acceptedFiles.splice(0, 1)
      setNewLeafiPlateId('')
      setNewPlateImage(null)
    }

    const uploadPlate = async () => {
      if (isUploading) {
        return
      }
      setIsUploading(true)
      const fileBase64 = await fileToBase64(acceptedFiles[0])
      const resp = await uploadLeafiPlate(treeId, newLeafiPlateId, fileBase64)

      if (resp?.code === 200) {
        setLeafiPlate(resp?.data?.plate)
        clearSelectedImage()
        onSuccess(resp?.data?.tree)
      }
      setIsUploading(false)
    }

    useEffect(() => {
      if (acceptedFiles[0]) {
        updateSelectedImage(acceptedFiles[0])
      } else {
        clearSelectedImage()
        setNewLeafiPlateId('')
      }
    }, [acceptedFiles])

    useEffect(() => {
      if (open && treeId && plateId) {
        getPlate()
      }
      clearSelectedImage()
      setLeafiPlate(null)
    }, [open, treeId, plateId])

    return (
      <Dialog
        open={open}
        maxWidth={leafiPlate && !viewOnly ? 'xl' : 'md'}
        fullWidth
        onClose={onClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}>
            <Typography variant="h6" color="primary.main">
              LeafiPlate
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!viewOnly && (
            <Alert severity="info">
              <Typography fontWeight="bold">
                To get plate ID get input automatically, please name the image
                file in format <i>LeafiPlate_[ID].*</i>
              </Typography>
              <Typography>
                Please drop or click the new plate area to select new image
              </Typography>
            </Alert>
          )}

          <Stack
            sx={{ mt: 2 }}
            direction="row"
            spacing={4}
            alignItems={'flex-start'}>
            {/* current Plate */}
            {isLoading && (
              <Stack flex={1} flexShrink={0} spacing={1} sx={{ pt: 1 }}>
                <Skeleton variant="rounded" sx={{ width: 1, height: 250 }} />
              </Stack>
            )}
            {leafiPlate && (
              <Stack
                flex={1}
                flexShrink={0}
                spacing={viewOnly ? 1 : 2}
                sx={{ ...(!viewOnly && { pt: 1 }) }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between">
                  <Typography textAlign="center" fontWeight="bold">
                    {leafiPlate?.plateId}
                  </Typography>
                  <Typography
                    variant="caption"
                    textAlign="center"
                    color={grey[800]}>
                    Uploaded at{' '}
                    {moment(leafiPlate.createdAt).format('YYYY-MM-DD HH:mm')}
                  </Typography>
                </Stack>
                <img width={'100%'} src={leafiPlate?.image} />
              </Stack>
            )}

            {/* New Plate */}
            {!viewOnly && (
              <Stack flex={1} flexShrink={0} spacing={1} {...getRootProps()}>
                <input {...getInputProps()} />

                <Stack direction="row" justifyContent={'space-between'}>
                  <Stack>
                    <Typography color={newLeafiPlateId ? red[600] : grey[600]}>
                      {newLeafiPlateId ? newLeafiPlateId : 'New LeafiPlate ID'}
                    </Typography>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="caption"
                        color={
                          !newLeafiPlateId && newPlateImage
                            ? red[400]
                            : grey[600]
                        }
                        fontWeight={
                          !newLeafiPlateId && newPlateImage
                            ? 'bolder'
                            : 'normal'
                        }>
                        Require valid LeafiPlate ID, please read instruction
                        above,
                      </Typography>
                      <Typography
                        sx={{ m: 0 }}
                        variant="caption"
                        color={invalidFileType ? red[800] : grey[600]}
                        fontWeight={invalidFileType ? 'bolder' : 'normal'}>
                        allowed *.jpeg, *.jpg, *.png, *.gif,
                      </Typography>
                      <Typography
                        sx={{ m: 0 }}
                        variant="caption"
                        color={invalidFileSize ? red[800] : grey[600]}
                        fontWeight={invalidFileSize ? 'bolder' : 'normal'}>
                        max size of 2 Mb
                      </Typography>
                    </Stack>
                  </Stack>

                  <Box>
                    <Button
                      disabled={!(newLeafiPlateId && newPlateImage)}
                      size="small"
                      variant={
                        !(newLeafiPlateId && newPlateImage)
                          ? 'text'
                          : 'contained'
                      }
                      onClick={(event) => {
                        event.stopPropagation()
                        uploadPlate()
                      }}>
                      Upload
                    </Button>
                  </Box>
                </Stack>

                {newPlateImage ? (
                  <img width={'100%'} src={newPlateImage} />
                ) : (
                  <Icons.LeafiPlateGrey style={{ opacity: 0.2 }} />
                )}
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }
)

LeafiPlate.displayName = 'LeafiPlate'
export default LeafiPlate
