import { SensorsPageListParams } from '@/routes/manage/data/sensors/Sensors'
import request from '../request'
import { Sensor } from '@/routes/manage/data/sensors/sensor.types'
import { ManageWeatherWarningListParams } from '@/routes/manage/data/weather-warnings/WeatherWarnings'

export const getWeatherWarnings = async (params: ManageWeatherWarningListParams) => {
  return request.get<{ sensors: Sensor[]; totalCount: number }>(
    'manage/weather/warnings',
    {
      data: {
        page: params.page,
        limit: params.limit,
        ...(params.date && { date: params.date.format('YYYY-MM-DD') })
      }
    }
  )
}
