import React, { forwardRef, useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  Paper,
  Stack,
  Typography,
  IconButton,
  Pagination,
  Tooltip,
  SvgIconOwnProps,
  IconButtonOwnProps
} from '@mui/material'
import { Icons } from '@/assets/icons'
import { getRepairTasks, stopRepairTask } from '@/service/manage/tilting-data'
import {
  RepairTiltingDataTask,
  DataTaskStatusEnum,
  StopRepairTaskAction,
  RepairPeriodEnum
} from './tilting-data.types'
import moment from 'moment'
import { TaskStatusColor, TaskStatusText } from './tilting-data.const'
import TipIcon from '@/components/TipIcon'
import { green, grey } from '@mui/material/colors'
import { getDecimal } from '@/utils/tool'
import Decimal from 'decimal.js'

interface DataHoursProps extends BoxProps {
  refreshFlag: number
  date: Date
}

const PageLimit = 10

const DataTasks = forwardRef<typeof Box, DataHoursProps>(
  ({ sx, refreshFlag, date, ...props }, ref) => {
    const [repairTasks, setRepairTasks] = useState<RepairTiltingDataTask[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [followCalendar, setFollowCalendar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)

    const fetchRepairTasks = async () => {
      if (loading) {
        return
      }
      setLoading(true)
      setRepairTasks([])
      setTotalCount(0)

      const resp = await getRepairTasks({
        page,
        limit: PageLimit,
        ...(followCalendar && { date })
      })

      if (resp?.code) {
        setRepairTasks(resp.data.dataTasks)
        setTotalCount(resp.data.totalCount)
      }
      setLoading(false)
    }

    const executeStopRepairTask = async (
      taskId,
      action: StopRepairTaskAction
    ) => {
      const resp = await stopRepairTask(taskId, action)
      if (resp?.code === 200) {
        fetchRepairTasks()
      }
    }

    useEffect(() => {
      fetchRepairTasks()
    }, [page, followCalendar, refreshFlag])

    useEffect(() => {
      if (followCalendar) {
        fetchRepairTasks()
      }
    }, [date])

    return (
      <Paper
        sx={{
          ...sx,
          height: 1,
          pt: 2,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
        <Stack
          sx={{ pb: 1, borderBottom: '1px solid #eeeeee' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="subtitle1" fontWeight="bold">
            Repair Tasks
          </Typography>
          <Stack direction="row">
            <Tooltip
              title={'Filter with the selected date on the calendar'}
              placement="top">
              <IconButton
                disabled={loading}
                color={followCalendar ? 'primary' : 'default'}
                size="small"
                onClick={() => setFollowCalendar(!followCalendar)}>
                <Icons.Event fontSize="inherit" />
              </IconButton>
            </Tooltip>

            <IconButton
              disabled={loading}
              size="small"
              onClick={() => fetchRepairTasks()}>
              <Icons.Refresh
                sx={{
                  ...(loading && { animation: 'spin 0.6s linear infinite' })
                }}
                fontSize="inherit"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Stack flexGrow={1} sx={{ p: 1, overflow: 'auto' }}>
          {repairTasks.map((task) => {
            return (
              <Stack
                key={task.createdAt.toString()}
                sx={{ p: 0.5, pb: 1, borderBottom: `1px solid ${grey[200]}` }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ color: grey[500] }}
                    spacing={0.5}>
                    <TipIcon
                      title={TaskStatusText[task.status]}
                      placement="top">
                      {task.status === DataTaskStatusEnum.Success ? (
                        <Icons.CheckCircleOutline
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                        />
                      ) : task.status === DataTaskStatusEnum.Terminated ? (
                        <Icons.StopOutlined
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                        />
                      ) : task.status === DataTaskStatusEnum.Running ? (
                        <Icons.HourglassTopOutlined
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                        />
                      ) : task.status === DataTaskStatusEnum.Failed ? (
                        <Icons.ErrorOutline
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                        />
                      ) : task.status === DataTaskStatusEnum.Suspended ? (
                        <Icons.PauseCircleOutlined
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                        />
                      ) : (
                        <Icons.Circle
                          fontSize="inherit"
                          color={TaskStatusColor[task.status]}
                          sx={{ transform: 'scale(0.5)' }}
                        />
                      )}
                    </TipIcon>
                    <Typography variant="body2">
                      {moment(task.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <Tooltip
                      title={
                        [DataTaskStatusEnum.Running].includes(task.status) &&
                        'Terminate'
                      }
                      placement="top">
                      <span>
                        <IconButton
                          size="small"
                          disabled={[
                            DataTaskStatusEnum.Terminated,
                            DataTaskStatusEnum.Success,
                            DataTaskStatusEnum.Failed
                          ].includes(task.status)}
                          color={
                            TaskStatusColor[
                              task.status
                            ] as IconButtonOwnProps['color']
                          }
                          onClick={() =>
                            executeStopRepairTask(task._id, 'terminate')
                          }>
                          <Icons.EveStopCircleOutlinednt fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Typography variant="body2">
                    {task.repairPeriod === RepairPeriodEnum.Day &&
                      `On ${moment(task.inputDate).format('YYYY-MM-DD')}`}

                    {task.repairPeriod === RepairPeriodEnum.Month &&
                      `From ${moment(task.inputDate)
                        .startOf('month')
                        .format('YYYY-MM-DD')} 
                      to ${moment(task.inputDate)
                        .endOf('month')
                        .format('YYYY-MM-DD')}`}
                  </Typography>

                  {task?.progress && (
                    <Typography
                      variant="body2"
                      color={
                        task.status === DataTaskStatusEnum.Running
                          ? green[500]
                          : grey[500]
                      }>
                      {new Decimal(task.progress.percentage)
                        .mul(100)
                        .toNumber()}
                      %
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )
          })}
        </Stack>

        <Pagination
          sx={{
            my: 2,
            justifyContent: 'center',
            '& .MuiPagination-ul': {
              justifyContent: 'center'
            }
          }}
          size="small"
          variant="outlined"
          count={Math.ceil(totalCount / PageLimit)}
          page={page}
          onChange={(_, newPage) => setPage(newPage)}
        />
      </Paper>
    )
  }
)

DataTasks.displayName = 'DataTasks'
export default DataTasks
