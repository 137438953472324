import React, { forwardRef, useState } from 'react'
import {
  Collapse,
  IconButton,
  MenuProps,
  Stack,
  StackProps,
  Tooltip,
  Typography
} from '@mui/material'
import { Group } from '@/routes/setting/users/groups/group.types'
import moment from 'moment'
import { Icons } from '@/assets/icons'
import Confirm from '@/components/Confirm'
import { deleteGroupForOrganization } from '@/service/manage/organizations'
import EditGroup from './EditGroup'

interface GroupListItemProps extends StackProps {
  group: Group
  onDeleted: () => void
  onEdited: () => void
}

const GroupListItem = forwardRef<typeof Stack, GroupListItemProps>(
  ({ group, onEdited, onDeleted, ...props }, ref) => {
    const [deleteConfirmAnchor, setDeleteConfirmAnchor] = useState<
      MenuProps['anchorEl'] | null
    >(null)
    const [deleting, setDeleting] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const deleteGroup = async () => {
      setDeleting(true)
      const resp = await deleteGroupForOrganization(
        group.organizationId,
        group.id
      )

      if (resp?.code === 200 && resp?.data) {
        setDeleting(false)
        setDeleteConfirmAnchor(null)
        onDeleted()
      }
    }

    return (
      <Stack sx={{ px: 3, py: 2, borderBottom: '1px solid #ddd' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{group.name}</Typography>

            <Tooltip title={'Members in Group'} placement="top">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Icons.Group fontSize="inherit" />
                <Typography variant="body2">{group.menberCount}</Typography>
              </Stack>
            </Tooltip>
            <Tooltip title={'Number of Trees'} placement="top">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Icons.Nature sx={{ fontSize: 14 }} />
                <Typography variant="body2">{group.treeCount}</Typography>
              </Stack>
            </Tooltip>
          </Stack>

          <Typography variant="caption">
            {moment(group.createdAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="caption">{group.description}</Typography>

          {!group.isDefault && (
            <Stack direction="row">
              <IconButton
                title="Edit Group"
                size="small"
                sx={{ fontSize: 'inherit' }}
                onClick={() => setShowEdit(true)}>
                <Icons.EditOutlined fontSize="inherit" />
              </IconButton>
              <IconButton
                title="Delete Group"
                size="small"
                sx={{ fontSize: 'inherit' }}
                onClick={(event) =>
                  setDeleteConfirmAnchor(event.currentTarget)
                }>
                <Icons.DeleteOutlineOutlined fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Collapse in={showEdit} exit={!showEdit}>
          <EditGroup
            open={showEdit}
            group={group}
            onSuccess={() => {
              onEdited()
              setShowEdit(false)
            }}
            onClose={() => setShowEdit(false)}
          />
        </Collapse>

        <Confirm
          open={Boolean(deleteConfirmAnchor)}
          pending={deleting}
          sx={{ mt: 3 }}
          anchorEl={deleteConfirmAnchor}
          title="Delete Group"
          icon={<Icons.DeleteForever fontSize="large" />}
          message={'Please confirm that you really want to delete this group'}
          onClose={() => setDeleteConfirmAnchor(null)}
          onConfirm={() => deleteGroup()}
        />
      </Stack>
    )
  }
)

GroupListItem.displayName = 'GroupListItem'
export default GroupListItem
