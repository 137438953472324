import { MapTreeType } from '@/routes/map/map.types'
import {
  getManageMapTrees,
  getManageTreeTotalcount
} from '@/service/manage/map'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CatchErrors } from '../reducer.helper'
import { manageSlice } from './manage.slice'
import { getMapTreesFromRes } from '../map/map.helper'

export const getManageMapTreesTrunk = createAsyncThunk<MapTreeType[]>(
  'manage/overview/getTreesAsync',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const mapTrees = []
      let totalCount = 0
      let limit = 15
      const totalCountResp = await getManageTreeTotalcount()

      if (totalCountResp?.code !== 200) {
        return []
      }
      totalCount = totalCountResp?.data
      dispatch(manageSlice.actions.setOverviewMapTreeTotalCount(totalCount))

      const totalPage = Math.ceil(totalCount / limit)
      for (let i = 0; i < totalPage; i++) {
        const page = i + 1
        const respItem = await getManageMapTrees({ page, limit })
        if (respItem?.code !== 200) {
          continue
        }
        mapTrees.push(
          ...respItem?.data?.trees.map((tree) => getMapTreesFromRes(tree))
        )
        dispatch(
          manageSlice.actions.setOverviewMapTreeLoadedCount(mapTrees.length)
        )
      }

      return mapTrees
    } catch (error) {
      return CatchErrors(error, rejectWithValue)
    }
  }
)
