import { HkoWeatherWarninInfo } from '@/routes/manage/data/weather-warnings/weather-warning.types'

export type WeatherWarning = {
  recordedAt: Date
  isValid: boolean
  isEmpty: boolean
  updatedAt: Date
  warningCodes: (
    | HkoWeatherWarningStatementCodeEnum
    | HkoWeatherWarningSubtypeCodeEnum
  )[]
  details: HkoWeatherWarninInfo
}
export type ModeInfo = {
  recordedAt: Date,
  mode: number,
  interval: number,
  warningCodes: string[],
}

export enum ModeEnum {
  mode1 = 1,
  mode2 = 2,
  mode3 = 3,
  mode4 = 4
}

export enum IntervalEnum {
  mode1 = 3 * 60,
  mode2 = 1 * 60,
  mode3 = 0.5 * 60,
  mode4 = 0.25 * 60
}

export enum HkoWeatherWarningStatementCodeEnum {
  WFIRE = 'WFIRE',
  WFROST = 'WFROST',
  WHOT = 'WHOT',
  WCOLD = 'WCOLD',
  WMSGNL = 'WMSGNL',
  WTCPRE8 = 'WTCPRE8',
  WFNTSA = 'WFNTSA',
  WL = 'WL',
  WTCSGNL = 'WTCSGNL',
  WTMW = 'WTMW',
  WTS = 'WTS'
}

export enum HkoWeatherWarningSubtypeCodeEnum {
  WFIREY = 'WFIREY',
  WFIRER = 'WFIRER',
  WRAINA = 'WRAINA',
  WRAINR = 'WRAINR',
  WRAINB = 'WRAINB',
  TC1 = 'TC1',
  TC3 = 'TC3',
  TC8NE = 'TC8NE',
  TC8SE = 'TC8SE',
  TC8SW = 'TC8SW',
  TC8NW = 'TC8NW',
  TC9 = 'TC9',
  TC10 = 'TC10',
  CANCEL = 'CANCEL'
}

export const WeatherWarningCodes = {
  ...HkoWeatherWarningStatementCodeEnum,
  ...HkoWeatherWarningSubtypeCodeEnum
}

const Mode1Intervals = [
  WeatherWarningCodes.WTCSGNL,
  WeatherWarningCodes.WMSGNL,
  WeatherWarningCodes.WTS,
  WeatherWarningCodes.WL,
  WeatherWarningCodes.WHOT,
  WeatherWarningCodes.WFIRE,
  WeatherWarningCodes.WFIREY,
  WeatherWarningCodes.WFIRER,
  WeatherWarningCodes.WCOLD,
  WeatherWarningCodes.WFROST,
  WeatherWarningCodes.WTMW,
  WeatherWarningCodes.WTCPRE8,
  WeatherWarningCodes.CANCEL
]

const Mode2Intervals = [
  WeatherWarningCodes.TC1,
  WeatherWarningCodes.WRAINA,
  WeatherWarningCodes.WFNTSA
]

const Mode3Intervals = [WeatherWarningCodes.TC3, WeatherWarningCodes.WRAINR]

const Mode4Intervals = [
  WeatherWarningCodes.TC8NE,
  WeatherWarningCodes.TC8SE,
  WeatherWarningCodes.TC8SW,
  WeatherWarningCodes.TC8NW,
  WeatherWarningCodes.TC9,
  WeatherWarningCodes.TC10,
  WeatherWarningCodes.WRAINB
]

export const WarningToInterval = {
  ...Mode1Intervals.reduce((acc, mode1WeatherWarningCodes) => {
    if (!acc[mode1WeatherWarningCodes]) {
      acc[mode1WeatherWarningCodes] = IntervalEnum.mode1
    }
    return acc
  }, {}),
  ...Mode2Intervals.reduce((acc, mode1WeatherWarningCodes) => {
    if (!acc[mode1WeatherWarningCodes]) {
      acc[mode1WeatherWarningCodes] = IntervalEnum.mode2
    }
    return acc
  }, {}),
  ...Mode3Intervals.reduce((acc, mode1WeatherWarningCodes) => {
    if (!acc[mode1WeatherWarningCodes]) {
      acc[mode1WeatherWarningCodes] = IntervalEnum.mode3
    }
    return acc
  }, {}),
  ...Mode4Intervals.reduce((acc, mode1WeatherWarningCodes) => {
    if (!acc[mode1WeatherWarningCodes]) {
      acc[mode1WeatherWarningCodes] = IntervalEnum.mode4
    }
    return acc
  }, {})
}
