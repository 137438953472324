import React, { forwardRef, useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material'
import Lightbox from 'yet-another-react-lightbox'

import { TreeImageItem } from '@/routes/manage/data/trees/actions/TreeImages'
import { grey } from '@mui/material/colors'
import moment from 'moment'
import { getTreeImages } from '@/service/manage/trees'
import PanoramaImage from '@/assets/images/map/tree-panorama-small.png'
import TrunkImage from '@/assets/images/map/tree-trunk-small.png'
import RootImage from '@/assets/images/map/tree-root-small.png'
import { MapTreeDetailsType } from '../map.types'

export const getDefImages = () => {
  return [
    {
      title: 'Panorama',
      src: PanoramaImage
    },
    {
      title: 'Trunk',
      src: TrunkImage
    },
    {
      title: 'Root',
      src: RootImage
    }
  ]
}

interface TreeImagesProps extends BoxProps {
  tree: MapTreeDetailsType
}

const TreeImages = forwardRef<typeof Box, TreeImagesProps>(
  ({ tree, ...props }, ref) => {
    const treeId = tree?.treeId
    const [lightBoxIndex, setLightBoxIndex] = useState(-1)
    const [images, setImages] = useState<TreeImageItem[]>(getDefImages())
    const [slides, setSlides] = useState([])

    const fetchTreeImages = async () => {
      const defImages = getDefImages()
      const resp = await getTreeImages(treeId, 140)
      if (resp?.code === 200) {
        const treeThumbnails = resp.data
        const newTreeImages = images.map((imageItem, idx) => {
          if (!treeThumbnails[idx]) {
            return defImages[idx]
          }
          const thumbnail = treeThumbnails[idx]
          imageItem.recordedAt = thumbnail.recordedAt
          imageItem.src = thumbnail.image
          return imageItem
        })
        setImages(newTreeImages)
      }
    }

    const fetchFullTreeImage = async () => {
      const resp = await getTreeImages(treeId)
      const defImages = getDefImages()
      if (resp?.code === 200 && resp.data.length > 0) {
        const fullTreeImages = resp.data
        const newSlides = images.map((imageItem, idx) => {
          const fullTreeImageItem = fullTreeImages[idx]
          if (!fullTreeImageItem) {
            const defItem = defImages[idx]
            return { alt: defItem.title, src: defItem.src }
          }
          const slide = {}
          // slide['width'] = 800
          slide['alt'] = imageItem.title
          slide['src'] = fullTreeImageItem.image
          return slide
        })
        console.log(newSlides)
        setSlides(newSlides)
      }
    }

    useEffect(() => {
      setImages(getDefImages())
      setSlides([])
      if (treeId) {
        fetchTreeImages()
      }
    }, [treeId])

    useEffect(() => {
      if (lightBoxIndex !== -1) {
        fetchFullTreeImage()
      }
    }, [lightBoxIndex])

    return (
      <Box sx={{ pl: 2, pr: 2, flexShrink: 0, height: 140 }} ref={ref}>
        <ImageList
          sx={{ width: 1, height: 140, mt: 0 }}
          cols={3}
          variant="quilted"
          rowHeight={140}>
          {images.map((item, idx) => {
            return (
              <ImageListItem
                key={idx}
                sx={{ bgcolor: grey[100] }}
                onClick={() => {
                  if (item?.src === getDefImages()[idx].src) {
                    return
                  }
                  setLightBoxIndex(idx)
                }}>
                <img src={item?.src} style={{ borderRadius: '8px 8px 0 0' }} />
                <ImageListItemBar
                  title={item?.recordedAt && item.title}
                  subtitle={
                    item?.recordedAt
                      ? moment(item?.recordedAt).format('YYYY-MM-DD HH:mm')
                      : item.title
                  }
                />
              </ImageListItem>
            )
          })}
        </ImageList>
        <Lightbox
          open={lightBoxIndex > -1}
          index={lightBoxIndex}
          styles={{ container: { backgroundColor: 'rgba(0,0,0,.5)' } }}
          slides={slides}
          close={() => setLightBoxIndex(-1)}
        />
      </Box>
    )
  }
)

TreeImages.displayName = 'TreeImages'
export default TreeImages
