import React, { forwardRef, useEffect } from 'react'
import {
  Box,
  BoxProps,
  IconButton,
  Paper,
  Stack,
  TextField
} from '@mui/material'
import DataGridC from '@/components/DataGridC'
import usePageList, { PageListParams } from '@/hooks/usePageList'
import { TitlingData } from '@/routes/map/details/charts/Charts'
import { getTiltingDatas } from '@/service/manage/tilting-data'
import { GridColDef } from '@mui/x-data-grid'
import { TiltingData } from './tilting-data.types'
import moment from 'moment'
import { getDecimal } from '@/utils/tool'
import { Icons } from '@/assets/icons'

export type PageFilters = {
  treeId?: string
  sensorId?: string
  date?: Date
}

export type TreePageListParams = PageListParams<PageFilters>

interface DataListProps extends BoxProps {
  date: Date
}

const DataList = forwardRef<typeof Box, DataListProps>(
  ({ date, ...props }, ref) => {
    const {
      loading,
      filters,
      page,
      limit,
      listData,
      totalCount,
      refresh,
      changePage,
      changeFilters
    } = usePageList<PageFilters, TitlingData>('tiltingDatas', getTiltingDatas)

    useEffect(() => {
      changeFilters({ date })
    }, [date])

    return (
      <Paper sx={{ width: 1, height: 1, border: '1px solid #eeeeee' }}>
        <Stack sx={{ height: 1 }} spacing={1}>
          <Stack
            direction="row"
            sx={{ pl: 1, pt: 2, pr: 2 }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                size={'small'}
                value={filters?.treeId || ''}
                placeholder="Tree ID"
                variant="standard"
                onChange={(evt) => changeFilters({ treeId: evt.target.value })}
              />
              <TextField
                size={'small'}
                value={filters?.sensorId || ''}
                placeholder="Sensor ID"
                variant="standard"
                onChange={(evt) =>
                  changeFilters({ sensorId: evt.target.value })
                }
              />
            </Stack>

            <Stack direction="row">
              <IconButton
                disabled={loading}
                size="small"
                onClick={() => refresh()}>
                <Icons.Refresh
                  sx={{
                    ...(loading && { animation: 'spin 0.6s linear infinite' })
                  }}
                  fontSize="inherit"
                />
              </IconButton>
            </Stack>
          </Stack>
          <DataGridC
            sx={{ flexGrow: 1, borderLeft: 0, borderRight: 0, borderRadius: 0 }}
            rows={listData}
            rowCount={totalCount}
            getRowId={(row) => row.id}
            columns={getColumns()}
            pageSizeOptions={[15]}
            paginationModel={{
              page: page > 0 ? page - 1 : 0,
              pageSize: limit
            }}
            onPaginationModelChange={(params) => {
              const isPageSizeChanged = limit !== params.pageSize
              changePage({
                page: isPageSizeChanged ? 1 : params.page + 1,
                limit: params.pageSize
              })
            }}
          />
        </Stack>
      </Paper>
    )
  }
)

DataList.displayName = 'DataList'
export default DataList

const getColumns = (): GridColDef<TiltingData>[] => {
  return [
    {
      field: 'recordedAt',
      headerName: 'Recorded At',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false,
      valueFormatter: (row) => moment(row.value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      field: 'treeId',
      headerName: 'Tree ID',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false
    },
    {
      field: 'sensorId',
      headerName: 'User Tree ID',
      width: 180,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'tiltingAngle',
      headerName: 'Tilting Angle',
      width: 120,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => getDecimal(params.value, 2)
    },

    {
      field: 'azimuth',
      headerName: 'Azimuth',
      width: 120,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => getDecimal(params.value, 2)
    },
    {
      field: 'voltage',
      headerName: 'Voltage',
      width: 100,
      filterable: false,
      sortable: false,
      align: 'center',
      disableColumnMenu: true
    },
    {
      field: 'csq',
      headerName: 'Signal',
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center'
    },
    {
      field: 'temperature',
      headerName: 'Temperature',
      headerAlign: 'center',
      width: 120,
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const temperatureValue = params.value
        if (!temperatureValue) return '-'
        const temperature = temperatureValue / 100
        return temperature > 0 ? `${getDecimal(temperature)}°C` : 0
      }
    }
  ]
}
