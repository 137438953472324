import JsEncrypt from 'jsencrypt'

const PublicKey = process.env.REACT_APP_PUBLIC_KEY

export const encryptPassword = (password: string) => {
  if (!PublicKey) {
    console.error('Invalid public key when encrypting password')
    return
  }
  const encryptor = new JsEncrypt()
  encryptor.setPublicKey(PublicKey)

  return encryptor.encrypt(password) || ''
}
