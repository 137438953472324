import React, { useEffect, useState } from 'react'

import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { getRhrReadRead, getWindSpeed } from '@/service/home'
import { CardStyle } from '../home.const'
import { WeatherData, WeatherReponseData, WindSpeed } from './weather.types'
import { getTemporatureAndHumindity } from './weather.helper'
import moment from 'moment'
import Item from './Item'

const Weather = () => {
  const them = useTheme()
  const smallscreen = useMediaQuery(them.breakpoints.down('lg'))
  const [weather, setWeather] = useState<WeatherData | null>(null)
  const [wind, setWind] = useState<WindSpeed | null>(null)
  const [loading, setLoading] = useState(false)

  const getWeather = async () => {
    setLoading(true)

    const resp = await getRhrReadRead()
    const watherData = resp.data as WeatherReponseData
    const weatherNow = getTemporatureAndHumindity(watherData)
    setWeather(weatherNow)

    const windResp = await getWindSpeed()
    if (windResp?.code) {
      setWind(windResp.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    getWeather()
  }, [])

  return (
    <Stack
      sx={{
        ...CardStyle,
        height: 140,
        borderRadius: 2,
        flexShrink: 0,
        overflow: 'hidden',
        ...(smallscreen && { display: 'none' })
      }}
      justifyContent="space-between">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent={'center'}
        sx={{
          py: 0.5,
          px: 1,
          color: 'white',
          backgroundColor: 'primary.main'
        }}>
        <Typography sx={{ m: 0 }} variant="body2">
          {weather?.desc}
        </Typography>
        <Typography sx={{ m: 0 }} variant="body2">
          {moment(weather?.updateTime).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </Stack>

      <Stack
        sx={{ pl: 2, pr: 2, flexGrow: 1 }}
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
        spacing={2}>
        <Item
          loading={loading}
          name={'Wind Speed'}
          value={wind?.speed}
          unit={'km/h'}
          addition={wind?.direction === 'N/A' ? '' : wind?.direction}
        />
        <Item
          loading={loading}
          name={'Temperature'}
          value={weather?.temperature?.data?.value}
          unit={'°C'}
        />

        <Item
          loading={loading}
          name={'Humidity'}
          value={weather?.humidity?.data?.value}
          unit={'%'}
        />
        <Box
          sx={{
            borderRadius: 2,
            overflow: 'hidden'
          }}>
          {loading ? (
            <Skeleton animation={'wave'}>
              <img
                style={{ width: 50, height: 50 }}
                src={
                  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                }
                alt="Weather"
              />
            </Skeleton>
          ) : (
            <img src={weather?.icons[0]} alt="Weather" />
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

export default React.memo(Weather)
