import { StatusSumary } from '@/routes/esg/EsgStatusSum'
import request from '../request'
import {
  CarbonSummary,
  TreeSummary
} from '@/routes/esg/species-carbon/EsgSpaciesCarbon'
import { AlertSummary, DataPackageSummary } from '@/routes/esg/EsgData'

export const getEsgStatusSummary = () => {
  return request.get<StatusSumary>('esg/status-summary')
}

export const getEsgCarbonSummary = () => {
  return request.get<CarbonSummary>('esg/carbon-summary')
}

export const getEsgTreeSummary = () => {
  return request.get<TreeSummary>('esg/tree-summary')
}

export const getEsgDataSummary = () => {
  return request.get<DataPackageSummary>('esg/data-summary')
}

export const getEsgAlertSummary = () => {
  return request.get<AlertSummary>('esg/alert-summary')
}
