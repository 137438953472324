import React, { FC, useState, useEffect } from 'react'
import {
  Typography,
  Slide,
  Stack,
  Divider,
  IconButton,
  Button,
  Paper
} from '@mui/material'
import moment from 'moment'
import DatesPicker from '@/components/DatesPicker'

import Attributes from './Attributes'
import Status from './Status'
import Sensor from './Sensor'
import { MapTreeDetailsType } from '../map.types'
import { Icons } from '@/assets/icons'
import { grey, red } from '@mui/material/colors'
import { getTreeDetails } from '@/service/map'
import Charts from './charts/Charts'
import TipIcon from '@/components/TipIcon'
import { AlertTypeEnum } from '@/routes/data/special-alerts/special-alerts.types'
import Download from './Download'
import TreeImages from './TreeImages'
import LeafiPlate from '@/routes/manage/data/trees/actions/LeafiPlate'
import TipIconBtn from '@/components/TipIconBtn'

interface IPropTypes {
  open: boolean
  treeId: string
  showTreeId?: boolean
  onClose?: () => void
  onEdit?: () => void
}

const TreeDetails: FC<IPropTypes> = ({
  open,
  treeId,
  showTreeId = true,
  onClose,
  onEdit
}) => {
  const [loading, setLoading] = useState(false)
  const [treeDetails, setTreeDetails] = useState<MapTreeDetailsType | null>(
    null
  )
  const [dataPeriod, setDataPeriod] = useState<[Date, Date]>([
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
    new Date()
  ])

  const [showPlate, setShowPlate] = useState(false)

  const tiltingAlert = treeDetails?.alerts?.find(
    ({ type }) => type === AlertTypeEnum.Tilting
  )

  const vibrationAlert = treeDetails?.alerts?.find(
    ({ type }) => type === AlertTypeEnum.Vibration
  )

  const fetchMapTreeDetails = async (id) => {
    setLoading(true)
    const resp = await getTreeDetails(id)
    if (resp?.code === 200) {
      setTreeDetails(resp.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (open && treeId) {
      fetchMapTreeDetails(treeId)
    }
  }, [treeId, open])

  return (
    <Slide direction="left" in={open} exit={!open}>
      <Paper
        sx={{
          position: 'absolute',
          zIndex: 100,
          right: 0,
          top: 0,
          width: 480,
          height: '100%'
        }}
        elevation={8}>
        {open && (
          <>
            <Stack
              sx={{
                p: 1,
                height: 1,
                overflow: 'auto'
              }}>
              <Stack
                direction="row"
                sx={{ p: 2 }}
                justifyContent="space-between"
                alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <TipIconBtn
                    size="small"
                    tip={
                      treeDetails?.plateId
                        ? `LeafiPlate ID: ${treeDetails?.plateId}`
                        : 'LeafiPlate is not available yet'
                    }
                    onClick={() => {
                      if (!treeDetails?.plateId) {
                        return
                      }
                      setShowPlate(true)
                    }}>
                    {treeDetails?.plateId ? (
                      <Icons.LeafiPlateOn style={{ cursor: 'pointer' }} />
                    ) : (
                      <Icons.LeafiPlateOff style={{ cursor: 'pointer' }} />
                    )}
                  </TipIconBtn>

                  <Typography variant={'h6'}>
                    {treeDetails?.userTreeId ? treeDetails?.userTreeId : treeId}
                  </Typography>

                  <TipIcon
                    title={
                      tiltingAlert?.active
                        ? `The latest alert detected at ${moment(
                            tiltingAlert.endedAt
                          ).format('YYYY-MM-DD HH:mm:ss')}`
                        : ''
                    }>
                    <Icons.ReportProblemOutlined
                      fontSize="inherit"
                      sx={{
                        color: tiltingAlert?.active ? red[400] : grey[300],
                        cursor: 'pointer'
                      }}
                    />
                  </TipIcon>

                  <TipIcon
                    title={
                      vibrationAlert?.active
                        ? `Vibration detected at ${moment(
                            treeDetails?.vibration?.at
                          ).format('YYYY-MM-DD HH:mm:ss')}`
                        : ''
                    }>
                    <Icons.Vibration
                      fontSize="inherit"
                      sx={{
                        color: vibrationAlert?.active ? red[400] : grey[300],
                        cursor: 'pointer'
                      }}
                    />
                  </TipIcon>

                  <TipIcon
                    placement="top"
                    title={`Maintenance status ${
                      treeDetails?.maintenance ? 'ON' : 'OFF'
                    }`}>
                    <Icons.HandymanOutlined
                      fontSize="inherit"
                      sx={{
                        color: treeDetails?.maintenance ? red[400] : grey[300],
                        cursor: 'pointer'
                      }}
                    />
                  </TipIcon>

                  {loading && (
                    <Icons.RotateRight
                      sx={{
                        animation: 'spin 0.6s linear infinite',
                        color: grey[300]
                      }}
                    />
                  )}
                </Stack>
                <Stack direction="row" alignItems={'center'}>
                  {onEdit && <Button onClick={() => onEdit()}>Edit</Button>}
                  <IconButton
                    size="small"
                    sx={{ color: grey[600] }}
                    onClick={() => {
                      onClose()
                      setTreeDetails(null)
                    }}>
                    <Icons.Close />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack sx={{ flexGrow: 1, overflow: 'auto' }} spacing={3}>
                <TreeImages tree={treeDetails} />
                <Attributes
                  showTreeId={showTreeId}
                  tree={treeDetails}></Attributes>
                <Divider sx={{ opacity: 0.6 }} />
                <Status tree={treeDetails}></Status>
                <Divider sx={{ opacity: 0.6 }} />
                <Sensor tree={treeDetails}></Sensor>
                <Divider sx={{ opacity: 0.6 }} />
                <Stack
                  sx={{ pl: 2, pr: 2 }}
                  direction="row"
                  justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icons.DateRangeOutlined
                      fontSize="small"
                      sx={{ color: grey[600] }}
                    />
                    <DatesPicker
                      dates={dataPeriod}
                      onDatesChange={(dates) => setDataPeriod(dates)}
                    />
                  </Stack>

                  <Download
                    treeId={treeDetails?.treeId}
                    userTreeId={treeDetails?.userTreeId}
                    period={dataPeriod}
                  />
                </Stack>
                <Charts period={dataPeriod} type="tilting" treeId={treeId} />
                <Charts period={dataPeriod} type="azimuth" treeId={treeId} />
                <Charts
                  period={dataPeriod}
                  type="tilting-level"
                  treeId={treeId}
                />
                <Charts
                  period={dataPeriod}
                  type="temperature"
                  treeId={treeId}
                />
              </Stack>
            </Stack>
          </>
        )}
        <LeafiPlate
          viewOnly
          open={showPlate}
          treeId={treeId}
          plateId={treeDetails?.plateId}
          onClose={() => setShowPlate(false)}
        />
      </Paper>
    </Slide>
  )
}

export default React.memo(TreeDetails)
