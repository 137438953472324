import React, { FC, forwardRef, useEffect } from 'react'

import { Typography, Stack, alpha, StackProps } from '@mui/material'
import { ModeInfo } from './mode.types'
import { getMode } from '@/service/home'
import { Icons } from '@/assets/icons'
import { grey, lightGreen } from '@mui/material/colors'
import TipIcon from '@/components/TipIcon'
import ModeDesc from './ModeDesc'
import CurrentWarning from './CurrentWarning'

interface ModeProps extends StackProps {}

const Mode = forwardRef<typeof Stack, ModeProps>(({ sx, ...props }, ref) => {
  const [mode, setMode] = React.useState<ModeInfo | null>(null)
  const [showDetails, setShowDetails] = React.useState(false)

  useEffect(() => {
    getMode().then((resp) => {
      if (resp?.code === 200) {
        setMode(resp.data)
      }
    })
  }, [])

  return (
    <>
      <Stack
        justifyContent={'space-between'}
        sx={{
          position: 'relative',
          pt: 1,
          borderRadius: 2,
          color: 'white',
          backgroundColor: 'secondary.main',
          cursor: 'pointer',
          flex: 1,
          '&:hover': {
            borderColor: lightGreen['A400'],
            boxShadow: `0 0 16px 4px ${lightGreen['A100']}`
          },
          ...sx
        }}
        onClick={() => setShowDetails(true)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: 1, pr: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
              CURRENT MODE
            </Typography>

            <TipIcon
              title={
                <Typography variant="body2">
                  The monitoring system could adjust as varying sampling
                  frequency modes wherein the high sampling frequency modes are
                  activated in a typhoon season or during an adverse weather
                  condition, and the low sampling frequency mode is activated in
                  a normal weather condition to maximize data capture for an
                  unusual pattern visualization.
                </Typography>
              }>
              <Icons.HelpOutline
                fontSize="inherit"
                sx={{ color: alpha(grey[900], 0.5) }}
              />
            </TipIcon>
          </Stack>
          <CurrentWarning mode={mode} />
        </Stack>

        <Stack flexGrow={1} justifyContent="center" alignItems="center">
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              position: 'relative',
              width: 40,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: alpha(grey[900], 0.2)
            }}>
            {mode?.mode}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderRadius: '0 0 8px 8px',
            backgroundColor: alpha(grey[900], 0.2),
            pl: 1,
            pr: 1,
            pt: 0.25,
            pb: 0.25
          }}>
          <Typography variant="caption">Uplink Interval</Typography>
          <Typography variant="caption">{mode?.interval} min</Typography>
        </Stack>
      </Stack>
      <ModeDesc open={showDetails} onClose={() => setShowDetails(false)} />
    </>
  )
})

export default Mode
