import React, { FC } from 'react'
import { Typography, Tooltip, Stack } from '@mui/material'
import { FieledNames, Units } from './details.const'
import CopyButton from '@/components/CopyButton'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'

interface IPropTypes {
  isLoading?: boolean
  value: string | number
  name: string
  type?: string
  tip?: string
  copyble?: boolean
}

const Item: FC<IPropTypes> = ({
  name,
  value,
  type = 'string',
  tip,
  copyble = false
}) => {

  return (
    <Stack direction="row" alignItems={'center'}>
      <Stack
        sx={{ width: 160, flexShrink: 0 }}
        direction="row"
        alignItems="center"
        spacing={1}>
        <Typography variant="body2">{FieledNames[name]}</Typography>
        {tip && (
          <Tooltip title={tip}>
            <Icons.HelpOutline sx={{ color: grey[400] }} fontSize="inherit" />
          </Tooltip>
        )}
      </Stack>

      <Typography sx={{ flexFlow: 1, m: 0 }} variant="body2" paragraph>
        {type === 'date' && (value || '-')}

        {type === 'number' &&
          (!value && value !== 0 ? '-' : `${value} ${Units[name] || ''}`)}

        {type === 'string' &&
          (value?.toString().length > 30 ? (
            <Tooltip title={value}>
              <Typography variant="body2" fontSize="inherit">
                {value && `${value?.toString().substring(0, 27)}...`}
              </Typography>
            </Tooltip>
          ) : (
            value
          ))}

        {name === 'azimuth' && (
          <Icons.Compass
            style={{
              marginLeft: 5,
              transform: `rotate(${value}deg)`,
              verticalAlign: 'middle'
            }}
          />
        )}
        {copyble && (
          <CopyButton title={FieledNames[name]} value={value?.toString()} />
        )}
      </Typography>
    </Stack>
  )
}

export default Item
