import { styled, TextField, TextFieldProps } from '@mui/material'

const TextFieldStyled = styled(TextField)(() => {
  return {
    '& .MuiTextField-root': {
      backgroundColor: '#ffffff !important'
    }
  }
})

const TextFieldC = (props: TextFieldProps) => {
  return (
    <TextFieldStyled
      sx={{ bgcolor: '#ffffff' }}
      InputProps={{ style: { backgroundColor: '#ffffff ' } }}
      {...props}
    />
  )
}

export default TextFieldC
