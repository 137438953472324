import { TreeDataStatusEnum } from "./import-trees.type"

export const TreeDataStatusText = {
    [TreeDataStatusEnum.Pending]: 'Pending',
    [TreeDataStatusEnum.Processing]: 'Processing',
    [TreeDataStatusEnum.Uploaded]: 'Uploaded',
    [TreeDataStatusEnum.Updated]: 'Updated',
    [TreeDataStatusEnum.Failed]: 'Failed',
    [TreeDataStatusEnum.Existed]: 'Existed',
    [TreeDataStatusEnum.Duplicated]: 'Duplicated'
}

export const TreeDataStatusColor = {
    [TreeDataStatusEnum.Pending]: 'default',
    [TreeDataStatusEnum.Uploaded]: 'success',
    [TreeDataStatusEnum.Updated]: 'success',
    [TreeDataStatusEnum.Failed]: 'error',
    [TreeDataStatusEnum.Existed]: 'secondary',
    [TreeDataStatusEnum.Duplicated]: 'warning'
}