import { MapTreeResp } from '@/store/reducers/map/map.types'
import request from '../request'
import { TreesFilterType } from '@/routes/map/map.types'

export const getManageTreeTotalcount = () => {
  return request.get<number>(`manage/map/tree-total-count`)
}

export const getManageMapTrees = (params: TreesFilterType) => {
  return request.get<{
    trees: MapTreeResp[]
    totalCount: number
  }>(`manage/map/trees`, { data: params })
}

export const getStorageSize = () => {
  return request.get<number>(`manage/map/data-storage`)
}

export const getBlockchainCount = () => {
  return request.get<number>(`manage/map/blockchain-count`)
}

export const getLatestDataTime = () => {
  return request.get<Date>(`manage/map/latest-data-time`)
}

export const sendDailyReport = () => {
  return request.post<Date>(`data/report/send-daily-report`)
}
