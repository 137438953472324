import { AzimuthChartData } from './chart.types'
import moment from 'moment'

import { getDecimal } from '@/utils/tool'
import { gradientColor } from '@/utils/color'
import { ChartColors } from './chart.const'

export const getAzimuthOption = (data: AzimuthChartData) => {
  const dataLength = data.dataset.length
  const maxTilting = Math.max(...data.dataset.map((item) => item[0])) * 1.1

  const colors = gradientColor('#00b87e', '#b80000', dataLength, 0.35)
  const icon = (color) => {
    return `<span style="display: inline-block; width: 10px; height: 10px; border-radius: 18px; background-color: ${color}"></span>`
  }

  return {
    polar: {},
    tooltip: {
      trigger: 'item',
      axisPointer: { type: 'line' },
      formatter: function (params) {
        const dataIndex = params.dataIndex
        const datetime = data.datetime[dataIndex]
        const datetimeStr =
          datetime && moment(datetime).format('YYYY-MM-DD HH:mm')
        const mark =
          dataIndex === 0
            ? ' Start at '
            : dataIndex === dataLength - 1
            ? ' End at'
            : ''
        return `
              ${icon(params.color)} ${mark} ${datetimeStr}  <br />  
              Tilting Δ (°) <b>${getDecimal(params.value[0], 2)}°</b> <br /> 
              Azimuth Δ (°) <b>${getDecimal(params.value[1], 2)}°</b>
              `
      },
      extraCssText: 'font-size: 14px; text-align:left;'
    },
    angleAxis: {
      type: 'value',
      max: 360,
      min: 0,
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        formatter: (value) => `${getDecimal(value, 2)}°`
      },
      splitLine: { show: true, lineStyle: { type: 'dashed' } }
    },
    radiusAxis: {
      max: maxTilting,
      min: 0,
      axisLine: { show: true },
      axisLabel: {
        rotate: 45,
        formatter: (value) => `${getDecimal(value, 2)}°`
      },
      splitLine: { show: true, lineStyle: { type: 'dashed' } }
    },
    series: [
      {
        type: 'scatter',
        coordinateSystem: 'polar',
        data: data.dataset,
        itemStyle: {
          color: (params) => {
            if (params.dataIndex === 0 || params.dataIndex === dataLength - 1) {
              return ChartColors.axisText
            } else {
              return colors[params.dataIndex]
            }
          }
        },
        showAllSymbol: true,
        symbol: (_, params) => {
          switch (params.dataIndex) {
            case 0: {
              return 'pin'
            }
            case dataLength - 1: {
              return 'triangle'
            }
            default: {
              return 'circle'
            }
          }
        },
        symbolSize: (_, params) => {
          switch (params.dataIndex) {
            case 0: {
              return 15
            }
            case dataLength - 1: {
              return 12
            }
            default: {
              return 10
            }
          }
        }
      }
    ]
  }
}
