import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Stack, Typography } from '@mui/material'
import {
  getTreeTiltingData,
  getTreeTiltingDataTotalCount,
  getTiltingVibrationResetTotalCount,
  getTiltingVibrationReset
} from '@/service/map'
import {
  TiltingChartData,
  AzimuthChartData,
  TemporatureChartData
} from './chart.types'
import moment from 'moment'
import { getDecimal } from '@/utils/tool'
import * as echarts from 'echarts/core'
import { getTiltingOptions } from './tilting.options'
import { getAzimuthOption } from './azimuth.options'
import { getTiltingLevelOptions } from './tilting-level.options'
import { getTemperatureOption } from './temperature.options'
import { ChartTitles } from './chart.const'
import { Icons } from '@/assets/icons'
import { grey } from '@mui/material/colors'

export type TitlingData = {
  recordedAt: Date
  tiltingAngle: number
  azimuth: number
  temperature: number
}
export type TitlingLevelData = {
  recordedAt: Date
  tiltingAngle: number
  tiltingResetValue: number
  vibration: boolean
}

interface TilingChartProps extends BoxProps {
  treeId: string
  type: 'tilting' | 'azimuth' | 'tilting-level' | 'temperature'
  period: [Date, Date]
}

const Charts = forwardRef<typeof Box, TilingChartProps>(
  ({ type, treeId, period, ...props }, ref) => {
    const chartElemRef = useRef(null)
    const chartInsRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    const [tiltingData, setTiltingData] = useState<TitlingData[]>([])

    const [tiltingVibrationData, setTiltingVibrationData] = useState<
      TitlingLevelData[]
    >([])

    const fetchTiltingData = async () => {
      if (isLoading || !treeId) {
        return
      }
      setIsLoading(true)

      let totalCount = 0
      const totalCountResp = await getTreeTiltingDataTotalCount(
        treeId,
        period.map((d) => d.getTime())
      )
      if (totalCountResp?.code === 200) {
        totalCount = totalCountResp?.data
      }

      const limit = 10
      const totalPage = Math.ceil(totalCount / limit)
      const newTiltingDatas = []

      for (let i = 0; i < totalPage; i++) {
        const page = i + 1
        setProgress(Math.floor((page / totalPage) * 100))
        const tiltingResp = await getTreeTiltingData({
          treeId: treeId,
          period: period.map((d) => d.getTime()),
          page,
          limit
        })
        if (tiltingResp?.code === 200) {
          newTiltingDatas.push(...(tiltingResp?.data?.data || []))
        }
      }

      setTiltingData(newTiltingDatas)
      setIsLoading(false)
      setProgress(0)
    }

    const fetchTiltingLevel = async () => {
      if (isLoading || !treeId) {
        return
      }
      setIsLoading(true)
      const dates = period.map((d) => d.getTime())

      let totalCount = 0
      const totalCountResp = await getTiltingVibrationResetTotalCount(
        treeId,
        dates
      )
      if (totalCountResp?.code === 200) {
        totalCount = totalCountResp?.data
      }

      const limit = 10
      const totalPage = Math.ceil(totalCount / limit)
      const newDateset = []
      for (let i = 0; i < totalPage; i++) {
        const page = i + 1
        setProgress(Math.floor((page / totalPage) * 100))
        const tiltingResp = await getTiltingVibrationReset({
          treeId: treeId,
          period: period.map((d) => d.getTime()),
          page,
          limit
        })
        if (tiltingResp?.code === 200) {
          newDateset.push(...(tiltingResp?.data?.data || []))
        }
      }
      setTiltingVibrationData(newDateset)
      setIsLoading(false)
      setProgress(0)
    }

    const updateTiltingChart = () => {
      if (!chartInsRef.current) return

      const chartData = tiltingData.reduce<TiltingChartData>(
        (acc, item) => {
          acc.x.push(moment(item.recordedAt).format('YYYY-MM-DD HH:mm'))
          acc.tiltingAngle.push(item.tiltingAngle)
          return acc
        },
        { x: [], tiltingAngle: [] }
      )
      const chartOptions = getTiltingOptions(chartData)
      chartInsRef.current.setOption(chartOptions)
    }

    const updateTiltingLevelChart = () => {
      if (!chartInsRef.current) return

      const chartData = tiltingVibrationData.reduce<TiltingChartData>(
        (acc, item) => {
          acc.x.push(moment(item.recordedAt).format('YYYY-MM-DD HH:mm'))
          acc.tiltingAngle.push(item?.tiltingAngle)
          acc.vibraiton.push(item?.vibration ? item?.tiltingAngle : 0)
          acc.tiltingResetValue.push(item?.tiltingResetValue ?? 0)
          return acc
        },
        { x: [], tiltingAngle: [], vibraiton: [], tiltingResetValue: [] }
      )

      const chartOptions = getTiltingLevelOptions(chartData)
      chartInsRef.current.setOption(chartOptions)
    }

    const updateAzimuthChart = () => {
      if (!chartInsRef.current) return

      const chartData = tiltingData.reduce(
        (acc, item) => {
          acc.datetime.push(moment(item.recordedAt).format('YYYY-MM-DD HH:mm'))
          acc.dataset.push([item.tiltingAngle, item.azimuth])
          return acc
        },
        { datetime: [], dataset: [] } as AzimuthChartData
      )

      const chartOptions = getAzimuthOption(chartData)
      chartInsRef.current.setOption(chartOptions)
    }

    const updateTemporatureChart = () => {
      if (!chartInsRef.current) return

      const chartData = tiltingData.reduce(
        (acc, item) => {
          acc.x.push(moment(item.recordedAt).format('YYYY-MM-DD HH:mm'))
          acc.y.push(getDecimal(item.temperature / 100))
          return acc
        },
        { x: [], y: [] } as TemporatureChartData
      )

      const chartOptions = getTemperatureOption(chartData)
      chartInsRef.current.setOption(chartOptions)
    }

    useEffect(() => {
      if (!chartInsRef.current) {
        chartInsRef.current = echarts.init(chartElemRef.current)
      }
    }, [])

    useEffect(() => {
      switch (type) {
        case 'tilting':
          updateTiltingChart()
          break
        case 'azimuth':
          updateAzimuthChart()
          break
        case 'tilting-level':
          updateTiltingLevelChart()
          break
        case 'temperature':
          updateTemporatureChart()
          break
        default: {
        }
      }
    }, [tiltingData, tiltingVibrationData])

    useEffect(() => {
      switch (type) {
        case 'azimuth':
        case 'tilting':
        case 'temperature':
          fetchTiltingData()
          break
        case 'tilting-level':
          fetchTiltingLevel()
          break
        default: {
        }
      }
    }, [treeId, period])

    return (
      <Stack
        sx={{
          height: type !== 'azimuth' ? 240 : 400,
          pl: 2,
          pr: 2,
          mb: 3,
          flexShrink: 0
        }}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row">
          <Typography variant={'subtitle2'} sx={{ flexShrink: 0 }}>
            {ChartTitles[type]}
          </Typography>
          {isLoading && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}>
              <Typography variant="body2">{progress}%</Typography>
              <Icons.RotateRight
                sx={{
                  animation: 'spin 0.6s linear infinite',
                  color: grey[600]
                }}
              />
            </Stack>
          )}
        </Stack>
        <Box
          ref={chartElemRef}
          sx={{ flexGrow: 1, opacity: isLoading ? 0.3 : 1 }}></Box>
      </Stack>
    )
  }
)

Charts.displayName = 'DetailCharts'
export default React.memo(Charts)
