import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalState } from './global.types'
import { TreeAlertStatusEnum } from '@/routes/manage/data/trees/trees.types'
import { AlertListType } from '@/components/AlertList'

const DefNavigation: GlobalState['navigation'] = {
  type: 'public',
  showText: true
}
const DefNavMenuPath: GlobalState['navMenuPath'] = {
  public: '/home',
  private: '/manage-overview'
}

const initialState = {
  navigation: DefNavigation,
  navMenuPath: DefNavMenuPath,
  treeAlert: {
    showList: false,
    showListType: 'any',
    distribution: {
      total: 0,
      tilting: 0,
      vibration: 0,
      disconnection: 0
    }
  }
} as GlobalState

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setNotification(state, action: PayloadAction<GlobalState['notification']>) {
      state.notification = action.payload
    },
    setAlertListStatus(
      state,
      action: PayloadAction<{ show: boolean; type: AlertListType }>
    ) {
      state.treeAlert.showList = action.payload.show
      state.treeAlert.showListType = action.payload.type
    },
    setTreeAlertDistribution(
      state,
      action: PayloadAction<GlobalState['treeAlert']['distribution']>
    ) {
      state.treeAlert.distribution = action.payload
    },
    resetNavigation(state) {
      state.navigation = DefNavigation
    },
    setNavigationShowText(state, action: PayloadAction<boolean>) {
      state.navigation.showText = action.payload
    },
    setNavMenuPath(
      state,
      action: PayloadAction<{
        type: 'public' | 'private'
        path: string
      }>
    ) {
      state.navMenuPath[action.payload.type] = action.payload.path
    },
    setNavigationType(state, action: PayloadAction<'public' | 'private'>) {
      state.navigation.type = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export default globalSlice.reducer
