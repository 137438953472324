import React, { forwardRef } from 'react'
import { SxProps, Tooltip, TooltipProps } from '@mui/material'

interface TipIconProps extends TooltipProps {}

const TipIcon = forwardRef<typeof Tooltip, TipIconProps>(
  ({ title, children, placement, ...props }, ref) => {
    return (
      <Tooltip title={title} placement={placement}>
        {children}
      </Tooltip>
    )
  }
)

TipIcon.displayName = 'TipIcon'
export default TipIcon
