import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MapState } from './map.types'
import { getTreesAsync } from './map.thunks'
import { FilterTypeEnum, MapTreeType } from '@/routes/map/map.types'
import { DefaultFilters } from '@/routes/map/view/Filters'

const initialState = {
  isLoadingTrees: false,
  treeTotalCount: 0,
  treeLoadedCount: 0,
  trees: [],
  filters: DefaultFilters
} as MapState

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setTrees(state, action: PayloadAction<MapTreeType[]>) {
      state.trees = action.payload
    },
    setTreeTotalcount(state, action: PayloadAction<number>) {
      state.treeTotalCount = action.payload
    },
    setTreeLoadedCount(state, action: PayloadAction<number>) {
      state.treeLoadedCount = action.payload
    },
    setMapFilter(state, action: PayloadAction<FilterTypeEnum[]>) {
      state.filters = action.payload
    }
  },
  extraReducers: (builder) => {
    // Trees
    builder.addCase(getTreesAsync.pending, (state) => {
      state.isLoadingTrees = true
    })
    builder.addCase(getTreesAsync.fulfilled, (state, { payload }) => {
      state.isLoadingTrees = false
      state.trees = payload
    })
    builder.addCase(getTreesAsync.rejected, (state) => {
      state.isLoadingTrees = false
    })
  }
})

export default mapSlice.reducer
