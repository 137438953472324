import React, { FC, useRef, forwardRef, useEffect } from 'react'

import { Tiltings } from '@/store/reducers/home/home.types'
import { Box, Stack, StackProps } from '@mui/material'
import TiltingImage from '@/assets/images/home/tree-tilted.svg'
import { ECharts } from 'echarts/core'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { TiltingLevelColors } from './TiltingLevel'
import { getTiltingChartOptions } from './tilting-level.helper'

echarts.use([PieChart, TitleComponent, TooltipComponent, CanvasRenderer])

interface TiltingChartProps extends StackProps {
  values: Tiltings
}

const TiltingChart = forwardRef<typeof Stack, TiltingChartProps>(
  ({ sx, values, ...props }, ref) => {
    const chartRef = useRef(null)
    const chartInsRef = useRef<ECharts | null>(null)

    useEffect(() => {
      if (chartInsRef.current) {
        chartInsRef.current.resize()
      }
      if (chartRef.current && !chartInsRef.current) {
        chartInsRef.current = echarts.init(chartRef.current)
      }
    }, [chartRef, chartInsRef])

    useEffect(() => {
      if (chartInsRef.current) {
        const options = getTiltingChartOptions(values)
        chartInsRef.current.setOption(options)
      }
    }, [values, chartInsRef])

    return (
      <Box
        sx={{
          ...sx,
          minWidth: 140,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${TiltingImage})`
        }}
        ref={chartRef}></Box>
    )
  }
)

export default TiltingChart
