import { Moment } from 'moment'
import { ServiceTypeEnum } from '../organization/organization.types'
import { RegionEnum } from '@/configs/config.regions'
import { Group } from '@/routes/setting/users/groups/group.types'

export enum RolesEnum {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER'
}

export type Subscription = {
  type: ServiceTypeEnum
  expiredAt: Date
}

export type OrganizationOptions = { id: string; name: string; fullname: string }
export type GroupOptions = { id: string; name: string; isDefault: boolean }

export type User = {
  id: string
  __v: number
  username: string
  password: string
  nickname: string
  regionId: RegionEnum
  group: {
    organizationId: string
    groupId: string
  }
  groupDetail?: Group
  email: string
  organizationDetail: {
    id: string
    name: string,
    disabled: boolean,
    deleted: boolean
  }
  additionalEmails: string[]
  phone: string
  role: RolesEnum
  isDefault?: boolean
  disabled: boolean
  expiredAt?: Moment | Date
  subscriptions: Subscription[]
  isLeafiotAdmin?: boolean
}

export type NewUser = {
  username: string
  nickname: string
  password: string
  regionId: RegionEnum
  organizationId: string
  groupId: string

  email: string
  additionalEmails: string[]
  phone: string
  role: RolesEnum
  disabled: boolean
}

export type UpdateUser = {
  nickname: string
  regionId: RegionEnum
  organizationId: string
  groupId: string
  email: string
  additionalEmails?: string[]
  phone: string
  role: RolesEnum
  disabled: boolean
}
