import React, { forwardRef, useEffect, useState } from 'react'
import { Paper, Stack, StackProps, Typography } from '@mui/material'
import { getStorageSize } from '@/service/manage/map'

interface DataStorageProps extends StackProps {}

const DataStorage = forwardRef<typeof Stack, DataStorageProps>(
  ({ ...props }, ref) => {
    const [dataAmount, setDataAmount] = useState<number>(0)
    const getDataAmount = async () => {
      const resp = await getStorageSize()
      if (resp?.code === 200) {
        setDataAmount(resp.data)
      }
    }
    useEffect(() => {
      getDataAmount()
    }, [])
    return (
      <Paper sx={{ width: 1, height: 1, px: 2, py: 1 }}>
        <Stack>
          <Typography sx={{ m: 0 }} paragraph variant="overline">
            Data Amount
          </Typography>
          <Typography sx={{ mb: 0 }} paragraph variant="h6">
            {dataAmount.toLocaleString()}
          </Typography>
        </Stack>
      </Paper>
    )
  }
)

DataStorage.displayName = 'DataStorage'
export default DataStorage
