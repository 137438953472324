import React, { forwardRef, useEffect } from 'react'
import {
  MenuList,
  MenuListProps,
  MenuItem,
  MenuItemProps,
  SxProps,
  useTheme,
  useMediaQuery,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '@/store'
import { PrivateRoutesPaths, PublicRoutesPaths } from '@/routes/Routes'
import { globalSlice } from '@/store/reducers/global/global.slice'

export type NavMenu = {
  icon: React.ReactNode
  text: string
  routePath: string
  seletedPath: string
}

interface NavMenusProps extends MenuListProps {
  menus: NavMenu[]
}

const NavMenus = forwardRef<typeof MenuList, NavMenusProps>(
  ({ menus, ...props }, ref) => {
    const dispatch = useAppDispatch()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const navigation = useAppSelector((state) => state.global.navigation)

    const changeNavigationPath = (params: {
      type: 'public' | 'private'
      path: string
    }) => {
      dispatch(globalSlice.actions.setNavMenuPath(params))
    }

    const changeNavigationType = (type: 'public' | 'private') => {
      dispatch(globalSlice.actions.setNavigationType(type))
    }

    // Listen route path
    // to cache and highlightcurrent navigation and page menus
    useEffect(() => {
      if (PublicRoutesPaths.includes(pathname)) {
        changeNavigationPath({ type: 'public', path: pathname })
      }
      if (PrivateRoutesPaths.includes(pathname)) {
        changeNavigationPath({ type: 'private', path: pathname })
      }
    }, [pathname])

    // Set navigation menu to admin section
    // When it is the route path of the admin menu on the page reload
    // pathname: /manage/map
    // adminPaths; [manage/map,...]
    useEffect(() => {
      if (
        PrivateRoutesPaths.includes(pathname) &&
        navigation.type === 'public'
      ) {
        changeNavigationType('private')
      }
    }, [pathname, navigation])

    return (
      <MenuList
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {menus.map((menu) => {
          return (
            <NavigationItem
              key={menu.seletedPath}
              selected={!!matchPath(menu.seletedPath, pathname)}
              showText={navigation.showText}
              icon={menu.icon}
              text={menu.text}
              onClick={() => navigate(menu.routePath)}
            />
          )
        })}
      </MenuList>
    )
  }
)

NavMenus.displayName = 'NavMenus'
export default NavMenus

const getActiveStyle = (isActive): SxProps => {
  return isActive ? { ...basicStyle, color: 'primary.main' } : basicStyle
}

const basicStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'text.secondary',
  width: 1,
  pt: 2,
  pb: 2
}

interface NavigationItemProps extends MenuItemProps {
  icon: React.ReactNode
  text: string
  showText: boolean
}

const NavigationItem = forwardRef<typeof MenuItem, NavigationItemProps>(
  ({ selected, icon, text, showText, onClick, ...props }, ref) => {
    const them = useTheme()
    const smallscreen = useMediaQuery(them.breakpoints.down('md'))

    return (
      <MenuItem
        selected={selected}
        sx={{
          ...getActiveStyle(selected),
          ...(smallscreen && { pt: 0.5, pb: 0.5 }),
          alignItems: 'center'
        }}
        onClick={onClick}>
        {showText ? (
          <>
            <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </>
        ) : (
          icon
        )}
      </MenuItem>
    )
  }
)
