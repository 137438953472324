export type DayDataItem = {
  day: string
  dataCount: number
}

export type TiltingData = {
  _id: string
  recordedAt: Date
  createdAt: Date
  treeId: string
  sensorId: string
  tiltingAngle: string
  azimuth: string
  voltage: string
  csq: string
  temperature: string
}

export enum DataTaskTypeEnum {
  Repair = 'REPAIR'
}

export enum DataTaskStatusEnum {
  Running = 'RUNNING',
  Failed = 'FAILED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  Success = 'SUCCESS'
}

export enum RepairPeriodEnum {
  Day = 'day',
  Month = 'month',
  Year = 'year'
}

type StopOrTerminateRecord = {
  status: boolean
  at: Date
}

export type StopRepairTaskAction = 'suspend' | 'terminate'

export class RepairTiltingDataTask {
  _id: string
  createdAt: Date
  status: DataTaskStatusEnum
  repairPeriod: RepairPeriodEnum
  inputDate: Date
  suspended: StopOrTerminateRecord
  terminated: StopOrTerminateRecord
  progress: {
    totoalDateCount: number
    executingDateIdx: number
    executingHourIdx: number
    percentage: number
  }
  remark: string
}
