import React, { forwardRef, useState } from 'react'
import {
  Paper,
  Stack,
  Box,
  IconButton,
  Chip,
  Tooltip,
  TextField,
  BoxProps,
  Button
} from '@mui/material'
import moment, { Moment } from 'moment'
import { PaginationParamsType } from '@/configs/config.types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Icons } from '@/assets/icons'
import { useAppSelector } from '@/store'
import TipIconBtn from '@/components/TipIconBtn'
import { GridColDef } from '@mui/x-data-grid'
import { grey } from '@mui/material/colors'
import { getSpecialAlerts } from '@/service/data/report'
import { SpecialAlert } from './special-alerts.types'
import EmailSnapshot from '../../manage/report/EmailSnapshot'
import { ReportTypeEnum } from '@/routes/manage/report/report.types'
import AlertList from '@/components/AlertList'
import { useNavigate } from 'react-router-dom'
import usePageList from '@/hooks/usePageList'
import DataGridC from '@/components/DataGridC'
import CopyButton from '@/components/CopyButton'
import { PublicAlertListStyle } from '@/routes/public.const'

interface SpecialAlertsProps extends BoxProps {}

export type PageParamsType = PaginationParamsType & {
  date: Moment
  reportId?: string
}

const SpecialAlerts = forwardRef<typeof Box, SpecialAlertsProps>(
  ({ ...props }, ref) => {
    const navigate = useNavigate()
    const user = useAppSelector((state) => state.users.user)

    const {
      loading,
      filters,
      page,
      limit,
      listData,
      totalCount,
      refresh,
      changePage,
      changeFilters
    } = usePageList<{ date?: Moment; reportId?: string }, SpecialAlert>(
      'specialAlerts',
      getSpecialAlerts
    )

    const [actionParams, setActionParams] = useState<{
      type: 'snapshot' | 'resend'
      data: SpecialAlert
    } | null>(null)

    const showTreeOnMap = async (treeId: string) => {
      navigate('/map', { state: { navTreeId: treeId } })
    }

    return (
      <>
        <Stack sx={{ height: 1 }} spacing={1}>
          <Stack
            sx={{ flexShrink: 0 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack direction="row" spacing={1}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{ backgroundColor: 'white' }}
                  format={'DD/MM/YYYY'}
                  value={filters?.date}
                  onChange={(value, context) => {
                    if (!context.validationError) {
                      changeFilters({ date: value })
                    }
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: 'start'
                    },
                    textField: {
                      size: 'small',
                      placeholder: 'Date',
                      InputProps: {
                        ...(filters?.date && {
                          endAdornment: (
                            <IconButton
                              size="small"
                              onClick={() =>
                                changeFilters({
                                  date: null
                                })
                              }>
                              <Icons.Close fontSize="inherit" />
                            </IconButton>
                          )
                        })
                      }
                    }
                  }}
                />
              </LocalizationProvider>
              <TextField
                size={'small'}
                sx={{ bgcolor: 'white' }}
                value={filters?.reportId || ''}
                placeholder="Special Alert ID"
                onChange={(evt) =>
                  changeFilters({
                    reportId: evt.target.value
                  })
                }
              />
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Tooltip title={'User Email'}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Icons.ContactMail
                      fontSize="small"
                      sx={{ color: 'primary.main' }}
                    />
                    {!user.email ? (
                      <Chip size="small" variant="outlined" label={'Empty'} />
                    ) : (
                      <Chip
                        size="small"
                        variant="outlined"
                        color={'primary'}
                        label={user.email}
                      />
                    )}
                  </Stack>
                </Tooltip>

                {user?.isDefault && (
                  <Tooltip title={'Additional Receipients'}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Icons.PeopleOutline
                        fontSize="small"
                        sx={{ color: grey[600] }}
                      />
                      <Box>
                        {!user?.additionalEmails ||
                        user?.additionalEmails?.filter(
                          (emailAddress) => !!emailAddress
                        )?.length === 0 ? (
                          <Chip
                            size="small"
                            variant="outlined"
                            label={'Empty'}
                          />
                        ) : (
                          user?.additionalEmails?.map((email) => (
                            <Chip size="small" key={email} label={email} />
                          ))
                        )}
                      </Box>
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
              <TipIconBtn tip="Refresh" size="small" onClick={() => refresh()}>
                <Icons.Refresh fontSize="inherit" />
              </TipIconBtn>
            </Stack>
          </Stack>

          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Paper sx={{ height: '100%' }}>
              <DataGridC
                loading={loading}
                getRowId={(row) => row.reportId}
                rows={listData}
                rowCount={totalCount}
                pagination
                hideFooterSelectedRowCount
                pageSizeOptions={[15]}
                paginationModel={{
                  page: page > 0 ? page - 1 : 0,
                  pageSize: limit
                }}
                onPaginationModelChange={(params) => {
                  const isPageSizeChanged = limit !== params.pageSize
                  changePage({
                    page: isPageSizeChanged ? 1 : params.page + 1,
                    limit: params.pageSize
                  })
                }}
                columns={getColumns({
                  showSnapshot: (report) =>
                    setActionParams({ type: 'snapshot', data: report })
                })}
              />
            </Paper>
          </Box>
        </Stack>
        <EmailSnapshot
          open={Boolean(actionParams?.type === 'snapshot')}
          title={
            actionParams?.data &&
            `Special-Alert_${actionParams?.data?.reportId}`
          }
          type={ReportTypeEnum.Alert}
          reportId={actionParams?.data?.reportId}
          onClose={() => setActionParams(null)}
        />
        <AlertList
          sx={PublicAlertListStyle}
          onLocate={(treeId) => showTreeOnMap(treeId)}
        />
      </>
    )
  }
)

SpecialAlerts.displayName = 'Alerts'
export default SpecialAlerts

const getColumns: (params?: {
  showSnapshot: (row: SpecialAlert) => void
}) => GridColDef<SpecialAlert>[] = ({ showSnapshot }) => {
  return [
    {
      field: 'reportId',
      headerName: 'Special Alert ID',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell(params) {
        return (
          <Stack direction={'row'}>
            <Button color={'primary'} onClick={() => showSnapshot(params.row)}>
              {params.value}
            </Button>
            <CopyButton size="small" title="Report ID" value={params.value} />
          </Stack>
        )
      }
    },
    {
      field: 'recordedAt',
      headerName: 'Recorded At',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      field: 'totalAlertCount',
      headerName: 'No. of Total ',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'tiltingAlertCount',
      headerName: 'No. of Angle Alarms',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'vibrationCount',
      headerName: 'No. of Vibrations',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1
    }
  ]
}
