import moment from 'moment'
import { NewUser, UpdateUser, RolesEnum } from './users.types'
import { RegionEnum } from '@/configs/config.regions'

export const DefaultNewUser: NewUser = {
  username: '',
  nickname: '',
  password: '',
  organizationId: '',
  groupId: '',
  regionId: RegionEnum.HongKong,
  email: '',
  additionalEmails: [],
  phone: '',
  role: RolesEnum.User,
  disabled: false
}

export const DefaultUpdateUser: UpdateUser = {
  nickname: '',
  email: '',
  regionId: RegionEnum.HongKong,
  organizationId: '',
  groupId: '',
  additionalEmails: [],
  phone: '',
  role: RolesEnum.User,
  disabled: false
}

export const RoleNames = {
  [RolesEnum.Admin]: 'Admin',
  [RolesEnum.Manager]: 'Manager',
  [RolesEnum.User]: 'User'
}

export const RoleNamesAbbrs = {
  [RolesEnum.Admin]: 'A',
  [RolesEnum.Manager]: 'M',
  [RolesEnum.User]: 'U'
}

export const RoleNamesColors = {
  [RolesEnum.Admin]: 'error.main',
  [RolesEnum.Manager]: 'info.main',
  [RolesEnum.User]: 'default'
}

export const RoleOpitons = [
  { value: RolesEnum.Admin, name: RoleNames[RolesEnum.Admin] },
  { value: RolesEnum.Manager, name: RoleNames[RolesEnum.Manager] },
  { value: RolesEnum.User, name: RoleNames[RolesEnum.User] }
]
