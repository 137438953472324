import React, { useState } from 'react'
import RootLayout from '../../components/page/RootLayout'
import EsgHeader from './EsgHeader'
import { Stack } from '@mui/material'
import EsgTimeSum from './EsgStatusSum'
import EsgSpaciesCarbon from './species-carbon/EsgSpaciesCarbon'
import EsgData from './EsgData'

const Esg = () => {
  const [refreshFlag, setRefreshFlag] = useState(+new Date())
  return (
    <RootLayout>
      <Stack sx={{ p: 2, height: 1, overflow: 'hidden' }} spacing={2}>
        <EsgHeader onFresh={() => setRefreshFlag(+new Date())} />
        <EsgTimeSum refreshFlag={refreshFlag} sx={{ height: 120 }} />
        <EsgSpaciesCarbon refreshFlag={refreshFlag} sx={{ flex: 1 }} />
        <EsgData refreshFlag={refreshFlag} sx={{ flex: 1 }} />
      </Stack>
    </RootLayout>
  )
}

Esg.displayName = 'Esg'
export default Esg
