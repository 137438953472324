import React, { forwardRef, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  BoxProps,
  Stack,
  SxProps
} from '@mui/material'

import InfoItem from './InfoItem'
import TilingLevel from './TilingLevel'
import Voltage from './Battery'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { getDecimal } from '@/utils/tool'
import { Icons } from '@/assets/icons'
import { grey, red } from '@mui/material/colors'
import { MapTreeType } from '@/routes/map/map.types'
import TipIcon from '@/components/TipIcon'
import { AlertTypeEnum } from '@/routes/data/special-alerts/special-alerts.types'
import TreeImages from './TreeImageSmall'
import LeafiPlateStatus from './LeafiPlateStatus'
import TreeAlertStatus from './TreeAlertStatus'
import { getTreeAlerts } from '@/service/home'
import { LowBatteryThreshHold } from '../view/view.const'

export const MapPopupContainerId = 'map-popup-container'

interface MapPopupProps extends BoxProps {
  data: MapTreeType
  onClose: () => void
}

const Popup = forwardRef<typeof Box, MapPopupProps>(
  ({ data, onClose, ...props }, ref) => {
    const navigate = useNavigate()
    const [alerts, setAlerts] = useState([])
    const tiltingAlert = alerts?.find(
      (alert) => alert.type === AlertTypeEnum.Tilting
    )
    const vibrationAlert = alerts?.find(
      (alert) => alert.type === AlertTypeEnum.Vibration
    )

    const fetchTreeAlerts = async (treeId) => {
      const resp = await getTreeAlerts(treeId)
      if (resp?.code) {
        setAlerts(resp?.data)
      }
    }

    useEffect(() => {
      if (data?.treeId) {
        fetchTreeAlerts(data?.treeId)
      }
    }, [data?.treeId])

    return (
      <Box id={MapPopupContainerId} sx={{ ...popupStyles }} ref={ref}>
        <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TreeImages treeId={data?.treeId} />
            <Typography variant="h6" sx={{ verticalAlign: 'baseline' }}>
              {data?.userTreeId}
            </Typography>
            <LeafiPlateStatus treeId={data?.treeId} plateId={data?.plateId} />

            <TreeAlertStatus
              type="tilting"
              active={Boolean(tiltingAlert?.active)}
              recordedAt={tiltingAlert?.recordedAt}
            />

            <TreeAlertStatus
              type="vibration"
              active={Boolean(vibrationAlert?.active)}
              recordedAt={vibrationAlert?.recordedAt}
            />

            <TipIcon
              placement="top"
              title={`Maintenance status ${data?.maintenance ? 'ON' : 'OFF'}`}>
              <Icons.HandymanOutlined
                fontSize="inherit"
                sx={{
                  color: data?.maintenance ? red[400] : grey[300],
                  cursor: 'pointer'
                }}
              />
            </TipIcon>
          </Stack>

          <Box>
            <Tooltip title={'More'}>
              <IconButton
                onClick={() => {
                  navigate('/map', {
                    state: { navTreeId: data.treeId }
                  })
                }}>
                <Icons.MoreHoriz
                  fontSize={'large'}
                  color={'primary'}
                  style={{ fontSize: 'inherit' }}></Icons.MoreHoriz>
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>

        <Stack spacing={1}>
          {data && (
            <>
              <InfoItem name={'sensorId'} value={data.sensorId} />
              <InfoItem
                type={'number'}
                name={'tiltingAngle'}
                value={getDecimal(data.tiltingAngle, 2)}
              />
              <InfoItem
                name={'azimuth'}
                type={'number'}
                tip="The angle between the tree tilting and the north direction, measured clockwise from the north direction."
                value={getDecimal(data?.azimuth, 2)}
              />
              <InfoItem name={'species'} value={data.species} />
              <InfoItem name={'address'} value={data?.address} />
              <TilingLevel
                value={
                  data?.voltage > LowBatteryThreshHold ? data.tiltingLevel : 0
                }
              />
              <Voltage
                name={'voltage'}
                value={getDecimal(
                  data?.voltage > 0 ? data?.voltage / 1000 : data?.voltage
                )}
              />
              <InfoItem
                type={'date'}
                name={'latestRecordedAt'}
                value={
                  data?.dataRecordedAt &&
                  moment(data?.dataRecordedAt).format('YYYY-MM-DD HH:mm:ss')
                }
              />
            </>
          )}
        </Stack>
      </Box>
    )
  }
)

export default Popup

const popupStyles: SxProps = {
  position: 'relative',
  width: 480,
  height: 340,
  borderRadius: 2,
  ml: '-240px',
  mt: '-348px',
  p: 2,
  backgroundColor: 'white',

  '&:after': {
    content: '""',
    position: 'absolute',
    left: '233px',
    bottom: '-7px',
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderTop: '8px solid white',
    clear: 'both'
  }
}
