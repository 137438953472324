import { coordinate } from "openlayers"

export const FieledNames = {
  treeId: 'Tree ID',
  userTreeId: 'User Tree ID',
  sensorId: 'Sensor ID',
  vibration: 'Vibration',
  voltage: 'Battery',
  dbh: 'DBH',
  height: 'Height',
  crownSpread: 'Crown Spread',
  coordinates: 'Coordinates',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  tiltingAngle: 'Tilting Angle Δ',
  azimuth: 'Azimuth Δ',
  isInThreat: 'Tilting Angle Δ',
  tiltingLevel: 'Tilting Level',
  treeLocation: 'Location',
  species: 'Species',
  dataReceivedAt: 'Data Received At',
  mode: 'Mode',
  sendInterval: 'Send Interval',
  samplingInterval: 'Sampling Interval',
  failureRetry: 'Failure Retry',
  tiltingAngleSmp: 'Tilting Angle',
  level: 'Tilting Level',
  address: 'Location',
  latestRecordedAt: 'Data Received At'
}

export const Units = {
  height: ' m',
  dbh: ' mm',
  crownSpread: ' m',
  voltage: 'V',
  tiltingAngle: '°',
  azimuth: '°',

  sendInterval: ' min',
  samplingInterval: ' min'
}

export const ModeMap = {
  360: '1',
  104: '1',
  180: '1',
  60: '2',
  30: '3',
  15: '4'
}
